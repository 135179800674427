<div class="table-data-count sdps-m-bottom_small gutter-10">
    <ems-basic-datatable-header-icon class="sdps-pull-left" *ngIf="count > 0 && showHeaderIcons"></ems-basic-datatable-header-icon>

    <div class="table-left" [ngClass]="!showRecords ? 'selected-count-top-padding':''">
        <div class="sdps-display-inline" *ngIf="count > 0 && selectedCount">
            <span class="sdps-p-left_small">Selected: {{selectedCount}} out of {{count}}</span>
        </div>
        <div class="sdps-display-inline" *ngIf="empIds">
            <span class="sdps-p-left_small">Selected: {{empIds.subAccSelected}}/{{empIds.subAccCount}} Accounts</span>
            <span class="sdps-p-left_small">and {{empIds.empSelected}}/{{empIds.empCount}} Employees</span>
        </div>
        <div class="sdps-display-inline" *ngIf="showTotal && count">
          <span class="sdps-p-left_small">{{ ((groupTotal !== undefined && groupTotal) ? businessUnit ? sharedConst[businessUnit].groupTotalTxt : sharedConst.groupTotalTxt : sharedConst.totalTxt)+ ' ' + count}}</span>
        </div>
      <div class="sdps-display-inline" *ngIf="showTotalForInstrumentGrps">
        <span class="sdps-p-left_small">{{ sharedConst.groupTotalTxt + ' ' + count}}</span>
      </div>
    </div>
    <div class="table-right" *ngIf="showRecords">
        <div class="no-of-records">
            <span>Show</span>
            <div class="sdps-dropdown sdps-dropdown--lightGrey emtr-dropdown">
                <select class="sdps-dropdown__select sdps-dropdown--lightGrey__select" [id]="selectedDropDownVal" [(ngModel)]="selectedDropDownVal" (change)="noOfRecordsToDisplay($event.target.value)">
                    <option *ngFor="let data of dataList">{{data}}</option>
                </select>
                <span class="sdps-dropdown__icon sdps-dropdown--lightGrey__icon">
                    <span aria-hidden="true" class="sch-caret-down"></span>
                </span>
            </div>
            Records
        </div>
    </div>
    <p class="sdps-clearfix no-margin"></p>
</div>

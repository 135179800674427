import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EmpGroupFormDataService} from '../create-employee-group/emp-group-form-data/emp-group-form-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Observer, Subscription} from 'rxjs';
import {EmployeeService} from '../../services/employee-service/employee.service';
import {SharedService, CustomSortService, SharedManageEmployeeGroupComponent, ManageEmpGrpService, SharedConstant} from '@ems/shared';
import {emtrConstant} from '../../constants/emtrConstants';
import {EmtrPagination, EmtrPaginationValues} from '../../models/pagination-model';
import {EmtrService} from '../../emtr.service';

@Component({
  selector: 'tr-employee-group-list',
  templateUrl: './employee-group-list.component.html'
})
export class EmployeeGroupListComponent implements OnInit, OnDestroy {

  currentOrgSubscription: Subscription;
  private messageTxt: string;
  sharedConstant = SharedConstant;
  orgId: any;
  empGroupSaveMsg: any;
  getConstant = emtrConstant;
  tableCountData: any = {
    empCount: 0,
    subAccount: 0,
    empSelected: 0,
    subAccSelected: 0,
    totalRecords: 0,
    paginationCount: 0
  };
  updatedCountResponse = {
    data: {},
    showError: false,
    showSpinner: true
  };
  pageObject: EmtrPagination = EmtrPaginationValues;
  employees: any[] = [];
  ruleNames = '';
  resetTable: any;
  tableComponent: any;
  clientInfo: any;
  localInterval: any;
  selectedModuleType = 'EmployeeGroup';
  externalAccess = false;
  removeSuccessMsg = false;
  businessUnit: any;
  schError = {
    minCharacter: false,
    invalidCharacter: false
  };
  showActionItems = false;
  downloadTemplate = '';
  userGuideName = this.sharedConstant.userGuide.mangeEmpGroupUG.empGrpUserGuideTxt;
  ugGutTrack = '';

  @ViewChild('empGrpDeleteConfirm', { static: true }) empGrpDeleteConfirm: any;
  @ViewChild(SharedManageEmployeeGroupComponent) sharedEmpGrp: SharedManageEmployeeGroupComponent;
  @ViewChild('toastMessage') msgToast: any;

  /* eslint-disable-next-line max-len */
  constructor(public emtrService: EmtrService, private employeeService: EmployeeService, public manageEmpGrpService: ManageEmpGrpService, public formDataService: EmpGroupFormDataService, private router: Router, private r: ActivatedRoute, public sharedService: SharedService, public customSortService: CustomSortService, @Inject('entitlementConst') public entitlementConst) {
    this.getSaveEmpGrpMessage();
  }

  ngOnInit() {
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
    this.pageObject.pageNo = 0;
    this.pageObject.pageSize = 15;
    this.resetTable = true;
    let orgId = '';
    if (localStorage.getItem('client')) {
      const profile = JSON.parse(localStorage.getItem('client'));
      orgId = profile.orgId;
    }
    this.currentOrgSubscription = this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAppAccess.manageEmpGroup.uuid, '', '');
        this.orgId = clientInfo && clientInfo.orgId;
        this.businessUnit = clientInfo && clientInfo.businessUnit;
        if (this.businessUnit && this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAppAccess.employeeSummary.uuid, '', '')) {
          this.sharedService.isLoadPageOnClientChange = true;
          this.router.navigate([this.sharedConstant[this.businessUnit].toLandingPage]);
        }
        if (orgId !== this.orgId && this.orgId !== null) {
          this.formDataService.resetForm();
        }
        clientInfo.externalAccess && clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
        this.manageEmpGrpService.orgId = this.orgId;
        this.emtrService.setCurrentOrg(this.orgId);
        this.employeeService.orgId = this.orgId;
        if (!this.resetTable) {
          this.customSortService.resetTable({field: 'updTs', order: -1}, this.tableComponent);
          this.clientInfo = 1;
        }
      }
      /* for user guide download */
      if (this.businessUnit && this.businessUnit !== this.sharedConstant.rbsBUText) {
        this.downloadTemplate = this.sharedConstant.userGuide.mangeEmpGroupUG[this.businessUnit].value;
        this.ugGutTrack = this.sharedConstant.userGuide.mangeEmpGroupUG[this.businessUnit].gutTrack;
      }
      this.removeSuccessMsg = false;
    });
    if (this.empGroupSaveMsg) {
      setTimeout(() => {
        this.msgToast.pushMessage('success', this.empGroupSaveMsg);
      }, 1);
      this.removeSuccessMsg = true;
    }
  }

  getTableRef(event) {
    this.tableComponent = event;
  }

  setErrorValue(event) {
    this.schError = event;
  }

  getEmittedValue(rowData) {
    this.employeeService.getEmpGroupData(rowData.id).subscribe((empGrp: any) => {
      this.formDataService.setMode('EDIT');
      const res = empGrp.data;
      res.selectedEmpCount = {noOfEmployees: res.noOfEmployees, noOfSubAccounts: res.noOfSubAccounts};
      if (this.businessUnit) {
        this.router.navigate([this.sharedConstant[this.businessUnit].toStep3], {relativeTo: this.r});
      }
      this.formDataService.validateAllSteps();
      this.formDataService.setFormData(res);
    });
  }

  navigateToCreateEmpGroup() {
    this.sharedService.deletedGrpOrPlan = false;
    const gutTrackValue = this.businessUnit ? this.sharedConstant[this.businessUnit].addNewGutTrackValue : 'newemployeegroup';
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue(gutTrackValue);
    this.formDataService.resetForm();
    if (this.businessUnit) {
      this.router.navigate([this.sharedConstant[this.businessUnit].toStep1], {relativeTo: this.r});
    }
  }

  getSaveEmpGrpMessage() {
    this.manageEmpGrpService.getMessage().subscribe((data) => {
      this.empGroupSaveMsg = data.text ? data.text : '';
    });
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.sharedService.orgIdValue = 0;
    this.manageEmpGrpService.clearMessage();
    if (this.businessUnit && this.router.routerState.snapshot.url.indexOf(this.sharedConstant[this.businessUnit].mainRoute) === -1) {
      this.formDataService.resetForm();
      this.sharedService.stateValues.multiSortMeta = '';
      this.sharedService.stateValues.pageObj.pageSize = 15;
      this.sharedService.stateValues.filterClicked = false;
      this.sharedService.stateValues.stateManagementFor = '';
      this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
      this.sharedService.clearLocalStorage();
      this.sharedService.tableDropdownVal.next(15);
    }
  }

  fetchRuleNamesLinkedToEmpGroup(grpData) {
    const confirmObj = {
      header: `${this.getConstant.rulesConstant.ruleGlobalConst.delete} - ${grpData.groupData.name}`,
      message: `${this.getConstant.rulesConstant.rulesLanding.deleteNotAssignedMsgConfirmation} ${this.getConstant.rulesConstant.rulesLanding.deleteMsgConfirmation}`,
      acceptBtn: this.getConstant.rulesConstant.ruleGlobalConst.delete,
      rejectBtn: this.getConstant.rulesConstant.ruleGlobalConst.cancel
    };
    const confirmWithCloseObj = {
      header: `${this.getConstant.rulesConstant.ruleGlobalConst.delete} - ${grpData.groupData.name}`,
      message: `${this.sharedConstant[this.businessUnit].empGrpDeleteMsg}`,
      acceptBtn: this.getConstant.rulesConstant.ruleGlobalConst.hideAcceptButton,
      rejectBtn: this.getConstant.rulesConstant.ruleGlobalConst.close
    };
    if (grpData.groupData.noOfRules) {
      this.employeeService.deleteEmployeeGrp(grpData.groupData.id, false).subscribe((response: any) => {
        const names = response.data;
        if (names) {
          if (names.rulesInActiveStatus.length > 0 || names.rulesInDraftStatus.length > 0 || names.rulesInScheduledStatus.length > 0) {
            Object.keys(names).forEach((status) => {
              const statusValue = names[status];
              if (status !== 'rulesInExpiredStatus' && statusValue.length > 0) {
                statusValue.forEach((key) => {
                  const rulesLinked = key.name;
                  this.ruleNames = this.ruleNames.concat('<li>' + rulesLinked + '</li>');
                });
                confirmWithCloseObj.message = `<strong>${grpData.groupData.name}</strong> ${this.sharedConstant[this.businessUnit].empGrpDeleteMsg} <ul class='sdps-list--ordered sdps-m-vertical_medium show-scrollable-list'>${this.ruleNames}</ul>`;
              }
            });
          } else {
            confirmObj.message = `<strong>${grpData.groupData.name}</strong> ${this.getConstant.rulesConstant.rulesLanding.deleteNotAssignedMsgConfirmation} ${this.getConstant.rulesConstant.rulesLanding.deleteMsgConfirmation}`;
          }
          const obj = this.ruleNames.length > 0 ? confirmWithCloseObj : confirmObj;
          this.deleteEmployeeGroup(obj, grpData);
        }
      });
    } else {
      confirmObj.message = `<strong>${grpData.groupData.name}</strong> ${this.getConstant.rulesConstant.rulesLanding.deleteNotAssignedMsgConfirmation} ${this.getConstant.rulesConstant.rulesLanding.deleteMsgConfirmation}`;
      this.deleteEmployeeGroup(confirmObj, grpData);
    }
  }

  deleteEmployeeGroup(confirmObj, grpData) {
    this.removeSuccessMsg = true;
    Observable.create((observer: Observer<boolean>) => {
      this.ruleNames = '';
      this.empGrpDeleteConfirm.showConfirmMsg(confirmObj, observer);
    }).subscribe(accept => {
      if (accept) {
        this.employeeService.deleteEmployeeGrp(grpData.groupData.id, true).subscribe(data => {
          this.messageTxt = '<strong>' + grpData.groupData.name + '</strong>&nbsp;' + this.sharedConstant[this.businessUnit].deleteEmpGroupOrPlanSuccessMsg;
          this.msgToast.pushMessage('success', this.messageTxt);
          const param = {
            paramObj: grpData.paramObj,
            filterObj: grpData.filterObj
          };
          this.getEmpGrpRecords(param);
        });
      }
    });
  }

  restoreEmployeeGroup(grpData) {
    grpData.active = true;
    this.removeSuccessMsg = true;
    this.manageEmpGrpService.saveEmpGroup(grpData).subscribe(res => {
      this.messageTxt = '<strong>' + grpData.groupData.name + '</strong>&nbsp;' + this.sharedConstant[this.businessUnit].restoreEmpGroupOrPlanSuccessMsg;
      this.msgToast.pushMessage('success', this.messageTxt);
      const param = {
        paramObj: grpData.paramObj,
        filterObj: grpData.filterObj
      };
      this.getEmpGrpRecords(param);
    });
  }

  getEmpGrpRecords(param) {
    const localFormData = this.formDataService.getFormData();
    if (localFormData.id !== '') {
      this.updatedCountResponse.showSpinner = true;
    }
    if (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '')) {
      param.filterObj.orgId = this.orgId;
      this.sharedService.stateValues.filterObj = param.filterObj;
      this.sharedService.stateValues.pageObj.pageNo = param.paramObj.pageNo;
      this.sharedService.stateValues.pageObj.pageSize = param.paramObj.pageSize;
      this.sharedService.stateValues.stateManagementFor = 'employeeGroup';
      this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
      this.sharedService.tableDropdownVal.next(param.paramObj.pageSize);
      this.pageObject.pageNo = param.paramObj.pageNo;
      this.pageObject.pageSize = param.paramObj.pageSize;
      this.manageEmpGrpService.getEmployeeGrpOrPlanList(param.paramObj, param.filterObj).subscribe((response) => {
        this.employees = response.data.content;
        if (this.employees.length > 0) {
          if (this.tableCountData.empCount === 0) {
            this.tableCountData.empCount = this.employees[0].noOfEmployees;
            this.tableCountData.subAccCount = this.employees[0].noOfSubAccounts;
            this.manageEmpGrpService.empCount = this.employees[0].noOfEmployees;
            this.manageEmpGrpService.empAccCount = this.employees[0].noOfSubAccounts;
          }
          this.tableCountData.totalRecords = this.sharedService.totalElementsWithCommas(response.data.totalElements);
          this.tableCountData.paginationCount = response.data.totalElements;
          this.sharedService.stateValues.totalElem = this.tableCountData.paginationCount;
          this.resetTable = false;
          return this.employees;
        } else {
          this.employees = [];
          this.tableCountData = {};
          return this.employees;
        }
      }, error => {
        this.employees = [];
        this.tableCountData = {};
        return this.employees;
      });
    }
  }

  paginateEmployeeList($event) {
    if (this.orgId) {
      this.sharedService.clearErrorMessage();
      $event.filterObj.orgId = this.orgId;
      if (this.sharedService.orgIdValue !== 0) {
        this.sharedService.orgIdValue = 0;
        this.getEmpGrpRecords($event);
      }
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'addlink-expanded-row',
  templateUrl: './expanded-row.component.html',
  styleUrls: ['./expanded-row.component.scss'],
})
export class ExpandedRowComponent implements OnInit {

  @Input() request: any;
  @Input() expandedRowColumns: any[] = [];
  @Input() isHidden: boolean;

  public filteredRequest: any[];

  constructor() { }

  ngOnInit() {
    this.filterRequest();
  }

  filterRequest(): void {
    this.filteredRequest = [];
    for (const expandedRowColumn of this.expandedRowColumns) {
      /* If source is comming from client central only display */
      if (expandedRowColumn.field === 'acctHolderName') {

        const acctOwnerFirstName = this.request.accountOwnerFirstName !== '' ? ', ' + this.request.accountOwnerFirstName : '';

        let fieldVal = this.request.accountOwnerLastName + acctOwnerFirstName;
        if (this.request.source !== null && this.request.source === 'Client Central') {
          fieldVal = this.request.accountOwnerLastName;
        }
        this.filteredRequest.push({
          field: fieldVal,
          header: expandedRowColumn.header,
        });
      } else if (expandedRowColumn.field === 'authAgentName') {
        if (this.request.authAgent && this.request.authAgent !== null) {
          this.filteredRequest.push({
            field: this.request.authAgent.authAgentLastName + ', ' + this.request.authAgent.authAgentFirstName,
            header: expandedRowColumn.header,
          });
        } else {
          this.filteredRequest.push({
            field: '',
            header: expandedRowColumn.header,
          });
        }
      } else if (expandedRowColumn.field === 'assignedTo') {

        let assignedToLastName = this.request.assignedToLastName;
        let assignedToFirstName = this.request.assignedToFirstName;

        if (assignedToLastName === null || assignedToLastName === 'null') {
          assignedToLastName = '';
        }
        if (assignedToFirstName === null || assignedToFirstName === 'null') {
          assignedToFirstName = '';
        } else {
          assignedToFirstName = ', ' + assignedToFirstName;
        }

        this.filteredRequest.push({
          field: assignedToLastName + assignedToFirstName,
          header: expandedRowColumn.header,
        });
      } else if (expandedRowColumn.field === 'accountHolderAffiliation') {
        let affiliation = this.request.accountHolderAffiliation;
        if (affiliation === null || affiliation === 'null') {
          affiliation = '';
        }
        this.filteredRequest.push({
          field: affiliation,
          header: expandedRowColumn.header,
        });
      } else if (expandedRowColumn.field === 'completedBy') {
        let completedBy = this.request.completedBy;
        if (completedBy === null || completedBy === 'null') {
          completedBy = '';
        }
        this.filteredRequest.push({
          field: completedBy,
          header: expandedRowColumn.header,
        });
      } else if (expandedRowColumn.field === 'completedDate') {
        let completedDate = this.request.completedDate;
        if (completedDate === null || completedDate === 'null') {
          completedDate = '';
        }
        this.filteredRequest.push({
          field: completedDate,
          header: expandedRowColumn.header,
        });
      } else {
        this.filteredRequest.push({
          field: this.request[expandedRowColumn.field],
          header: expandedRowColumn.header
        });
      }
    }
  }
}


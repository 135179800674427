import {Directive, EventEmitter, Inject, Input, Output} from '@angular/core';
import {NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[emsCharacterLimit]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: CharacterLimitDirective, multi: true}
  ]
})
export class CharacterLimitDirective implements Validator {

  constructor(@Inject('commonConstants') public commonConstants) {
  }

  calValue: any;
  @Input() charMaxLimit;
  @Output() emsCharacterLimitCheck = new EventEmitter<boolean>();

  validate() {
    this.calValue = this.charMaxLimit - (this.charMaxLimit / this.commonConstants.charLimitValues.tenPercent);
    this.emsCharacterLimitCheck.emit(this.calValue);
    return null;
  }
}

import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {SharedConstant, SharedService} from '@ems/shared';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'tr-manage-employees',
  templateUrl: './manage-employees.component.html'
})
export class ManageEmployeesComponent implements OnInit, OnDestroy {
  businessUnit: any;
  sharedConstant = SharedConstant;
  currentOrgSubscription: Subscription;
  hasEmpSummaryAccess = true;

  constructor(public sharedService: SharedService, public router: Router, @Inject('entitlementConst') public entitlementConst) {
  }

  ngOnInit() {
    /* set value to parentPage during navigation */
    this.currentOrgSubscription = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.hasEmpSummaryAccess = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAppAccess.employeeSummary.uuid, '', '');
        this.businessUnit = clientInfo && clientInfo.businessUnit;
        if (this.hasEmpSummaryAccess && !this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAppAccess.manageEmployee.uuid, '', '')) {
          this.sharedService.isLoadPageOnClientChange = true;
          this.router.navigate([this.sharedConstant[this.businessUnit].toEmpParticipantLandingPage]);
        }
      }
      this.sharedService.parentPage = this.businessUnit ? this.sharedConstant[this.businessUnit].toEmpParticipantLandingPage : '/ems/emtr/manage-employees';
    });
  }

  ngOnDestroy() {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
  }

}

import {
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MessageService} from 'primeng/api'
import {Paginator} from 'primeng/paginator';
import {Observable, Observer, Subscription} from 'rxjs';
import {SharedConstant} from '../../constants/sharedConstants';
import {EmployeeDetails} from '../../models/EmployeeDetails';
import {empOrParticipantTableHeader, ManageEmpOrParticipantHeader} from '../../models/manage-employees-table-header';
import {SharedService} from '../../shared.service';
import * as temp from 'moment';
const moment = temp['default'];
import {ManageEmployeeDataService} from '../../services/manage-employee/manage-employee-data.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AccountNumberValidationService} from '../../services/accountNumberValidation/account-number-validation.service';
import {ManageEmployeeOrParticpantListActionMenuItems} from '../../models/manage-employee-action-menu';
import { CustomSortService } from '../../services/custom-sort/custom-sort.service';

@Component({
  selector: 'ems-manage-employees',
  templateUrl: './shared-manage-employees.component.html',
  styleUrls: ['./shared-manage-employees.component.scss']
})

export class SharedManageEmployeesComponent implements OnInit, OnChanges, OnDestroy {
  constructor(public sharedService: SharedService, private el: ElementRef, public manageEmployeeDataService: ManageEmployeeDataService, @Inject('entitlementConst') public entitlementConst, public fb: UntypedFormBuilder,
              public accountNumberValidationService: AccountNumberValidationService,
              public customSortService: CustomSortService, public messageService: MessageService) {
    this.sharedService.setHistoryResponse.subscribe(value => {
      this.empHistory.empHistoryData = value.data.content;
      this.empHistory.historyRecords = value.data.totalElements;
      this.empHistory.historyMessage = value.message;
    });
  }

  empHistory = {
    employeeId: '',
    historyId: '',
    empSubType: '',
    empHistoryData: '',
    historyRecords: '',
    historyMessage: '',
    empName: ''
  };
  landingPageMsg: any;
  currentOrgSubscription: Subscription;
  getOrgId: any;
  @Input() clientName: string;
  @Input() businessUnit: string;
  @Input() showColumns: any = true;
  @Input() assignFormData: any;
  @Input() empAccformData: any;
  @Output() selectedEmployeeAccounts: any = new EventEmitter();
  @Output() rowSelected: any = new EventEmitter();
  @Output() selectedAccounts: any = new EventEmitter();
  @Output() employeeAccounts: any = new EventEmitter();
  @ViewChild('p', { static: true }) paginator: Paginator;
  @Output() editEvent = new EventEmitter<EmployeeDetails>();
  @ViewChild('viewHistory', { static: true }) viewHistoryData: any;
  @ViewChild('toastMessage') msgToast: any;
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @Output() emitData = new EventEmitter();

  employees: any[];
  totalRecords: any;
  noOfRecords: any = 15;
  selectedArr: any = [];
  pageVal: any = 1;
  sortData: any;
  empIds: any[] = [];
  getSharedConstant = SharedConstant;
  public empAccntTblHeader: ManageEmpOrParticipantHeader = empOrParticipantTableHeader;
  currentParentNode: any;
  empAccTableCols: any[];
  subAccntNumbers: any[] = [];
  cancelButton = true;
  chkCompanyGrp: any;
  child = 0;
  parent = 0;
  moment = moment;
  bulkUploadClicked = false;
  sortField: any;
  pageNo: any = 1;
  param = {
    orgId: '',
    pageNumber: 1,
    pageSize: 1,
    sortField: ''
  };

  toggle = false;
  errMsg = [];
  filterClicked = false;
  filterBtnDisableForFN = false;
  filterBtnDisableForLN = false;
  filterBtnDisableForEmpId = false;
  filterBtnDisableForacctHolderFN = false;
  filterBtnDisableForacctHolderLN = false;
  invalidEmpId = false;
  invalidFN = false;
  invalidLN = false;
  invalidacctHolderFN = false;
  invalidacctHolderLN = false;
  manageEmpForm: UntypedFormGroup;
  isAccNumInvalid = false;
  schErrorMsg: string;
  showSchError = false;
  subAcctNum: any;
  empId: any;
  showActionMenu: boolean;
  actionMenu: any[];
  empHistoryData: any;
  deleteMsg: any;
  showToast = false;
  externalAccess = false;
  hasEMTRAccess = false;
  showActionItems = false;
  multiSortMeta: any = [];
  hasGrpOrPlanVal = '';
  downloadTemplate = '';
  userGuideName = this.getSharedConstant.userGuide.manageEmpGU.manageEmpUserGuideTxt;
  ugGutTrackValue = 'adobe-dl_manageemployeesuserguide|fireonce';
  filterGutTrackValue = 'adobe-dl_manageemployeesuserguide|fireonce';
  isSortingChanged = false;
  defaultSortDataField = {field: 'updTs', order: -1};
  initialLoad = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedArr = [];
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    /* Clear selectedGroupAndPlanNameInEmpGroup value when moving out of manage employees/participants page */
    this.sharedService.selectedGroupAndPlanNameInEmpGroup = '';
  }

  paramObj(pageNo, records, sortCol) {
    return this.param = {
      orgId: this.getOrgId,
      pageNumber: pageNo,
      pageSize: records,
      sortField: sortCol
    };
  }

  ngOnInit() {
    this.manageEmpForm = this.fb.group({
      empId: [''],
      lastName: [''],
      firstName: [''],
      acctHolderLn: [''],
      acctHolderFn: [''],
      subAcctNum: [''],
      status: [''],
      ruleIds: [''],
      updTs: ['']
    });
    this.empIds = [];
    this.subAccntNumbers = [];
    const pageNo = 1;
    const sortField = '';
    const noOfRecords = this.noOfRecords;
    this.multiSortMeta.push(this.defaultSortDataField);
    this.customSortService.sortArr = [];

    this.currentOrgSubscription = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAppAccess.manageEmployee.uuid, '', '');
        this.hasEMTRAccess = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '');
        this.getOrgId = clientInfo.orgId;
        this.empAccTableCols = this.empAccntTblHeader[clientInfo.businessUnit];
        clientInfo.externalAccess && clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
        this.toggle = false;
        this.multiSortMeta = [this.defaultSortDataField];
        this.messageService.clear();
        this.resetForm();
        /* Clear selectedGroupAndPlanNameInEmpGroup value when ever the client is changed */
        if (this.sharedService.isClientChanged) {
          this.sharedService.selectedGroupAndPlanNameInEmpGroup = '';
        }
        this.sharedService.isClientChanged = false;
        if (this.sharedService.selectedGroupAndPlanNameInEmpGroup) {
          this.toggleFunction();
        }
        // if (this.sortField !== undefined) {
        //   console.log("In ngOnInit");
        //   this.getEmpAccntResponse(this.paramObj(pageNo, noOfRecords, this.sortField), true);
        // }
        /* for user guide download */
        if (clientInfo.businessUnit) {
          this.downloadTemplate = this.getSharedConstant.userGuide.manageEmpGU[clientInfo.businessUnit].value;
          this.filterGutTrackValue = this.getSharedConstant[clientInfo.businessUnit].empParticipantFilterGutTrackValue;
        }
      }
    });
    this.onChange();
    this.sharedService.schErrorMsg.subscribe(value => {
      if (value.length > 0) {
        this.showSchError = true;
        this.schErrorMsg = value;
      } else {
        this.showSchError = false;
      }
    });
    this.getSuccessMessage();
  }

  getEmpAccntResponse(param, initialLoad) {
    const localUpdatedFormValues = this.manageEmpForm.getRawValue();
    const filterObj: any = {
      orgId: this.getOrgId,
      empId: localUpdatedFormValues.empId ? localUpdatedFormValues.empId : null,
      accountNum: localUpdatedFormValues.subAcctNum ? localUpdatedFormValues.subAcctNum : null,
      firstName: localUpdatedFormValues.firstName ? localUpdatedFormValues.firstName.trim().toUpperCase() : null,
      lastName: localUpdatedFormValues.lastName ? localUpdatedFormValues.lastName.trim().toUpperCase() : null,
      accountHolderFN: localUpdatedFormValues.acctHolderFn ? localUpdatedFormValues.acctHolderFn.trim().toUpperCase() : null,
      accountHolderLN: localUpdatedFormValues.acctHolderLn ? localUpdatedFormValues.acctHolderLn.trim().toUpperCase() : null,
      groupName: this.hasGrpOrPlanVal ? this.hasGrpOrPlanVal : 'All'
    };

    const defaultObj: any = {
      orgId: this.getOrgId,
    };

    if (this.hasEMTRAccess && this.getOrgId !== undefined && this.getOrgId !== 0) {
      if (this.filterClicked) {
        this.sharedService.getEmployeeList(param, filterObj, null, null).subscribe((response) => {
          this.setEmployeeAccntListData(response, initialLoad, filterObj);
        });
      } else {
        if (!this.sharedService.selectedGroupAndPlanNameInEmpGroup) {
          this.sharedService.getEmployeeList(param, defaultObj, null, null).subscribe((response) => {
            this.setEmployeeAccntListData(response, initialLoad, defaultObj);
          });
        }
      }
    }
  }

  setEmployeeAccntListData(response, initialLoad, dataObj) {
    this.sharedService.clearErrorMessage();
    this.employees = response.data.content;
    if (response.data) {
      this.populateData(this.employees);
      this.totalRecords = response.data.totalElements;
      if (this.empAccformData && initialLoad && this.assignFormData.employeeAccounts.length > 0) {
        this.subAccntNumbers = this.assignFormData.subAccntNumbers;
        this.empIds = this.assignFormData.empIds;
        /* eslint-disable-next-line max-len */
        this.selectedArr = (this.assignFormData.empIds && !this.assignFormData.empIds.length && this.assignFormData.subAccntNumbers &&
          !this.assignFormData.subAccntNumbers.length) ?
          this.populateSelectedArr(this.formatJson(this.empAccformData, ''), response.data.totalElements, dataObj) : this.empAccformData;
        this.getSelectedCount(this.selectedArr);
      }
    }
  }

  populateSelectedArr(selectedArr, totalEles, dataObj) {
    let selectedEmpAccounts = [];
    const pageNo = 1;
    const pageSize = totalEles !== 0 ? totalEles : 15;
    const sortField = '';
    if (this.hasEMTRAccess) {
      this.sharedService.getEmployeeList(this.paramObj(pageNo, pageSize, sortField), dataObj, null, null).subscribe(response => {
        const employees = this.formatJson(response.data.content, '');
        const data = this.sharedService.populateEmpAccounts(employees, selectedArr, selectedEmpAccounts, this.empIds, this.subAccntNumbers);
        selectedEmpAccounts = data.selectedArr;
        this.empIds = data.empIds;
        this.subAccntNumbers = data.subAccntNumbers;
        const emitData = {
          empIds: this.empIds,
          subAccntNumbers: this.subAccntNumbers,
          selectedArr: selectedEmpAccounts
        };
        this.assignFormData.employeeAccounts = selectedEmpAccounts;
        this.selectedArr = selectedEmpAccounts;
        this.getSelectedCount(this.selectedArr);
        this.employeeAccounts.emit(emitData);
      });
    }
  }

  populateData(employees) {
    this.employees = this.formatJson(employees, '');
    this.employees = this.employees.filter((element) => {
      return element !== undefined;
    });
    setTimeout(() => {
      this.checkParentEmpId('');
    }, 500);
    return this.employees;
  }

  receiveDataFromChild(e) {
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    this.noOfRecords = Number(e);
    this.paginator.changePage(0);
    this.messageService.clear();
    this.getEmpAccntResponse(this.paramObj(this.pageNo, this.noOfRecords, this.sortField), false);
  }

  customSort(event) {
    if (event.multiSortMeta) {
      this.paginator.changePage(0);
      if (this.sortField !== this.customSortService.customSort(event)) {
        this.sortField = this.customSortService.customSort(event);
        this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
        const noOfRecords = this.noOfRecords;
        if (this.sharedService.empListMultiSortMeta !== event.multiSortMeta && !this.initialLoad) {
          this.sharedService.empListMultiSortMeta = event.multiSortMeta;
          this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
          this.initialLoad = true;
        } else if (this.initialLoad && this.sharedService.empListMultiSortMeta !== event.multiSortMeta) {
          this.messageService.clear();
          this.sharedService.empListMultiSortMeta = event.multiSortMeta;
          this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
        }
      }
    }
  }

  paginate(e) {
    if (this.pageNo !== e.page + 1) {
      this.messageService.clear();
      this.pageNo = e.page + 1;
      this.sortField = this.param.sortField;
      const noOfRecords = this.noOfRecords;
      this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
    }
  }

  formatJson(data, type) {
    return data.map((dataEle, i) => {
      if (dataEle !== null && dataEle !== undefined) {
        const children = dataEle.accounts;
        delete dataEle.accounts;
        if (type) {
          dataEle.tempEmpId = dataEle.empId;
          dataEle.empId = dataEle.empId + dataEle.subAcctNum;
        } else {
          if (dataEle.empId === null) {
            dataEle.empId = i + 'null';
          }
        }
        const obj: object = {
          data: dataEle,
        };
        const childrenTxt = 'children';
        const expandTxt = 'expanded';
        if (children) {
          obj[childrenTxt] = this.formatJson(children, 'children');
          obj[expandTxt] = true;
        }
        return obj;
      }
    });
  }

  isParentNode(rowNode) {
    if (!rowNode.level) {
      this.currentParentNode = rowNode;
      return false;
    }
    return true;
  }

  mapResponse(empData) {
    let c = 0;
    return empData.map((data) => {
      if (data !== undefined) {
        c = c + data.children.length;
        return c;
      }
    });
  }

  countForSelectedArr(arr) {
    const localEmpId = [];
    let partialSelected;
    arr.forEach(data => {
      if (data.parent) {
        /* Edit Scenario partialSelected will be undefined so setting it to true */
        partialSelected = (data.parent.partialSelected && data.parent.partialSelected === true) ? data.parent.partialSelected : false;
        if (partialSelected && data.parent.data.empId !== null && localEmpId.indexOf(data.parent.data.empId) === -1) {
          this.parent++;
          localEmpId.push(data.parent.data.empId);
        } else if (data.parent.data.empId.indexOf('null') !== -1) {
          this.parent++;
        }
        this.child++;
      } else {
        if (data.data.empId.indexOf('null') !== -1 && !partialSelected) {
          this.parent++;
        }
      }
    });
  }

  removeDuplicateEmpId(empId) {
    return this.empIds.indexOf(empId) === -1 ? this.empIds.push(empId) : this.empIds.splice(this.empIds.indexOf(empId), 1);
  }

  removeDuplicateAcc(childNodes, remove) {
    if (remove) {
      return childNodes.map(data =>
        (this.subAccntNumbers.indexOf(data.data.subAcctNum) === -1) ? this.subAccntNumbers.splice(
          this.subAccntNumbers.indexOf(data.data.subAcctNum), 1) : ''
      );
    } else {
      return childNodes.map(data => this.subAccntNumbers.indexOf(data.data.subAcctNum) === -1 ?
        this.subAccntNumbers.push(data.data.subAcctNum) :
        this.subAccntNumbers.splice(this.subAccntNumbers.indexOf(data.data.subAcctNum), 1));
    }
  }

  removeDuplicatesubAcc(subAcc) {
    return this.subAccntNumbers.indexOf(subAcc) === -1 ? this.subAccntNumbers.push(subAcc) :
      this.subAccntNumbers.splice(this.subAccntNumbers.indexOf(subAcc), 1);
  }

  getSelectedEmpAccounts(e, selectedVal, el) {
    this.showParentCheckboxForEmptyRuleId(el);
    this.el.nativeElement.querySelector('.pi-minus') ?
      this.el.nativeElement.querySelector('.pi-minus').classList.remove('pi-minus') :
      this.el.nativeElement.querySelector('.pi-minus');
    this.empIds = [];
    this.subAccntNumbers = [];
    let wholeArray = this.selectedArr;
    wholeArray.forEach(val => {
      if (val.children && val.data && val.data.empId.indexOf('null') === -1) {
        this.removeDuplicateEmpId(val.data.empId);
        val.children.forEach(record => {
          wholeArray = wholeArray.filter(item => item.data.empId !== record.data.empId);
        });
      }
    });

    wholeArray.forEach(val => {
      if (val.parent && val.parent !== null) {
        this.removeDuplicatesubAcc(val.data.subAcctNum);
      }
    });
    this.getSelectedCount(this.selectedArr);
    this.selectedEmployeeAccounts.emit(this.selectedArr);
    this.rowSelected.emit();
  }

  /* Handling the empAccounts Selection/UnSelection Functionality */
  showParentCheckboxForEmptyRuleId(el) {
    /* Removing minus */
    const wholeArray = this.el.nativeElement.querySelectorAll('.pi-minus');
    wholeArray.forEach(element => {
      element.classList.remove('pi-minus');
    });

    const parentNode = el.target.parentNode.offsetParent.parentElement;

    if (el.target.matches('.p-highlight')) {
      this.checkParentEmpId('');
    } else {
      if (!el.target.parentElement.classList.contains('p-state-disabled')) {
        parentNode.querySelector('.p-checkbox-icon').classList.remove('pi-check');
      }
    }
  }

  /* Handling the parent empty empId checkbox checked while loading */
  checkParentEmpId(data) {
    const wholeArray = this.el.nativeElement.querySelectorAll('tr');
    wholeArray.forEach(element => {
      if (element.querySelector('.p-highlight') && element.querySelector('.check-disabled')) {
        const entireCheckedArray = element.querySelector('.p-checkbox-icon');
        data ? entireCheckedArray.classList.remove('pi-check') : entireCheckedArray.classList.add('pi-check');
      }
    });
    this.sharedService.removeEmptyCheckbox(this.el, 'employees');
  }

  getSelectedCount(selectedArr) {
    this.parent = 0;
    this.child = 0;
    if (selectedArr) {
      this.countForSelectedArr(selectedArr);
    }

    const emitData = {
      empIds: this.empIds,
      subAccntNumbers: this.subAccntNumbers,
      selectedArr: this.selectedArr,
    };
    this.selectedAccounts.emit(emitData);
    this.employeeAccounts.emit(emitData);
  }

  addOrEditEmp(empData: EmployeeDetails, empId: any, param) {
    /* created deep copy of rowdata for bug #15232*/
    const empDataClone = JSON.parse(JSON.stringify(empData));
    if (empId.indexOf(null) === -1) {
      empDataClone.empId = empId;
    } else {
      empDataClone.empId = '';
    }
    this.sharedService.addOrEditEmployee = param;
    this.editEvent.emit(empDataClone);
  }

  updateEmployeeList(param) {
    param.sortField = '';
    this.paginator.changePage(0);
    this.getEmpAccntResponse(this.paramObj(param.pageNumber, param.pageSize, param.sortField), false);
  }

  uploadEmployeeList() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked =  true;
    this.sharedService.loadDataTrackValue('manageemployees_bulkupload');
    this.cancelButton = false;
    this.sharedService.parentPage = '/ems/emtr/manage-employees';
    this.bulkUploadClicked = true;
  }

  loadEmpHistory(ruleData) {
    this.empHistory.historyId = ruleData.subAcctNum;
    this.empHistory.employeeId = ruleData.tempEmpId;
    this.empHistory.empSubType = ruleData.type;
    this.empHistoryData = ruleData;
    this.empHistory.empName = this.getSharedConstant[this.businessUnit].employeeOrParticipantHistory + ' - ' + ruleData.acctHolderLn + ' ' + ruleData.acctHolderFn;
    this.sharedService.loadViewHistory(this.getOrgId, ruleData, 'empaccount', this.viewHistoryData);
  }

  getHistoryPagination(subAcctNum, data) {
    this.sharedService.getHistoryPaginatedValues(this.empHistoryData, 'empaccount', data);
  }

  onChange() {
    this.manageEmpForm.get('subAcctNum').valueChanges.subscribe(value => {
      /* check Account Number contains 8 digits */
      this.isAccNumInvalid = this.accountNumberValidationService.validateAccountNumber(value);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    /* Validate the field and set the error message */
    this.manageEmpForm.get('empId').valueChanges.subscribe(value => {
      this.filterBtnDisableForEmpId = this.sharedService.validationFields.filterBtnDisableForEmpId = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidEmpId = this.sharedService.validationFields.invalidEmpId = this.sharedService.isValidOrInvalid(value, 'empId', this.manageEmpForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.manageEmpForm.get('firstName').valueChanges.subscribe(value => {
      this.filterBtnDisableForFN = this.sharedService.validationFields.filterBtnDisableForFN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidFN = this.sharedService.validationFields.invalidFN = this.sharedService.isValidOrInvalid(value, 'firstName', this.manageEmpForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.manageEmpForm.get('lastName').valueChanges.subscribe(value => {
      this.filterBtnDisableForLN = this.sharedService.validationFields.filterBtnDisableForLN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidLN = this.sharedService.validationFields.invalidLN = this.sharedService.isValidOrInvalid(value, 'lastName', this.manageEmpForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.manageEmpForm.get('acctHolderFn').valueChanges.subscribe(value => {
      this.filterBtnDisableForacctHolderFN = this.sharedService.validationFields.filterBtnDisableForacctHolderFN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidacctHolderFN = this.sharedService.validationFields.invalidacctHolderFN = this.sharedService.isValidOrInvalid(value, 'acctHolderFn', this.manageEmpForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.manageEmpForm.get('acctHolderLn').valueChanges.subscribe(value => {
      this.filterBtnDisableForacctHolderLN = this.sharedService.validationFields.filterBtnDisableForacctHolderLN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidacctHolderLN = this.sharedService.validationFields.invalidacctHolderLN = this.sharedService.isValidOrInvalid(value, 'acctHolderLn', this.manageEmpForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });
  }

  toggleFunction() {
    this.toggle = !this.toggle;
    this.manageEmployeeDataService.filterToggleClicked = this.toggle;
  }

  clickedClearButton(resetSortFromUI?) {
    this.resetForm(resetSortFromUI);
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    const noOfRecords = this.noOfRecords;
    this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
  }

  resetForm(resetSortFromUI?) {
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
    }
    this.manageEmployeeDataService.clearClicked.emit(true);
    this.manageEmpForm.reset();
    this.paginator.changePage(0);
    this.filterClicked = false;
    this.isAccNumInvalid = false;
    this.sharedService.schErrorMsg.emit('');
  }

  applyFilter() {
    this.paginator.changePage(0);
    this.filterClicked = true;
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    const noOfRecords = this.noOfRecords;
    this.messageService.clear();
    this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
  }


  deleteEmployee(ruleData) {
    const confirmObj = {
      header: `${this.getSharedConstant[this.businessUnit].removeEmployeeOrParticipant} - ${this.getSharedConstant[this.businessUnit].empOrParticipantsID}: ${ruleData.tempEmpId}`,
      /* eslint-disable-next-line max-len */
      message: `${this.getSharedConstant[this.businessUnit].removeEmployeeOrParticipantAlert}<br><strong>Account Number: </strong>${ruleData.subAcctNum}<br><strong>First Name: </strong>${ruleData.firstName}<br><strong>Last Name: </strong>${ruleData.lastName}<br>`,
      acceptBtn: this.getSharedConstant.deleteModal.yesTxt,
      rejectBtn: this.getSharedConstant.deleteModal.noTxt
    };
    const deleteFormData = {
      orgId: this.getOrgId,
      empId: ruleData.tempEmpId,
      subAccountNumber: ruleData.subAcctNum,
      forceDelete: true
    };
    Observable.create((observer: Observer<boolean>) => {
      this.confirmationMessage.showConfirmMsg(confirmObj, observer);
    }).subscribe(accept => {
      if (accept) {
        this.showToast = false;
        this.sharedService.errorMessage.next(false);
        this.sharedService.deleteEmpAccount(deleteFormData).subscribe(data => {
            this.showToast = true;
            if (this.businessUnit !== 'RBS') {
              /* eslint-disable-next-line max-len */
              this.deleteMsg = '<strong>' + 'Employee ID: ' + ruleData.tempEmpId + '</strong> <strong>' + 'Account Number: ' + ruleData.subAcctNum + '</strong> - <strong>' + this.getSharedConstant.deleteModal.employeeTxt + '&nbsp;' + ruleData.lastName + ',' + ruleData.firstName + '</strong>&nbsp;' + this.getSharedConstant.deleteModal.deleteSuccessMsg;
            } else {
              /* eslint-disable-next-line max-len */
              this.deleteMsg = '<strong>' + 'Participant ID: ' + ruleData.tempEmpId + '</strong> <strong>' + 'Account Number: ' + ruleData.subAcctNum + '</strong> - <strong>' + this.getSharedConstant.deleteModal.participantTxt + '&nbsp;' + ruleData.lastName + ',' + ruleData.firstName + '</strong>&nbsp;' + this.getSharedConstant.deleteModal.deleteSuccessMsg;
            }
            this.msgToast.pushMessage('success', this.deleteMsg);
            this.emitData.emit();
          },
          (err) => {
            if (err.status === 400) {
              this.sharedService.clearErrorMessage();
              const failurMessage = err.error.message;
              this.msgToast.pushMessage('error', failurMessage);
            }
          });
      }
    });
  }


  formData(rowData) {
    this.actionMenu = ManageEmployeeOrParticpantListActionMenuItems[this.businessUnit].filter(item => item.label);
    if (rowData.empId.indexOf('null') !== -1) {
      this.removeMenuItem(this.getSharedConstant[this.businessUnit].addEmployeeOrParticipant);
      this.removeMenuItem(this.getSharedConstant[this.businessUnit].removeEmployeeOrParticipant);
    }

    if (!this.showActionItems) {
      this.removeMenuItem(this.getSharedConstant[this.businessUnit].addEmployeeOrParticipant);
      this.removeMenuItem('Edit');
      this.removeMenuItem(this.getSharedConstant[this.businessUnit].removeEmployeeOrParticipant);
    }
    return this.actionMenu;
  }

  removeMenuItem(removeItem) {
    const index = this.actionMenu.findIndex(x => x.label === removeItem);
    if (index !== -1) {
      this.actionMenu.splice(index, 1);
    }
  }

  @HostListener('document:click', [])
  onClick() { /* Capture the Click Event outside the function */
    if (!this.showActionMenu) {
      this.empId = '';
      this.subAcctNum = '';
    }
    this.showActionMenu = false;
  }

  onActionMenuClick(event: any, ruleData: any, rowNode: any) {
    const actionItem = event.target.parentElement.innerText;
    this.messageService.clear();
    this.sharedService.clearErrorMessage();
    if (actionItem === 'Edit') {
      this.addOrEditEmp(ruleData, rowNode.parent.data.empId, 'editEmployee');
    } else if (actionItem === 'History') {
      this.loadEmpHistory(ruleData);
    } else if (actionItem === this.getSharedConstant[this.businessUnit].addEmployeeOrParticipant) {
      this.addOrEditEmp(ruleData, rowNode.parent.data.empId, 'addEployee');
    } else if (actionItem === this.getSharedConstant[this.businessUnit].removeEmployeeOrParticipant) {
      this.deleteEmployee(ruleData);
    }
  }

  getSuccessMessage() {
    this.manageEmployeeDataService.getMessage().subscribe((data) => {
      this.landingPageMsg = data.text ? data.text : '';
      if (this.landingPageMsg && this.landingPageMsg !== null && this.landingPageMsg !== '') {
        setTimeout(() => {
          if (this.landingPageMsg && this.landingPageMsg !== undefined) {
            this.msgToast.pushMessage('success', this.landingPageMsg);
          }
        }, 1);
      }
    });
  }
}

<div class="sdps-text-l-body security-container" *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')">
  <div class="security-main" class="sdps-m-left_xx-large">
    <div class="security-restrict-container" class="sdps-p-top_large">
      <p class="sdps-m-bottom_x-small sdps-text-l-bold-body" *ngIf="(attributesFormData.ruleType === 'INDSEC' && (attributesFormData.rulesCategory === getConstant.rulesConstant.ruleGlobalConst.override))">
        {{getConstant.rulesConstant.autoComplete.instrumentGroupToOverride}}
        <span class="sdps-text-market-red" *ngIf="(countOfIndSec<1 && selectedData.length>=1) || (countOfIndSec === 0 && selectedData.length === 0)" [innerHTML]="commonConstants.asterix"></span>
      </p>
      <p class="sdps-m-bottom_x-small sdps-text-l-bold-body" *ngIf="(attributesFormData.ruleType === 'INDSEC' && attributesFormData.rulesCategory === getConstant.rulesConstant.autoComplete.RESTRICT_LST_ENGTxt)">
        {{getConstant.rulesConstant.autoComplete.instrumentGroupToRestrict}}
        <span class="sdps-text-market-red" *ngIf="(countOfIndSec<1 && selectedData.length>=1) || (countOfIndSec === 0 && selectedData.length === 0)" [innerHTML]="commonConstants.asterix"></span>
      </p>
<div class="restrict-holder sdps-grid-container no-margin">
    <div class="col-4 auto-complete common-data-table">
      <p-autoComplete [(ngModel)]="text" [suggestions]="results" (completeMethod)="autocompleteSearch($event)"
                      [size]="30"
                      placeholder="{{getConstant.rulesConstant.autoComplete.instrumentGroupPlaceholder}}"
                      [minLength]="1" class="auto-complete-list" (onSelect)="selectedVal($event)" (onKeyUp)="clearShowErr();"
                      delay="2000" [ngClass]="{'invalidSearchBox': text && showErr}">
        <ng-template let-search pTemplate="item" let-columns="columns">
          <span class="search-symbol truncate-ellipses" [title]="search.name" [innerHTML]="highlightTxt(search.name,text)"></span>
          <span class="search-desc truncate-ellipses" [title]="search.description" [innerHTML]="highlightTxt(search.description,text)"></span>
        </ng-template>
      </p-autoComplete>
      <em class="pi pi-search"></em>
  </div>
</div>
    </div>
  </div>
</div>

<div class="security-list sdps-p-top_large">
  <div>
    <ems-table-record-count [count]="gridData.length" (emitter)="tt.reset();receiveDataFromChild($event)" [selectedCount]="selectedData.length" [showHeaderIcons]="false"></ems-table-record-count>
    <p-table [columns]="cols" [value]="gridData" [(selection)]="selectedData" [rows]="noOfRecords" [paginator]="true" [totalRecords]="totalRecords"
             [sortOrder]=1 sortMode="multiple" #tt class="common-data-table">

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length + 1" class="sdps-text-center">
            {{getConstant.rulesConstant.autoComplete.noInstrumentGroupsSelected}}.
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="table-checkbox-selection">
            <p-tableHeaderCheckbox class="emtr-checkbox" (click)="updateInstrumentGrps()" [disabled]="!sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')" ></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" [attr.arialabel]="commonConstants.sorting.sortText"
                        [attr.ariaLabelDesc]="commonConstants.sorting.descText"
                        [attr.ariaLabelAsc]="commonConstants.sorting.ascText"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr class="table-checkbox-selection" [pSelectableRow]="rowData">
          <td>
            <p-tableCheckbox class="emtr-checkbox" [value]="rowData" (click)="updateInstrumentGrps()" [disabled]="!sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns" class="sdps-truncate--ellipses">
            <span *ngIf="col.field !== 'tickerSymbols'" [title]="rowData[col.field]">{{rowData[col.field]}}</span>
            <span *ngIf="col.field === 'tickerSymbols'">
              <a class="ruleGrpLinks cursor" (click)="openViewSymbolModal(rowData)"
                    [title]="getConstant.rulesConstant.ruleGlobalConst.viewSymboleInstGrpText"
                    [innerHTML]="getConstant.rulesConstant.ruleGlobalConst.viewSymboleInstGrpText"></a>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<tr-view-symbols-modal #child></tr-view-symbols-modal>

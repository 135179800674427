<div class="sdps-text-l-body security-container">
    <div class="security-main" [ngClass]="isInstGrp ? '' : 'sdps-m-left_xx-large'">
        <div class="security-restrict-container" [ngClass]="isInstGrp ? '' : 'sdps-p-top_large'">
          <p class="sdps-m-bottom_x-small sdps-text-l-bold-body">{{(ruleType === getConstant.rulesConstant.autoComplete.FNDISUTxt) ? getConstant.rulesConstant.autoComplete.searchFundFamiliesToRestrict : (!isInstGrp) ? getConstant.rulesConstant.autoComplete.searchAndSelectSecurityTo : getConstant.rulesConstant.autoComplete.searchAndSelectSecurity}}
            {{(rulesCategory === 'RESTRICT_LST_ENG')? getConstant.rulesConstant.ruleTypeLabels.RESTRICT_LST_ENG : (rulesCategory === 'Override')? getConstant.rulesConstant.ruleGlobalConst.override : (rulesCategory === 'Watchlist')? getConstant.rulesConstant.ruleGlobalConst.watchList : ''}}
            <span *ngIf="isInstGrp || (countOfInstGrp < 1 && selectedData.length>=1) || (countOfInstGrp === 0 && selectedData.length === 0)" class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
          </p>

            <div class="restrict-holder sdps-grid-container no-margin">
                <div class="col-3" *ngIf="(rulesCategory !== getConstant.rulesConstant.autoComplete.RESTRICT_LST_ENGTxt && ruleType !== getConstant.rulesConstant.autoComplete.FNDISUTxt && !isInstGrp)">
                    <tr-custom-multiselect [selectedValues]="multiselectSelectedData" (emitSelectedvalues)="updateMultiselectValues($event)" ></tr-custom-multiselect>
                </div>
                <div class="col-4">
                    <div class="auto-complete common-data-table">
                        <p-autoComplete [(ngModel)]="text" [suggestions]="results" (completeMethod)="autocompleteSearch($event)" [size]="30"
                                        placeholder="{{ ruleType === getConstant.rulesConstant.autoComplete.FNDISUTxt ? getConstant.rulesConstant.autoComplete.fundFamilySearchPlaceHolder : getConstant.rulesConstant.autoComplete.placeholder }}"
                                        [minLength]="1" class="auto-complete-list" (onSelect)="selectedVal($event)" (onKeyUp)="openModal($event,child)" delay="2000" [ngClass]="{'invalidSearchBox': text && showErr}">
                            <ng-template let-search pTemplate="item">
                                <span class="search-symbol" [innerHTML]="highlightTxt(search.id,text)"></span>
                                <span class="search-desc" [innerHTML]="highlightTxt(search.name,text)"></span>
                            </ng-template>
                        </p-autoComplete>
                        <em class="pi pi-search"></em>
                    </div>
                </div>
              <div class="col-2 sdps-p-top_x-small">
                <!-- Advanced Search -->
                <a class="advanced-search" [title]="getConstant.rulesConstant.advancedSearchTxt" (click)="loadSecurityModal=true; child.openModal($event,searchSecurities, text)" [innerHTML]="getConstant.rulesConstant.advancedSearchTxt"></a>
                <tr-search-security-modal [loadModal]="loadSecurityModal" [isInstGrp]="isInstGrp" [selectedModalRulesCategory]="selectedRulesCategory" [rulesCategory]="rulesCategory" [ruleType]="ruleType" (checkedRecords)="getSelectedRecords($event)" #child></tr-search-security-modal>
              </div>
            </div>
        </div>
    </div>
</div>

<div class="security-list sdps-p-top_large">
    <div>
        <ems-table-record-count [count]="gridData.length" (emitter)="tt.reset();receiveDataFromChild($event)" [selectedCount]="selectedData.length" [showHeaderIcons]="false"></ems-table-record-count>
        <p-table [columns]="cols" [value]="gridData" [(selection)]="selectedData" [rows]="noOfRecords" [paginator]="true" [totalRecords]="totalRecords"
                 [sortOrder]=1 sortMode="multiple" #tt class="common-data-table">

            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length + 1" class="sdps-text-center">
                        {{(ruleType === getConstant.rulesConstant.autoComplete.FNDISUTxt) ? getConstant.rulesConstant.autoComplete.noFundFamiliesSelected : (!isInstGrp) ? getConstant.rulesConstant.autoComplete.noSecuritiesSelectedTo : getConstant.rulesConstant.autoComplete.noSecuritiesSelected}}&nbsp;{{(rulesCategory === 'RESTRICT_LST_ENG')? getConstant.rulesConstant.ruleTypeLabels.RESTRICT_LST_ENG : (rulesCategory === 'Override')? getConstant.rulesConstant.ruleGlobalConst.override : (rulesCategory === 'WATCH_LST_ENG')? getConstant.rulesConstant.ruleGlobalConst.watchList : ''}}.
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th class="table-checkbox-selection">
                        <p-tableHeaderCheckbox class="emtr-checkbox" (click)="updateSecurities()"></p-tableHeaderCheckbox>
                    </th>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field" [attr.arialabel]="commonConstants.sorting.sortText"
                              [attr.ariaLabelDesc]="commonConstants.sorting.descText"
                              [attr.ariaLabelAsc]="commonConstants.sorting.ascText"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">

                <tr class="table-checkbox-selection" [pSelectableRow]="rowData">
                    <td>
                        <p-tableCheckbox class="emtr-checkbox" [value]="rowData" (click)="updateSecurities()"></p-tableCheckbox>
                    </td>
                    <td *ngFor="let col of columns">
                        <span *ngIf="col.field === 'name'">{{rowData[col.field] | titlecase}}</span>
                        <span *ngIf="col.field !== 'name'">{{rowData[col.field]}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>



<ems-table-record-count class="tbl-rec-count" [count]="tableCountData.totalRecords" (emitter)="receiveDataFromChild($event);paginator.changePage(0);"
  [selectedCount]='showSelectedcount' *ngIf="employees && !showSelection" [showHeaderIcons]="false" [empIds]='' [groupTotal]="!showSelection" [showTotal]='tableCountData.totalRecords'></ems-table-record-count>

<ems-table-record-count [count]="" (emitter)="receiveDataFromChild($event);paginator.changePage(0);" [selectedCount]='showSelectedcount'
                        *ngIf="employees && showSelection" [showHeaderIcons]="false" [empIds]='' [showTotal]=''></ems-table-record-count>

<ems-view-history #viewHistory [historyId]="empGrpHistoryRecord.historyId" [getOrgId]="getOrgId" [ruleSubType]="empGrpHistoryRecord.empGrpSubType" [historyTitle]="empGrpHistoryRecord.empGrpName"
                   [historyData]="empGrpHistoryRecord.empGrpHistory" [totalElements]="empGrpHistoryRecord.historyRecords" (paginateHistoryList)="getHistoryPagination(empGrpHistoryRecord.historyId, $event)"></ems-view-history>


<form [formGroup]="empGroupOrPlanForm">
<p-table [columns]="header" [value]="employees" [(selection)]="selectedArr" [customSort]="true" dataKey="id" metaKeySelection="true"
         [lazy]="true" (onLazyLoad)="customSort($event)" [multiSortMeta]="multiSortMeta" sortMode="multiple" #empGrps class="common-data-table">

  <ng-template pTemplate="header" let-columns>
    <tr class="filter-header-input">
      <th class="table-checkbox-selection filter-icon">
        <a (click)="toggleFunction()" class="cursor" href="javascript:void(0)">
          <em class="pi pi-filter"></em>
        </a>
      </th>
      <th class="table-checkbox-selection" *ngIf="showSelection">
        <p-tableHeaderCheckbox class="emtr-checkbox sdps-p-right_x-small" (click)="grpSelected('',$event)"></p-tableHeaderCheckbox>
      </th>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="(col.field==='noOfRules' || col.field==='noOfSubAccounts' || col.field==='noOfEmployees' || col.field==='actions')"
        [ngClass]="{'w-25-per': col.header === 'Plan Name' || col.header === 'Plan Description' || col.header === 'Employee Group Name' || col.header === 'Group Description', 'w-12-per': col.header === 'Last Updated'}">
        {{col.header}}
        <p-sortIcon [field]="col.field" *ngIf="(col.field!=='noOfRules' && col.field!=='noOfSubAccounts' && col.field!=='noOfEmployees' && col.field!=='actions')"></p-sortIcon>
      </th>
      <th *ngIf="toggle && moduleType !== 'EmployeeGroup'" class="w-200-px"></th>
    </tr>
    <tr *ngIf="toggle" class="filtered-row-input">
      <th></th>
      <th *ngIf="showSelection"></th>
      <th class="sdps-p-left_small sdps-m-bottom_small" *ngFor="let col of columns ;let i = index;">
        <input pInputText formControlName="name" class="w-100-per" type="text" name="name" [maxLength]="35"
               *ngIf="col.field === 'name' && businessUnit !== sharedConst.rbsBUText" emsAlphaNumericSpecialCharacterValidation (keydown.enter)="!( empGroupOrPlanForm.invalid || filterBtnDisableForName) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
               [ngClass]="{'invalidField': (filterBtnDisableForName || (empGroupOrPlanForm.get('name').value && empGroupOrPlanForm.get('name').invalid))}">
        <input pInputText formControlName="name" class="w-100-per" type="text" name="name" [maxLength]="35"
               *ngIf="col.field === 'name' && businessUnit === sharedConst.rbsBUText" (keydown.enter)="!( empGroupOrPlanForm.invalid || filterBtnDisableForName) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
               [ngClass]="{'invalidField': filterBtnDisableForName}">
        <input pInputText formControlName="description" class="w-100-per" type="text" name="description" (keydown.enter)="!( empGroupOrPlanForm.invalid || filterBtnDisableForName)? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
               *ngIf="col.field === 'description'" [maxLength]="35">
        <div *ngIf="col.field === 'updTs'">
          <ems-date-range-filter #range (submittedDate)="getSelectedDate($event);"></ems-date-range-filter>
        </div>
        <div *ngIf="col.field === 'actions'" class="w-200-px sdps-p-right_none">
          <button class="buttons button-filter" [disabled]="!empGroupOrPlanForm.dirty && !isSortingChanged"
                  [innerHTML]="sharedConst.filterButton.clearAllButton"
                  (click)="filterClicked? clickedClearButton(isSortingChanged) : resetForm(isSortingChanged); clearSuccessAndErrorMessages();"></button>
          <button class="buttons button-save button-filter" (click)="applyFilter()"
                  [disabled]="filterBtnDisableForName || empGroupOrPlanForm.invalid"
                  [innerHTML]="sharedConst.filterButton.applyFilterButton"></button>
        </div>
      </th>
      <th *ngIf="moduleType !== 'EmployeeGroup'" class="w-200-px sdps-p-right_none">
        <button class="buttons button-filter sdps-m-bottom_small" [disabled]="!empGroupOrPlanForm.dirty && !isSortingChanged"
                [innerHTML]="sharedConst.filterButton.clearAllButton"
                (click)="filterClicked? clickedClearButton(isSortingChanged) : resetForm(isSortingChanged); sharedService.clearSuccessMsgs(this.messageService, this.showSelection);"></button>
        <button class="buttons button-save button-filter sdps-m-bottom_small" (click)="applyFilter()"
                [disabled]="filterBtnDisableForName || empGroupOrPlanForm.invalid"
                [innerHTML]="sharedConst.filterButton.applyFilterButton"></button>
      </th>
    </tr>

    <tr *ngIf="moduleType === 'EmployeeGroup' && toggle && showActionItems" class="filter-header-input">
      <th></th>
      <th *ngFor="let col of groupSubHeader">
        {{col.header}}
      </th>
    </tr>

    <tr *ngIf="moduleType === 'EmployeeGroup' && toggle && showActionItems" class="filtered-row-input sdps-p-bottom_small">
      <th></th>
      <th class="sdps-p-left_small sdps-p-bottom_medium" *ngFor="let col of groupSubHeader">
        <div class="timePickerForm emtr-dropdown sdps-p-right_small" *ngIf="col.field === 'showRecords'">
          <p-dropdown appendTo="body" optionLabel="label" formControlName="{{col.field}}" [options]="sharedConst.showRecords">
          </p-dropdown>
        </div>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length + (moduleType !== 'EmployeeGroup' ? 3 : 1)" class="sdps-text-center sdps-font-weight-bold" [innerHTML]="sharedConst.noRecords"></td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData">
      <td class="table-checkbox-selection filter-icon"></td>
      <td *ngIf="showSelection">
        <p-tableCheckbox class="emtr-checkbox sdps-p-right_x-small" [value]="rowData" (click)="grpSelected(rowData,$event)"></p-tableCheckbox>
      </td>
      <ng-container *ngFor="let col of columns">
        <td *ngIf="col.field !== 'actions'">
          <span *ngIf="col.field!=='updTs' && col.field !=='noOfRules' && col.field !=='name' && col.field !=='noOfEmployees' && col.field !=='noOfSubAccounts'" [innerHTML]='rowData[col.field]'></span>

          <span *ngIf="moduleType !== 'EmployeeGroup' && col.field ==='noOfEmployees'" [innerHTML]='rowData[col.field]'></span>

          <div *ngIf="moduleType !== 'EmployeeGroup' && col.field ==='noOfSubAccounts'">
            <button type="button" class="cursor sdps-p-around_xx-small highlight-no-of-accounts" [innerHTML]='rowData[col.field]'
            (click)="navigateToManageEmployee(rowData)">
            </button>
          </div>

          <span *ngIf="moduleType === 'EmployeeGroup' && col.field ==='noOfEmployees' && businessUnit!== 'RBS' && rowData.assignStatus !== 'Failed'" [innerHTML]='(processID.indexOf(rowData.id) !== -1) ? ((showUpdatedCount && showUpdatedCount.data.noOfEmployees) ? showUpdatedCount.data.noOfEmployees : " ") : rowData[col.field]'></span>

          <div *ngIf="moduleType === 'EmployeeGroup' && col.field ==='noOfSubAccounts' && rowData.assignStatus !== 'Failed'">
            <button *ngIf="!sharedService.deletedGrpOrPlan" type="button" class="cursor sdps-p-around_xx-small highlight-no-of-accounts" [innerHTML]='(processID.indexOf(rowData.id) !== -1) ?
            ((showUpdatedCount && showUpdatedCount.data.noOfSubAccounts) ? showUpdatedCount.data.noOfSubAccounts : " ") : rowData[col.field]'
            (click)="navigateToManageEmployee(rowData)">
            </button>
            <span *ngIf="sharedService.deletedGrpOrPlan" [innerHTML]='rowData[col.field]'></span>
          </div>

          <div *ngIf="col.field ==='noOfRules'">
            <button *ngIf="!sharedService.deletedGrpOrPlan" type="button" class="cursor sdps-p-around_xx-small highlight-emp-group-rules" (click)='navigateToRulesPage(rowData)' [innerHTML]='rowData[col.field]'>
            </button>
            <span *ngIf="sharedService.deletedGrpOrPlan" [innerHTML]='rowData[col.field]'></span>
          </div>
          <div *ngIf="moduleType === 'EmployeeGroup' && (col.field ==='noOfEmployees' || col.field ==='noOfSubAccounts')">
            <span *ngIf="processID.indexOf(rowData.id) !== -1  && showSpinnerFlag[rowData.id]"><p-progressSpinner class="spinnerStyle"></p-progressSpinner></span>
            <span *ngIf="processID.indexOf(rowData.id) !== -1  && showErrorFlag[rowData.id]">{{rowData[col.field]}} <em class="sch-exclamation-octagon" pTooltip="{{sharedConst[businessUnit].toolTipMsgexclamatoryIcon}}"
                                                                                                                        tooltipPosition="left"></em></span>
            <span *ngIf="rowData.assignStatus === 'Failed'">{{rowData[col.field]}} <em class="sch-exclamation-octagon" [pTooltip]="businessUnit ? sharedConst[businessUnit].toolTipMsgexclamatoryIcon : ''"
                                                                                       tooltipPosition="left"></em></span>
          </div>
          <span *ngIf="col.field==='updTs'" [innerHTML]="(rowData[col.field]) ? moment(rowData[col.field]).format('MM/DD/YYYY') : ''"></span>
          <span *ngIf="col.field==='name' && !showSelection" [ngClass]="{'empGrpLinks cursor': rowData['type'] !== 'ORG'}" (click)="(rowData['type'] !== 'ORG')? editEmpGrp(rowData): ''"
                [innerHTML]='rowData[col.field]'></span>
          <span *ngIf="col.field==='name' && showSelection" [innerHTML]='rowData[col.field]'></span>
        </td>
      </ng-container>
      <td *ngIf="!showSelection">
        <div class="action-menu-dropdown" *ngIf="rowData['type'] !== 'ORG'">
          <button type="button" class="cursor sdps-p-around_xx-small" (click)="showActionMenu=true;  empId=rowData['id']; formData();"
                  [ngClass]="{'active-menu':(empId===rowData['id'])}">
            <em class="pi pi-ellipsis-h sdps-p-around_xx-small"></em>
          </button>
          <p-menu [model]="actionMenu" *ngIf="empId===rowData['id']" class="menu-dropdown"
                  (click)="onActionMenuClick($event, rowData)"></p-menu>
        </div>
      </td>
      <td *ngIf="toggle && moduleType !== 'EmployeeGroup'" class="w-200-px"></td>
    </tr>
  </ng-template>
</p-table>
</form>

<p-paginator class="common-pagination" [rows]="this.param.pageSize" [totalRecords]="tableCountData.paginationCount" (onPageChange)="paginate($event)"
             #p></p-paginator>

<sdps-modal id='advance-search-security-modal' [sdpsTitle]="labels" class="sdps-custom-modal" [size]="'flex--large'"
           (close)="closeModal()" [backDropCloseDisabled]="true">
  <div slot="modal-body">
    <sdps-notification type="error" class="sdps-m-top_none" *ngIf="(inputSearchTextBox && (!gridRecords || exactMatchErrorMsg || showNoRecordsErrorForAll)) || invaildFileError || showError || showMaxCountError || (selectedMultiselectRecords && searchType === 'All' && selectedMultiselectRecords.length < 1)">
      <div class="sdps-notification__text">
        <p>Please correct following error.</p>
        <ul class="sdps-navigation--horizontal__list error-list">
          <li *ngIf="inputSearchTextBox && (!gridRecords || exactMatchErrorMsg || showNoRecordsErrorForAll)">{{emtrConst.rulesConstant.autoComplete.noRecords}}</li>
          <li *ngIf="invaildFileError" [innerHTML]="emtrConst.rulesConstant.autoComplete.invalidFileFormatError"></li>
          <li *ngIf="showError" [innerHTML]="emtrConst.rulesConstant.autoComplete.uploadFailedError"></li>
          <li *ngIf="showMaxCountError" [innerHTML]="emtrConst.rulesConstant.autoComplete.uploadMaxCountError"></li>
          <li *ngIf="searchType === 'All' && selectedMultiselectRecords && selectedMultiselectRecords.length < 1" [innerHTML]="emtrConst.rulesConstant.autoComplete.searchSecurityMustBeSelected"></li>
        </ul>
      </div>
    </sdps-notification>

    <sdps-notification type="confirmation" class="toast-confirmation sdps-m-top_none" *ngIf="toastMsg !== ''">
      <div class="sdps-notification__text" [innerHTML]="toastMsg"></div>
    </sdps-notification>

    <div class="security-search-container modal-container">
      <div class="sdps-p-bottom_small">
        <strong>{{ ((ruleType === emtrConst.rulesConstant.autoComplete.FNDISUTxt && rulesCategory === 'RESTRICT_LST_ENG') ? emtrConst.rulesConstant.autoComplete.searchFundFamiliesToRestrict + ' ' +emtrConst.rulesConstant.ruleTypeLabels.RESTRICT_LST_ENG :
          (ruleType === emtrConst.rulesConstant.autoComplete.FNDISUTxt && rulesCategory === 'Override') ? emtrConst.rulesConstant.autoComplete.searchFundFamiliesToRestrict + ' ' +emtrConst.rulesConstant.ruleTypeLabels.Override_Fund :
           (!isInstGrp) ? emtrConst.rulesConstant.ruleTypeLabels.search_Securities  + ' ' + emtrConst.rulesConstant.ruleTypeLabels['security_' + selectedModalRulesCategory] : emtrConst.rulesConstant.autoComplete.searchAndSelectSecurity)}}
          <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
        </strong>
      </div>
      <form class="search-restrict-holder no-margin sdps-grid-container">
        <!-- Displaying Multiselect -->
        <div class="col-3" *ngIf="ruleType !== emtrConst.rulesConstant.autoComplete.FNDISUTxt">
          <div class="security-restrict-container">
            <div class="restrict-holder">
              <tr-custom-multiselect *ngIf="loadModal" [selectedValues]="multiselectSelectedData" [isDisabled]="searchType !== 'All'"
                                     (emitSelectedvalues)="getChildVal($event)"></tr-custom-multiselect>
            </div>
          </div>
        </div>
        <!-- Displaying Search Input box -->
        <div class="col-5 " [ngClass]="(ruleType === emtrConst.rulesConstant.autoComplete.FNDISUTxt) ? 'sdps-p-left_none' : null">
          <div class="sdps-form-element">
            <div class="sdps-form-element__control">
              <div class="sdps-form-element__addon">
                <span aria-hidden="true" class="sdps-form-element__addon--text sdps-form-element__addon--left sdps-p-top_x-small ">
                  <span class="sch-search"></span>
                </span>
                <input class="sdps-input sdps-input--left sdps-overlay--active .sdps-datepicker__calendar__header"
                       [(ngModel)]="inputSearchTextBox" (keyup)="resetInputErrorField()"
                       role="textbox" type="text" [ngModelOptions]="{standalone: true}" [ngClass]="{'invalidField': inputSearchTextBox && (!gridRecords || exactMatchErrorMsg || showNoRecordsErrorForAll)}">
              </div>
            </div>
          </div>
        </div>
        <!-- Displaying Search Button -->
        <div class="col-4">
          <div class="sdps-pull-left">
            <button class="buttons" type="submit" [disabled]="!inputSearchTextBox || (ruleType !== emtrConst.rulesConstant.autoComplete.FNDISUTxt && searchType === 'All' && selectedMultiselectRecords && selectedMultiselectRecords.length < 1)" (click)="updateSearch()">Search
            </button>
          </div>
        </div>
      </form>

      <!-- Bottom Grid -->
      <div class="search-result-table no-margin" *ngIf="gridRecords">
        <div class="sdps-grid-fluid" *ngIf="gridData && gridData.length === emtrConst.rulesConstant.maxSecurityCount">
          <div class="col-6">
            <p *ngIf="gridData.length === emtrConst.rulesConstant.maxSecurityCount">
              {{emtrConst.rulesConstant.ruleTypeLabels.securityInfoText}} <strong>{{gridData.length}}</strong>.
            </p>
          </div>
        </div>

        <div class="sdps-row emtr-radio sdps-m-around_none sdps-p-bottom_medium sdps-grid-container">
          <div class="sdps-p-top_small" *ngIf="ruleType !== emtrConst.rulesConstant.autoComplete.FNDISUTxt">
              <p-radioButton class="sdps-p-right_medium" name="rulesCategory" value="All" label="All"
                             [(ngModel)]="searchType"></p-radioButton>
              <p-radioButton class="sdps-p-right_medium" name="rulesCategory" value="Exact Ticker" label="Exact Ticker"
                             [(ngModel)]="searchType"></p-radioButton>
              <p-radioButton class="sdps-p-right_medium" name="rulesCategory" value="Exact CUSIP" label="Exact CUSIP"
                             [(ngModel)]="searchType"></p-radioButton>

            </div>
            <!-- Displaying Download Template Button -->
            <div class="col sdps-p-left_medium sdps-p-top_xx-small">
              <button type="button" class="buttons button-without-background sdps-pull-left"
                      *ngIf="ruleType !== emtrConst.rulesConstant.autoComplete.FNDISUTxt"
                      (click)="downloadFileSystem()">
                <span class="centered-container">
                  <em class="ems-icon pi pi-cloud-download sdps-pull-left sdps-p-right_small"></em>
                  {{sharedConst.bulkUpload.downloadBulkSecurityTemplate}}
                </span>
              </button>

              <!-- Displaying Choose file button to upload the file -->
              <div *ngIf="ruleType !== emtrConst.rulesConstant.autoComplete.FNDISUTxt"
                   class="file-upload sdps-pull-left sdps-p-right_xx-large">
                <div class="file-section choose-file-border security-uploads-button" #content>
                  <p-fileUpload #fileUploader method="POST" name="file[]" accept=".csv" maxFileSize="{{fileSize}}"
                                chooseLabel="Choose Bulk Load File" [showUploadButton]="false" [showCancelButton]="false"
                                styleClass="file-upload-btn" (onSelect)="fileSelection($event)">
                    <ng-template let-file pTemplate="file">
                      <ul *ngIf="uploadedFile.length">
                        <li *ngFor="let file of uploadedFile">{{file.name}} - {{file.size}} bytes</li>
                      </ul>
                    </ng-template>
                  </p-fileUpload>
                </div>
              </div>
              <span class="sdps-display-inline sdps-pull-right sdps-p-top_small" *ngIf="selectedData.length">Selected: {{selectedData.length}} out of {{gridData.length}}</span>
            </div>
          </div>

        <div>
          <p-table [columns]="columns" [value]="gridData" [sortOrder]=1
                   sortMode="multiple" class="common-data-table">
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td [attr.colspan]="columns.length + 1" class="sdps-text-center">
                  {{(ruleType === emtrConst.rulesConstant.autoComplete.FNDISUTxt) ? emtrConst.rulesConstant.autoComplete.noFundFamiliesSelected :
                  (!isInstGrp) ? emtrConst.rulesConstant.autoComplete.noSecuritiesSelectedTo : emtrConst.rulesConstant.autoComplete.noSecuritiesSelected}}&nbsp;
                  {{(rulesCategory === 'RESTRICT_LST_ENG')? emtrConst.rulesConstant.ruleTypeLabels.RESTRICT_LST_ENG :
                  (rulesCategory === 'Override') ? emtrConst.rulesConstant.ruleGlobalConst.override : (rulesCategory === 'Watchlist') ? emtrConst.rulesConstant.ruleGlobalConst.watchList : ''}}.
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="w-10-per"
                    [ngClass]="col.field === 'tickerSymbol' ? 'search-text-highlight' : null">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" [attr.arialabel]="commonConstants.sorting.sortText"
                              [attr.ariaLabelDesc]="commonConstants.sorting.descText"
                              [attr.ariaLabelAsc]="commonConstants.sorting.ascText"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div *ngIf="gridRecords" (scroll)="onScroll($event)"
             [ngClass]="{'show-scroll' : gridData.length}">
          <p-table [columns]="columns" [value]="gridData" [(selection)]="selectedData" class="common-data-table">
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" class="w-10-per" [ngClass]="col.field === 'name' ? 'search-text-highlight' : null">
                  <p-tableCheckbox [value]="rowData" [disabled]="isFileUploaded && !rowData.securityFound" *ngIf="(ruleType === emtrConst.rulesConstant.autoComplete.FNDISUTxt) ?  (col.field === 'id') : (col.field === 'tickerSymbol')"
                                   class="emtr-checkbox"></p-tableCheckbox>
                  {{rowData[col.field]}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer Buttons -->
  <div slot="modal-footer">
    <div class="step-action-buttons sdps-pull-left">
      <button class="buttons" type="button" (click)="closeModal(); sharedService.closeModal('advance-search-security-modal')">Cancel</button>
      <button type="button" [disabled]="selectedData && selectedData.length === 0" class="buttons button-save save"
              (click)="selectGridRecords()">Select
      </button>
    </div>
  </div>
</sdps-modal>

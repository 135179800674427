import {
  AfterViewChecked, AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as temp from 'moment';
const moment = temp['default'];
import {RuleService} from '../../services/rule-service/rule.service';
import {RuleListActionMenuItems} from '../../models/rule-list-action-menu';
import {EmtrService} from '../../emtr.service';
import {FormDataService, CustomSortService, SharedService, DateRangeFilterComponent, SharedConstant} from '@ems/shared';
import {emtrConstant} from '../../constants/emtrConstants';
import {RuleListTable, RuleListTableHeader} from '../../models/rule-list-table-header';
import {EmtrPagination, EmtrPaginationValues} from '../../models/pagination-model';
import {Observable, Observer, Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {MessageService} from 'primeng/api'
import {Paginator} from 'primeng/paginator';
import {Table} from 'primeng/table';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {EmtrDatepickerComponent} from '../emtr-datepicker/emtr-datepicker.component';
import {RuleListSubHeaderComponent} from '../rule-list-sub-header/rule-list-sub-header.component';
import {ViewHistoryComponent} from '@ems/shared';

@Component({
  selector: 'tr-rules-list',
  templateUrl: './rules-list.component.html'
})

export class RulesListComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  multiSortMeta: any = [];
  orgId: any;
  emtrConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  moment = moment;
  ruleListTableCols: RuleListTable[] = RuleListTableHeader;
  ruleList: any;
  selectedRules: any = [];
  totalRules: any = 0;
  pageObject: EmtrPagination = EmtrPaginationValues;
  actionMenu: any[];
  ruleId: string;
  showActionMenu: boolean;
  ruleType: string;
  deleteMsg: any;
  ruleSaveMsg: any;
  businessUnit: string;
  isApplyFilterClicked = false;
  stateVal: any;

  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild('p') paginator: Paginator;
  @ViewChild('toastMessage') msgToast: any;
  @ViewChild('viewHistory', {static: false}) viewHistoryData: any;
  @ViewChild(Table) tableComponent: Table;
  @ViewChild('range') rangeCalendar: DateRangeFilterComponent;
  @ViewChild('emtrStartDatepickerComponent') startDate: EmtrDatepickerComponent;
  @ViewChild('emtrEndDatepickerComponent') endDate: EmtrDatepickerComponent;
  @ViewChildren(RuleListSubHeaderComponent) subHeader: QueryList<RuleListSubHeaderComponent>;

  currentOrgSubscription: Subscription;
  ruleHistory: any;
  historyId: any;
  historyRecords: any;
  ruleName = '';
  ruleSubType: any;
  resetTable: any;
  toggle = false;
  ruleListForm: UntypedFormGroup;
  filterSelectedDate: any;
  isClickedClearBtn = false;
  ruleTypeSelectedOptions = [];
  statusSelectedOptions = [];
  dateErrCss: boolean;
  endDateLessThan: boolean;
  errorMessage = '';
  isInValidFromDate = false;
  isInValidToDate = false;
  filterBtnDisable = false;
  updatedStatusOptions: any = [];
  externalAccess = false;
  removeSuccessMsg = false;
  showActionItems = false;
  clientsInfo: any = [];
  batchCitiOrg: any;
  downloadTemplate = '';
  userGuideName = this.getSharedConstant.userGuide.ruleUG.ruleUserGuideTxt;
  isHidden: boolean;
  filterBtnDisableForTradeSymbol = false;
  filterBtnDisableForCusip = false;
  hasValueTradeSymbolField = false ;
  hasValueCusipField = false;
  hasGroupPlanValue = false;
  specialCharValidation = false;
  hasValueViewField = false;
  subHeaderValueForPagination: any;
  showActionItemForGRule = false;
  ruleGutTrackValue = 'adobe-dl_rulesuserguide|fireonce';
  hasInstrumentGroupValue = false;
  deletedRuleList = false;
  isSortingChanged = false;
  defaultSortDataField = {field: 'updTs', order: -1};
  sortClearButtonReset = false;
  clientType: boolean;
  clientTypeSub: Subscription;
  historyMessage = '';

  @HostListener('document:click', [])
  onClick() { /* Capture the Click Event outside the function */
    if (!this.showActionMenu) {
      this.ruleId = '';
      this.ruleType = '';
    }
    this.showActionMenu = false;
  }

  /* eslint-disable-next-line max-len */
  constructor(public ruleService: RuleService, public emtrService: EmtrService, private router: Router, private r: ActivatedRoute,
              private formDataService: FormDataService, private datePipe: DatePipe, public customSortService: CustomSortService,
              public sharedService: SharedService, @Inject('entitlementConst') public entitlementConst, public fb: UntypedFormBuilder,
              public el: ElementRef, public messageService: MessageService) {
    // this.errorServiceService.clearMessage();
    this.stateVal = this.sharedService.getStateVal();
    this.getSaveRuleMessage();
    this.sharedService.setHistoryResponse.subscribe(value => {
      this.ruleHistory = value.data.content;
      this.historyRecords = value.data.totalElements;
      this.historyMessage = value.message;
    });
    this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.clientsInfo = clientInfo;
        this.hasValueViewField = false;
        this.hasGroupPlanValue = false;
        this.hasInstrumentGroupValue = false;
      }
    });
    this.batchCitiOrg =  this.sharedService.applicationInfo.data.batchCitiOrg;
  }

  ngAfterViewInit() {
    // this.Grids.changes.subscribe((comps: QueryList <ViewHistoryComponent>) =>
    // {
    //   this.SearchGrid = comps.first;
    //   console.log('SearchGrid :', this.SearchGrid)
    // });
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.ruleService.clearMessage();
    this.sharedService.updatedDate.next('');
    this.sharedService.requestStatusFilterObj.fromDate = '';
    this.sharedService.requestStatusFilterObj.toDate = '';
    this.sharedService.tableDropdownVal.next(15);
    /* Clearing sessionStorage value for other routes for State management*/
    if (this.router.routerState.snapshot.url.indexOf('rules') === -1) {
      this.sharedService.resetInstrumentGroupValue.next('reset');
      this.sharedService.stateValues.instrumentSearchedKey = '';
      this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
      this.sharedService.clearLocalStorage();
      this.ruleService.isSubHeaderFlag = false;
    } else {
      this.sharedService.resetInstrumentGroupValue.next('');
    }
  }

  ngOnInit() {
    this.ruleListForm = this.fb.group({
      name: [''],
      status: [''],
      ruleType: [''],
      subType: [''],
      startDt: this.fb.group({
        date: ['']
      }),
      endDt: this.fb.group({
        date: ['']
      }),
      updTs: ['']
    });
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.getSharedConstant.clientCount);
    this.pageObject.pageNo = this.stateVal && this.stateVal.pageObj && this.stateVal.pageObj.pageNo ? this.stateVal.pageObj.pageNo : 0;
    this.pageObject.pageSize = this.stateVal && this.stateVal.pageObj && this.stateVal.pageObj.pageSize ? this.stateVal.pageObj.pageSize : 15;
    this.stateVal && this.stateVal.multiSortMeta ? this.multiSortMeta = this.stateVal.multiSortMeta : this.multiSortMeta.push(this.defaultSortDataField);
    if (this.paginator) {
      this.paginator.totalRecords = this.stateVal ? this.stateVal.totalElem : 0;
    }
    this.customSortService.sortArr = [];
    this.resetTable = true;
    this.currentOrgSubscription = this.emtrService.clientInformation.subscribe(clientInfo => {
      if (clientInfo && clientInfo !== null) {
        this.businessUnit = clientInfo.businessUnit;
        this.ruleService.businessUnit = clientInfo.businessUnit;
        this.resetForm();
        this.toggle = false;
        if (this.sharedService.selectedGroupOrPlanName) {
          this.isApplyFilterClicked = true;
          this.toggleFunction();
          this.sharedService.stateValues.filterClicked = this.toggle;
          this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
          this.stateVal = this.sharedService.getStateVal();
        }
        if (this.sharedService.isClientChanged) {
          this.sharedService.stateValues.pageObj.pageNo = 0;
          this.sharedService.stateValues.pageObj.pageSize = 15;
          this.sharedService.stateValues.filterClicked = false;
          this.sharedService.stateValues.filterObj = '';
          this.sharedService.stateValues.instrumentSearchedKey = '';
          this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
          if (this.stateVal) {
            this.stateVal.pageObj.pageNo = 0;
            this.stateVal.pageObj.pageSize = 15;
          }
          this.sharedService.tableDropdownVal.next(this.stateVal ? this.stateVal.pageObj.pageSize : 15);
          this.stateVal = this.sharedService.getStateVal();
        }

        /* Assigning filter icon value from sessionStorage for State management*/
        if (this.stateVal && this.stateVal.filterObj) {
          this.pageObject.pageNo = this.stateVal && this.stateVal.pageObj.pageNo ? this.stateVal.pageObj.pageNo : 0;
          this.pageObject.pageSize = this.stateVal && this.stateVal.pageObj.pageSize ? this.stateVal.pageObj.pageSize : 15;
          this.stateVal && this.stateVal.multiSortMeta ? this.multiSortMeta = this.stateVal.multiSortMeta : this.multiSortMeta.push(this.defaultSortDataField);
          this.toggle = this.stateVal.filterClicked;
          this.ruleService.isSubHeaderFlag = this.toggle;
          if (this.stateVal.filterClicked) {
            this.ruleListForm.markAsDirty();
          }
          this.isApplyFilterClicked = this.stateVal.filterClicked;
        }

        this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '');
        this.showActionItemForGRule = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageGlobalRule.uuid, '', '');
        this.orgId = clientInfo && clientInfo.orgId;
        this.ruleService.orgId = this.orgId;
        this.emtrService.setCurrentOrg(this.orgId);
        this.ruleService.orgId = this.orgId;
        clientInfo.externalAccess && clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
        if (!this.resetTable) {
          this.multiSortMeta = [this.defaultSortDataField];
          this.customSortService.resetTable(this.defaultSortDataField, this.tableComponent);
        }
      }
      /* for user guide download */
      if (this.businessUnit) {
        this.downloadTemplate = this.getSharedConstant.userGuide.ruleUG[this.businessUnit].value;
      }
      this.removeSuccessMsg = false;
    });

    if (this.ruleSaveMsg) {
      setTimeout(() => {
        this.msgToast.pushMessage('success', this.ruleSaveMsg);
      }, 1);
      this.removeSuccessMsg = true;
    }

    this.emtrConstant.ruleFilter.ruleType.forEach((item) => this.ruleTypeSelectedOptions.push(item.value));
    this.ruleListForm.patchValue({ruleType: this.ruleTypeSelectedOptions});
    this.emtrConstant.ruleFilter.status.forEach((item) => this.statusSelectedOptions.push(item.value));
    this.updatedStatusOptions = [...this.statusSelectedOptions];
    const index =  this.updatedStatusOptions.indexOf('Expired');
    if (index > -1) {
      this.updatedStatusOptions.splice(index, 1);
    }
    this.ruleListForm.patchValue({status: this.updatedStatusOptions});
    const newObj = {label: 'All', value: 'All'};
    this.ruleListForm.patchValue({subType: newObj});
    this.onChanges();

    this.ruleService.invalidTradeSymbolField.subscribe(value => {
      this.filterBtnDisableForTradeSymbol = value;
    });

    this.ruleService.hasValueTradeSymbolField.subscribe(value => {
      this.hasValueTradeSymbolField = value;
    });

    this.ruleService.invalidCusipSymbolField.subscribe(value => {
      this.filterBtnDisableForCusip = value;
    });

    this.ruleService.hasValueCusipField.subscribe(value => {
      this.hasValueCusipField = value;
    });

    this.ruleService.hasGroupPlanValue.subscribe(value => {
      this.hasGroupPlanValue = value;
    });

    this.ruleService.specialCharValidation.subscribe(value => {
      this.specialCharValidation = value;
    });

    this.ruleService.hasValueViewField.subscribe(value => {
      this.hasValueViewField = value;
    });
    this.ruleService.hasInstrumentGroupValue.subscribe(value => {
      this.hasInstrumentGroupValue = value;
    });
  }

  getHistoryPagination(id, data) {
    this.sharedService.getHistoryPaginatedValues(id, 'rule', data);
  }

  formData(rowData) {
    RuleListActionMenuItems.forEach(data => {
      if (this.batchCitiOrg === this.clientsInfo.orgId) {
        if (data.label === 'History') {
          data.disabled = true;
        }
      } else {
          data.disabled = false;
      }
    });

    if (this.deletedRuleList) {
      this.actionMenu = RuleListActionMenuItems.filter(item => (item.label === 'History' || item.label === 'Restore'));
    } else if (rowData.orgId === 0 && (this.ruleType === 'Restriction' && rowData.subType === 'SECTYP')) {
      this.actionMenu = RuleListActionMenuItems.filter(item => (item.label !== 'Restriction' && item.label !== 'Copy' && item.label !== 'Restore'));
    } else if (rowData.orgId === 0) {
      this.actionMenu = RuleListActionMenuItems.filter(item => (item.label !== 'Restriction' && item.label !== 'Override' && item.label !== 'Copy' && item.label !== 'Restore'));
    }  else if (this.ruleType === 'Override' && rowData.subType === 'FNDFMLY') {
      this.actionMenu = RuleListActionMenuItems.filter(item => (item.label !== this.ruleType && item.label !== 'Restriction' && item.label !== 'Restore'));
    } else if (this.ruleType === 'Restriction' || rowData.subType === 'FNDFMLY') {
      this.actionMenu = RuleListActionMenuItems.filter(item => (item.label !== this.ruleType && item.label !== 'Restore'));
    } else if (this.ruleType === 'Watchlist') {
      this.actionMenu = RuleListActionMenuItems.filter(item => (item.label !== 'Override' && item.label !== 'Restore'));
    } else {
      this.actionMenu = RuleListActionMenuItems.filter(item => (item.label !== 'Restriction' && item.label !== 'Override' && item.label !== 'Restore'));
    }

    if (!this.showActionItems || (rowData.orgId === 0 && !this.showActionItemForGRule)) {
      if (this.ruleType === 'Restriction' || rowData.subType === 'FNDFMLY') {
        this.removeMenuItem('Override');
      } else if (this.ruleType === 'Watchlist') {
        this.removeMenuItem('Restriction');
      } else {
        this.removeMenuItem('Watchlist');
      }
      this.removeMenuItem('Copy');
      this.removeMenuItem('Delete');
      this.removeMenuItem('Restore');
    }
    return this.actionMenu;
  }

  removeMenuItem(removeItem) {
    const index = this.actionMenu.findIndex(x => x.label === removeItem);
    if (index !== -1) {
      this.actionMenu.splice(index, 1);
    }
  }

  navigateCreateRule() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue('rule_addnew');
    this.ruleService.deletedRuleInfo = false;
    this.formDataService.resetForm();
    this.router.navigate(['../create-rule'], {relativeTo: this.r});
  }

  setFilterObj() {
    const localUpdatedFormValues = this.ruleListForm.getRawValue();
    const isStatusAll = this.statusSelectedOptions.every(val => localUpdatedFormValues.status.indexOf(val) !== -1);
    const isRuleTypeAll = this.ruleTypeSelectedOptions.every((val, index) => val === localUpdatedFormValues.ruleType[index]);
    const fromDate = [];
    if (this.filterSelectedDate !== undefined && this.filterSelectedDate !== '' && this.filterSelectedDate !== null) {
      this.filterSelectedDate.forEach(date => {
        fromDate.push(date);
      });
    }
    const filterObj = {
      orgId: this.orgId ? this.orgId : 0,
      ruleName: localUpdatedFormValues.name ? localUpdatedFormValues.name : null,
      statusList: localUpdatedFormValues.status ? (isStatusAll ? localUpdatedFormValues.status = ['All'] : localUpdatedFormValues.status) : [],
      subType: localUpdatedFormValues.subType.value,
      ruleTypeList: localUpdatedFormValues.ruleType ? (isRuleTypeAll ? localUpdatedFormValues.ruleType = ['All'] : localUpdatedFormValues.ruleType) : [],
      startDate: localUpdatedFormValues.startDt.date ? moment(localUpdatedFormValues.startDt.date).format('MM/DD/YYYY') : '',
      endDate: localUpdatedFormValues.endDt.date ? moment(localUpdatedFormValues.endDt.date).format('MM/DD/YYYY') : '',
      lastUpdatedFromDate: fromDate.length > 0 ? moment(fromDate[0]).format('MM/DD/YYYY') : '',
      lastUpdatedToDate: fromDate.length > 0 ? moment(fromDate[1]).format('MM/DD/YYYY') : '',
      symbol: this.subHeaderValueForPagination.tradeSymbol,
      cusip: this.subHeaderValueForPagination.cusipSymbol,
      groupName: this.subHeaderValueForPagination.empGroupName,
      instrumentGroupId: this.subHeaderValueForPagination.instrumentGroupId,
      view: this.subHeaderValueForPagination.view,
      active: this.subHeaderValueForPagination.active
    };
    return filterObj;
  }

  getUpdatedRulesHeader(filterObj) {
    if (this.businessUnit === this.getSharedConstant.rbsBUText && (filterObj.view === 'Global' || filterObj.view === 'Semi-Global')) {
      this.ruleListTableCols = this.ruleListTableCols.filter(col => col.field !== 'countOfAccountsAssigned');
    } else {
      this.ruleListTableCols = RuleListTableHeader;
    }
  }

  getRules() {
    const filterObj = this.setFilterObj();
    const defaultObj = {
      orgId: this.orgId ? this.orgId : 0,
      statusList: this.emtrConstant.ruleFilter.statusDefaultValue,
      subType: this.getSharedConstant.groupNameAll,
      ruleTypeList: [this.getSharedConstant.groupNameAll],
      groupName: this.getSharedConstant.groupNameAll,
      instrumentGroupId: 0,
      view: this.businessUnit === this.getSharedConstant.rbsBUText ? 'Client' : null,
      active: true
    };
    const filterObjVal = !this.sharedService.isClientChanged ? this.stateVal && this.stateVal.filterObj ? this.stateVal.filterObj : filterObj : filterObj;
    const filterParam = this.isApplyFilterClicked ? filterObjVal : defaultObj;
    if (this.sharedService.isClientChanged) {
      this.pageObject.pageNo = 0;
      this.pageObject.pageSize = 15;
    }
    this.sharedService.isClientChanged = false;
    this.updateformValues(filterParam);
    this.getUpdatedRulesHeader(filterParam);
    if (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '')) {
      this.ruleService.getRules(this.pageObject, filterParam).subscribe((rules) => {
        this.ruleList = rules.data.content;
        this.totalRules = rules.data.totalElements;
        this.resetTable = false;

        /* Updating State Values for paginator to support page Val for State management*/
        this.sharedService.stateValues.totalElem = this.totalRules;
        this.sharedService.stateValues.pageObj.pageNo = this.pageObject.pageNo;
        this.sharedService.stateValues.pageObj.pageSize = this.pageObject.pageSize;
        this.sharedService.stateValues.multiSortMeta = this.multiSortMeta;
        this.sharedService.stateValues.filterClicked = this.isApplyFilterClicked;
        this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
        this.stateVal = this.sharedService.getStateVal();
      });
    }
  }

  getSaveRuleMessage() {
    this.ruleService.getMessage().subscribe((data) => {
      this.ruleSaveMsg = data.text ? data.text : '';
    });
  }

  onActionMenuClick(event: any, ruleData: any) {
    const actionItem = event.target.parentElement.innerText;
    this.messageService.clear();
    this.sharedService.clearErrorMessage();
    if (actionItem === 'Copy') {
      this.copyRule(ruleData.id);
    } else if (actionItem === 'Restriction' || actionItem === 'Override') {
      this.twoStepEditRule(ruleData, actionItem);
    } else if (actionItem === 'Delete') {
      this.deleteRule(ruleData);
    } else if (actionItem === 'History' && this.batchCitiOrg !== this.clientsInfo.orgId) {
      this.historyId = ruleData.id;
      this.ruleSubType = ruleData.subType;
      this.ruleName = this.emtrConstant.rulesConstant.ruleHistory + ' - ' + ruleData.name + ' '+ '(' + ruleData.id +')';
      this.sharedService.loadViewHistory(this.orgId, ruleData, 'rule', this.viewHistoryData);
    } else if (actionItem === 'Restore') {
      this.restoreRule(ruleData);
    }
  }

  /* To restore the deleted rule */
  restoreRule(ruleData) {
    /* Clear any service error message */
    this.sharedService.clearErrorMessage();

    /* Calling rule update endpoint with active flag as true to restore the deleted rule */
    this.ruleService.restoreRule(ruleData.id, ruleData.global).subscribe((data: any) => {
      /* Set success message for restored rule */
      const messageTxt = '<strong>' + ruleData.name + '</strong> - <strong>' + ruleData.ruleType + '</strong>&nbsp;' + this.emtrConstant.rulesConstant.ruleGlobalConst.restoreSuccessMsg;
      this.msgToast.pushMessage('success', messageTxt);
      /* Calling rule list endpoint to update the displayed rule list on landing page */
      this.getRules();
    });
    this.removeSuccessMsg = true;
  }

  copyRule(ruleId) {
    this.formDataService.resetForm();
    this.ruleService.getRuleData(ruleId, '').subscribe((res: any) => {
      const data = res.data;
      this.formDataService.setIdForCopy(data.id);
      data.id = '';
      data.startDt = data.endDt = '';
      data.status = 'DRAFT';
      if (this.businessUnit === 'RBS') {
        this.ruleService.oldRBSRuleNameForCopyFlow = '';
      }
      data.name = data.name + this.emtrConstant.rulesConstant.ruleGlobalConst.copySuffixTxt;
      data.changeRequestId = '';
      if (this.businessUnit === 'DBS' && !this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
        delete data.instrumentGroups;
        delete data.instrumentGroupsInfos;
      }
      this.formDataService.setFormData(data);
      this.formDataService.setMode('Copy');
      this.router.navigate(['../create-rule'], {relativeTo: this.r});
    });
  }

  editRule(rowData) {
      this.ruleService.deletedRuleInfo = this.deletedRuleList;
      this.formDataService.resetForm();
      this.ruleService.getRuleData(rowData.id, rowData.global).subscribe((ruleData: any) => {
        this.formDataService.setMode('EDIT');
        const res = ruleData.data;
        const allowGREdit = res.orgId === 0 ? this.showActionItemForGRule : true;
        if (res.status === 'DRAFT' && this.showActionItems && allowGREdit && !this.deletedRuleList) {
          this.verifyDate(res);
          this.router.navigate(['../create-rule'], {relativeTo: this.r});
        } else {
          res.activate = true;
          this.router.navigate(['../create-rule/step4'], {relativeTo: this.r});
          this.formDataService.validateAllSteps();
        }
        res.assignOrgIds = res.global ? res.global.orgIds : [];
        res.globalRule = res.orgId === 0;
        res.micAGSelectedFlag = this.setMiscAssetGroupFlag(res);
        this.formDataService.setFormData(res);
      });
  }

  twoStepEditRule(ruleData, actionItem) {
    this.formDataService.resetForm();
    this.ruleService.getRuleData(ruleData.id, ruleData.global || '').subscribe((res: any) => {
      res.data.startDt = res.data.endDt = '';
      res.data.micAGSelectedFlag = this.setMiscAssetGroupFlag(res.data);
      if (actionItem !== 'Recurrence') {
        res.data.employeeGroups = res.data.empAccounts ? res.data.empAccounts = '' : res.data.employeeInfos = '';
      }
      res.data.name = res.data.name + this.emtrConstant.rulesConstant.editRule.copyRulename;
      res.data.changeRequestId = '';
      if (this.businessUnit === 'DBS' && !this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
        delete res.data.instrumentGroups;
        delete res.data.instrumentGroupsInfos;
      }
      this.formDataService.setFormData(res.data);
      this.formDataService.setMode(actionItem);
      this.router.navigate(['/ems/emtr/rules/edit-rule'], {relativeTo: this.r});
    });
  }

  setMiscAssetGroupFlag(res) {
    const miscAssetGroups = res.assetGroups ? res.assetGroups : [];
    let miscAGFlag = false;
    miscAssetGroups.forEach(data => {
      if (data.code.startsWith('g-')) {
        miscAGFlag = true;
      }
    });
    return miscAGFlag;
  }

  deleteRule(ruleData) {
    const confirmObj = {
      header: `${this.emtrConstant.rulesConstant.rulesLanding.delete} - ${ruleData.name}`,
      message: `${this.emtrConstant.rulesConstant.rulesLanding.deleteMsgConfirmation}`,
      acceptBtn: this.emtrConstant.rulesConstant.rulesLanding.delete,
      rejectBtn: this.emtrConstant.rulesConstant.rulesLanding.cancel
    };
    Observable.create((observer: Observer<boolean>) => {
      this.confirmationMessage.showConfirmMsg(confirmObj, observer);
    }).subscribe(accept => {
      if (accept) {
        this.ruleService.deleteRule(ruleData.id).subscribe(data => {
          this.deleteMsg = '<strong>' + ruleData.name + '</strong> - <strong>' + ruleData.ruleType + '</strong>&nbsp;' + this.emtrConstant.rulesConstant.ruleGlobalConst.deleteSuccessMsg;
          this.msgToast.pushMessage('success', this.deleteMsg);
          this.getRules();
        });
        this.removeSuccessMsg = true;
      }
    });
  }

  formatDate(date) {
    const formattedDate = date.split('T');
    const splitedData = formattedDate[0];
    const newDate = this.datePipe.transform(splitedData, 'YYYY/MM/DD');
    return newDate;
  }

  verifyDate(data) {
    const ruleDate = data.startDt;
    const currentdate: any = new Date(moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss'));
    if ((moment(ruleDate).format('YYYY/MM/DD') < moment(currentdate.date).format('YYYY/MM/DD'))) {
      data.startDt = '';
    }
  }

  customSort(event) {
    if (event.multiSortMeta) {
      this.multiSortMeta = event.multiSortMeta;
      const sortArr = this.customSortService.customSort(event);
      this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
      if (this.orgId && this.pageObject.sortField !== sortArr) {
        const checkStateVal = this.sharedService.getStateVal();
        if (checkStateVal && JSON.stringify(event.multiSortMeta) !== JSON.stringify(checkStateVal.multiSortMeta)) {
          this.stateVal.pageObj.pageNo = 0;
          this.setPaginationtoDefault();
        }
        /* Assigning updated page number from sessionStorage for State management*/
        if (this.stateVal && this.stateVal.pageObj.pageNo) {
          setTimeout(() => {
            if (this.paginator) {
              this.paginator.totalRecords = this.stateVal ? this.stateVal.totalElem : 0;
              this.paginator.changePage(this.stateVal.pageObj.pageNo > 0 ? this.stateVal.pageObj.pageNo : 0);
            }
          }, 0);
        }
        this.pageObject = {
          pageNo: this.pageObject.pageNo,
          pageSize: this.pageObject.pageSize,
          sortField: sortArr
        };
        if (!this.sharedService.selectedGroupOrPlanName || this.sortClearButtonReset) {
          this.getRules();
          this.sortClearButtonReset = false;
        }
      }
    }
  }

  paginate(e) {
    if (this.pageObject.pageNo !== e.page) {
      this.pageObject.pageNo = e.page;
      this.messageService.clear();
      this.sharedService.clearErrorMessage();
      this.getRules();
    }
  }

  toggleFunction() {
    this.toggle = !this.toggle;
    this.ruleService.isSubHeaderFlag = this.toggle;

    /* Assigning filter value to sessionStorage for State management*/
    this.sharedService.stateValues.toggle = this.toggle;
    this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
  }

  onChanges(): void {
    this.ruleListForm.valueChanges.subscribe(() => {
      if (this.ruleListForm.get('startDt').get('date').value && this.ruleListForm.get('endDt').get('date').value) {
        this.endDateLessThan = Date.parse(this.ruleListForm.get('startDt').get('date').value) > Date.parse(this.ruleListForm.get('endDt').get('date').value);
      } else {
        this.endDateLessThan = false;
      }

      /* To display error message for End date/time and Start date/time entered */
      if (this.endDateLessThan) {
        this.errorMessage = this.emtrConstant.rulesConstant.dateTimePicker.endDateError;
        this.dateErrCss = true;
      } else {
        this.dateErrCss = false;
        this.errorMessage = '';
      }
    });
    this.ruleListForm.get('name').valueChanges.subscribe(value => {
      if (value && value.length < 2) {
        this.filterBtnDisable = true;
      } else {
        this.filterBtnDisable = false;
      }
    });
    this.ruleListForm.get('status').valueChanges.subscribe(value => {
      if (value && value.length === 0) {
        this.ruleListForm.patchValue({ status: this.statusSelectedOptions });
      }
    });
    this.ruleListForm.get('ruleType').valueChanges.subscribe(value => {
      if (value && value.length === 0) {
        this.ruleListForm.patchValue({ ruleType: this.ruleTypeSelectedOptions });
      }
    });
  }

  calendarValidationEvent(value) {
    if (value.dateLabel === this.emtrConstant.rulesConstant.startDate) {
      this.isInValidFromDate = value.isInValidDate;
    } else if (value.dateLabel === this.emtrConstant.rulesConstant.endDate) {
      this.isInValidToDate = value.isInValidDate;
    }
  }

  getSelectedDate(date) {
    this.filterSelectedDate = date;
    this.ruleListForm.get('updTs').setValue(this.filterSelectedDate);
    if (!this.isClickedClearBtn && this.filterSelectedDate !== '') {
      this.ruleListForm.markAsDirty();
    }
  }

  applyFilter(event) {
    /* Set the selected date value to the manageUserListForm before hitting the Filter API */
    this.isApplyFilterClicked = true;
    if (this.rangeCalendar && this.rangeCalendar.dateValue) {
      this.rangeCalendar.applyToDate();
      this.getSelectedDate(this.rangeCalendar.dateRangeForm.get('dateSelected').value);
    }
    const subHeaderFormValues = {
      tradeSymbol: event && event.ruleListSubHeaderForm.tradeSymbol ? event.ruleListSubHeaderForm.tradeSymbol : null,
      cusipSymbol: event && event.ruleListSubHeaderForm.cusipSymbol ? event.ruleListSubHeaderForm.cusipSymbol : null,
      empGroupName: event ? event.ruleListSubHeaderForm.empGroupName : 'All',
      instrumentGroupId: event ? event.ruleListSubHeaderForm.instrumentGroupId : 0,
      view: this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.globalRuleSummary.uuid, '', '') ? event ? event.ruleListSubHeaderForm.view.value : 'Client' : null,
      active: event ? event.ruleListSubHeaderForm.showRecords.value : true
    };
    this.subHeaderValueForPagination = subHeaderFormValues;
    if (this.stateVal) {
      this.stateVal.pageObj.pageSize = 15;
      this.stateVal.pageObj.pageNo = 0;
      this.stateVal.filterObj = this.setFilterObj();
    }
    this.messageService.clear();
    this.getRules();
  }

  clickedClearButton(resetSortFromUI?) {
    this.messageService.clear();
    this.resetForm(resetSortFromUI);
    if (!this.sortClearButtonReset) {
      this.getRules();
    }
    this.resetInstrumentGroupData();
  }
  resetDefaultForm(resetSortFromUI?) {
    this.messageService.clear();
    this.resetForm(resetSortFromUI);
    this.resetInstrumentGroupData();
  }
  resetInstrumentGroupData() {
    if (this.businessUnit === 'RBS' || this.businessUnit === 'DBS') {
      this.sharedService.resetInstrumentGroupValue.next('reset');
    }
  }

  resetForm(resetSortFromUI?) {
    this.isApplyFilterClicked = false;
    this.ruleListForm.reset();
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
      this.sortClearButtonReset = true;
    }
    this.ruleService.clearClicked.emit(true);
    this.hasValueViewField = false;
    this.hasGroupPlanValue = false;
    this.hasInstrumentGroupValue = false;
    if (this.startDate) {
      this.startDate.clearErrorFlag();
    }
    if (this.endDate) {
      this.endDate.clearErrorFlag();
    }
    this.endDateLessThan = false;
    this.errorMessage = '';
    const newObj = {label: 'All', value: 'All'};
    this.ruleListForm.patchValue({subType: newObj, ruleType: this.ruleTypeSelectedOptions, status: this.updatedStatusOptions});
    if (this.toggle) {
      this.isClickedClearBtn = true;
      this.rangeCalendar.clearSelection();
    }
    this.setPaginationtoDefault();
    this.isClickedClearBtn = false;
    this.subHeaderValueForPagination = '';
    this.sharedService.sendErrorMessage('');
  }

  ngAfterViewChecked(): void {
    const elementRefStatus = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[0];
    this.sharedService.setMultiSelectDefaultLabel(elementRefStatus, this.ruleListForm, 'status', this.statusSelectedOptions.length);
    const elementRef = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[1];
    this.sharedService.setMultiSelectDefaultLabel(elementRef, this.ruleListForm, 'ruleType', this.ruleTypeSelectedOptions.length);
  }

  updateformValues(formVals) {
    this.deletedRuleList = !formVals.active;
    this.sharedService.updatedDate.next(formVals.lastUpdatedFromDate ? {name: 'Require Indexing', fromDate: formVals.lastUpdatedFromDate, toDate: formVals.lastUpdatedToDate} : '');
    if (this.isApplyFilterClicked) {
      this.ruleListForm.patchValue({
        name: formVals.ruleName,
        status: formVals.statusList ? formVals.statusList.length > 0 && formVals.statusList.indexOf('All') >= 0 ? this.statusSelectedOptions : formVals.statusList : this.statusSelectedOptions,
        ruleType: formVals.ruleTypeList ? formVals.ruleTypeList.length > 0 && formVals.ruleTypeList.indexOf('All') >= 0 ? this.ruleTypeSelectedOptions : formVals.ruleTypeList : this.ruleTypeSelectedOptions,
        subType: {
          label: formVals.subType ? formVals.subType === 'SECTYP' ? 'Security Type' : formVals.subType === 'INDSEC' ? 'Individual Security' : formVals.subType === 'FNDFMLY' ? 'Fund Family' : 'All' : 'All',
          value: formVals.subType ? formVals.subType : 'All'
        },
        startDt: {
          date: formVals.startDate
        },
        endDt: {
          date: formVals.endDate
        }
      });
    }
  }

  setPaginationtoDefault() {
    this.pageObject.pageNo = 0;
    if (this.paginator) {
      this.paginator.changePage(0);
      this.messageService.clear();
      this.sharedService.clearErrorMessage();
    }
  }

  applyClicked() {
    this.isApplyFilterClicked = true;
    this.setPaginationtoDefault();
    this.ruleService.filterClickedSubHeader.emit(true);
  }
}

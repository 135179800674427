import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EmpGroupFormDataService} from '../emp-group-form-data/emp-group-form-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ManageEmpGrpService, SharedConstant, SharedService} from '@ems/shared';
import {emtrConstant} from '../../../constants/emtrConstants';
import {EmtrService} from '../../../emtr.service';

@Component({
  selector: 'tr-step2-add-employees',
  templateUrl: './step2-add-employees.component.html'
})
export class Step2AddEmployeesComponent implements OnInit, OnDestroy {

  getConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  employeeGroups: any[] = [];
  employeeAccounts: any[] = [];
  addEmployeeFormData: any;
  selectedValues: any;
  initialLoad = true;
  message: any;
  noOfRecords: any = 15;
  cancelButton = true;
  selectedArr: any = [];
  selectedArrAccount: any = [];
  orgId = '';
  employees: any[];
  empIds: any[] = [];
  subAccNumbers: any[] = [];
  ruleType: string;
  parent = 0;
  child = 0;
  localObserver: any;
  confirmMessage: any;
  setPreviousStep = false;
  param = {
    orgId: '',
    pageNumber: 1,
    pageSize: 1,
    sortField: ''
  };
  is10b5Client = false;
  loadedModuleType = 'EmployeeGroup';
  mergedArr: any[];
  isEmitted: any = false;
  subAccntNumbers: any[] = [];
  newlyAssignedEmployeeAccounts: any = {};
  modifiedSelectedRecords: any = {};
  businessUnit: any;

  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild('uploadList', { static: true }) uploadList: ElementRef;

  constructor(public sharedService: SharedService, public formDataService: EmpGroupFormDataService, private router: Router, private r: ActivatedRoute, private el: ElementRef, private manageEmpService: ManageEmpGrpService, public emtrService: EmtrService) {
    this.addEmployeeFormData = {...this.formDataService.getFormData()};
    this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.businessUnit = this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.sharedService.groupType : clientInfo.businessUnit;
        this.loadedModuleType = this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.sharedService.groupType : 'EmployeeGroup';
      }
    });
  }

  ngOnInit() {
    this.orgId = this.manageEmpService.orgId;
    if (this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts) {
      this.selectedArr = this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts;
    }
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    this.sharedService.schErrorMsg.subscribe(value => {
      this.emtrService.schErrorMsg.emit(value);
      if (value.length > 0) {
        this.emtrService.showSchError.emit(true);
      } else {
        this.emtrService.showSchError.emit(false);
      }
    });
    this.is10b5Client = this.sharedService.get10b5ClientDetails();

    if (this.uploadList) {
      this.uploadList.nativeElement.focus();
    }
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = (this.confirmMessage.message !== undefined && this.confirmMessage.message) ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    if ((this.cancelButton && this.addEmployeeFormData.mode === 'EDIT') || (this.cancelButton && this.addEmployeeFormData.mode !== 'EDIT' && (this.selectedArrAccount.length > 0 || this.selectedArr.length > 0))) {
      this.localObserver = observer;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  storePageInfo(type) {
    if (this.addEmployeeFormData.mode === 'EDIT') {
      this.mergedArr = this.isEmitted ? this.selectedArr : this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts;
    } else {
      this.mergedArr = this.selectedArr;
    }
    const wholeArray = this.mergedArr;
    const localSelectedItem = this.sharedService.setSelectionItem(wholeArray);
    let formObj = {};
    if (this.addEmployeeFormData.mode === 'EDIT') {
      formObj = {
        empIds: this.setPreviousStep ? [] : localSelectedItem.empIds,
        subAccntNumbers: this.setPreviousStep ? [] : localSelectedItem.subAccntNumbers,
        employeeAccounts: this.mergedArr,
        newSelectedRecords: this.newlyAssignedEmployeeAccounts.newSelectedRecords,
        removedExistingSelected: this.newlyAssignedEmployeeAccounts.removedExistingSelected,
        assignEmpIds: this.modifiedSelectedRecords.assignEmpIds,
        assignSubAcctNumbers: this.modifiedSelectedRecords.assignSubAcctNumbers,
        unAssignEmpIds: this.modifiedSelectedRecords.unAssignEmpIds,
        unAssignSubAcctNumbers: this.modifiedSelectedRecords.unAssignSubAcctNumbers,
        subAccntNewlySelected: this.newlyAssignedEmployeeAccounts.subAccntNewlySelected,
        removedExistingEmpId: this.newlyAssignedEmployeeAccounts.removedExistingEmpId,
      };
    } else {
      formObj = {
        empIds: localSelectedItem.empIds,
        subAccntNumbers: localSelectedItem.subAccntNumbers,
        employeeAccounts: this.mergedArr
      };
    }
    if (type === 'falseStep2') {
      this.formDataService.setStep2(formObj, false);
    } else {
      this.formDataService.setStep2(formObj, true);
    }
  }

  goToPreviousStep() {
    this.setPreviousStep = true;
    this.cancelButton = false;
    this.storePageInfo('falseStep2');
    this.navigateTo('../step1');
  }

  goToNextStep() {
    this.storePageInfo('');
    this.cancelButton = false;
    this.navigateTo('../step3');
  }

  navigateTo(path) {
    this.router.navigate([path], {relativeTo: this.r});
  }

  cancelCreateEmpGroup() {
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.router.navigate([this.getSharedConstant[this.businessUnit].toLandingPage]);
  }

  uploadEmployeeList() {
    this.sharedService.clearErrorMessage();
    /* To update the pagename when bulk upload btn is clicked. */
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    this.sharedService.loadDataTrackValue('client_redirect');
    this.cancelButton = this.compareEmployeeObj(this.selectedArr, this.selectedValues) ? false : true;
    this.sharedService.parentPage = '';
    this.router.navigate([this.getSharedConstant[this.businessUnit].groupOrPlanBulkUploadRoute]);
  }

  compareEmployeeObj(arr1, arr2) {
    /*To compare selectedArr array with its previous value in order to check whether any changes are made in edit flow */
    let flag = false;
    if (arr1.length!== arr2.length) {
      return flag;
    }
    for (let index = 0; index < arr1.length; index++) {
      const val = arr1[index];
      if (!val.children) {
        if (val.data.empId !== arr2[index].data.empId) {
          flag = false;
          break;
        } else {
          flag = true;
        }
      }
    }
    return flag;
  }

  updateSelectedArray(e) {
    if (this.initialLoad) {
      this.selectedValues = e;
      this.initialLoad = false;
    }
    this.selectedArr = e;
    this.isEmitted = true;
  }

  updateNewlySelectedArray(e) {
    this.newlyAssignedEmployeeAccounts = e;
    const assignedData = this.sharedService.setSelectionItem(e.newSelectedRecords);
    let finalLocalUnselected;
    let multipEmployeeSelection;
    let empIdAlreadyRemoved;
    if (e.localAlreadyRemovedArr && e.localAlreadyRemovedArr.length > 0) {
      const localFinalLocalUnselected = this.sharedService.setSelectionItem(e.localAlreadyRemovedArr);
      finalLocalUnselected = localFinalLocalUnselected ? localFinalLocalUnselected : [];
    }

    if (e.subAccntNewlySelected && e.subAccntNewlySelected.length > 0) {
      const localMultipEmployeeSelection = this.sharedService.setSelectionItem(e.subAccntNewlySelected);
      multipEmployeeSelection = localMultipEmployeeSelection ? localMultipEmployeeSelection : [];
    }

    if (e.removedExistingEmpId && e.removedExistingEmpId.length > 0) {
      const localEmpIdAlreadyRemoved = this.sharedService.setSelectionItem(e.removedExistingEmpId);
      empIdAlreadyRemoved = localEmpIdAlreadyRemoved ? localEmpIdAlreadyRemoved : [];
    }

    this.modifiedSelectedRecords = {
      assignEmpIds: this.sharedService.removeDuplicates(this.sharedService.formData(assignedData, multipEmployeeSelection, 'empIds')),
      assignSubAcctNumbers: this.sharedService.removeDuplicates(this.sharedService.formData(assignedData, multipEmployeeSelection, 'subAccntNumbers')),
      unAssignEmpIds: this.sharedService.removeDuplicates(this.sharedService.formData(finalLocalUnselected, empIdAlreadyRemoved, 'empIds')),
      unAssignSubAcctNumbers: (finalLocalUnselected !== undefined) ? this.sharedService.removeDuplicates(finalLocalUnselected.subAccntNumbers) : [],
    };
  }

  updateSelectedAccountsArray(e) {
    this.selectedArrAccount = e;
  }

  ngOnDestroy(): void {
    this.sharedService.clearValidationFields();
  }
}

<form [formGroup]="requestListSubHeaderForm">
  <div class="expanded-row-filter">
    <div *ngFor="let col of filteredRequest; let i = index;"
         [ngClass]="{'expanded-row-item': true, 'expanded-row-item-large': isHidden, 'expanded-row-item-xx-large': (col.header === 'Submitted Date' || col.header === 'Completed Date'), 'sdps-hide' : (col.header === '407 Liable' && isHidden)}">
      <div class="er-item-header" title="{{col.header}}" [ngClass]="{'sdps-p-bottom_small': i<6}">
        <span *ngIf="!isHidden && onFilterClicked && col.header === '407 Liable'">
          {{col.header}}
        </span>
        <span *ngIf="onFilterClicked && col.header !== '407 Liable'">
          {{col.header}}
        </span>
      </div>

      <div class="filter-input-block" [ngClass]="{'er-min-height': i<6 && onFilterClicked}">
        <div class="timePickerForm liable-drpdwn" *ngIf="!isHidden && onFilterClicked && col.enum === 'dropdown' && col.field ==='liable407'">
          <p-dropdown appendTo="body" [options]="getAdlConst.liableData" placeholder=" " formControlName="{{col.field}}"
                      class="sdps-p-top_small editableTimepicker request-list-header-dropdown"
                      optionLabel="label" [disabled]="disableForm === 'disable'">
          </p-dropdown>
        </div>

        <div *ngIf="onFilterClicked && col.header === 'Submitted Date' && col.enum === 'calendar'" class="requestlist-calendar">
          <ems-date-range-filter #range [disableForm]="disableForm === 'disable'" [selectFuturedates]="false"
                                 (submittedDate)="getSelectedDate($event);" [limitCustomRangeTo6Months]="true">
          </ems-date-range-filter>
        </div>

        <div *ngIf="onFilterClicked && col.enum === 'multiselect' && col.field ==='source'"
             class="col-2 emtr-dropdown timePickerForm sdps-m-around_small request-list-filter request-list-multiselect-font-size request-list-filter-multi-dropdown">
          <p-multiSelect appendTo="body" formControlName="{{col.field}}" [options]="sourceDropdownData"
                         [filter]="true" [defaultLabel]="' '" [displaySelectedLabel]="true"
                         [maxSelectedLabels]="1" [showHeader]="true"
                         [filterPlaceHolder]="'All'" [filter]="true" [disabled]="disableForm === 'disable' || businessRbs ==='RBS'">
          </p-multiSelect>
        </div>
      </div>
    </div>
  </div>
</form>

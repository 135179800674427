import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy, Inject
} from '@angular/core';
import {
  InstrumentGroupTableHeader,
  InstrumentGroupTableSubHeader,
  TableHeader
} from '../../models/instrument-group-header';
import {emtrConstant} from '../../constants/emtrConstants';
import {EmtrService} from '../../emtr.service';
import {Observable , Observer , Subscription} from 'rxjs';
import {
  InstrumentGroupsListActionMenuItems,
  InstrumentGroupsListHistoryActionMenuItem, InstrumentGroupsListRestoreActionMenuItems
} from '../../models/rule-list-action-menu';
import {MessageService} from 'primeng/api'
import {Paginator} from 'primeng/paginator';
import {EmtrPagination, EmtrPaginationValues} from '../../models/pagination-model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  CustomSortService,
  DateRangeFilterComponent,
  ManageEmpGrpService,
  SharedConstant,
  SharedService
} from '@ems/shared';
import * as temp from 'moment';
const moment = temp['default'];
import {InstrumentGroupFormDataService} from '../create-instrument-group/instrument-group-form-data/instrument-group-form-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InstrumentGroupService} from '../../services/instrument-group-service/instrument-group.service';

@Component({
  selector: 'tr-instrument-group-list',
  templateUrl: './instrument-group-list.component.html'
})
export class InstrumentGroupListComponent implements OnInit, OnDestroy {

  @ViewChild('p', { static: true }) paginator: Paginator;
  @Input() showSelection: any;
  @Output() sendError: any = new EventEmitter();
  @ViewChild('toastMessage') msgToast: any;
  @ViewChild('instrumentGrpDeleteConfirmMsg', { static: true }) instrumentGrpDeleteConfirmMsg: any;
  @ViewChild('range') rangeCalendar: DateRangeFilterComponent;
  @ViewChild('viewHistory', { static: true }) viewHistoryData: any;

  instGrpHistoryRecord = {
    historyId: '',
    instGrpSecurities: '',
    instGrpName: '',
    instGrpHistory: '',
    historyRecords: ''
  };
  currentOrgSubscription: Subscription;
  public groupHeader: TableHeader[] = InstrumentGroupTableHeader;
  public groupSubHeader: TableHeader[] = InstrumentGroupTableSubHeader;
  pageObject: EmtrPagination = EmtrPaginationValues;
  toggle = false;
  getEmtrConstants = emtrConstant;
  sharedConstant = SharedConstant;
  orgId: any;
  showActionMenu: boolean;
  groupId: any;
  actionMenu: any[];
  showSchError = false;
  removeSuccessMsg = false;
  instrumentGroupForm: UntypedFormGroup;
  sharedConst = SharedConstant;
  filterBtnDisableForName = false;
  filterSelectedDate: any;
  filterClicked = false;
  formFilterObj: any;
  sortField: any;
  pageNo: any = 1;
  noOfRecords: any = 15;
  param = {
    pageNo: 1,
    pageSize: 15,
    sortField: ''
  };
  schError = {
    minCharacter: false,
    invalidCharacter: false
  };
  showToast = false;
  defaultObj: any;
  response: any;
  multiSortMeta: any = [];
  multiSortMetaDup: any = [];
  getOrgId: any;
  instrumentGrps: any;
  resetTable: any;
  totalGroups: any = 0;
  paginationCount: any = 0;
  moment = moment;
  userPolicy: any;
  businessUnitData = [];
  isClickedClearBtn = false;
  instGroupSaveMsg: any;
  showActionItems = true;
  messageTxt: any;
  businessUnit: any;
  ruleNames = '';
  clientName = '';
  stateVal: any;
  initialLoad = false;
  deletedIGList = false;
  isSortingChanged = false;
  defaultSortDataField = {field: 'updTs', order: -1};
  sortClearButtonReset = false;
  k4ClientName: any;

  constructor(public sharedService: SharedService, public fb: UntypedFormBuilder, public customSortService: CustomSortService, public messageService: MessageService,
              public emtrService: EmtrService, public instrumentGrpFormDatsaService: InstrumentGroupFormDataService,
              private router: Router, private r: ActivatedRoute, public instrumentGroupService: InstrumentGroupService,
              public manageEmpGrpService: ManageEmpGrpService, @Inject('entitlementConst') public entitlementConst) {
    this.getSaveInstGrpMessage();
    this.sharedService.setHistoryResponse.subscribe(value => {
      this.instGrpHistoryRecord.instGrpHistory = value.data.content;
      this.instGrpHistoryRecord.historyRecords = value.data.totalElements;
    });
    this.stateVal = this.sharedService.getStateVal();
  }

  @HostListener('document:click', [])
  onClick() { /* Capture the Click Event outside the function */
    if (!this.showActionMenu) {
      this.groupId = '';
    }
    this.showActionMenu = false;
  }

  ngOnInit() {
    /* To disable the client dropdown in the header and set the client dropdown value to all */
    this.sharedService.emitClientDropdownDisableValue.next(true);
    this.userPolicy = this.sharedService.getPolicyResponse('success');
    this.instrumentGroupForm = this.fb.group({
      name: [''],
      description: [''],
      businessUnit: [''],
      updTs: [''],
      showRecords: ['']
    });

    /* Set 'Active' as default value for Show Records dropdown */
    const showRecorValue = this.getEmtrConstants.showRecords[0];
    this.instrumentGroupForm.get('showRecords').setValue(showRecorValue);

    this.sharedService.userBUList.forEach(bu => {
      /* Verifying entitlement for both BU and client level to push BU in the BU filter dropdown */
      /* eslint-disable-next-line max-len */
      if ((this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === bu && val.entitlementList.map(y => y.entitlementName).indexOf('INSTRUMENTS_SUMMARY') !== -1)) || (this.userPolicy.data.userPolicy.clientPolicy && bu === this.sharedConstant.rbsBUText && this.userPolicy.data.userPolicy.clientPolicy.some(val => val.entitlementList.map(y => y.entitlementName).indexOf('INSTRUMENTS_SUMMARY') !== -1))) {
        const obj = {name: bu, value: bu};
        this.businessUnitData.push(obj);
      }
    });

    if (this.businessUnitData.length > 1) {
      const All = {name: 'All', value: 'All'};
      this.businessUnitData.unshift(All);
      this.instrumentGroupForm.get('businessUnit').setValue(All);
    } else {
      this.instrumentGroupForm.get('businessUnit').setValue(this.businessUnitData[0]);
      this.instrumentGroupForm.get('businessUnit').disable();
    }

    this.pageObject.pageNo = this.stateVal && this.stateVal.pageObj.pageNo ? this.stateVal.pageObj.pageNo : 0;
    this.pageObject.pageSize = this.stateVal && this.stateVal.pageObj.pageSize ? this.stateVal.pageObj.pageSize : 15;
    this.stateVal && this.stateVal.multiSortMeta ? this.multiSortMeta = this.stateVal.multiSortMeta : this.multiSortMeta.push(this.defaultSortDataField);
    this.customSortService.sortArr = [];
    this.sharedService.tableDropdownVal.next(this.stateVal ? this.stateVal.pageObj.pageSize : 15);
    this.currentOrgSubscription = this.emtrService.clientInformation.subscribe(clientInfo => {
      this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageInstruments.uuid, '', '');
      this.businessUnit = clientInfo && clientInfo.businessUnit;
      if (clientInfo !== null) {
        this.k4ClientName = clientInfo.k4ClientName;
        /* When client is selected and the selected client does not have access to INSTRUMENTS_SUMMARY entitlement */
        if (!this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
          /* If selected client have Rule Agent role access, then navigate to Rules */
          if (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '')) {
            this.router.navigate(['ems/emtr/rules']);
          }
        }
        this.orgId = clientInfo && clientInfo.orgId;
        this.sharedService.getOrgId = clientInfo && clientInfo.orgId;
        this.emtrService.setCurrentOrg(this.orgId);
      }
    });
    this.onChanges();
    if (this.instGroupSaveMsg) {
      setTimeout(() => {
        this.msgToast.pushMessage('success', this.instGroupSaveMsg);
      }, 1);
      this.removeSuccessMsg = true;
    }
    /* Assigning filter icon value from sessionStorage for State management*/
    if (this.stateVal) {
      this.toggle = this.stateVal.filterClicked;
      this.filterClicked = this.stateVal.filterClicked;
      if (this.filterClicked) {
        this.instrumentGroupForm.markAsDirty();
      }
    }
    this.initialLoad = true;
  }

  toggleFunction() {
    this.sharedService.gutTracking('adobe-fl_instrumentgroupsfilter|fireonce');
    this.toggle = !this.toggle;
    /* Assigning filter value to sessionStorage for State management*/
    this.sharedService.stateValues.toggle = this.toggle;
    this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
  }

  getSelectedDate(date) {
    this.filterSelectedDate = date;
    this.instrumentGroupForm.get('updTs').setValue(this.filterSelectedDate);
    if (!this.isClickedClearBtn && this.filterSelectedDate) {
      this.instrumentGroupForm.markAsDirty();
    }
  }

  resetForm(resetSortFromUI?) {
    this.filterClicked = false;
    this.clearSuccessAndErrorMessages();
    this.instrumentGroupForm.reset();
    this.instrumentGroupForm.markAsPristine();
    const showRecorValue = this.getEmtrConstants.showRecords[0];
    this.instrumentGroupForm.get('showRecords').setValue(showRecorValue);
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
      this.sortClearButtonReset = true;
    }
    if (this.toggle) {
      this.isClickedClearBtn = true;
      this.rangeCalendar.clearSelection();
    }
    this.pageObject.pageNo = 0;
    if (this.paginator) {
      this.paginator.changePage(0);
    }
    this.filterClicked = this.isClickedClearBtn = this.filterBtnDisableForName = false;
  }

  clickedClearButton(resetSortFromUI?) {
    this.resetForm(resetSortFromUI);
    this.sharedService.tableDropdownVal.next(this.pageObject.pageSize);
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    if (!this.sortClearButtonReset) {
      this.getInstrumentGroupList();
    }
  }

  applyFilter() {
    this.filterClicked = true;
    if (this.rangeCalendar) {
      this.rangeCalendar.applyToDate();
      this.getSelectedDate(this.rangeCalendar.dateRangeForm.get('dateSelected').value);
    }
    this.pageObject.pageNo = 0;
    if (this.paginator) {
      this.paginator.changePage(0);
    }
    /* Updating values to sessionStorage for State management*/
    if (this.stateVal) {
      this.stateVal.pageObj.pageSize = 15;
      this.stateVal.pageObj.pageNo = 0;
      this.stateVal.filterObj = this.setFormValues();
    }
    this.clearSuccessAndErrorMessages();
    this.getInstrumentGroupList();
  }

  onChanges() {
    this.instrumentGroupForm.get('name').valueChanges.subscribe(value => {
      if (value) {
        if (value.length < 2) {
          this.filterBtnDisableForName = true;
          this.schError.minCharacter = true;
        } else {
          this.filterBtnDisableForName = false;
          this.schError.minCharacter = false;
        }
        if (this.instrumentGroupForm.get('name').invalid) {
          this.schError.invalidCharacter = true;
        } else {
          this.schError.invalidCharacter = false;
        }
      } else {
        this.schError.invalidCharacter = false;
        this.schError.minCharacter = false;
        this.filterBtnDisableForName = false;
      }
      this.sendError.emit(this.schError);
    });
  }

  customSort(event) {
    if (event.multiSortMeta) {
      this.multiSortMetaDup = this.multiSortMeta;
      this.multiSortMeta = event.multiSortMeta;
      const sortArr = this.customSortService.customSort(event);
      if (this.pageObject.sortField !== sortArr) {
        this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
        const checkStateVal = this.sharedService.getStateVal();
        if (checkStateVal && JSON.stringify(event.multiSortMeta) !== JSON.stringify(checkStateVal.multiSortMeta)) {
          if (this.stateVal && this.stateVal.pageObj) {
            this.stateVal.pageObj.pageNo = 0;
          }
          this.pageObject.pageNo = 0;
          if (this.paginator) {
            this.paginator.changePage(0);
          }
        }
        /* Assigning updated page number from sessionStorage for State management*/
        if (this.stateVal && this.stateVal.pageObj.pageNo) {
          setTimeout(() => {
            this.paginator.totalRecords = this.stateVal ? this.stateVal.totalElem : 0;
            this.paginator.changePage(this.stateVal.pageObj.pageNo > 0 ? this.stateVal.pageObj.pageNo : 0);
          }, 0);
        }
        this.pageObject = {
          pageNo: this.pageObject.pageNo,
          pageSize: this.pageObject.pageSize,
          sortField: sortArr
        };
        if (this.initialLoad) {
          this.getInstrumentGroupList();
          this.initialLoad = false;
        } else {
          this.sharedService.clearErrorMessage();
          if ((this.multiSortMetaDup !== this.multiSortMeta) || this.multiSortMetaDup.length > 1 || this.sortClearButtonReset) {
            this.messageService.clear();
            this.getInstrumentGroupList();
            this.sortClearButtonReset = false;
          }
        }
      }
    }
  }

  formData() {
    /* Action item for soft-deleted instrument group will be 'History' and 'Restore' */
    const actionItem = this.deletedIGList ? InstrumentGroupsListRestoreActionMenuItems : this.showActionItems ? InstrumentGroupsListActionMenuItems : InstrumentGroupsListHistoryActionMenuItem;
    this.actionMenu = actionItem.filter(item => item.label);
    return this.actionMenu;
  }

  paginate(e) {
    if (this.pageObject.pageNo !== e.page) {
      this.pageObject.pageNo = e.page;
      this.clearSuccessAndErrorMessages();
      this.getInstrumentGroupList();
    }
  }

  setFormValues() {
    const localUpdatedFormValues = this.instrumentGroupForm.getRawValue();
    const fromDate = [];
    if (this.filterSelectedDate !== undefined && this.filterSelectedDate !== '' && this.filterSelectedDate !== null) {
      this.filterSelectedDate.forEach(date => {
        fromDate.push(date);
      });
    }
    const formFilterObj: any = {
      name: localUpdatedFormValues.name ? localUpdatedFormValues.name : '',
      description: localUpdatedFormValues.description ? localUpdatedFormValues.description : '',
      lastUpdatedFromDate: fromDate.length > 0 ? moment(fromDate[0]).format('MM/DD/YYYY') : '',
      lastUpdatedToDate: fromDate.length > 0 ? moment(fromDate[1]).format('MM/DD/YYYY') : '',
      businessUnit: localUpdatedFormValues.businessUnit ? localUpdatedFormValues.businessUnit.value : '',
      active: localUpdatedFormValues.showRecords ? localUpdatedFormValues.showRecords.value : '',
    };
    return formFilterObj;
  }

  getInstrumentGroupList() {
    const formFilterObj = this.setFormValues();
    const defaultObj = {
      name: '',
      description: '',
      lastUpdatedFromDate: '',
      lastUpdatedToDate: '',
      businessUnit: this.businessUnitData[0].name,
      active: true
    };
    const filterParam = !this.sharedService.isClientChanged ? this.stateVal && this.stateVal.filterObj ? this.stateVal.filterObj : formFilterObj : formFilterObj;
    this.sharedService.isClientChanged = false;
    if (this.filterClicked) {
      this.updateformValues(filterParam);
      this.instrumentGroupService.getInstrumentGroupList(this.pageObject, filterParam).subscribe((response) => {
        if (response.data) {
          this.instrumentGrps = response.data.content;
          this.totalGroups = this.sharedService.totalElementsWithCommas(response.data.totalElements);
          this.paginationCount = response.data.totalElements;
          if (this.instrumentGrps.length > 0) {
            this.resetTable = false;
            return this.instrumentGrps;
          }
        }
      }, error => {
        this.instrumentGrps = [];
        return this.instrumentGrps;
      });
      this.sharedService.stateValues.filterObj = this.stateVal.filterObj ? this.stateVal.filterObj : formFilterObj;
      this.updateStateValues(formFilterObj);
    } else {
      this.updateformValues(defaultObj);
      this.instrumentGroupService.getInstrumentGroupList(this.pageObject, defaultObj).subscribe((response) => {
        if (response.data) {
          this.instrumentGrps = response.data.content;
          this.totalGroups = this.sharedService.totalElementsWithCommas(response.data.totalElements);
          this.paginationCount = response.data.totalElements;
          if (this.instrumentGrps.length > 0) {
            this.resetTable = false;
            return this.instrumentGrps;
          }
        }
      }, error => {
        this.instrumentGrps = [];
        return this.instrumentGrps;
      });
      this.updateStateValues(defaultObj);
    }
  }

  updateStateValues(formFilterObj) {
    /* Updating State Values for paginator to support page Val for State management*/
    this.sharedService.stateValues.totalElem = this.sharedService.stateValues.totalElem ? this.sharedService.stateValues.totalElem : this.paginationCount;
    this.sharedService.stateValues.pageObj.pageNo = this.pageObject.pageNo;
    this.sharedService.stateValues.pageObj.pageSize = this.pageObject.pageSize;
    this.sharedService.stateValues.multiSortMeta = this.multiSortMeta;
    this.sharedService.stateValues.filterClicked = this.filterClicked;
    this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
    this.stateVal = this.sharedService.getStateVal();
    this.sharedService.tableDropdownVal.next(this.stateVal ? this.stateVal.pageObj.pageSize : this.pageObject.pageSize);
  }

  navigateToCreateInstrumentGroup() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue('newinstrumentgroup');
    this.instrumentGrpFormDatsaService.resetForm();
    this.instrumentGroupService.deletedIG = false;
    this.router.navigate([this.getEmtrConstants.instrumentGroups.instrumentGroupList.toStep1], {relativeTo: this.r});
  }

  getSaveInstGrpMessage() {
    this.manageEmpGrpService.getMessage().subscribe((data) => {
      this.instGroupSaveMsg = data.text ? data.text : '';
    });
  }

  onActionMenuClick(event: any, insGroupData: any) {
    const actionItem = event.target.parentElement.innerText;
    this.clearSuccessAndErrorMessages();
    if (actionItem === 'Delete') {
      this.fetchRuleNamesLinkedToInstrumentGroup(insGroupData);
    } else if (actionItem === 'History') {
      this.loadEmpGrpHistory(insGroupData);
    } else if (actionItem === 'Restore') {
      this.restoreInstrumentGroup(insGroupData);
    }
  }

  loadEmpGrpHistory(instGrpData) {
    this.instGrpHistoryRecord.historyId = instGrpData.id;
    this.instGrpHistoryRecord.instGrpSecurities = instGrpData.type;
    this.instGrpHistoryRecord.instGrpName = this.getEmtrConstants.instrumentGroups.instrumentGroupList.instrumentGroupHistory + ' - ' + instGrpData.name;
    this.sharedService.loadViewHistory(this.getOrgId, instGrpData, 'instGroup', this.viewHistoryData);
  }

  /* To restore the deleted instrument group */
  restoreInstrumentGroup(instGrpData) {
    /* Clear any service error message */
    this.sharedService.clearErrorMessage();

    /* Set active field to true */
    instGrpData.active = true;

    /* Calling instrument grp update endpoint with active flag as true to restore the deleted instrument group */
    this.instrumentGroupService.saveInstrumentGroup(instGrpData).subscribe(value => {
      this.showToast = true;
      /* Set success message for restored instrument group */
      this.messageTxt = '<strong>' + instGrpData.name + '</strong>&nbsp;' + this.getEmtrConstants.instrumentGroups.instrumentGrpDelete.instrumentGroupRestoreMsg;
      this.msgToast.pushMessage('success', this.messageTxt);
      /* Calling instrument grp list endpoint to update the displayed instrument group list on landing page */
      this.getInstrumentGroupList();
    }, (err) => {
      this.showToast = false;
    });
  }

  getHistoryPagination(id, data) {
    this.sharedService.getHistoryPaginatedValues(id, 'instGroup', data);
  }

  editInstrumentGroup(rowData) {
    this.instrumentGrpFormDatsaService.resetForm();
    this.instrumentGroupService.deletedIG = this.deletedIGList;
    this.instrumentGroupService.getSecurityGroupbyID(rowData.id, rowData.businessUnit).subscribe((instGrpData: any) => {
      this.instrumentGrpFormDatsaService.setMode('EDIT');
      const res = instGrpData.data;
      this.router.navigate([this.getEmtrConstants.instrumentGroups.instrumentGroupList.toStep3], {relativeTo: this.r});
      this.instrumentGrpFormDatsaService.validateAllSteps();
      this.instrumentGrpFormDatsaService.setFormData(res);
    });
  }

  fetchRuleNamesLinkedToInstrumentGroup(insGroupData) {
    const confirmObj = {
      header: `${this.getEmtrConstants.rulesConstant.ruleGlobalConst.delete} - ${insGroupData.name}`,
      message: `<strong>${insGroupData.name} </strong>${this.getEmtrConstants.rulesConstant.rulesLanding.deleteNotAssignedMsgConfirmation} ${this.getEmtrConstants.rulesConstant.rulesLanding.deleteMsgConfirmation}`,
      acceptBtn: this.getEmtrConstants.rulesConstant.ruleGlobalConst.delete,
      rejectBtn: this.getEmtrConstants.rulesConstant.ruleGlobalConst.cancel
    };
    const confirmWithCloseObj = {
      header: `${this.getEmtrConstants.rulesConstant.ruleGlobalConst.delete} - ${insGroupData.name}`,
      message: `${this.getEmtrConstants.rulesConstant.rulesLanding.instrumentGrpDeleteMsg}`,
      acceptBtn: this.getEmtrConstants.rulesConstant.ruleGlobalConst.hideAcceptButton,
      rejectBtn: this.getEmtrConstants.rulesConstant.ruleGlobalConst.close,

    };
    this.instrumentGroupService.deleteInstrumentGrp(insGroupData.id, false).subscribe((response: any) => {
      const names = response.data;
      if (names) {
        if (names.noOfRules > 0) {
          if (names.rulesInActiveStatus.length > 0 || names.rulesInDraftStatus.length > 0 || names.rulesInScheduledStatus.length > 0) {
            Object.keys(names).forEach((status) => {
              const statusValue = names[status];
              if (status !== 'rulesInExpiredStatus' && statusValue.length > 0) {
                statusValue.forEach((key) => {
                  const rulesLinked = key.name;
                  const clientName = key.customClientName;
                  this.ruleNames = this.ruleNames.concat('<li><strong>' + rulesLinked + '</strong>' + ' - ' + clientName + '</li>');
                });
                confirmWithCloseObj.message = `<strong>${insGroupData.name}</strong> ${this.sharedConstant.instrumentGrpDeleteMsg} <ul class='sdps-list--ordered sdps-m-vertical_medium show-scrollable-list'>${this.ruleNames}</ul>`;
              }
            });
          } else {
            confirmObj.message = `<strong>${insGroupData.name}</strong> ${this.getEmtrConstants.rulesConstant.rulesLanding.deleteNotAssignedMsgConfirmation} ${this.getEmtrConstants.rulesConstant.rulesLanding.deleteMsgConfirmation}`;
          }
          const obj = this.ruleNames.length > 0 ? confirmWithCloseObj : confirmObj;
          this.deleteInstrumentGroup(obj, insGroupData);
        } else {
          confirmObj.message = `<strong>${insGroupData.name}</strong> ${this.getEmtrConstants.rulesConstant.rulesLanding.deleteNotAssignedMsgConfirmation} ${this.getEmtrConstants.rulesConstant.rulesLanding.deleteMsgConfirmation}`;
          this.deleteInstrumentGroup(confirmObj, insGroupData);
        }
      }
    });
  }

  deleteInstrumentGroup(confirmObj , insGroupData) {
    Observable.create((observer: Observer<boolean>) => {
      this.ruleNames = '';
      this.instrumentGrpDeleteConfirmMsg.showConfirmMsg(confirmObj, observer);
    }).subscribe(accept => {
      if (accept) {
        this.showToast = false;
        this.sharedService.errorMessage.next(false);
        this.instrumentGroupService.deleteInstrumentGrp(insGroupData.id, true).subscribe(data => {
        this.showToast = true;
        this.messageTxt = '<strong>' + insGroupData.name + '</strong>&nbsp;' + this.getEmtrConstants.instrumentGroups.instrumentGrpDelete.instrumentGroupDeleteMsg;
        this.msgToast.pushMessage('success', this.messageTxt);
        this.pageObject.pageNo = 0;
        this.pageObject.pageSize = 15;
        this.getInstrumentGroupList();
        },
        (err) => {
          if (err.status === 400) {
            this.sharedService.clearErrorMessage();
            const failureMessage = err.error.message;
            this.msgToast.pushMessage('error', failureMessage);
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    if (this.router.routerState.snapshot.url.indexOf(this.getEmtrConstants.instrumentGroups.instrumentGroupList.mainRoute) === -1) {
      this.instrumentGrpFormDatsaService.resetForm();
      this.sharedService.clearLocalStorage();
    }
    this.manageEmpGrpService.clearMessage();
    this.sharedService.updatedDate.next('');
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
    this.sharedService.tableDropdownVal.next(15);
  }

  updateformValues(formVals) {
    this.deletedIGList = !formVals.active;
    this.sharedService.updatedDate.next(formVals.lastUpdatedFromDate ? {name: 'Require Indexing', fromDate: formVals.lastUpdatedFromDate, toDate: formVals.lastUpdatedToDate } : '');
    const showRecordLabel = formVals.active ? 'Active' : 'Deleted';
    this.instrumentGroupForm.patchValue({
      name: formVals.name ? formVals.name : '',
      description: formVals.description ? formVals.description : '',
      businessUnit: formVals.businessUnit ? {name: formVals.businessUnit, value: formVals.businessUnit} : '',
      showRecords: {value: formVals.active, label: showRecordLabel}
    });
  }

  clearSuccessAndErrorMessages() {
    this.messageService.clear(); 
    this.sharedService.clearErrorMessage();
  }
}

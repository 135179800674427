import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {emtrConstant} from '../../constants/emtrConstants';
import {RuleService} from '../../services/rule-service/rule.service';
import {AssetGroupsTableHeader, TableHeader} from '../../models/security-type.model';
import {SharedService, SharedConstant} from '@ems/shared';
import {SecurityTypeComponent} from'../security-type/security-type.component';

@Component({
  selector: 'tr-preset-rules-and-asset-group',
  templateUrl: './preset-rules-and-asset-group.component.html',
  styleUrls: ['./preset-rules-and-asset-group.component.scss']
})
export class PresetRulesAndAssetGroupComponent implements OnInit , AfterViewChecked {

  protected readonly getConstant = emtrConstant;

  @Input() presetRuleIdInput: any;
  @Input() assetGroupsInput: any = {};
  @Input() selectedValues: any = [];
  @Input() globalRuleFlag: any = [];
  @Input() micAGSelectedFlag = false;
  @Output() presetRuleChange = new EventEmitter<any>();
  @Output() presetRuleKey = new EventEmitter<any>();
  @Output() emitWarningMsg = new EventEmitter<any>();
  @Output() assetGroupChange = new EventEmitter<any>();
  @Output() micAssetGroupSelectedFlag = new EventEmitter<boolean>();
  @Output() selectedActionDropdownValue = new EventEmitter<any>();
  @Output() customValue = new EventEmitter<boolean>();

  @ViewChild('reviewSecurity', { static: true }) reviewSecurityType: SecurityTypeComponent;

  presetRuleInput: any;
  expandedPresetRule = true;
  expandedAssetGroup = true;
  expandedMiscAssetGroup = true;
  emtrConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  assetGroups = [];
  assetGroupsWithAttributes = [];
  presetRules = [];
  mutualFundsDropdownValue = [];
  assetGroupsSelection = [];
  showWarningMsg: any;
  presetRulesSet1: any;
  presetRulesSet2: any;
  presetRuleId: any;
  customAssetGrpId: any;
  assetGroupsTreeData: any = [];
  selectedNodes: any = [];
  newSelectedNodes: any = [];
  checked = false;
  superParentNode: any;
  rowNodeArray: any = [];
  childrenNode: any = [];
  columnsLevel: any[];
  assetGroupTableHeader: TableHeader[] = AssetGroupsTableHeader;
  count = 0;
  index = 0;
  selectedAction: any;
  selectedCustomAssetGrp = [];
  customAssetGrp = [];
  customAssetGrpFlag = false;
  miscellaneousAssetGrpForm = this.fb.group({});
  miscAssetGroups = [];
  debtAssGrpName = '';
  businessUnit: any = '';
  assetGrpLabel: any = '';
  securityData = [];
  multiLevelSecurityType: any = {};
  custom = false;

  constructor(public ruleService: RuleService, public el: ElementRef, public fb: UntypedFormBuilder, @Inject('entitlementConst') public entitlementConst, public sharedService: SharedService) {
    this.sharedService.clientInformation.subscribe(clientInfo => {
      this.businessUnit = clientInfo && clientInfo.businessUnit;
    });
  }

  ngOnInit() {
    this.columnsLevel = this.assetGroupTableHeader;
    /* Set CUSTOM ASSET GROUP as default selection */
    this.presetRuleInput = this.emtrConstant.presetRules.customAssetGroupText;
    /* Set label for asset group section based on the client selected (DBS/RBS client) */
    this.assetGrpLabel = this.businessUnit && this.businessUnit === this.getSharedConstant.rbsBUText ? this.emtrConstant.presetRules.assetGroupText : this.emtrConstant.presetRules.assetGroupsAndAttributesText;
    this.presetRuleKey.emit(this.presetRuleInput);
    /* Set 'Select' as default selection in the OER dropdown */
    this.selectedAction = {name: 'Select', value: ''};
    if (this.sharedService.assetGrpCachedData && this.sharedService.assetGrpCachedData.length > 0 && this.businessUnit === this.sharedService.assetGrpCachedData[0].bu) {
      this.fetchAssetGroups(this.sharedService.assetGrpCachedData);
    } else {
      this.getAssetGroups();
    }
    if (this.businessUnit !== 'DBS') {
      if (this.sharedService.presetRuleCachedData && this.sharedService.presetRuleCachedData.length > 0) {
        this.fetchPresetRules(this.sharedService.presetRuleCachedData);
      } else {
        this.getPresetRules();
      }
    } else {
      const localObj = {assetGroups: this.assetGroupsInput};
      /* Custom flag will be set to true for MF preset rules selection only and for DBS asset group selection custom flag will be set to false */
      this.custom = false;
      setTimeout(() => {
        this.updateAssetGroup(localObj);
        this.updateCheckboxCssForDBS();
        this.emitSelectedAGandPresetRule(this.presetRuleId, this.presetRuleInput, this.assetGroupsSelection, false);
      }, 500);
  }
    if (!this.globalRuleFlag && this.micAGSelectedFlag) {
      this.miscellaneousAssetGrpForm.reset();
      this.assetGroupsInput = [];
    }
  }

  getAssetGroups() {
    /* Fetch Asset group and global asset group */
    this.ruleService.getAssetGroups().subscribe(assetGrp => {
      this.fetchAssetGroups(assetGrp.data);
    });
  }

  getPresetRules() {
    /* Fetch preset rules to be displayed and the corresponding asset group details */
    this.ruleService.getPresetRules().subscribe(presetData => {
      this.fetchPresetRules(presetData.data);
    });
  }

  fetchPresetRules(presetData) {
    /* Fetch preset rules to be displayed and the corresponding asset group details */
      this.presetRules = presetData;
      this.presetRules.forEach(presetRuleData => {
        if (presetRuleData.key === this.emtrConstant.presetRules.customAssetGroupText) {
          this.customAssetGrpId = presetRuleData.id;
        }
      });

      /* Divide the presetRules list into 2, for display purpose on UI Screen */
      this.presetRulesSet1 = this.presetRules && this.presetRules.slice(0, 5);
      this.presetRulesSet2 = this.presetRules && this.presetRules.slice(5, this.presetRules.length);

      /* Set the selected preset rule and asset groups value - data sent from parent component */
      setTimeout(() => {
        /* To update the secCatgs value in the preset rules from the asset group secCatgs. In preset rules we only get the missing securities other than asset groups
        * hence to populate all the required value we need to get all the securities from preset rules and asset groups */
        if (this.sharedService.presetRuleCachedData && this.sharedService.presetRuleCachedData.length === 0) {
          this.presetRules = this.ruleService.updateSecCatgsInPresentRule(this.presetRules, this.assetGroups);
          this.sharedService.presetRuleCachedData = this.presetRules;
        }
        if (this.presetRuleIdInput) {
          this.presetRuleId = this.presetRuleIdInput;
          this.presetRules.forEach(presetRuleData => {
            if (this.presetRuleId === presetRuleData.id) {
              if (presetRuleData.id === this.customAssetGrpId) {
                this.customAssetGrpFlag = true;
              }
              this.presetRuleInput = presetRuleData.key;
              if (this.micAGSelectedFlag && this.globalRuleFlag) {
                this.updateMiscAssetGroupSelection();
              } else {
                this.updatePresetRule();
              }
            }
          });
        }
      }, 500);
  }

  /* Fetch asset group to be displayed */
  fetchAssetGroups(assetGrp) {
    setTimeout(() => {
      if (this.assetGroupsInput && this.assetGroupsInput.some(val => val.name === this.emtrConstant.ruleSteps.step2.mutualFunds)) {
        this.assetGroupsInput.forEach(data => {
          data.attributes.forEach(operation => {
            if (operation.name === this.emtrConstant.ruleSteps.step2.operatingNetExpenseRatio) {
              this.selectedAction = {name: operation.value, code : operation.code, value: operation.value };
            }
          });
        });
      }
    }, 1250);

    /* Fetch Asset group and global asset group */
    this.assetGroups = assetGrp;

    if (this.assetGroups.length > 0) {
      this.sharedService.assetGrpCachedData = [];
      this.assetGroups.forEach(val => {
        /* We are iterating each value and pushing data into cache, to avoid the lose of attribute value */
        this.sharedService.assetGrpCachedData.push({
          bu: val.bu,
          code: val.code ? val.code : '',
          id: val.id,
          name: val.name,
          label: val.label ? val.label : '',
          attributes: val.attributes ? val.attributes : [],
          secCatgs1: val.secCatgs1,
          secCatgs2: val.secCatgs2,
          secCatgs3: val.secCatgs3,
          order: val.order
        });
        val.attributes.forEach(type => {
          /* Set Operating / Net Expense Ratio dropdown value */
          if (type.htmlType === 'dropdown') {
            const newObj = {name: 'Select', value: ''};
            this.mutualFundsDropdownValue.push(newObj);
            type.value.forEach(percentage => {
              const obj = {name: percentage, code: type.code, value: percentage};
              this.mutualFundsDropdownValue.push(obj);
            });
          }
        });

        /* Forming miscellaneous asset group array to create form control and to display miscellaneous asset group on the screen */
        if (val.code.startsWith('g-')) {
          const localVal = {id: val.id, name: val.name, code: val.code, attributes: val.attributes, secCatgs1: val.secCatgs1, secCatgs2: val.secCatgs2, secCatgs3: val.secCatgs3};
          this.miscAssetGroups.push(localVal);
          if (val.name === 'Debt') {
            this.debtAssGrpName = val.attributes[0].name;
          }
        }
      });
    }

    /* Creating form controls for Miscellaneous asset group names */
    this.miscAssetGroups.forEach(asstGrpVal => {
      this.miscellaneousAssetGrpForm.addControl(asstGrpVal.name, this.fb.control(''));
    });

    /* Format the response of asset group to Treetable JSON Format */
    this.assetGroupsTreeData = this.formatJson(this.assetGroups);
  }

  /* Formatting the Backend JSON to Treetable JSON Format */
  formatJson(data) {
    return data.map(dataEle => {
      let subLevels = [];
      dataEle.attributes.forEach(value => {
        if (value) {
          value.parent = dataEle.name;
        }
        subLevels.push({data: value});
      });
      this.assetGroupsWithAttributes.push({id: dataEle.id, name: dataEle.name, code: dataEle.code ? dataEle.code : '', attributes: dataEle.attributes ? dataEle.attributes : []});
      delete dataEle.attributes;
      /* Creating object of a Parent & child relation */
      if (subLevels) {
        const obj = {
          data: dataEle,
          children: subLevels,
          expanded: true,
          name: dataEle.name,
          label: dataEle.label ? dataEle.label : ''
        };
        subLevels = [];
        return obj;
      } else {
        /* Creating object of a Parent with no attributes/children */
        const obj = {
          data: dataEle,
          children: [],
          expanded: false,
        };
        return obj;
      }
    });
  }

  /* Value creation for each checkbox/node of Treetable */
  isParentNode(rowNode) {
    this.count++;
    /* rowNodeArray will contain the value of all the checkbox in asset group table -> helps in pushing data into
    selectedNode array when any preset rule is selected or during the edit flow */
    if (rowNode && this.rowNodeArray.indexOf(rowNode.node) === -1) {
      this.rowNodeArray.push(rowNode.node);
    }

    /* Assign the node value to parent checkbox */
    if (rowNode && !rowNode.level) {
      this.superParentNode = rowNode;
      this.childrenNode = [];
      this.count = 0;
    }

    /* Create an array children node value to assign value to children checkbox */
    if (rowNode && (rowNode.parent && this.count === 1)) {
      if (rowNode.parent.children.length > 0) {
        rowNode.parent.children.forEach(data => {
          const newObj = {
            level: 1,
            node: {data: data.data, parent: rowNode.parent},
            parent: rowNode.parent,
          };
          this.childrenNode.push(newObj);
        });
      }
      return true;
    } else {
      /*to print parent which does not have children*/
      if (this.count === 0 && rowNode && rowNode.node.children.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  togglePresetRule() {
    this.expandedPresetRule = !this.expandedPresetRule;
  }

  toggleAssetGroup() {
    this.expandedAssetGroup = !this.expandedAssetGroup;
  }

  toggleMiscellaneousAssetGroup() {
    this.expandedMiscAssetGroup = !this.expandedMiscAssetGroup;
  }

  updatePresetRule() {
    /* Remove the warning msg on parent component when any preset rule is selected */
    this.showWarningMsg = this.globalRuleFlag ? '' : this.getSharedConstant.RBS.warningMsgForPresetRulesOrSecurities;
    this.emitWarningMsg.emit(this.showWarningMsg);

    /* Clear out the miscellaneous asset group selection, when any preset rule is selected */
    this.miscellaneousAssetGrpForm.reset();

    /* Clear assetGroupsSelection, selectedNodes and selectedArr before setting the new values */
    this.assetGroupsSelection = [];
    this.selectedNodes = [];

    /* Clear security value selected on Review Security Details before setting the new values */
    this.multiLevelSecurityType = [];

    /* Set 'Select' as default selection in the OER dropdown */
    this.selectedAction = {name: 'Select', value: ''};
    const selectedArr = [];

    /* Add and mark partialSelected field as false for all the nodes */
    const localRowNode = this.rowNodeArray;
    localRowNode.forEach(nodeVal => {
      nodeVal.partialSelected = false;
    });

    /* Iterate through the presetRules list and through corresponding assetGroups associated with each the preset rule for
    making the asset group selection */
    this.presetRules.forEach(rule => {
      const secCatgs = {
        secCatgs1: [],
        secCatgs2: [],
        secCatgs3: []
      }

      /* Compare the selected preset rule with each key value iterated from the presetRules list */
      if (this.presetRuleInput === rule.key) {
        this.presetRuleId = rule.id;

        /* Set the custom flag to the value given be api, custom will be true for all MF rules else for other rules custom will be false */
        this.custom = rule.custom;

        /* The selected rule will have all the required secCatgs, and to form the selected security array to display on the review security details modal call fetchAndPupulateSecurityTypeList */
        secCatgs.secCatgs1 = rule.secCatgs1 ? rule.secCatgs1 : [];
        secCatgs.secCatgs2 = rule.secCatgs2 ? rule.secCatgs2 : [];
        secCatgs.secCatgs3 = rule.secCatgs3 ? rule.secCatgs3 : [];

        if (this.presetRuleId !== this.customAssetGrpId) {
          /* Set the customAssetGrpFlag to false if the selected preset rule is not CUSTOM ASSET GROUP */
          this.customAssetGrpFlag = false;
        }
        if (rule.assetGroups.length > 0 || this.customAssetGrpFlag) {
          /* Fetch the assetGroups info from the assetGroupInput if the selected preset rule is CUSTOM ASSET GROUP
           else fetch it from the preset rule list returned from api */
          const localRules = this.customAssetGrpFlag ? {assetGroups: this.assetGroupsInput} : rule;
          /* Iterate through the assetGroups corresponding to the selected preset rule to make the asset group selection */
          localRules.assetGroups.forEach(assetGrp => {
            /* Mainly this section is for loading the securites of the selected asset group, when user lands on rule step2 from step1/step3 */
            if (this.customAssetGrpFlag) {
              /* Custom flag will be set to true for MF preset rules selection only and for any asset group selection custom flag will be set to false */
              this.custom = false;
              this.assetGroups.some(grp => assetGrp.name === grp.name && grp.secCatgs1 ? grp.secCatgs1.filter(security => secCatgs.secCatgs1.indexOf(security) === -1 ? secCatgs.secCatgs1.push(security) : '') : '');
              this.assetGroups.some(grp => assetGrp.name === grp.name && grp.secCatgs2 ? grp.secCatgs2.filter(security => secCatgs.secCatgs2.indexOf(security) === -1 ? secCatgs.secCatgs2.push(security) : '') : '');
              this.assetGroups.some(grp => assetGrp.name === grp.name && grp.secCatgs3 ? grp.secCatgs3.filter(security => secCatgs.secCatgs3.indexOf(security) === -1 ? secCatgs.secCatgs3.push(security) : '') : '');
            }
            if (assetGrp.attributes && assetGrp.attributes.length > 0) {
              assetGrp.attributes.forEach(attributes => {
                /* Iterate through rowNodeArray that contain the value of all the checkbox in asset group table to push the asset group
                corresponding to the selected preset rule into selectedNode array --> pushing the data of child selection */
                this.rowNodeArray.forEach(rowData => {
                  if (rowData.data.name === attributes.name && rowData.parent.data.name === assetGrp.name) {
                    rowData.parent.partialSelected = true;
                    rowData.partialSelected = false;
                    if (this.emtrConstant.ruleSteps.step2.operatingNetExpenseRatio === attributes.name) {
                      rowData.selectedValueIs = attributes.value;
                    }
                    selectedArr.push(rowData);
                  }
                });
              });
            } else {
              /* Iterate through rowNodeArray that contain the value of all the checkbox in asset group table to push the asset group
                corresponding to the selected preset rule into selectedNode array --> pushing the data of parent selection */
              this.rowNodeArray.forEach(rowData => {
                if (rowData.data.name === assetGrp.name) {
                  rowData.partialSelected = false;
                  selectedArr.push(rowData);
                }
              });
            }
          });

          /* Call fetchAndPupulateSecurityTypeList to populate the security modal with fetched secCatgs  */
          this.fetchAndPupulateSecurityTypeList(secCatgs);

          const localAssetGrp = [];
          let assetGrpValue = [];
          this.assetGroupsWithAttributes.forEach(assetGrpsVal => {
            localRules.assetGroups.forEach(grp => {
              if (assetGrpsVal.name === grp.name) {
                assetGrpValue = [];
                grp.code = assetGrpsVal.code ? assetGrpsVal.code : '';
                grp.attributes.forEach( (userSelected) => {
                  const matchedMF = assetGrpsVal.attributes.filter( (MFtype) => MFtype.name === userSelected.name) ;
                  if (matchedMF && matchedMF.length > 0) {
                    userSelected.code = matchedMF[0].code ? matchedMF[0].code : '';
                    assetGrpValue.push({name: userSelected.name, code: userSelected.code, value: userSelected.value});
                  }
                });
                localAssetGrp.push({id: assetGrpsVal.id, name: grp.name, code: grp.code, attributes: assetGrpValue});
              }
            });
          });
          this.assetGroupsSelection = localAssetGrp;
        }
      }
    });
    this.selectedNodes = selectedArr;
    this.updateCheckboxCss();
    this.emitSelectedAGandPresetRule(this.presetRuleId, this.presetRuleInput, this.assetGroupsSelection, false);
  }

  updateAssetGroup(localRules) {
    /* Clear security value selected on Review Security Details before setting the new values */
    this.multiLevelSecurityType = [];

    const selectedArr = [];
    /* Iterate through the assetGroups corresponding to the selected preset rule to make the asset group selection */
    localRules.assetGroups.forEach(assetGrp => {
      if (assetGrp.attributes && assetGrp.attributes.length > 0) {
        assetGrp.attributes.forEach(attributes => {
          /* Iterate through rowNodeArray that contain the value of all the checkbox in asset group table to push the asset group
          corresponding to the selected preset rule into selectedNode array --> pushing the data of child selection */
          this.rowNodeArray.forEach(rowData => {
            if (rowData.data.name === attributes.name && rowData.parent.data.name === assetGrp.name) {
              rowData.parent.partialSelected = true;
              rowData.partialSelected = false;
              if (this.emtrConstant.ruleSteps.step2.operatingNetExpenseRatio === attributes.name) {
                rowData.selectedValueIs = attributes.value;
              }

              /* This section is for loading the securites of the selected DBS asset group, when user lands on rule step2 from step1/step3 */
              const secCatgs = {
                secCatgs1: rowData.parent.data.secCatgs1 ? rowData.parent.data.secCatgs1 : [],
                secCatgs2: rowData.parent.data.secCatgs2 ? rowData.parent.data.secCatgs2 : [],
                secCatgs3: rowData.parent.data.secCatgs3 ? rowData.parent.data.secCatgs3 : []
              }
              this.fetchAndPupulateSecurityTypeList(secCatgs);
              selectedArr.push(rowData);
            }
          });
        });
      } else {
        /* Iterate through rowNodeArray that contain the value of all the checkbox in asset group table to push the asset group
          corresponding to the selected preset rule into selectedNode array --> pushing the data of parent selection */
        this.rowNodeArray.forEach(rowData => {
          if (rowData.data.name === assetGrp.name) {
            rowData.partialSelected = false;
            selectedArr.push(rowData);
          }
        });
      }
    });

    const localAssetGrp = [];
    let assetGrpValue = [];
    this.assetGroupsWithAttributes.forEach(assetGrpsVal => {
      localRules.assetGroups.forEach(grp => {
        if (assetGrpsVal.name === grp.name) {
          assetGrpValue = [];
          grp.code = assetGrpsVal.code ? assetGrpsVal.code : '';
          grp.attributes.forEach( (userSelected) => {
            const matchedMF = assetGrpsVal.attributes.filter( (MFtype) => MFtype.name === userSelected.name) ;
            if (matchedMF && matchedMF.length > 0) {
              userSelected.code = matchedMF[0].code ? matchedMF[0].code : '';
              assetGrpValue.push({name: userSelected.name, code: userSelected.code, value: userSelected.value});
            }
          });
          localAssetGrp.push({id: assetGrpsVal.id, name: grp.name, code: grp.code, attributes: assetGrpValue});
        }
      });
    });
    this.assetGroupsSelection = localAssetGrp;
    this.selectedNodes = selectedArr;
    this.newSelectedNodes = selectedArr;
  }

  updateCheckboxCssForDBS() {
    const wholeArray = this.el.nativeElement.querySelectorAll('td.assetGrptd');
    /* Remove the css from all checkboxes before adding any new css */
    wholeArray.forEach(element => {
      this.updateAssetGroupSelection('remove', '', element);
    });

    /* Adding css for parent and the child checkbox */
    this.selectedNodes.forEach((rowData) => {
      wholeArray.forEach(element => {
        const outerText = element.outerText;
        /* Adding the css to the parent checkbox, whose children are selected */
        if (rowData.parent) {
          if (element.outerText === rowData.parent.data.name) {
            this.updateAssetGroupSelection('add', 'check', element);
          } else if (element.outerText === rowData.data.name && element.parentElement.outerText.includes(rowData.parent.data.name)) {
            /* Adding the css to the children checkbox and to parent checkbox, whose children are not selected */
            this.updateAssetGroupSelection('add', 'check', element);
          }
        } else if (element.parentElement.outerText.includes(rowData.data.name)) {
          this.updateAssetGroupSelection('add', 'check', element);
        }
      });
    });
  }

  updateCheckboxCss() {
    const wholeArray = this.el.nativeElement.querySelectorAll('td.assetGrptd');
    /* Remove the css from all checkboxes before adding any new css */
    wholeArray.forEach(element => {
      this.updateAssetGroupSelection('remove', '', element);
    });

    if (wholeArray && wholeArray.length > 0) {
      /* Adding hyphen to Mutual Fund parent checkbox when value is selected in the Operating / Net Expense Ratio dropdown */
      const entireCheckedArray1 = wholeArray[0].querySelector('.p-checkbox-box');
      const entireCheckedArray2 = wholeArray[0].querySelector('.p-checkbox-icon');
      if (this.selectedAction && this.selectedAction.value !== '') {
        this.selectedActionDropdownValue.emit(this.selectedAction);
        entireCheckedArray1.classList.add('p-highlight');
        entireCheckedArray2.classList.add('pi');
        entireCheckedArray2.classList.add('pi-minus');
      } else {
        this.selectedActionDropdownValue.emit(undefined);
      }
    }

    /* Adding css for parent and the child checkbox */
    this.selectedNodes.forEach((rowData) => {
      wholeArray.forEach(element => {
        const outerText = element.outerText;
        /* Adding the css to the parent checkbox, whose children are selected */
        if (rowData.parent) {
          if (element.outerText === rowData.parent.data.name || (rowData.parent.data.name === 'Options' && outerText.toUpperCase().includes('OPTIONS' + '\n' + 'NOTE: CLOSING ORDERS ALWAYS PERMITTED'))) {
            if (this.businessUnit !== 'DBS') {
            this.updateAssetGroupSelection('add', 'hyphen', element);
            } else {
            this.updateAssetGroupSelection('remove', 'hyphen', element);
            this.updateAssetGroupSelection('add', 'check', element);
            }
          } else if (element.outerText === rowData.data.name && element.parentElement.outerText.includes(rowData.parent.data.name)) {
            /* Adding the css to the children checkbox and to parent checkbox, whose children are not selected */
            this.updateAssetGroupSelection('add', 'check', element);
          }
        } else if (element.outerText === rowData.data.name || (rowData.data.name === 'Options' && outerText.toUpperCase().includes('OPTIONS' + '\n' + 'NOTE: CLOSING ORDERS ALWAYS PERMITTED'))) {
          this.updateAssetGroupSelection('add', 'check', element);
        }
      });
    });
  }

   /* istanbul ignore start */
  updateAssetGroupSelection(action, item, element) {
    const entireCheckedArray1 = element.querySelector('.p-checkbox-box');
    const entireCheckedArray2 = element.querySelector('.p-checkbox-icon');
    if (entireCheckedArray1 && entireCheckedArray2) {
      /* Clear the previous selection made on Asset Groups section */
      if (action === 'remove') {
        entireCheckedArray1.classList.remove('p-highlight');
        entireCheckedArray2.classList.remove('pi');
        entireCheckedArray2.classList.remove('pi-check');
        entireCheckedArray2.classList.remove('pi-minus');
      } else {
        /* Add the css to select the asset groups corresponding to the preset rule selected */
        entireCheckedArray1.classList.add('p-highlight');
        entireCheckedArray2.classList.add('pi');
        if (item === 'hyphen') {
          entireCheckedArray2.classList.add('pi-minus');
        } else {
          entireCheckedArray2.classList.remove('pi-minus');
          entireCheckedArray2.classList.add('pi-check');
        }
      }
    }

    setTimeout(() => {
     this.highlightParent();
    });
  }
  /* istanbul ignore end */

  setWarningMsg() {
    if (this.presetRuleInput !== this.emtrConstant.presetRules.customAssetGroupText) {
      /* To show the warning msg on parent component when changes made in asset groups */
      this.showWarningMsg = this.emtrConstant.presetRules.assetGrpWarningMsg;
      this.emitWarningMsg.emit(this.showWarningMsg);

      /* When changes is made in asset groups set preset rule to CUSTOM ASSET GROUP */
      this.presetRuleInput = this.emtrConstant.presetRules.customAssetGroupText;
    }
  }

  getSelectedAssetGroups() {
    if (this.presetRuleInput === this.emtrConstant.presetRules.customAssetGroupText) {
      /* Custom flag will be set to true for MF preset rules selection only and for any asset group selection custom flag will be set to false */
      this.custom = false;

      let obj = [];
      this.selectedCustomAssetGrp = [];
      this.customAssetGrp = [];

      /* Clear security value selected on Review Security Details before setting the new values */
      this.multiLevelSecurityType = [];

      /* This is to load the securites on the review security modal when user makes a selection in asset group section for parent/child node */
      this.selectedNodes.forEach(rowData => {
        const secCatgs = {
          secCatgs1: rowData.parent ? rowData.parent.data.secCatgs1 ? rowData.parent.data.secCatgs1 : [] : rowData.data.secCatgs1 ? rowData.data.secCatgs1 : [],
          secCatgs2: rowData.parent ? rowData.parent.data.secCatgs2 ? rowData.parent.data.secCatgs2 : [] : rowData.data.secCatgs2 ? rowData.data.secCatgs2 : [],
          secCatgs3: rowData.parent ? rowData.parent.data.secCatgs3 ? rowData.parent.data.secCatgs3 : [] : rowData.data.secCatgs3 ? rowData.data.secCatgs3 : [],
        }
        this.fetchAndPupulateSecurityTypeList(secCatgs);

        this.customAssetGrp = [...this.selectedCustomAssetGrp];
        /* When parent node/checkbox is selected, attributes array should be empty for RBS*/
        if (rowData.children && rowData.children.length >= 0) {
          let childAttr = [];
          if (this.businessUnit === 'DBS') {
            childAttr = rowData.children.map(childEl => {
              return {
                name: childEl.data.name,
                code: childEl.data.code ? childEl.data.code : '',
                value: 'true',
              };
            });
          }
          this.selectedCustomAssetGrp.push({
            id: rowData.data.id,
            name: rowData.name,
            code: rowData.data.code ? rowData.data.code : '',
            attributes: childAttr && childAttr.length ? childAttr : [],
          });
          if (rowData.children.length > 0 && this.businessUnit !== 'DBS') {
            /* Remove the duplicate entry -> when child was selected first n then parent selection was made */
            const dupCount = this.selectedCustomAssetGrp.findIndex(a => a.name === rowData.name && a.attributes.length === rowData.children.length);
            if (dupCount !== -1) {
              this.selectedCustomAssetGrp.splice(dupCount, 1);
            }
          }
        } else {
          /* When child node/checkbox is selected, attach the child value in the attributes array */
          if (this.selectedCustomAssetGrp.every(val => val.name !== rowData.parent.name)) {
            obj = [];
            if (rowData.data.name !== this.emtrConstant.ruleSteps.step2.operatingNetExpenseRatio) {
              obj.push({
                name: rowData.data.name,
                code: rowData.data.code ? rowData.data.code : '',
                value: 'true',
              });
              this.selectedCustomAssetGrp.push({
                id: rowData.parent.data.id,
                name: rowData.parent.name,
                code: rowData.parent.data.code ? rowData.parent.data.code : '',
                attributes: obj,
              });
            } else if (this.emtrConstant.ruleSteps.step2.operatingNetExpenseRatio === rowData.data.name) {
              const selectedRuleValue = JSON.parse(JSON.stringify(rowData.data));
              selectedRuleValue.value = rowData.selectedValueIs;
              delete selectedRuleValue.htmlType;
              delete selectedRuleValue.order;
              delete selectedRuleValue.parent;
              obj.push(selectedRuleValue);
              this.selectedCustomAssetGrp.push({
                id: rowData.parent.data.id,
                name: rowData.parent.name,
                code: rowData.parent.data.code ? rowData.parent.data.code : '',
                attributes: obj,
              });
            }
          } else {
            /* When more than 1 child node/checkbox is selected for a parent, attach the child value in the attributes array */
            let parentArray = [];
            this.selectedCustomAssetGrp.forEach(selectedNode => {
              obj = [];
              parentArray = [];
              const childrenArray = this.selectedNodes.filter(val => (val.parent && (val.parent.name === selectedNode.name && selectedNode.attributes.length > 0)));
              childrenArray.forEach(company => {
                parentArray = [];
                if (company.data.name !== this.emtrConstant.ruleSteps.step2.operatingNetExpenseRatio) {
                  obj.push({
                    name: company.data.name,
                    code: company.data.code ? company.data.code : '',
                    value: 'true',
                  });
                } else if (this.emtrConstant.ruleSteps.step2.operatingNetExpenseRatio === rowData.data.name) {
                  const selectedRuleValue = JSON.parse(JSON.stringify(rowData.data));
                  selectedRuleValue.value = rowData.selectedValueIs;
                  delete selectedRuleValue.htmlType;
                  delete selectedRuleValue.order;
                  delete selectedRuleValue.parent;
                  obj.push(selectedRuleValue);
                }
                if (obj.length > 0) {
                  parentArray = company.parent.data.sscLevel;
                }
              });
              this.customAssetGrp.push({
                id: selectedNode.id,
                name: selectedNode.name,
                code: selectedNode.code ? selectedNode.code : '',
                attributes: obj,
              });
              const dupCount = this.customAssetGrp.findIndex(a => a.name === selectedNode.name && a.attributes !== obj);
              if (dupCount !== -1) {
                this.customAssetGrp.splice(dupCount, 1);
              }
            });
            this.selectedCustomAssetGrp = this.customAssetGrp;
          }
        }
      });
    }
    this.emitSelectedAGandPresetRule(this.customAssetGrpId, this.presetRuleInput, this.selectedCustomAssetGrp, false);
  }

  childNodeSelected(el) {
    /* Clear out the miscellaneous asset group selection, when any attribute of a regular asset group is selected */
    this.miscellaneousAssetGrpForm.reset();

    /* Clear security value selected on Review Security Details before setting the new values */
    this.multiLevelSecurityType = [];

    const isNetExpenseSelected = (this.selectedAction && this.selectedAction.name.indexOf('=') !== '-1');
    if (isNetExpenseSelected) {
      this.assetGroupsTreeData.forEach((assetGroupType: any) => {
        if (assetGroupType.name === 'Mutual Funds' && this.businessUnit === 'RBS') {
          /* Push OER % only if the selectedAction contains any value */
          if (this.selectedAction.value !== '') {
            let macthedIndex = -1;
            for (let i = 0; i < this.selectedNodes.length; i++) {
              if (assetGroupType.children[assetGroupType.children.length - 1] && this.selectedNodes[i].data && this.selectedNodes[i].data.name === assetGroupType.children[assetGroupType.children.length - 1].data.name) {
                macthedIndex = i;
              }
            }
            if (macthedIndex >= 0) {
              this.selectedNodes[macthedIndex].selectedValueIs = this.selectedAction.value;
            } else {
              assetGroupType.children[assetGroupType.children.length - 1].selectedValueIs = this.selectedAction.value;
              this.selectedNodes.push(assetGroupType.children[assetGroupType.children.length - 1]);
            }
          } else {
            /* When OER dropdown is set to default selection i.e 'Select' */
            const modifiedSelectedNodes = this.selectedNodes;
            for (let i = 0; i < this.selectedNodes.length; i++) {
              /* If selectedNodes arr contains OER data then remove the data from the arr */
              if (assetGroupType.children[assetGroupType.children.length - 1] && this.selectedNodes[i].data && this.selectedNodes[i].data.name === assetGroupType.children[assetGroupType.children.length - 1].data.name) {
                modifiedSelectedNodes.splice(i, 1);
              }
            }
            this.selectedNodes = modifiedSelectedNodes;
          }
        }
      });
    }

    /* Logic to allow only single attribute selection for Option asset group */
    /* Filtering out all the child nodes selected for the Option asset group */
    const optionAGAttributes = this.selectedNodes.filter(val => val.parent && val.parent.data.name === this.emtrConstant.ruleSteps.step2.optionsAG);
    const arrLength = optionAGAttributes.length;
    /* Checking if more than one child is selected for the Option asset group */
    if (optionAGAttributes && arrLength > 1) {
      /* If more than 1 child node is selected then keep the last/latest selected child node and remove the other children nodes */
      /* Here we are first finding the index of the child node other than the latest selected child, and delete numbe will depend
      on the length of the filtered value, hence it is arrLength-1. except the last value in the array all the other values will be spliced */
      this.selectedNodes.splice(this.selectedNodes.findIndex(val => val.parent && val.parent.data.name === this.emtrConstant.ruleSteps.step2.optionsAG && val.data.name !== optionAGAttributes[arrLength - 1].data.name), arrLength - 1);
    }

    const newSelectedArr = this.selectedNodes;
    /* Remove the parent node from selectedNode when the corresponding child node/checkbox is selected  */
    this.selectedNodes.forEach((rowData) => {
      if (rowData.parent) {
        rowData.parent.partialSelected = true;
        const parentIndex = newSelectedArr.findIndex(a => a.data.name === rowData.parent.name);
        if (parentIndex !== -1) {
          newSelectedArr.splice(parentIndex, 1);
          const index = newSelectedArr.findIndex(a => a.children && a.data.name === rowData.parent.name);
          if (index !== -1) {
            newSelectedArr.splice(index, 1);
          }
        }
      }
    });
    this.selectedNodes = newSelectedArr;
    if (this.businessUnit === 'DBS') {
      if ((this.newSelectedNodes && this.newSelectedNodes.length === 0) || (this.selectedNodes && this.selectedNodes.length === 0)) {
        this.newSelectedNodes = this.selectedNodes;
      } else {
        this.newSelectedNodes.forEach(val => {
          const localArr = this.selectedNodes.filter(childVal => childVal.parent && childVal.parent.name === val.name);
          if (localArr && localArr.length > 0) {
            this.selectedNodes.splice(this.selectedNodes.indexOf(localArr[0]), 1);
          }
        });
        this.newSelectedNodes = this.selectedNodes;
      }
      this.updateCheckboxCssForDBS();
    } else {
      this.updateCheckboxCss();
    }

    /* To form the asset group array for Custom Asset Group preset rule -> to send in api payload */
    this.getSelectedAssetGroups();
  }

  parentNodeSelected(el) {
    /* Clear out the miscellaneous asset group selection, when any regular asset group is selected */
    this.miscellaneousAssetGrpForm.reset();

    /* Clear security value selected on Review Security Details before setting the new values */
    this.multiLevelSecurityType = [];

    const selectedArrWithoutChildren = this.selectedNodes;
    /* Remove all the child nodes from selectedNode when the corresponding parent node/checkbox is selected  */
    this.selectedNodes.forEach(selectedNode => {
      const childrenArray = selectedArrWithoutChildren.filter(child => child.parent && (selectedNode.name === child.parent.name));
      childrenArray.forEach(company => {
        if (company.parent && (selectedNode.name === company.parent.name)) {
          selectedArrWithoutChildren.splice(selectedArrWithoutChildren.indexOf(company), 1);
          if (selectedNode.name === this.emtrConstant.ruleSteps.step2.mutualFunds) {
            this.selectedAction = {name: 'Select', value: ''};
            this.selectedActionDropdownValue.emit(undefined);
          }
        }
      });
    });
    this.selectedNodes = selectedArrWithoutChildren;
    if (this.businessUnit === 'DBS') {
      if ((this.newSelectedNodes && this.newSelectedNodes.length === 0) || (this.selectedNodes && this.selectedNodes.length === 0)) {
        this.newSelectedNodes = this.selectedNodes;
      } else {
        this.newSelectedNodes.forEach(val => {
          const localArr = this.selectedNodes.filter(parent => val.parent && parent.name === val.parent.name);
          if (localArr && localArr.length > 0) {
            this.selectedNodes.splice(this.selectedNodes.indexOf(localArr[0]), 1);
          }
        });
        this.newSelectedNodes = this.selectedNodes;
      }
      this.updateCheckboxCssForDBS();
    } else {
    /* Remove the css from all the child nodes when the corresponding parent node/checkbox is selected  */
    this.selectedNodes.forEach(id => {
      const wholeArray = this.el.nativeElement.querySelectorAll('tr.assetGrptr')[id.data.id - 1]; // NOSONAR
      const tdArray = wholeArray && wholeArray.querySelectorAll('td'); // NOSONAR
      if (tdArray) {
        tdArray.forEach((element, i) => {
          /*Avoiding the first td to not to remove the check icon since it is a parent*/
          const entireCheckedArray1 = element.querySelector('.p-checkbox-box'); // NOSONAR
          const entireCheckedArray2 = element.querySelector('.p-checkbox-icon'); // NOSONAR
          if (i > 0) {
            if (entireCheckedArray1 && entireCheckedArray2) {
              entireCheckedArray1.classList.remove('p-highlight'); // NOSONAR
              entireCheckedArray2.classList.remove('pi'); // NOSONAR
              entireCheckedArray2.classList.remove('pi-check'); // NOSONAR
            }
          }
        });
      }
    });
  }
    this.highlightParent();
    this.getSelectedAssetGroups();
  }

  highlightParent() {
    this.selectedNodes.forEach(id => {
      if (id.parent) {
        const val = this.businessUnit === 'DBS' ? 12 : 1;
        const wholeArray = this.el.nativeElement.querySelectorAll('tr.assetGrptr')[id.parent.data.id - val]; // NOSONAR
        const tdArray = wholeArray && wholeArray.querySelectorAll('td'); // NOSONAR
        if (tdArray) {
          tdArray.forEach((element, i) => {
            if (element.innerText) {
              /*Avoiding the first td to not to remove the check icon since it is a parent*/
              const entireCheckedArray1 = element.querySelector('.p-checkbox-box'); // NOSONAR
              const entireCheckedArray2 = element.querySelector('.p-checkbox-icon'); // NOSONAR
              if (i === 0) {
                if (entireCheckedArray1 && entireCheckedArray2) {
                  entireCheckedArray1.classList.add('p-highlight');
                  if (this.businessUnit === 'DBS') {
                    entireCheckedArray2.classList.add('pi');
                    entireCheckedArray2.classList.remove('pi-minus');
                    entireCheckedArray2.classList.add('pi-check');
                  }
                }
              }
            }
          });
        }
      }
    });
    if (this.businessUnit === 'DBS') {
      const wholeArray = this.el.nativeElement.querySelectorAll('tr.assetGrptr'); // NOSONAR
      wholeArray.forEach(tdArr => {
        const tdArray = tdArr.querySelectorAll('td');
        /* Remove the css from all checkboxes before adding any new css */
        if (tdArray) {
          tdArray.forEach((element, i) => {
            const flag = this.selectedNodes.length === 0 ? [] : this.selectedNodes.filter(x => (x.parent && x.parent.name === element.innerText) || (x.children && x.name === element.innerText));
            if (element.innerText && (flag && flag.length === 0)) {
              /*Avoiding the first td to not to remove the check icon since it is a parent*/
              const entireCheckedArray1 = element.querySelector('.p-checkbox-box'); // NOSONAR
              const entireCheckedArray2 = element.querySelector('.p-checkbox-icon'); // NOSONAR
              if (i === 0) {
                if (entireCheckedArray1 && entireCheckedArray2) {
                  entireCheckedArray2.classList.remove('pi');
                  entireCheckedArray2.classList.remove('pi-minus');
                  entireCheckedArray2.classList.remove('pi-check');
                }
              }
            }
          });
        }
      });
    }
  }

  miscellaneousAssetGroupSelected() {
    /* Custom flag will be set to true for MF preset rules selection only and for miscellaneous asset group selection custom flag will be set to false */
    this.custom = false;

    /* Clear assetGroupsSelection, selectedNodes and selectedArr before setting the new values */
    this.assetGroupsSelection = [];
    this.selectedNodes = [];

    /* Clear security value selected on Review Security Details before setting the new values */
    this.multiLevelSecurityType = [];

    /* Set 'Select' as default selection in the OER dropdown of general asset group */
    this.selectedAction = {name: 'Select', value: ''};

    /* Add and mark partialSelected field as false for all the nodes */
    const localRowNode = this.rowNodeArray;
    localRowNode.forEach(nodeVal => {
      nodeVal.partialSelected = false;
    });

    const wholeArray = this.el.nativeElement.querySelectorAll('td.assetGrptd');
    /* Remove the css from general asset group checkboxes */
    wholeArray.forEach(element => {
      this.updateAssetGroupSelection('remove', '', element);
    });

    const localForm = this.miscellaneousAssetGrpForm.getRawValue();
    const localAssetGrp = [];
    this.miscAssetGroups.forEach(miscVal => {
      if (localForm[miscVal.name] && localForm[miscVal.name].length > 0) {
        /* This is to load the securites on the review security modal when user makes a selection in miscellaneous asset group section */
        const secCatgs = {
          secCatgs1: miscVal.secCatgs1 ? miscVal.secCatgs1 : [],
          secCatgs2: miscVal.secCatgs2 ? miscVal.secCatgs2 : [],
          secCatgs3: miscVal.secCatgs3 ? miscVal.secCatgs3 : []
        }
        this.fetchAndPupulateSecurityTypeList(secCatgs);

        localAssetGrp.push({
          id: miscVal.id,
          name: miscVal.name,
          code: miscVal.code,
          attributes: miscVal.attributes ? miscVal.attributes : [],
        });
      }
    });
    this.presetRuleInput = this.emtrConstant.presetRules.customAssetGroupText;
    this.emitSelectedAGandPresetRule(this.customAssetGrpId, this.emtrConstant.presetRules.customAssetGroupText, localAssetGrp, true);
  }

  updateMiscAssetGroupSelection() {
    /* Custom flag will be set to true for MF preset rules selection only and for miscellaneous asset group selection custom flag will be set to false */
    this.custom = false;
    const localForm = this.miscellaneousAssetGrpForm.getRawValue();
    const localAssetGrp = [];
    const secCatgs = {
      secCatgs1: [],
      secCatgs2: [],
      secCatgs3: []
    }
    this.assetGroupsInput.forEach(miscVal => {
      /* This section is for loading the securites of the selected miscellaneous asset group, when user lands on rule step2 from step1/step3 */
      this.assetGroups.some(grp => miscVal.name === grp.name && grp.secCatgs1 ? grp.secCatgs1.filter(security => secCatgs.secCatgs1.push(security)) : '');
      this.assetGroups.some(grp => miscVal.name === grp.name && grp.secCatgs2 ? grp.secCatgs2.filter(security => secCatgs.secCatgs2.push(security)) : '');
      this.assetGroups.some(grp => miscVal.name === grp.name && grp.secCatgs3 ? grp.secCatgs3.filter(security => secCatgs.secCatgs3.push(security)) : '');

      this.miscellaneousAssetGrpForm.get(miscVal.name).setValue([miscVal.name]);
      localAssetGrp.push({
        id: miscVal.id,
        name: miscVal.name,
        code: miscVal.code,
        attributes: miscVal.attributes,
      });
    });
    /* Call fetchAndPupulateSecurityTypeList to populate the modal with formed secCatgs  */
    this.fetchAndPupulateSecurityTypeList(secCatgs);
    this.emitSelectedAGandPresetRule(this.customAssetGrpId, this.emtrConstant.presetRules.customAssetGroupText, localAssetGrp, true);
  }

  emitSelectedAGandPresetRule(presetRuleChange, presetRuleKey, assetGroupChange, micAGFlag) {
    /* Emit the selected preset rule and the corresponding asset Groups to the parent component */
    this.presetRuleChange.emit(presetRuleChange);
    this.presetRuleKey.emit(presetRuleKey);
    this.assetGroupChange.emit(assetGroupChange);
    this.micAssetGroupSelectedFlag.emit(micAGFlag);
    this.customValue.emit(this.custom);
  }

  /* Performing security endpoint call while clicking Review Security Type link */
  openSecurityType() {
    this.ruleService.getSecurities(false).subscribe((data: any) => {
      this.securityData = data.esoLevels;
      if (this.securityData && this.securityData.length > 0) {
        this.reviewSecurityType.openModal(event, this.securityData);
      }
    });
  }

  formatSecurities(securities, level) {
    return securities.map(item => {
      return {
        data: {
          desc: item.secTypNm,
          code: item.secTypCd,
          level
        }
      };
    });
  }

  recurrsive(data, array) {
    if (data.subLevels) {
      data.subLevels.forEach((subLevel) => {
        if (subLevel.subLevels) {
          array.push({...subLevel});
          this.recurrsive(subLevel, array);
        } else {
          array.push({...subLevel});
        }
      });
      return array;
    } else {
      return data;
    }
  }

  fetchAndPupulateSecurityTypeList(SecurityValue) {
    if (this.securityData && this.securityData.length ===0) {
      this.ruleService.getSecurities(false).subscribe((data: any) => {
        this.securityData = data.esoLevels;
        this.populateSecurityTypeData(SecurityValue);
      });
    } else {
      this.populateSecurityTypeData(SecurityValue);
    }
  }

  populateSecurityTypeData(SecurityValue) {
    const securityData = JSON.parse(JSON.stringify(this.securityData));
    if (securityData) {
      const secCatgs1 = this.formatSecurities(SecurityValue.secCatgs1, 'SC1');
      const secCatgs2 = this.formatSecurities(SecurityValue.secCatgs2, 'SC2');
      const secCatgs3 = this.formatSecurities(SecurityValue.secCatgs3, 'SC3');
      const selectedValues = secCatgs1.concat(secCatgs2).concat(secCatgs3);
      selectedValues.forEach(selectedData => {
        securityData.forEach(secData => {
          if (JSON.stringify(secData).indexOf('\"code\":\"' + selectedData.data.code + '"') !== -1) {
            if (!this.multiLevelSecurityType[secData.desc]) {
              this.multiLevelSecurityType[secData.desc] = [];
            }
            if (selectedData.data.level === 'SC1') {
              this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                .concat(this.reviewSecurityType.formatJson(this.recurrsive({...secData}, [])))
                .concat(this.reviewSecurityType.formatJson([{...secData}]));
            } else if (selectedData.data.level === 'SC2') {
              secData.subLevels.forEach(secSubLevelData => {
                if (JSON.stringify(secSubLevelData).indexOf('\"code\":\"' + selectedData.data.code + '"') !== -1) {
                  this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                    .concat(this.reviewSecurityType.formatJson(this.recurrsive({...secSubLevelData}, [])))
                    .concat(this.reviewSecurityType.formatJson([{...secSubLevelData}]));
                }
              });
            } else {
              this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                .concat(this.reviewSecurityType.formatJson([selectedData.data]));
            }
          }
        });
      });
      this.updateSecurityValues(this.multiLevelSecurityType);
    }
  }

  /* Setting the formObj and displaying the selected Data in Page*/
  updateSecurityValues(selectedValues) {
    this.multiLevelSecurityType = Object.assign({}, selectedValues);
    const wholeArray = Object.assign({}, this.multiLevelSecurityType);
    Object.keys(wholeArray).forEach(key => {
      wholeArray[key].forEach(keys => {
        if (keys.children) {
          keys.data.selected = 'ALL';
          keys.children.forEach(record => {
            wholeArray[key] = wholeArray[key].filter(item => item.data.code !== record.data.code);
          });
        }
      });
      /* when we are getting few securities from preset and few from asset group, and the formed secCatgs2 has all the Level2 selected then we are making the Level1 selection for that security */
      const secCatgs = {
        secCatgs1: [],
        secCatgs2: [],
        secCatgs3: []
      }
      /* Clearing this.multiLevelSecurityType[sec.desc] so that duplicate data are not pushed so the 'Select All' checkbox on modal is selected when all securities are selected */
      this.securityData.some(sec => sec.desc == key && sec.subLevels.length === wholeArray[key].length ? (secCatgs.secCatgs1.push({secTypNm: sec.desc, secTypCd: sec.code}), this.multiLevelSecurityType[sec.desc] = [],this.populateSecurityTypeData(secCatgs)) : '')
    });
  }

  ngAfterViewChecked() {
    if (this.businessUnit === 'DBS') {
      this.highlightParent();
    }
  }
}


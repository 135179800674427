<form [formGroup]="ruleListSubHeaderForm">
  <div class="expanded-row-filter sdps-p-bottom_medium">
    <div *ngFor="let col of filteredRequest; let i = index;">
      <!-- Header section of the rule sub-header -->
      <div class="sdps-p-left_small sdps-p-bottom_small sdps-p-top_small">
        <span *ngIf="col.header !== 'Group Name' && col.header !== 'View' && col.header !== 'Show Records' && col.header !== 'Instrument Group'">{{col.header}}</span>
        <span *ngIf="col.header === 'Group Name'">{{sharedConstant[businessUnit].enterGroupOrPlanName}}</span>
        <span  *ngIf="col.header === 'Instrument Group' && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid,'','')">
          {{col.header}}
        </span>
        <span  *ngIf="col.header === 'View'&& sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.globalRuleSummary.uuid,'','')">
          {{col.header}}
        </span>
        <span  *ngIf="col.header === 'Show Records' && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid,'','')">
          {{col.header}}
        </span>
      </div>

        <!-- Symbol and CUSIP -->
        <div class="sdps-p-left_small sdps-m-right_none" *ngIf="col.enum === 'textbox'">
          <input pInputText formControlName="{{col.field}}" class="w-95-per" type="text" name="{{col.field}}" emsAlphaNumericSpecialCharacterValidation
                 [ngClass]="{'w-65-per' : col.field === 'cusipSymbol','invalidField': col.field === 'cusipSymbol' ? filterBtnDisableForCusip || (ruleListSubHeaderForm.get('cusipSymbol').value && ruleListSubHeaderForm.get('cusipSymbol').invalid) : filterBtnDisableForTradeSymbol || (ruleListSubHeaderForm.get('tradeSymbol').value && ruleListSubHeaderForm.get('tradeSymbol').invalid)}"
          (keydown.enter)="enterFilterValue()" onkeydown="return (event.keyCode !== 13)"
          [maxlength]="col.field === 'cusipSymbol' ? '9' : ''">
        </div>

        <!-- Group/Plan Name -->
        <div class="timePickerForm on-scroll emtr-dropdown mutual-fund-asset-group-dropdown sdps-p-left_small sdps-p-right_small group-plan-name-dropdown" *ngIf="col.enum === 'dropdown' && col.field === 'empGroupName'">
          <p-dropdown #dropdown name="{{col.field}}" [options]="empGroupDropDownOpts" [(ngModel)]="dropdownInputValue" (ngModelChange)="groupNameUpdated()"  [ngModelOptions]="{standalone: true}" [filter]="true"
              (onShow)="clearSearch(dropdown)" (keyup)="searchedText($event, 'groupName')">
            <ng-template let-empRecords pTemplate="item">
              <div class="ui-helper-clearfix display-image scrollHere">
                <span>{{empRecords.value}}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>

      <!-- Instrument Group -->
      <div class="timePickerForm on-scroll emtr-dropdown mutual-fund-asset-group-dropdown sdps-p-left_small sdps-p-right_small instrument-group-name-dropdown" *ngIf="col.enum === 'dropdown' && col.header === 'Instrument Group' && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid,'','')">
        <p-dropdown #instdropdown name="{{col.field}}" [options]="instrumentGroupDropDownOpts" [(ngModel)]="instrumentGroupDropdownValue" (onChange)="instrumentGroupNameUpdated($event)" [ngModelOptions]="{standalone: true}" [filter]="true"
                    (onShow)="clearInstrumentSearch(instdropdown)" (input)="searchedText($event, 'instrumentGroup')" (onHide)="hideOptions()">
          <ng-template let-instrumentRecords pTemplate="record">
            <div class="ui-helper-clearfix display-image scrollHere">
              <span>{{instrumentRecords.label}}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>


        <!-- View Dropdown -->
        <div class="timePickerForm emtr-dropdown sdps-p-left_small sdps-p-right_small" *ngIf="col.field === 'view' && col.enum === 'dropdown' && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.globalRuleSummary.uuid,'','')">
          <p-dropdown appendTo="body" optionLabel="label" formControlName="{{col.field}}" [options]="emtrConstant.ruleFilter.view">
          </p-dropdown>
        </div>

      <!-- Show Records dropdown -->
      <div class="timePickerForm emtr-dropdown sdps-p-left_small sdps-p-right_small"
           *ngIf="col.field === 'showRecords' && col.enum === 'dropdown' && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid,'','')">
        <p-dropdown appendTo="body" optionLabel="label" formControlName="{{col.field}}"
                    [options]="emtrConstant.showRecords">
        </p-dropdown>
      </div>
    </div>
  </div>
</form>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ManageClientService } from '@core_services/admin-menu/manage-client.service';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import { Subscription } from 'rxjs';
import { AppService } from '@core_services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShellPagination, ShellPaginationValues } from '@shell_components/model/pagination-model';
import { MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { CustomSortService, DateRangeFilterComponent, SharedService } from '@ems/shared';
import { Table } from 'primeng/table';
import {
  ManageClientsListTableHeader, manageClientsListTableHeaderForAllBU,
  manageClientsListTableHeaderForNonRBS, manageClientsListTableHeaderForRBS, manageClientsListTableSubHeader
} from '@shell_components/model/manage-clients-list-table-header';
import * as temp from 'moment';
const moment = temp['default'];
import { AddlinkService } from '@ems/addlink';
import { entitlementConst } from '@shell_components/constants/entitlementConstants';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AddlinkConstant} from '../../../../../projects/ems/addlink/src/lib/constants/addlinkConstants';
import {LocalStorageService} from '@core_services/local-storage.service';

@Component({
  selector: 'app-manage-clients-list',
  templateUrl: './manage-clients-list.component.html'
})
export class ManageClientsListComponent implements OnInit, OnDestroy {

  private currentOrgSubscription: Subscription;

  getOrgId: any;
  clientList: any;
  clientSaveMsg: any;
  moment = moment;
  totalClients: any = 0;
  paginationCount: any = 0;
  shellConstant = ShellConstant;
  getAdlConst = AddlinkConstant;
  entitlementConst = entitlementConst;
  pageObject: ShellPagination = ShellPaginationValues;
  manageClientListTableCols: ManageClientsListTableHeader[] = manageClientsListTableHeaderForAllBU;
  manageClientListTableSubHeaderCols: ManageClientsListTableHeader[] = manageClientsListTableSubHeader;
  resetTable: any;
  @ViewChild('p', { static: true }) paginator: Paginator;
  @ViewChild('toastMessage', { static: true }) msgToast: any;
  @ViewChild(Table, { static: true }) tableComponent: Table;
  @ViewChild('range') rangeCalendar: DateRangeFilterComponent;
  multiSortMeta: any = [];
  toggle = false;
  manageClientListForm: UntypedFormGroup;
  filterBtnDisable = false;
  filterClicked = false;
  filterSelectedDate: any;
  isClickedClearBtn = false;
  externalAccess = false;
  stateVal: any;
  storeClientInfo: any;
  tablePageSize: any = 15;
  hasClientAdminAccess = true;
  isManageClientComponent = true;
  filterState: any;
  disableCustId = false;
  disableK4Master = false;
  rbsBU = false;
  dbsSpsBU = false;
  multiSortMetaDup: any = [];
  initialLoad = false;
  userPolicy: any;
  displaySetupNewClient = false;
  userBUData: any = [];
  isSortingChanged = false;
  defaultSortDataField = {field: 'modifiedDate', order: -1};
  sortClearButtonReset = false;

  constructor(public localStorageService: LocalStorageService, public fb: UntypedFormBuilder, public manageClientService: ManageClientService, private shellService: AppService, private router: Router, private r: ActivatedRoute, public addlinkService: AddlinkService,
              public customSortService: CustomSortService, public sharedService: SharedService, public messageService: MessageService) {
    this.getClientSaveMessage();
    this.manageClientService.editMode = false;
    /* Assigning Selected Client data to Header from sessionStorage for State management*/
    this.stateVal = this.sharedService.getStateVal();
    if (this.stateVal) {
      this.sharedService.clientInfo.next(this.stateVal.clientObj);
    }

    /* Navigate to respective page based on the selected client access */
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      if (this.isManageClientComponent && clientInfo && !this.sharedService.checkEntitlements(entitlementConst.navUUID.manageClient.uuid, '', '')) {
        /* If client is selected and the selected client does not have access to Client Admin but has access to User Admin, then navigate to Manage User */
        if (this.sharedService.checkEntitlements(entitlementConst.navUUID.manageUser.uuid, '', '')) {
          this.sharedService.loadDataTrackValue('client_redirect');
          this.isManageClientComponent = false;
          this.router.navigate(['ems/admin/manage-users']);
        } else if (this.sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, entitlementConst.navUUID.accountSummary.uuid, '')) {
          this.sharedService.loadDataTrackValue('client_redirect');
          /* If client is selected and the selected client does not have access to Client Admin or User Admin, then navigate to Workbench */
          this.isManageClientComponent = false;
          this.router.navigate(['ems/workbench']);
        } else if (this.sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
          this.sharedService.loadDataTrackValue('client_redirect');
          this.isManageClientComponent = false;
          this.router.navigate(['/ems/emtr/instrument-groups/instrument-group-list']);
        }
      }
    });
  }

  ngOnInit() {
    this.manageClientListForm =  this.fb.group({
      custId: [''],
      masterAccount: [''],
      applications: [''],
      linkType: [''],
      businessUnit: [''],
      modifiedDate: [''],
      accessType: ['']
    });
    this.getDataForBU();
    this.pageObject.pageNo = this.stateVal && this.stateVal.pageObj.pageNo ? this.stateVal.pageObj.pageNo : 1;
    this.pageObject.pageSize = this.stateVal && this.stateVal.pageObj.pageSize ? this.stateVal.pageObj.pageSize : 15;
    this.stateVal && this.stateVal.multiSortMeta ? this.multiSortMeta = this.stateVal.multiSortMeta : this.multiSortMeta.push(this.defaultSortDataField);
    this.paginator.totalRecords = this.stateVal ? this.stateVal.totalElem : 0;
    this.customSortService.sortArr = [];
    this.resetTable = true;
    this.tablePageSize = this.pageObject.pageSize;
    /* if client level access setup button disabled*/
    this.userPolicy = this.sharedService.getPolicyResponse('success');
    if (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0) {
      this.displaySetupNewClient = true;
    }
    this.currentOrgSubscription = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.storeClientInfo = clientInfo;
        this.getOrgId = clientInfo && clientInfo.orgId;
        this.hasClientAdminAccess = this.sharedService.checkEntitlements(entitlementConst.navUUID.manageClient.uuid, '', '');
        clientInfo.externalAccess && clientInfo.clientName !== 'All' && clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
        this.manageClientService.orgId = this.getOrgId;
        this.shellService.setCurrentOrg(this.getOrgId);
        if (this.sharedService.isClientChanged) {
          this.clearSuccessAndErrorMessages();
          this.multiSortMeta = [this.defaultSortDataField];
          if (this.stateVal && this.stateVal.filterObj.custId) {
            this.stateVal.filterObj.custId = clientInfo.custId;
          }
          this.pageObject.pageNo = 1;
          this.pageObject.pageSize = 15;
          this.sharedService.stateValues.pageObj.pageNo = 1;
          this.sharedService.stateValues.pageObj.pageSize = 15;
          this.sharedService.stateValues.filterClicked = false;
          this.filterClicked = false;
          this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
          this.sharedService.tableDropdownVal.next(this.stateVal ? this.stateVal.pageObj.pageSize : 15);
          if (this.stateVal) {
            this.stateVal.pageObj.pageNo = 1;
            this.stateVal.pageObj.pageSize = 15;
            this.tablePageSize = 15;
          }
          this.stateVal = this.sharedService.getStateVal();
        }
        if (!this.resetTable) {
          this.customSortService.resetTable(this.defaultSortDataField, this.tableComponent);
        }
        /* Assigning filter icon value from sessionStorage for State management*/
        if (this.stateVal) {
          this.toggle = this.stateVal.filterClicked;
          this.filterClicked = this.stateVal.filterClicked;
          if (this.filterClicked) {
            this.manageClientListForm.markAsDirty();
          }
        }
      }
    });
    if (this.clientSaveMsg) {
      setTimeout(() => {
        this.msgToast.pushMessage('success', this.clientSaveMsg);
      }, 1);
    }
    this.onChanges();
    this.initialLoad = true;
  }

  ngOnDestroy(): void {
    this.isManageClientComponent = false;
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.manageClientService.clearMessage();
    this.sharedService.updatedDate.next('');
    this.sharedService.requestStatusFilterObj.fromDate = '';
    this.sharedService.requestStatusFilterObj.toDate = '';
    this.sharedService.tableDropdownVal.next(15);
    /* Clearing sessionStorage value for other routes for State management*/
    if (this.router.routerState.snapshot.url.indexOf('manage-clients') === -1) {
      this.sharedService.clearLocalStorage();
    }
  }

  navigateToSetupNewClient() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue('setupnewclient');
    this.manageClientService.editMode = false;
    this.router.navigate(['../create-client'], { relativeTo: this.r });
  }

  setFormValues() {
    const localUpdatedFormValues = this.manageClientListForm.getRawValue();
    const clientName = JSON.parse(this.localStorageService.getProfile());
    this.getOrgId = clientName && clientName.orgId;
    const fromDate = [];
    if (this.filterSelectedDate !== undefined && this.filterSelectedDate !== '' && this.filterSelectedDate !== null) {
      this.filterSelectedDate.forEach(date => {
        fromDate.push(date);
      });
    }
    const formFilterObj: any = {
      application: localUpdatedFormValues.applications ? localUpdatedFormValues.applications.value : '',
      externalAccess: localUpdatedFormValues.accessType ? localUpdatedFormValues.accessType.value : '',
      businessUnit: localUpdatedFormValues.businessUnit ? localUpdatedFormValues.businessUnit.value : '',
      custId: localUpdatedFormValues.custId ? localUpdatedFormValues.custId : null,
      masterAccount: localUpdatedFormValues.masterAccount ? localUpdatedFormValues.masterAccount : null,
      fromDate: fromDate.length > 0 ? moment(fromDate[0]).format('MM/DD/YYYY') : '',
      linkType: localUpdatedFormValues.linkType ? localUpdatedFormValues.linkType.value : '',
      orgId: this.getOrgId ? this.getOrgId : '',
      toDate: fromDate.length > 0 ? moment(fromDate[1]).format('MM/DD/YYYY') : '',
    };

    return formFilterObj;
  }

  getManageClients() {
    const formFilterObj =  this.setFormValues();
    const defaultObj: any = {
      application: 'All',
      externalAccess: 'All',
      businessUnit: this.userBUData.length > 1 ? 'All' : this.userBUData[0] ? this.userBUData[0].value : '',
      custId: null,
      masterAccount: null,
      fromDate: '',
      linkType: 'All',
      orgId: this.getOrgId ? this.getOrgId : '',
      toDate: ''
    };
    const clientName = JSON.parse(this.localStorageService.getProfile());
    this.sharedService.stateValues.multiSortMeta = this.multiSortMeta;
    const filterParam = !this.sharedService.isClientChanged ? this.stateVal && this.stateVal.filterObj ? this.stateVal.filterObj : formFilterObj  : formFilterObj;
    if (this.sharedService.isClientChanged && ((this.storeClientInfo && this.storeClientInfo.clientName) !== (clientName && clientName.clientName))) {
      this.pageObject.pageNo = 1;
      this.pageObject.pageSize = 15;
    }
    this.sharedService.isClientChanged = false;
    const localUpdatedFormValues = this.manageClientListForm.getRawValue();
    if (this.hasClientAdminAccess) {
      if (this.filterClicked) {
        /* Update filter values from sessionStorage for State management*/
        this.updateformValues(localUpdatedFormValues, filterParam);
        this.manageClientService.getManageClients(this.pageObject, filterParam).subscribe((clients) => {
          this.getClientsSuccessResponse(clients);
        });
      } else {
        /* Update filter values from sessionStorage for State management*/
        this.updateformValues(localUpdatedFormValues, defaultObj);
        this.manageClientService.getManageClients(this.pageObject, defaultObj).subscribe(users => {
          this.getClientsSuccessResponse(users);
        });
      }
    }
  }

  getClientsSuccessResponse(clients) {
    if (clients.data !== null) {
      this.clientList = clients.data.content;
      this.totalClients = this.sharedService.totalElementsWithCommas(clients.data.totalElements);
      this.paginationCount = clients.data.totalElements;
      this.resetTable = false;
      /* Updating State Values for paginator to support page Val for State management*/
      this.sharedService.stateValues.totalElem = this.paginationCount;
      this.sharedService.stateValues.pageObj.pageNo = this.pageObject.pageNo;
      this.sharedService.stateValues.pageObj.pageSize = this.pageObject.pageSize;
      this.sharedService.stateValues.multiSortMeta = this.multiSortMeta;
      this.sharedService.stateValues.filterClicked = this.filterClicked;
      this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
      this.stateVal = this.sharedService.getStateVal();
    }
  }

  updateformValues(formControls, formVals) {
    this.sharedService.tableDropdownVal.next(this.stateVal ? this.stateVal.pageObj.pageSize : this.pageObject.pageSize);
    this.sharedService.updatedDate.next(formVals.fromDate ? {name: 'Require Indexing', fromDate: formVals.fromDate, toDate: formVals.toDate } : '');
    const acsTypConst = this.shellConstant.manageClients.mangeClientList;
    const accessTypeLabel = formVals.externalAccess && formVals.externalAccess === 'Y' ? acsTypConst.externalAcsTyp : formVals.externalAccess && formVals.externalAccess === 'N' ? acsTypConst.internalAcsTyp : 'All';
    this.manageClientListForm.patchValue({
      applications: {label: formVals.application ? formVals.application : 'All', value: formVals.application ? formVals.application : 'All'},
      accessType: {label: accessTypeLabel, value: formVals.externalAccess ? formVals.externalAccess : 'All'},
      businessUnit: {label: formVals.businessUnit ? formVals.businessUnit : 'All', value: formVals.businessUnit ? formVals.businessUnit : 'All'},
      custId: formVals.custId,
      masterAccount: formVals.masterAccount,
      linkType: {label: formVals.linkType ? formVals.linkType : 'All', value: formVals.linkType ? formVals.linkType : 'All'},
    });
  }

  getClientSaveMessage() {
    this.manageClientService.getMessage().subscribe((data) => {
      this.clientSaveMsg = data.text ? data.text : '';
    });
  }

  removeEmptyValue(rowDatum: any) {
    return rowDatum.filter(a => a !== '').join(', ');
  }

  customSort(event) {
    if (event.multiSortMeta && this.pageObject.sortField !== this.customSortService.customSort(event)) {
      this.multiSortMetaDup = this.multiSortMeta;
      this.multiSortMeta = event.multiSortMeta;
      const sortArr = this.customSortService.customSort(event);
      const checkStateVal = this.sharedService.getStateVal();
      if (checkStateVal && JSON.stringify(event.multiSortMeta) !== JSON.stringify(checkStateVal.multiSortMeta)) {
        this.stateVal.pageObj.pageNo = 1;
      }
      /* Assigning updated page number from sessionStorage for State management*/
      if (this.stateVal && this.stateVal.pageObj.pageNo) {
        setTimeout(() => {
          this.paginator.totalRecords = this.stateVal ? this.stateVal.totalElem : 0;
          this.paginator.changePage(this.stateVal.pageObj.pageNo > 1 ? this.stateVal.pageObj.pageNo  - 1 : 0);
        }, 0);
      }
      this.pageObject = {
        pageNo: this.pageObject.pageNo,
        pageSize: this.pageObject.pageSize,
        sortField: sortArr
      };
      this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
      if (this.initialLoad) {
        this.getManageClients();
        this.initialLoad = false;
      } else {
        if ((this.multiSortMetaDup === this.multiSortMeta) || this.multiSortMetaDup.length > 1 || this.sortClearButtonReset) {
          this.getManageClients();
          this.sortClearButtonReset = false;
        }
      }
    }
  }

  editClient(orgId: any) {
    this.manageClientService.editMode = true;
    this.manageClientService.getCustId = orgId;
    this.router.navigate(['../create-client'], { relativeTo: this.r });
  }

  toggleFctn() {
    this.toggle = !this.toggle;
    /* Assigning filter value to sessionStorage for State management*/
    this.sharedService.stateValues.filterClicked = this.toggle;
    this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
  }
  getDataForBU() {
    this.manageClientService.getBusinessUnitDropdownValue().subscribe((buData: any) => {
      this.userBUData = [];
      buData.data.forEach(data => {
        const add = { label: data.name, value: data.name };
        this.userBUData.push(add);
      });
      const newObj = { label: 'All', value: 'All' };
      if (this.userBUData.length > 1) {
        this.userBUData.unshift(newObj);
      } else {
        /* If User has access to only 1 businessUnit,then populate the BU field and disable the BU */
        this.manageClientListForm.get('businessUnit').setValue(this.userBUData[0]);
        this.manageClientListForm.get('businessUnit').disable();
      }
      buData.data.forEach(data => {
        if (data.name === this.shellConstant.rbsBUText) {
          this.rbsBU = true;
        } else if (data.name === this.shellConstant.manageUserPage.dbs || data.name === this.shellConstant.manageUserPage.sps) {
          this.dbsSpsBU = true;
        }
      });

      if (this.rbsBU && !this.dbsSpsBU) {
        this.manageClientListTableCols = manageClientsListTableHeaderForRBS;
      } else if (this.dbsSpsBU && !this.rbsBU) {
        this.manageClientListTableCols = manageClientsListTableHeaderForNonRBS;
      }
    });
  }

  onChanges(): void {
    this.manageClientListForm.get('businessUnit').valueChanges.subscribe(value => {
      /* Reset and disable the LinkType if the selected BU is RBS */
      if (value && value.value === this.shellConstant.rbsBUText) {
        const newObj = {label: 'All', value: 'All'};
        this.manageClientListForm.patchValue({linkType: newObj});
        this.manageClientListForm.get('linkType').disable();
      } else if (!this.manageClientListForm.get('custId').value && !this.manageClientListForm.get('masterAccount').value) {
        this.manageClientListForm.get('linkType').enable();
      }
    });

    this.manageClientListForm.get('custId').valueChanges.subscribe(value => {
      /* Disable the K4 Master if value is entered in custId field */
      if (value) {
        this.disableK4Master = true;
      } else {
        this.disableK4Master = false;
      }
      this.disableInputFields();
    });

    this.manageClientListForm.get('masterAccount').valueChanges.subscribe(value => {
      /* Disable the custId if value is entered in K4 Master field */
      if (value) {
        this.disableCustId = true;
      } else {
        this.disableCustId = false;
      }
      this.disableInputFields();
    });
  }

  clickedClearBtn(resetSortFromUI?) {
    this.filterClicked = false;
    this.isClickedClearBtn = false;
    this.pageObject.pageNo = 1;
    this.resetForm(resetSortFromUI);
    this.sharedService.tableDropdownVal.next(this.pageObject.pageSize);
    this.paginator.changePage(0);
    if (!this.sortClearButtonReset) {
      this.getManageClients();
    }
  }

  resetForm(resetSortFromUI?) {
    this.manageClientListForm.reset();
    const newObj = {label: 'All', value: 'All'};
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
      this.sortClearButtonReset = true;
    }
    this.manageClientListForm.patchValue({applications: newObj, linkType: newObj, businessUnit: this.userBUData.length === 1 ? this.userBUData[0] : newObj, accessType: newObj});
    if (this.toggle && this.rangeCalendar) {
      this.isClickedClearBtn = true;
      this.rangeCalendar.clearSelection();
    }
    this.filterBtnDisable = false;
    this.pageObject.pageNo = 1;
  }

  paginate(e) {
    if (this.pageObject.pageNo !== e.page + 1) {
      const clientName = JSON.parse(this.localStorageService.getProfile());
      const pageVals: any = (this.storeClientInfo && this.storeClientInfo.clientName) !== (clientName && clientName.clientName) ? this.stateVal && this.stateVal.pageObj.pageNo ? this.stateVal.pageObj.pageNo : e.page + 1 : e.page + 1;
      this.pageObject.pageNo = pageVals;
      this.clearSuccessAndErrorMessages();
      this.getManageClients();
    }
  }

  getSelectedDate(date) {
    this.filterSelectedDate = date;
    this.manageClientListForm.get('modifiedDate').setValue(this.filterSelectedDate);
    if (this.filterSelectedDate) {
      this.isClickedClearBtn = false;
      this.manageClientListForm.markAsDirty();
    }
  }

  applyFilter() {
    /* Set the selected date value to the manageClientListForm before hitting the Filter API */
    if (this.rangeCalendar) {
      this.rangeCalendar.applyToDate();
      this.getSelectedDate(this.rangeCalendar.dateRangeForm.get('dateSelected').value);
    }

    this.pageObject.pageNo = 1;
    this.paginator.changePage(0);
    this.filterClicked = true;
    this.clearSuccessAndErrorMessages();
    /* Updating values to sessionStorage for State management*/
    if (this.stateVal) {
      this.stateVal.pageObj.pageSize = 15;
      this.stateVal.pageObj.pageNo = 1;
      this.stateVal.filterObj = this.setFormValues();
    }
    this.getManageClients();
  }

  disableInputFields() {
    const k4MasterValue = this.manageClientListForm.get('masterAccount').value;
    const custIdValue = this.manageClientListForm.get('custId').value;
    /* Disable the other fields if value is entered in K4 Master or custId field */
    if (k4MasterValue || custIdValue) {
      /* Disable the Apply filter button if value entered in Master Account or custId field is less than 2 character*/
      if ((k4MasterValue && k4MasterValue.length < 2) || (custIdValue && custIdValue.length < 2)) {
        this.filterBtnDisable = true;
      } else {
        this.filterBtnDisable = false;
      }
      this.filterState = 'disable';
      const newObj = {label: 'All', value: 'All'};
      this.manageClientListForm.patchValue({
        applications: newObj,
        linkType: newObj,
        businessUnit: this.userBUData.length === 1 ? this.userBUData[0] : newObj,
        accessType: newObj
      });
      if (this.rangeCalendar) {
        this.rangeCalendar.clearSelection();
      }
      Object.keys(this.manageClientListForm.controls).forEach((controlName) => {
        if (controlName !== 'custId' && controlName !== 'masterAccount') {
          this.manageClientListForm.controls[controlName][this.filterState]();
        }
      });
    } else {
      /* Enable the fields when value is removed from K4 Master or custId field */
      this.filterState = 'enable';
      this.filterBtnDisable = false;
      this.manageClientListForm.get('applications').enable();
      this.manageClientListForm.get('accessType').enable();
      if (this.userBUData.length > 1) {
        this.manageClientListForm.get('businessUnit').enable();
        if (this.manageClientListForm.get('businessUnit').value.value !== this.shellConstant.rbsBUText) {
          this.manageClientListForm.get('linkType').enable();
        }
      } else if (this.userBUData.length === 1 && this.userBUData[0].value !== this.shellConstant.rbsBUText) {
        this.manageClientListForm.get('linkType').enable();
      }
    }

    /* Enable the Clear btn by making the form dirty, upon returning to the Landing Page from edit client screen or when filter date is selected */
    if (!this.isClickedClearBtn && ((this.stateVal && this.stateVal.filterClicked) || this.filterSelectedDate)) {
      this.manageClientListForm.markAsDirty();
    }
  }

  clearSuccessAndErrorMessages() {
    this.messageService.clear(); 
    this.sharedService.clearErrorMessage();
  }
}

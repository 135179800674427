<div class="sdps-p-horizontal_medium">
  <sdps-notification type="information" class="sdps-m-top_none" *ngIf="showClientMsg">
    <p class="sdps-notification__text" [innerHTML]="getShellConstant.noCientSelected"></p>
  </sdps-notification>
</div>

<div class="sdps-p-horizontal_medium" *ngIf="!showClientMsg">
  <div class="full-width">
    <form [formGroup]="reportDetails">
      <div class="sdps-grid-container sdps-wrap report-dropdown">

        <div class="sdps-row sdps-p-top_large">
          <label>
            <p-radioButton class="emtr-radio ems-inline" id="snapShot" name="selectedButtonType" formControlName="selectedButtonType"
              value="{{getShellConstant.reports.RulesDetails.snapShot}}" (ngModelChange)="editSnapshotHistory()" label="{{getShellConstant.reports.RulesDetails.snapShot}}"></p-radioButton>
          </label>

          <label class="sdps-m-left_small" *ngIf="batchCitiOrg !== orgID">
            <p-radioButton class="emtr-radio ems-inline" id="history" name="selectedButtonType" formControlName="selectedButtonType"
              value="{{getShellConstant.reports.RulesDetails.historyTxt}}" (ngModelChange)="editSnapshotHistory()" label="{{getShellConstant.reports.RulesDetails.historyTxt}}"></p-radioButton>
          </label>
        </div>

        <div class="sdps-row sdps-p-top_x-large" *ngIf="!snapshotSelected">
          <div class="display-flex">
            <app-report-from-to-datepicker (calendarValidation)="calendarValidation($event)" [dateRange]="90" [initialDateRange]="7" (getEmittedVal)="setDate($event)"
              [calendarForm]="reportDetails" (throwDateError)="throwDateError($event)" (throwDateRangeError)="throwDateRangeError($event)"></app-report-from-to-datepicker>
          </div>
        </div>

        <div class="sdps-row sdps-p-top_large select-report-type" *ngIf="!snapshotSelected">
          <div class="col-3 sdps-p-left_none" *ngIf="businessUnit !== sharedConstant.rbsBUText">
            <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (reportDetails.get('employeeID').value && reportDetails.get('employeeID').invalid)}">
              <span class="p-float-label">
                <input type="text" name="employeeID" formControlName="employeeID" [maxLength]="35" emsEmployeeIdPatterncheck emsCharacterLimit
                  [charMaxLimit]="getCommConst.charLimitValues.thirtyFiveMaxLimit" (emsCharacterLimitCheck)="showEmpIdCharLimit = $event"
                  pInputText/>
                  <label [innerHTML]="sharedConstant[businessUnit].empOrParticipantsID"></label>
              </span>
            </div>
            <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small" *ngIf="reportDetails.get('employeeID').value && reportDetails.get('employeeID').value.length > showEmpIdCharLimit">{{getShellConstant.reports.characterLimit}}
              <span class="character-limit">{{reportDetails.get('employeeID').value.length}} /{{getCommConst.charLimitValues.thirtyFiveMaxLimit}}
              </span>
            </p>
          </div>
          <div class="col-3 sdps-p-left_none">
            <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (reportDetails.get('employeeAccount').value && (reportDetails.get('employeeAccount').invalid || reportDetails.get('employeeAccount').value.length < 8))}">
              <span class="p-float-label">
                <input type="text" name="employeeAccount" [maxlength]="8" onkeypress="if(event.which<48 || event.which>57) return false;" emsNumberPatterncheck formControlName="employeeAccount" pInputText/>
                <label [innerHTML]="getShellConstant.reports.presetReports.accountNumber"></label>
              </span>
            </div>
          </div>
          <div class="col-3 sdps-p-left_none">
            <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (reportDetails.get('employeeLastName').value && reportDetails.get('employeeLastName').invalid)}">
              <span class="p-float-label">
                <input type="text" *ngIf="businessUnit === sharedConstant.rbsBUText" name="employeeLastName" [maxLength]="30" formControlName="employeeLastName" emsNamePatternCheck emsCharacterLimit
                  (emsCharacterLimitCheck)="showTextboxCharLimit = $event" [charMaxLimit]="getCommConst.charLimitValues.thirtyMaxLimit"
                  pInputText/>
                <input type="text" *ngIf="businessUnit !== sharedConstant.rbsBUText" name="employeeLastName" [maxLength]="30" formControlName="employeeLastName" emsCharacterLimit
                  (emsCharacterLimitCheck)="showTextboxCharLimit = $event" [charMaxLimit]="getCommConst.charLimitValues.thirtyMaxLimit"
                  pInputText/>
                  <label [innerHTML]="sharedConstant[businessUnit].empOrParticipantsLastName"></label>
              </span>
            </div>
            <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml" *ngIf="reportDetails.get('employeeLastName').value && reportDetails.get('employeeLastName').value.length > showTextboxCharLimit">{{getShellConstant.reports.characterLimit}}
              <span class="character-limit">{{reportDetails.get('employeeLastName').value.length}} /{{getCommConst.charLimitValues.thirtyMaxLimit}}
              </span>
            </p>
          </div>

        </div>

        <div class="sdps-row sdps-p-top_large select-report-type">
          <div class="col-3 sdps-p-left_none" *ngIf="!snapshotSelected">
            <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (reportDetails.get('accountHolderLastName').value && reportDetails.get('accountHolderLastName').invalid)}">
              <span class="p-float-label">
                <input type="text" *ngIf="businessUnit === sharedConstant.rbsBUText" name="accountHolderLastName" [maxLength]="30" formControlName="accountHolderLastName" emsNamePatternCheck
                       emsCharacterLimit (emsCharacterLimitCheck)="showTextboxCharLimit = $event" [charMaxLimit]="getCommConst.charLimitValues.thirtyMaxLimit"
                       pInputText/>
                <input type="text" *ngIf="businessUnit !== sharedConstant.rbsBUText" name="accountHolderLastName" [maxLength]="30" formControlName="accountHolderLastName"
                       emsCharacterLimit (emsCharacterLimitCheck)="showTextboxCharLimit = $event" [charMaxLimit]="getCommConst.charLimitValues.thirtyMaxLimit"
                       pInputText/>
                <label [innerHTML]="getShellConstant.reports.presetReports.accountHolderLastName"></label>
              </span>
            </div>
            <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml" *ngIf="reportDetails.get('accountHolderLastName').value && reportDetails.get('accountHolderLastName').value.length > showTextboxCharLimit">{{getShellConstant.reports.characterLimit}}
              <span class="character-limit">{{reportDetails.get('accountHolderLastName').value.length}} /{{getCommConst.charLimitValues.thirtyMaxLimit}}
              </span>
            </p>
          </div>
          <div class="col-3 sdps-p-left_none">
            <div class="sdps-p-left_none sdps-dropdown--lightGrey emtr-dropdown employee-dropdown dropdown-width employee-association-dropdown">
              <span class="p-float-label">
              <p-dropdown #dropdown [placeholder]="reportService.businessUnit === getShellConstant.rbsBUText ? getShellConstant.reports.presetReports.enterPlanName : getShellConstant.reports.presetReports.enterGroupName" [options]="empGroupDropDownOpts"
                          formControlName="empGroupName" (onShow)="clearSearch(dropdown)"
                          filter="true" (keyup)="searchedText($event, 'groupName')">
                <ng-template let-empRecords pTemplate="item">
                  <div class="ui-helper-clearfix display-image"
                       (click)="stopDisabledAllGroupsClick($event, empRecords.value)"
                       [ngClass]="{'disabled-option': (snapshotSelected && (empRecords.value === 'All Groups' || empRecords.value === 'All Plans'))}">
                    <span>{{empRecords.value}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
               <label [ngClass]="{'emp-label': dropdown.value !== null}">{{reportService.businessUnit === getShellConstant.rbsBUText ? getShellConstant.reports.presetReports.enterPlanName : getShellConstant.reports.presetReports.enterGroupName}}</label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>

    <ems-step-wizard-buttons [showCancelBtn]="true" (closeBtn)="cancelReport()" [showRunReportBtn]="true" [disableBtn]="snapshotSelected ? parent.schErrorMsg.length > 0 || disableRunButton : reportDetails.invalid || parent.schErrorMsg.length > 0 || endDateLessThanStartDate || dateError || disableRunButton"
      (runReport)="runReports()"></ems-step-wizard-buttons>
  </div>
</div>

import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  Directive,
  AfterContentInit,
  Inject,
  OnDestroy
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { AddlinkService } from '../../../../addlink.service';
import { affiliations } from '../../../../constants/affiliations';
import { RequestListService } from '../../../services/request-list.service';
import {Request} from '../../../../core/link/link.component';
import {LinkService} from '../../../services/link.service';
import { CommonService } from '../../../services/common.service';
import { SharedService, EmsMultiselectComponent } from '@ems/shared';
import { AddlinkConstant } from '../../../../constants/addlinkConstants';
import { Subscription } from 'rxjs';

@Directive({ selector: '[addlinkAfterIf]' })
export class AfterIfDirective implements AfterContentInit {
  @Output('addlinkAfterIf')
  public after: EventEmitter<AfterIfDirective> = new EventEmitter();

  public ngAfterContentInit(): void {
    setTimeout(() => {
      // timeout helps prevent unexpected change errors
      this.after.next(this);
    });
  }
}

@Component({
  selector: 'addlink-edit-requests-modal',
  templateUrl: './edit-requests-modal.component.html',
  styleUrls: ['./edit-requests-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditRequestsModalComponent implements OnInit, OnDestroy {
  addLinkConstant = AddlinkConstant;
  public title: string;
  public affiliations: SelectItem[] = affiliations;
  public selectedAffiliation: SelectItem;
  public isSameAsEmployee: boolean;

  public requestId: string;
  public clientId: string;
  public authAgentId: string;
  public masterAccount: string;
  public accountNumber: string;
  public employeeFirstName: string;
  public employeeLastName: string;
  public accountHolderFirstName: string;
  public accountHolderLastName: string;
  public accountHolderSameAsEmployee: boolean;
  public employeeToAccountHolderAffiliation: string;
  public source: string;
  public formValueChange = false;
  public affiliation: string;
  public requests: Request[];
  public isAuthAgentRole: boolean;

  public editRequestsForm: UntypedFormGroup;
  public accFirstName: any;
  public accLastName: any;
  showTextboxCharLimit: boolean;
  @Output() udpateRequest = new EventEmitter<any>();
  @ViewChild('focusElement') focusElement: ElementRef;
  @ViewChild('affiliationsSelector') affiliationsSelector: EmsMultiselectComponent;
  intervalJobs: any;
  editRequestInfo: any = {};
  oldAffiliationVal = [];
  isAffiliationValChange = false;
  clientInformationSubscribe: Subscription;
  orgId: any;
  requestOrgId: any;

  constructor(private commonService: CommonService, public sharedService: SharedService, private formBuilder: UntypedFormBuilder, 
    private requestListService: RequestListService, private addlinkService: AddlinkService, private linkService: LinkService, @Inject('commonConstants') public commonConstants) {
  }

  /* Initialize component variables */
  ngOnInit() {
    this.title = 'Edit Request';
    this.isSameAsEmployee = true;
    this.setupEditRequestsForm();
    this.detectEditRequestsFormChanges();
    this.clientInformationSubscribe =  this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.orgId = clientInfo.orgId;
      }
    });
  }

  /* Setup Edit Requests Form */
  public setupEditRequestsForm(): void {
    this.editRequestsForm = this.formBuilder.group({
      employeeFirstName: new UntypedFormControl('', Validators.required),
      employeeLastName: new UntypedFormControl('', Validators.required),
      accountHolderSameAsEmployee: [false],
      accountHolderFirstName: new UntypedFormControl('', Validators.required),
      accountHolderLastName: new UntypedFormControl('', Validators.required),
      employeeToAccountHolderAffiliation: new UntypedFormControl({ value: '', disabled: false }, null),
    });
  }

  /* Detect Form Changes */
  public detectEditRequestsFormChanges(): void {
    /* Detect Changes On Employee To Account Holder Affiliation. */
    this.editRequestsForm.get('employeeToAccountHolderAffiliation').valueChanges.subscribe(val => {
      /* The old value will be assigned when the modal opens in focusModal method. Comparing the old value and the value
      in the form to enable the Resubmit button only when the value is changed. Not using Form.dirty bec the dirty will
      be true from the start for employeeToAccountHolderAffiliation */
      if (val && ((val.length > 0 && this.oldAffiliationVal.length > 0 && this.oldAffiliationVal[0].value !== val[0].value) || (val.length > 0 && this.oldAffiliationVal.length === 0))) {
        this.isAffiliationValChange = true;
      }
    });
    this.detectAccountHolderSameAsEmployeeChanges();
    this.detectEmployeeNameChanges();
  }

  /* Detect Any Changes To The Account Holder Same As Employee Checkbox Field */
  public detectAccountHolderSameAsEmployeeChanges(): void {
    // Detect Changes To Account Holder Same as Employee Check Box.
    this.editRequestsForm.controls.accountHolderSameAsEmployee.valueChanges.subscribe(accountHolderSameAsEmployee => {
      this.formValueChange = false;

      if (accountHolderSameAsEmployee === false) {

        // Clear the Account Holder Names.
        this.editRequestsForm.controls.accountHolderFirstName.reset();
        this.editRequestsForm.controls.accountHolderLastName.reset();

        // Enable Account Holder Fields If CheckBox is UnChecked.
        this.editRequestsForm.controls.accountHolderFirstName.enable();
        this.editRequestsForm.controls.accountHolderLastName.enable();
        this.editRequestsForm.controls.employeeToAccountHolderAffiliation.enable();
        if (this.editRequestsForm.dirty) {
          this.formValueChange = true;
        }
      } else {

        // Clear The Account Holder Affiliation Value.
        this.editRequestsForm.controls.employeeToAccountHolderAffiliation.setValue([]);

        // Set the Account Holder First and Last Names Equal To The Employees.
        const employeeFirstName = this.editRequestsForm.controls.employeeFirstName.value;
        const employeeLastName = this.editRequestsForm.controls.employeeLastName.value;
        this.editRequestsForm.controls.accountHolderFirstName.setValue(employeeFirstName);
        this.editRequestsForm.controls.accountHolderLastName.setValue(employeeLastName);
        if ((this.accFirstName && this.accFirstName !== employeeFirstName) || (this.accLastName && this.accLastName !== employeeLastName) || this.editRequestsForm.get('employeeFirstName').dirty || this.editRequestsForm.get('employeeLastName').dirty) {
          this.formValueChange = true;
        }

        // Disable Account Holder Fields If CheckBox is Checked.
        this.editRequestsForm.controls.accountHolderFirstName.disable();
        this.editRequestsForm.controls.accountHolderLastName.disable();
        this.editRequestsForm.controls.employeeToAccountHolderAffiliation.disable();
        if (this.editRequestsForm.get('employeeFirstName').dirty || this.editRequestsForm.get('employeeLastName').dirty) {
          this.formValueChange = true;
        }
      }
    });
  }

  /* Detect Any Changes To The Employee Name Fields */
  public detectEmployeeNameChanges(): void {

    // Detect Changes On Employee First Name.
    this.editRequestsForm.controls.employeeFirstName.valueChanges.subscribe(employeeFirstName => {
      this.formValueChange = true;
      // If Same As Employee is Checked Then Copy The Employee First Name To Account Holder First Name.
      if (this.editRequestsForm.controls.accountHolderSameAsEmployee.value === true) {
        this.editRequestsForm.controls.accountHolderFirstName.setValue(employeeFirstName);
      }
    });

    // Detect Changes On Employee Last Name.
    this.editRequestsForm.controls.employeeLastName.valueChanges.subscribe(employeeLastName => {
      this.formValueChange = true;
      // If Same As Employee is Checked Then Copy The Employee Last Name To Account Holder Last Name.
      if (this.editRequestsForm.controls.accountHolderSameAsEmployee.value === true) {
        this.editRequestsForm.controls.accountHolderLastName.setValue(employeeLastName);
      }
    });
  }

  /* Reset The Edit Requests Form To Defualt Values. */
  public resetEditRequestsForm(): void {

    // Loop through all of the form controls.
    Object.keys(this.editRequestsForm.controls).forEach(key => {

      // Set there values to empty string.
      this.editRequestsForm.controls[key].setValue(null);

      // If its the Account Holder Same As Employee Checkbox set the value to true.
      if (key === 'accountHolderSameAsEmployee') {
        this.editRequestsForm.controls[key].setValue(true);
      }
    });
  }

  /* Opens The Modal */
  public openModal(e: Event, requestId: string, accountNumber: string, orgId: string, authAgentId: string, masterAccount: string, empFirstName: string, empLastName: string, acctHolderFirstName: string, acctHolderLastName: string, affiliation: string, source: string): void {
    e.stopPropagation();
    this.requestId = requestId;
    this.accountNumber = accountNumber;
    this.requestOrgId = orgId;
    this.authAgentId = authAgentId;
    this.masterAccount = masterAccount;
    this.affiliation = affiliation;
    this.source = source;

    this.editRequestsForm.controls.employeeFirstName.setValue(empFirstName);
    this.editRequestsForm.controls.employeeLastName.setValue(empLastName);

    if (empFirstName === acctHolderFirstName && empLastName === acctHolderLastName) {
      this.accFirstName = empFirstName;
      this.accLastName = empLastName;
      this.editRequestsForm.controls.accountHolderSameAsEmployee.setValue(true);
    } else {
      this.accFirstName = acctHolderFirstName;
      this.accLastName = acctHolderLastName;
      this.editRequestsForm.controls.accountHolderSameAsEmployee.setValue(false);
    }

    this.editRequestsForm.controls.accountHolderFirstName.setValue(acctHolderFirstName);
    this.editRequestsForm.controls.accountHolderLastName.setValue(acctHolderLastName);
    this.accFirstName = this.editRequestsForm.get('accountHolderFirstName').value;
    this.accLastName = this.editRequestsForm.get('accountHolderLastName').value;

    this.sharedService.openModal('beta-edit-request-modal');
  }

  /* Closes The Modal */
  public closeModal(): void {
    this.oldAffiliationVal = [];
    this.isAffiliationValChange = false;
    this.formValueChange = false;
    this.editRequestsForm.reset();
    this.resetEditRequestsForm();
    this.sharedService.closeModal('beta-edit-request-modal');
  }

  public submit(): void {
    let count = 0;
    this.requestListService.resubmitValidationMismatch(this.editRequestsForm, this.requestId, this.requestOrgId, this.authAgentId, this.masterAccount, this.accountNumber, this.source).subscribe((data) => {
      if (this.requestId) {
        this.intervalJobs = setInterval(() => {
          this.getRequestStatus(this.requestId);
          count++;
          if (count === 5) {
            window.clearInterval(this.intervalJobs);
          }
        }, this.addLinkConstant.reqDetailPollingInterval);
      }
      this.closeModal();
    });
  }

  getRequestStatus(requestIds) {
    this.sharedService.setRefresh.emit(true);
    this.commonService.pollRequestDetailsByRequestId(requestIds, this.orgId).subscribe( (res) => {
      this.editRequestInfo = res.data.requestInfo;
      this.editRequestInfo.disableIcon = true;
      const status = this.sharedService.isInternalApp() ? this.editRequestInfo.internalStatus : this.editRequestInfo.status;
      if (status !== 'Validating' && status !== 'Received') {
        clearInterval(this.intervalJobs);
        this.editRequestInfo.disableIcon = false;
        this.sharedService.setRefresh.emit(false);
      }
      this.udpateRequest.emit(this.editRequestInfo);
    }, error => {
      clearInterval(this.intervalJobs);
    });
  }

  public focusModal() {
    if (this.affiliation) {
      let aff = null;
      for (const entry of this.affiliationsSelector.options) {
        if (entry.value === this.affiliation) {
          aff = entry;
        }
      }
      this.oldAffiliationVal = [aff];
      this.affiliationsSelector.value = [aff];
      this.affiliationsSelector.onModelChange(this.affiliationsSelector.value);
    }
  }

  public resetDisableIcon() {
    this.editRequestInfo.disableIcon = false;
    this.editRequestInfo.requestId = this.requestId;
    this.udpateRequest.emit(this.editRequestInfo);
  }

  ngOnDestroy(): void {
    if (this.clientInformationSubscribe) {
      this.clientInformationSubscribe.unsubscribe();
    }
  }
}

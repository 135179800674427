<sdps-modal id='edit-employee-modal' [sdpsTitle]="title" class="sdps-custom-modal" [size]="'flex--medium'" (close)="closeModal()">
  <div slot="modal-body">
    <form class="container" [formGroup]="editRequestsForm">
      <sdps-notification type="error" class="sdps-m-top_none" *ngIf="(editRequestsForm.get('employeeFirstName').value && editRequestsForm.get('employeeFirstName').invalid) ||
          (editRequestsForm.get('employeeLastName').value && editRequestsForm.get('employeeLastName').invalid) ||
          (serviceErrorMsg) || (editRequestsForm.get('employeeId').value && invalidEmpId) ||
          (editRequestsForm.get('employeeFirstName').invalid && editRequestsForm.get('employeeFirstName').errors.required && sharedService.addOrEditEmployee !== 'addEployee') ||
          (editRequestsForm.get('employeeLastName').invalid && editRequestsForm.get('employeeLastName').errors.required && sharedService.addOrEditEmployee !== 'addEployee')">
        <div class="sdps-notification__text" *ngIf="sharedService.addOrEditEmployee === 'addEployee'" class="sdps-text-l-body">
          <p *ngIf="editRequestsForm.get('employeeId').value && invalidEmpId">
            {{getSharedConstant.textFieldErrorMsg.employeeIdErrorMsg}}
          </p>
          <p *ngIf="serviceErrorMsg" [innerHTML]="serviceErrorMsg"></p>
        </div>
        <div class="sdps-notification__text" *ngIf="sharedService.addOrEditEmployee !== 'addEployee' && !serviceErrorMsg">
          <p [innerHTML]="getSharedConstant.pleaseCorrectFollowingError"></p>
          <ul class="sdps-navigation--horizontal__list error-list">
            <li *ngIf="invalidEmpId" [innerHTML]="getSharedConstant.textFieldErrorMsg.employeeIdErrorMsg"></li>
            <li
              *ngIf="editRequestsForm.get('employeeFirstName').invalid && editRequestsForm.get('employeeFirstName').errors.required"
              [innerHTML]="getSharedConstant[businessUnit].employeeOrParticipantFirstNameRequiredMsg"></li>
            <li
              *ngIf="editRequestsForm.get('employeeFirstName').invalid && editRequestsForm.get('employeeFirstName').errors.maxlength"
              [innerHTML]="getConstant.mangeEmployees.editEmployeeBlankMsg.employeeFirstNameInvalidLengthMsg"></li>
            <li
              *ngIf="editRequestsForm.get('employeeLastName').invalid && editRequestsForm.get('employeeLastName').errors.required"
              [innerHTML]="getSharedConstant[businessUnit].employeeOrParticipantLastNameRequiredMsg"></li>
            <li
              *ngIf="editRequestsForm.get('employeeLastName').invalid && editRequestsForm.get('employeeLastName').errors.maxlength "
              [innerHTML]="getConstant.mangeEmployees.editEmployeeBlankMsg.employeeLastNameInvalidLengthMsg"></li>
          </ul>
        </div>
        <div *ngIf="sharedService.addOrEditEmployee !== 'addEployee' && serviceErrorMsg" class="sdps-text-l-body">
          <p *ngIf="serviceErrorMsg" [innerHTML]="serviceErrorMsg"></p>
        </div>
      </sdps-notification>
      <span class="sdps-text-l-bold-body sdps-pull-right">
        {{getConstant.mangeEmployees.editEmployeeBlankMsg.accountNumber}} : {{accountNumber}}
      </span>
      <div class="sdps-grid-fluid sdps-wrap">
        <div class="sdps-row sdps-m-vertical_large sdps-wrap">
          <div class="sdps-row">
            <div class="col-content col-m-4 col-s-12 input-box"
                 [ngClass]="{'invalidEmployee':invalidEmpId}">
              <span class="p-float-label">
                <input type="text" name="employeeId" formControlName="employeeId" [maxLength]="35"
                       emsEmployeeIdPatterncheck emsCharacterLimit
                       [charMaxLimit]="commonConstants.charLimitValues.thirtyFiveMaxLimit"
                       (emsCharacterLimitCheck)="showEmpIdCharLimit = $event" pInputText/>
                <label>{{getSharedConstant[businessUnit].empOrParticipantsID}}
                  <span *ngIf="sharedService.addOrEditEmployee === 'addEployee'" class="sdps-text-market-red"
                        [innerHTML]="commonConstants.asterix"></span>
                </label>
              </span>
            </div>
          </div>
          <p class="col-4 sdps-text-right sdps-p-right_medium sdps-m-around_none sdps-text-small"
             *ngIf="editRequestsForm.get('employeeId').value && editRequestsForm.get('employeeId').value.length > showEmpIdCharLimit">{{getConstant.rulesConstant.characterLimit}}
            <span class="character-limit">{{editRequestsForm.get('employeeId').value.length}}
              /{{commonConstants.charLimitValues.thirtyFiveMaxLimit}}</span>
          </p>
        </div>

        <div class="sdps-p-right_medium">
          <div class="col-content col-m-4 col-s-12 input-box"
               [ngClass]="{'invalidEmployee':(editRequestsForm.controls['employeeFirstName'].value && editRequestsForm.get('employeeFirstName').invalid)}">

            <span class="p-float-label">

              <input type="text" formControlName="employeeFirstName" [maxlength]="50"
                     emsCharacterLimit [charMaxLimit]="commonConstants.charLimitValues.fiftyMaxLimit"
                     (emsCharacterLimitCheck)="showCharLimit = $event" pInputText/>
              <label>
                {{getSharedConstant[businessUnit].empOrParticipantsFirstName}}
                <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
              </label>
            </span>
          </div>
          <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
             *ngIf="editRequestsForm.get('employeeFirstName').value && editRequestsForm.get('employeeFirstName').value.length > showCharLimit">
            {{getConstant.rulesConstant.characterLimit}}
            <span class="character-limit">{{editRequestsForm.get('employeeFirstName').value.length}}
              /{{commonConstants.charLimitValues.fiftyMaxLimit}}</span>
          </p>
        </div>

        <div>
          <div class="col-content col-m-4 col-s-12 input-box"
               [ngClass]="{'invalidEmployee':(editRequestsForm.controls['employeeLastName'].value ) && editRequestsForm.get('employeeLastName').invalid}">
            <span class="p-float-label">
              <input type="text" formControlName="employeeLastName"  emsCharacterLimit
                     [charMaxLimit]="commonConstants.charLimitValues.fiftyMaxLimit"
                     (emsCharacterLimitCheck)="showCharLimit = $event" [maxlength]="50" pInputText/>
              <label>
                {{getSharedConstant[businessUnit].empOrParticipantsLastName}}
                <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
              </label>
            </span>
          </div>
          <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
             *ngIf="editRequestsForm.get('employeeLastName').value && editRequestsForm.get('employeeLastName').value.length > showCharLimit">
            {{getConstant.rulesConstant.characterLimit}}
            <span class="character-limit">{{editRequestsForm.get('employeeLastName').value.length}}
              /{{commonConstants.charLimitValues.fiftyMaxLimit}}</span>
          </p>
        </div>
      </div>
    </form>
  </div>

  <div slot="modal-footer">
    <button class="buttons" type="reset" (click)="sharedService.closeModal('edit-employee-modal')"
              [innerHTML]="getConstant.rulesConstant.ruleGlobalConst.cancel"></button>
    <button type="submit" [disabled]="editRequestsForm.invalid || invalidEmpId" class="buttons button-save"
              (click)="updateEmployee()" [innerHTML]="getConstant.submitButton"></button>
  </div>
</sdps-modal>

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnDestroy, Inject
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedConstant} from '../../constants/sharedConstants';
import {ManageEmpGrpService} from '../../services/manage-emp-grp-service/manage-emp-grp.service';
import {SharedService} from '../../shared.service';
import {ToastMessageComponent} from '../toast-message/toast-message.component';
import {HttpResponse} from '@angular/common/http';
import {ConfigUrlService} from '../../services/config-url/config-url.service';
import {ManageEmployeeDataService} from '../../services/manage-employee/manage-employee-data.service';
import {interval, Subscription, Observable} from 'rxjs';
import {delay} from 'rxjs/operators';
import 'rxjs/add/observable/interval';
import 'rxjs/add/observable/timer';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {LinkDelinkService} from '../../services/link-delink-service/link-delink-service.service';
@Component({
  selector: 'ems-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() getOrgId: any;
  @Input() lib: any;
  @Input() downloadTemplate: any;
  @Input() uploadFileApi: any;
  @Input() parentPage: any;
  @Input() createdName: any;
  @Input() groupDesc: any;
  @Input() downloadTemplateName: any;
  @Input() downloadTemplateLabel: any;
  @Input() bulkUploadTitles: any;
  @Input() linkType: any;
  @Input() downloadTemplateGutTack: any;
  @Input() chooseFileGutTack: any;
  @Input() uploadBtnGutTack: any;
  @Output() FileUploadEmit: any = new EventEmitter();
  @Output() hasFormValuesChanged: any = new EventEmitter();
  sharedEnv: any;
  sharedConstant = SharedConstant;
  isFileUploaded = false;
  fileSize = this.sharedConstant.bulkUpload.maxFileSize;
  showError = false;
  showUploadError = false;
  uploadedFilesize: any;
  subscription: Subscription;
  errorMessage: any;
  fileName: any;
  files: any[] = [];
  uploadedFile: any[] = [];
  fileData: any;
  displayFileName: any;
  downloadURL: any;
  uploadResponse: any = [];
  isModalOpen = false;
  modalTitle = this.sharedConstant.bulkUpload.uploadErrorsTxt;
  successMsg: any;
  RequestInterval: any;
  uploadErrorMsg: any;
  uploadID: any;
  currentOrgSubscription: Subscription;
  externalAccess = false;
  public masterAccountOptions: any[];
  usertype: any;
  @ViewChild(ToastMessageComponent, { static: true }) msgToast: ToastMessageComponent;
  @ViewChild('fileUploader') localUploader: ElementRef;
  uploadedEmit: any = {};
  configURL = this.config.setApplicationUrl();
  cols: any;
  fileProcessStatus: any;
  fileUploadStatus: any = false;
  showSuccessInfoMsg: any = false;
  uploadErrorFileMsg: any;
  addlinkSuccessMsg: any;
  authorizedAgents: any;
  isAuthAgentRole: boolean;
  public BulkUploadForm: UntypedFormGroup;
  is10b5Client = false;
  showPlanIDField = false;
  planIDTxt = 'planId';
  countOfSL = 0;
  showRemarksTxtboxCharLimit: boolean;
  public businessUnit: any;
  clientId: any;
  isAAExceptBU: boolean;
  clientInfo: any;
  linkMethod: any;
  showClient: any = false;
  hasADDLinkAccess = false;
  uploadText: string;
  uploadButtonText: string;
  initialValues: any;
  constructor(public linkDelinkService: LinkDelinkService, private formBuilder: UntypedFormBuilder, @Inject('entitlementConst') public entitlementConst,
              private router: Router, public sharedSer: SharedService,
              private changeDetector: ChangeDetectorRef, private r: ActivatedRoute, private manageEmpGrpService: ManageEmpGrpService,
              public config: ConfigUrlService, private manageEmployeeDataService: ManageEmployeeDataService, @Inject('commonConstants') public commonConstants) {
    this.sharedEnv = this.sharedSer.env;
    this.cols = [
      {field: 'rowNumber', header: 'Row Number'},
      {field: 'status', header: 'Status'},
      {field: 'rawData', header: 'rawData'},
      {field: 'data', header: 'failMessages'},
    ];
  }

  ngOnDestroy() {
    if (this.RequestInterval && this.RequestInterval._subscriptions && this.RequestInterval._subscriptions.length > 0) {
      this.RequestInterval.unsubscribe();
    }
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.sharedSer.disableClientSelector.next(this.sharedSer.ClientCount === this.sharedConstant.clientCount);
  }

  ngAfterViewChecked() {
    if (this.linkMethod === this.sharedConstant.bulkGlobal.multi && this.countOfSL === 1 && this.usertype === this.sharedConstant.bulkGlobal.internal) {
      this.BulkUploadForm.get('masterAccounts').disable({emitEvent: false});
    }
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.showPlanIDField = false;
    this.linkDelinkService.showRemarks = false;
    this.BulkUploadForm = this.formBuilder.group({
      masterAccounts: new UntypedFormControl([], Validators.required),
      authAgent: new UntypedFormControl([], Validators.required),
      remarks: new UntypedFormControl(''),
      planId: new UntypedFormControl(''),
      doNotShowRemarkToClient: new UntypedFormControl(false),
    });
    this.BulkUploadForm.valueChanges.subscribe(x => {
      this.uploadedEmit.validFile = this.BulkUploadForm.valid;
      this.FileUploadEmit.emit(this.uploadedEmit);
      this.hasFormValuesChanged.emit(this.formHasChanged());
    });
    this.linkDelinkService.authAgentValidation(this.BulkUploadForm);
    this.usertype = this.sharedSer.isInternalApp() ? 'Internal' : 'External';
    this.sharedSer.selectedMasterAccnt.subscribe(value => {
      if (value && value.some(accnt => accnt.accountSubType && accnt.accountSubType.indexOf('10b5-1') !== -1)) {
        this.BulkUploadForm.get('planId').reset();
        this.showPlanIDField = true;
      } else {
        this.BulkUploadForm.get('planId').reset();
        this.showPlanIDField = false;
      }
      /* If selected Account is 10b5-1 MasterAccount adding validators to formControlName-PlanId */
      if (this.showPlanIDField && this.is10b5Client) {
        this.BulkUploadForm.controls[this.planIDTxt].setValidators(Validators.required);
        this.BulkUploadForm.controls[this.planIDTxt].updateValueAndValidity();
      } else {  /* If selected Account is not a 10b5-1 MasterAccount removing validators to formControlName-PlanId */
        this.BulkUploadForm.controls[this.planIDTxt].clearValidators();
        this.BulkUploadForm.controls[this.planIDTxt].updateValueAndValidity();
      }
      this.initialValues = this.BulkUploadForm.getRawValue();
      this.hasFormValuesChanged.emit(this.formHasChanged());
    });

    this.currentOrgSubscription = this.sharedSer.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.businessUnit = clientInfo.businessUnit;
        this.hasADDLinkAccess = this.sharedSer.checkEntitlements(this.entitlementConst.navUUID.accountLinking.uuid, '', '');
        this.getOrgId = clientInfo.orgId;
        this.sharedSer.getOrgId = clientInfo.orgId;
        clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
        this.clientId = clientInfo.clientId;
        this.clientInfo = clientInfo;
        this.linkMethod = clientInfo.linkType === 'Dual' ? 'Multi' : clientInfo.linkType;
        this.is10b5Client = clientInfo.tenB51;
        this.showPlanIDField = false;
        this.closeInfo();
        this.BulkUploadForm.get('masterAccounts').setValue([]);
        this.BulkUploadForm.get('authAgent').setValue([]);
        this.BulkUploadForm.get('remarks').setValue('');
        this.countOfSL = 0;
        if (this.clientId !== null && this.lib === 'ADDLINK') {
          this.processMasterAcc();
        }
        this.initialValues = this.BulkUploadForm.getRawValue();
        this.hasFormValuesChanged.emit(this.formHasChanged());
      }
      if (this.hasADDLinkAccess && this.usertype !== 'Internal') {
        this.isAuthAgentRole = true;
        this.BulkUploadForm.controls.authAgent.setValidators([]);
        this.BulkUploadForm.controls.authAgent.updateValueAndValidity();
      } else {
        this.isAuthAgentRole = false;
      }
      this.initialValues = this.BulkUploadForm.getRawValue();
      this.hasFormValuesChanged.emit(this.formHasChanged());
    });

    this.downloadURL = this.configURL.sposEndPoint + '/files/templates/' + this.downloadTemplate;
    this.addlinkSuccessMsg = this.sharedConstant.bulkUpload.addlinkSuccessMsg + ' ' + this.sharedConstant.bulkUpload.requestList;
    this.uploadText = this.sharedConstant.bulkUpload.uploadText + '<em class="highlight-color">*</em>';
    this.uploadButtonText = this.sharedConstant.bulkUpload.chooseFileText;
    this.initialValues = this.BulkUploadForm.getRawValue();
    this.hasFormValuesChanged.emit(this.formHasChanged());
   }

  processMasterAcc() {
    if (this.hasADDLinkAccess) {
      this.linkDelinkService.getLinkMasterAccounts(this.getOrgId, this.clientId).subscribe(result => {
        const getMasterAccData: any = this.linkDelinkService.populateMasterAccounts(this.BulkUploadForm, this.clientId, this.linkMethod, this.countOfSL, this.usertype, result.data);
        this.masterAccountOptions = getMasterAccData.masterAccountOptions;
        this.countOfSL = getMasterAccData.countOfSL;
        if (this.usertype === 'External' && this.countOfSL === 1) {
          this.showPlanIDField = this.is10b5Client;
          if (this.showPlanIDField) {
            this.BulkUploadForm.controls[this.planIDTxt].setValidators(Validators.required);
            this.BulkUploadForm.controls[this.planIDTxt].updateValueAndValidity();
          }
        }
        this.initialValues = this.BulkUploadForm.getRawValue();
        this.hasFormValuesChanged.emit(this.formHasChanged());
      });

      this.linkDelinkService.getAuthorizedAgents(this.getOrgId).subscribe(result => {
        this.businessUnit = result.data.authAgentExceptionBusinesUnits;
        this.isAAExceptBU = this.linkDelinkService.populateAuthorizedAgents(this.businessUnit, this.clientInfo);
        if (this.isAAExceptBU) {
          this.BulkUploadForm.controls.authAgent.enable();
          this.authorizedAgents = this.linkDelinkService.getAuthAgents(this.clientId, result.data);
        } else {
          this.BulkUploadForm.controls.authAgent.disable();
        }
        this.initialValues = this.BulkUploadForm.getRawValue();
        this.hasFormValuesChanged.emit(this.formHasChanged());
      });
    }
  }

  fileSelection(e) {
    this.sharedSer.clearErrorMessage();
    this.sharedSer.gutTracking(this.chooseFileGutTack);

    /* BELOW IF CONDITION explained
    e.files is called when we select using choose file button
    e[0] is for drag and drop of the file*/
    if ((e.files || e[0]) && !this.fileName) {
      this.fileName = e.files ? e.files[0].name : e[0].name;
      this.fileData = e.files ? e.files[0] : e[0];
      this.uploadedFilesize = e.files ? e.files[0].size : e[0].size;
      this.files.push(this.fileName);
      this.uploadButtonText = this.sharedConstant.bulkUpload.removeFileText;
      if (this.fileName.substring(this.fileName.lastIndexOf('.') + 1, this.fileName.length).toLowerCase() === 'csv') {
        this.isFileUploaded = true;
        this.showError = false;
        this.showUploadError = false;
        this.uploadResponse.success = false;
        if (this.uploadedFilesize > this.fileSize) {
          this.showError = true;
          this.errorMessage = this.sharedConstant.bulkUpload.fileSize;
        }
      } else {
        this.showError = true;
        this.isFileUploaded = true;
        this.errorMessage = this.sharedConstant.bulkUpload.fileType;
      }
      this.uploadedEmit = {
        fileData: this.fileData,
        isFileUploaded: this.isFileUploaded,
        showError: this.showError,
        validFile: this.lib === 'ADDLINK' ? this.BulkUploadForm.valid : true,
      };
      this.FileUploadEmit.emit(this.uploadedEmit);
      this.hasFormValuesChanged.emit(this.formHasChanged());
    }
  }

  removeFile(fileuploader, remove) {
    this.files.splice(0, 1);
    remove ? this.files.splice(fileuploader, 1) : fileuploader.clear();
    this.isFileUploaded = false;
    this.fileName = '';
    this.sharedSer.clearErrorMessage();
    this.uploadButtonText = this.sharedConstant.bulkUpload.chooseFileText;
    if (remove) {
      this.showError = false;
      this.showUploadError = false;
      this.uploadResponse.errorMessage = null;
      this.uploadErrorFileMsg = '';
    }
    this.uploadedEmit = {
      isFileUploaded: this.isFileUploaded,
      showError: this.showError,
      validFile: this.lib === 'ADDLINK' ? this.BulkUploadForm.valid : true,
    };
    this.FileUploadEmit.emit(this.uploadedEmit);
    this.hasFormValuesChanged.emit(this.formHasChanged());
  }

  cancelFileUpload() {
    this.router.navigate([this.parentPage]);
  }

  closeInfo() {
    this.onClose('close');
    this.successMsg = null;
    this.fileProcessStatus = null;
  }

  /*closing the Upload Error Modal*/
  onClose(data) {
    this.showSuccessInfoMsg = false;
    this.isFileUploaded = false;
    this.isModalOpen = false;
    data ? this.removeFile(this.localUploader, 'remove') : this.removeFile(this.localUploader, '');
    this.uploadedEmit = {
      isFileUploaded: this.isFileUploaded,
      showError: this.showError,
    };
    this.FileUploadEmit.emit(this.uploadedEmit);
    this.hasFormValuesChanged.emit(this.formHasChanged());
  }

 uploadFile() {
    this.uploadResponse = [];
    const formData = new FormData();
    formData.append('file', this.fileData);
    if (this.lib === 'ADDLINK') {
      const masterAccountNbr = [];
      const masterAccounts: any =  this.BulkUploadForm.get('masterAccounts').value;
      for (const masterAccount of masterAccounts) {
        masterAccountNbr.push(masterAccount.value);
      }
      const authagentVal = this.isAAExceptBU && this.BulkUploadForm.get('authAgent').value.length > 0 ? this.BulkUploadForm.get('authAgent').value[0].label : '';
      const uploadInfo: any = {
        uploadType: 'ADDLINK',
        // showRemarkToClient will be Y when checkbox is checked
        data : {
          authAgentFN: authagentVal ? authagentVal.split(',')[1].trim() : '',
          authAgentLN: authagentVal ? authagentVal.split(',')[0].trim() : '',
          orgId: this.getOrgId,
          jobName: 'AddlinkUpload',
          masterAccountNbrList: masterAccountNbr,
          remark: this.BulkUploadForm.get('remarks').value,
          showRemarkToClient: this.BulkUploadForm.get('doNotShowRemarkToClient').value ? 'Yes' : 'No',
          planId: this.BulkUploadForm.get('planId').value,
        }
    };
      formData.append('uploadInfo ', JSON.stringify(uploadInfo));
      this.uploadeFile(formData);
    } else if (this.lib === 'EMPLOYEE') {
      const uploadInfo = {
        uploadType: 'EMPLOYEE',
        data: {
          orgId: this.getOrgId,
          jobName: 'EmpUpload'
        }
      };
      formData.append('uploadInfo ', JSON.stringify(uploadInfo));
      this.uploadeFile(formData);
    } else if (this.lib === 'EMPLOYEEGROUP') {
      const uploadInfo = {
        uploadType: 'EMPLOYEEGROUP',
        data: {
          orgId: this.getOrgId,
          jobName: this.businessUnit ? this.sharedConstant[this.businessUnit].groupOrPartcUploadTemplate : 'EMPLOYEEGROUP',
          groupName: this.createdName,
          groupDesc: this.groupDesc
        }
      };
      formData.append('uploadInfo ', JSON.stringify(uploadInfo));
      this.uploadeFile(formData);
    } else if (this.lib === 'RULE') {
      const uploadInfo = {
        uploadType: 'RULE',
        data: {
          orgId: this.getOrgId,
          jobName: 'RuleUpload'
        }
      };
      formData.append('uploadInfo ', JSON.stringify(uploadInfo));
      this.uploadeFile(formData);
    }
  }

  uploadeFile(data) {
    this.sharedSer.uploadFile(data).subscribe((response: any) => {
      this.uploadResponse = response;
      this.uploadID = response.data;
      this.uploadErrorHandling();
    });
  }

  uploadErrorHandling() {
    if (this.uploadResponse.success === true) {
      this.uploadErrorFileMsg = '';
      this.fileUploadStatus = true;
      this.showSuccessInfoMsg = true;
      this.successMsg = this.sharedConstant.bulkUpload.fileReceived;
      setTimeout(() => {
        this.navigateToUplodsList();
      }, 2000);
    } else {
      this.fileUploadStatus = false;
      /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
      this.sharedSer.isDTBtnClicked = true;
      this.sharedSer.loadDataTrackValue(this.uploadBtnGutTack);
      this.sharedSer.loadSpsPageCall();

      this.uploadErrorFileMsg = this.uploadResponse.failMsg;
      this.showUploadError = true;
      this.fileProcessStatus = null;
      this.showSuccessInfoMsg = false;
    }
  }

  getRegexValue(params) {
    if (params.indexOf('(') > -1) {
      let args: any = /\(\s*([^)]+?)\s*\)/.exec(params);
      if (args[1]) {
        args = args[1].split(/\s*,\s*/);
        return args;
      }
    }
  }

  downloadFileSystem() {
    this.sharedSer.gutTracking(this.downloadTemplateGutTack);

    this.sharedSer.downloadFileSystem(this.downloadTemplate, 'spos')
      .subscribe((response: HttpResponse<Blob>) => {
        let filename = '';

        if (this.downloadTemplate === 'RULE') {
          filename = this.sharedConstant.bulkUpload.rulesTempFileName;
        } else if (this.downloadTemplate === 'EMPGROUP') {
          filename = this.sharedConstant.bulkUpload.empGrpTempFileName;
        } else if (this.downloadTemplate === 'PARTICIPANT') {
          filename = this.sharedConstant.bulkUpload.planTempFileName;
        } else if (this.downloadTemplate === 'EMP') {
          filename = this.sharedConstant.bulkUpload.empTempFileName;
        } else if (this.downloadTemplate === 'ADDLINKDBSINT' || this.downloadTemplate === 'ADDLINKDBSEXT' || this.downloadTemplate === 'ADDLINKSPS') {
          filename = this.sharedConstant.bulkUpload.addlinkTemplate;
        } else {
          filename = 'random-file.xslx';
        }

        this.sharedSer.saveFile(response.body, filename);
      });
  }

  navigateToUplodsList() {
    this.router.navigate(['/ems/uploads/uploads-list'], {relativeTo: this.r});
  }

  public populateAuthorizedAgents(): void {
    this.authorizedAgents = [];
    this.linkDelinkService.populateAuthorizedAgents(this.businessUnit, this.clientInfo);
  }

  formHasChanged(): boolean {
    if (this.initialValues) {
      const hasformChanged = Object.entries(this.initialValues).some(
        ([field, value]) => {
          if (Array.isArray(value)) {
            // check for Empty array as [] === [] will give you false
            if ((this.initialValues[field].length === 0 && this.BulkUploadForm.getRawValue()[field].length === 0)) {
              return false;
            } else {
                return value.length !== this.BulkUploadForm.getRawValue()[field].length;
            }
          } else {
            const initialValue = value || '';
            const formValue = this.BulkUploadForm.getRawValue()[field] || '';
            return initialValue !== formValue;
          }
        }
      );
      return hasformChanged;
    } else {
      return false;
    }
  }
}

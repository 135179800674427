<ems-toast-message #ToastMessageComponent></ems-toast-message>

<sdps-notification type="confirmation" class="sdps-m-top_none" *ngIf="(fileProcessStatus === 'Successful' && successMsg)">
  <p class="sdps-notification__text" [innerHTML]="successMsg"></p>
  <p *ngIf="lib!=='ADDLINK'">{{sharedConstant.bulkUpload.fileProcessing}}{{fileProcessStatus}}</p>
  <button class="cursor sdps-button sdps-messaging__close sch sch-x schwab-cross-icon sdps-p-top_x-small sdps-notification__text" type="button"
    (click)="closeInfo()"></button>
</sdps-notification>

<sdps-notification type="information" class="sdps-m-top_none" *ngIf="fileUploadStatus">
  <div class="sdps-notification__text">
    <div *ngIf="!showError && !showUploadError && showSuccessInfoMsg">
      <p [innerHTML]="successMsg"></p>
      <p [innerHTML]="sharedConstant.bulkUpload.uploadRedirectMsg"></p>
    </div>
  </div>
</sdps-notification>

<sdps-notification type="error" class="sdps-m-top_none" *ngIf="showError || showUploadError">
  <div class="sdps-notification__text">
    <p *ngIf="showError && !showUploadError" [innerHTML]="errorMessage"></p>
    <p *ngIf="uploadErrorFileMsg" [innerHTML]="uploadErrorFileMsg"></p>
  </div>
  <button class="cursor sdps-button sdps-messaging__close sch sch-x schwab-cross-icon sdps-p-top_x-small" type="button"
    (click)="onClose('close')"></button>
</sdps-notification>

<sdps-notification type="error" class="sdps-m-top_none" *ngIf="uploadResponse.errorMessage">
  <div class="sdps-notification__text">
    <p [innerHTML]="uploadResponse.errorMessage"></p>
  </div>
  <button class="sdps-button sdps-messaging__close sch sch-x schwab-cross-icon sdps-p-top_x-small" type="button"
    (click)="onClose('')"></button>
</sdps-notification>

<sdps-notification type="error" class="sdps-m-top_none" *ngIf="(BulkUploadForm.get('planId').value && BulkUploadForm.get('planId').invalid && showPlanIDField)">
  <div class="sdps-notification__text">
    <p [innerHTML]="sharedConstant.pleaseCorrectFollowingError"></p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngIf="(BulkUploadForm.get('planId').value && BulkUploadForm.get('planId').invalid)"
        [innerHTML]="sharedConstant.textFieldErrorMsg.invalidNumericCharacterEntered"></li>
    </ul>
  </div>
</sdps-notification>

<h2 *ngIf="this.getOrgId !== 0" class="page-title sdps-m-bottom_large sdps-p-left_small sdps-display-inline-block"
  [innerHTML]="bulkUploadTitles ? bulkUploadTitles : this.sharedConstant.bulkUpload.bulkUploadText"></h2>

<span *ngIf="this.getOrgId !== 0" class="sdps-m-horizontal_large client-selection-indicator"
      [ngClass]="this.externalAccess ? 'external-access' : (sharedSer.clientInformation | async)?.businessUnit | lowercase"
      [innerHTML]="(sharedSer.clientInformation | async)?.k4ClientName">

</span>

<sdps-notification type="information" class="sdps-m-top_none" *ngIf="this.getOrgId === 0">
  <p class="sdps-notification__text" [innerHTML]="sharedConstant.bulkUpload.noClientSelected"></p>
</sdps-notification>

<form [formGroup]="BulkUploadForm">
  <div class="file-upload" *ngIf="this.getOrgId !== 0">
    <!-- ADDLink - Master Account, Auth Agent, Plan Id & Remarks -->
    <div *ngIf="lib === 'ADDLINK'">
      <div class="file-section sdps-p-around_large">
      <div class="sdps-grid-fluid sdps-wrap">
        <div class="sdps-row col-full sdps-m-top_small no-padding-left">
          <!-- Master Account -->
          <div class="col-3 sdps-text-left" *ngIf="(((linkMethod === sharedConstant.bulkGlobal.multi && (countOfSL === 0 || countOfSL > 1)) || linkMethod === sharedConstant.bulkGlobal.standard) && (usertype === sharedConstant.bulkGlobal.external)) || usertype === sharedConstant.bulkGlobal.internal">
            <ems-multiselect [highlightColor]="true" [singleSelect]="false" [label]="'Master Account'" [searchable]="false"
              [options]="masterAccountOptions" formControlName="masterAccounts" [linkType]="linkMethod"
              [disabled]="linkMethod === sharedConstant.bulkGlobal.multi && countOfSL === 1 && usertype === sharedConstant.bulkGlobal.internal">
            </ems-multiselect>

          </div>

          <!-- Plan ID -->
          <div class="col-3" *ngIf="showPlanIDField">
            <div class="ems-input link-input-textbox"
              [ngClass]="{'invalidField': (BulkUploadForm.get('planId').value && BulkUploadForm.get('planId').invalid)}">
              <span class="p-float-label">
                <input type="text" formControlName="planId" emsNumberPatterncheck pInputText />
                <label>Plan ID<em class="highlight-color">*</em></label>
              </span>
            </div>
          </div>

          <!-- Auth Agent -->
          <div class="col-3 sdps-text-left" *ngIf="!isAuthAgentRole">

            <ems-multiselect [highlightColor]="true" [singleSelect]="true" [label]="'Authorized Agent'" [searchable]="true"
              [options]="authorizedAgents" formControlName="authAgent">
            </ems-multiselect>
            <div class="ems-add-auth"
              *ngIf="isAAExceptBU && sharedSer.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid,'','')">
            </div>
          </div>

          <!-- Remarks -->
          <div class="col-3 remark-container">
            <div class="ems-input link-input-textbox">
              <span class="p-float-label">
                <input type="text" formControlName="remarks" maxlength="1000" emsCharacterLimit
                  (emsCharacterLimitCheck)="showRemarksTxtboxCharLimit = $event"
                  [charMaxLimit]="commonConstants.charLimitValues.thousandMaxLimit" pInputText>
                <label>Remarks<em
                    *ngIf="BulkUploadForm.get('doNotShowRemarkToClient').value === true" class="highlight-color">*</em></label>
              </span>
            </div>
            <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml"
                *ngIf="BulkUploadForm.get('remarks').value.length > showRemarksTxtboxCharLimit">
                {{commonConstants.characterLimit}}
                <span class="character-limit">{{BulkUploadForm.get('remarks').value.length}}
                  /{{commonConstants.charLimitValues.thousandMaxLimit}}</span>
              </p>
            <div class="ems-checkbox sdps-text-left"
              *ngIf="!isAuthAgentRole || sharedSer.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid,'','') && usertype === sharedConstant.bulkGlobal.internal">
              <p-checkbox formControlName="doNotShowRemarkToClient" label="Do not show this remark to Client"
                binary="true"></p-checkbox>
            </div>

          </div>
        </div>
      </div>
    </div>
    </div>

    <!-- Download Template -->
    <p class="sdps-m-bottom_large">&nbsp;</p>
    <div class="file-section sdps-p-around_large">
      <div class="display-content sdps-p-bottom_large"
        [innerHTML]="downloadTemplateLabel ? downloadTemplateLabel : this.sharedConstant.bulkUpload.defaultTemplateLabel">
      </div>
      <form (ngSubmit)="downloadFileSystem()">
        <input [(ngModel)]="downloadTemplate" name="file-system" type="hidden">
        <button class="buttons" [innerText]="sharedConstant.bulkUpload.downloadTemplateTxt">
          <em class="pi pi-file sdps-p-right_x-small"></em>
        </button>
      </form>
    </div>

    <!-- Choose File -->
    <p class="sdps-m-bottom_large">&nbsp;</p>
    <div class="file-section sdps-p-around_large" ems-drag-drop #content (Select)="fileSelection($event)">
      <div class="display-content sdps-p-bottom_large" [innerHTML]="uploadText"></div>
      <div class="action-button">
        <p-fileUpload #fileUploader method="POST" name="file[]" accept=".csv" maxFileSize="{{fileSize}}"
          chooseLabel="{{uploadButtonText}}" [disabled]="isFileUploaded" [showUploadButton]="false" [showCancelButton]="false"
          styleClass="file-upload-btn" (onSelect)="fileSelection($event)">
          <ng-template let-file pTemplate="file">
            <ul *ngIf="uploadedFile.length">
              <li *ngFor="let file of uploadedFile">{{file.name}} - {{file.size}} bytes</li>
            </ul>
          </ng-template>
        </p-fileUpload>

        <div *ngFor="let file of files; let i=index" class="sdps-p-top_large">
          <em class="pi pi-times" (click)="removeFile(i,'remove')"></em>{{fileName}}
        </div>

      </div>
    </div>

    <p class="sdps-m-bottom_x-large">&nbsp;</p>
  </div>
</form>

<div class="sdps-p-horizontal_medium">
  <sdps-notification type="information" class="sdps-m-top_none" *ngIf="showClientMsg">
    <p class="sdps-notification__text" [innerHTML]="getShellConstant.noCientSelected"></p>
  </sdps-notification>
</div>
<div class="sdps-p-horizontal_medium" *ngIf="!showClientMsg">
    <form [formGroup]="reportDetails" >
      <div class="sdps-grid-container sdps-wrap report-dropdown">

        <div class="sdps-row sdps-p-top_large">
          <label>
            <p-radioButton class="emtr-radio ems-inline" id="snapShot" name="selectedButtonType" formControlName="selectedButtonType"
              value="{{getShellConstant.reports.RulesDetails.snapShot}}" (ngModelChange)="editSnapshotHistory()" label="{{getShellConstant.reports.RulesDetails.snapShot}}"></p-radioButton>
          </label>

          <label class="sdps-m-left_small">
            <p-radioButton class="emtr-radio ems-inline" id="history" name="selectedButtonType" formControlName="selectedButtonType"
              value="{{getShellConstant.reports.RulesDetails.historyTxt}}" (ngModelChange)="editSnapshotHistory()" label="{{getShellConstant.reports.RulesDetails.historyTxt}}"></p-radioButton>
          </label>
        </div>

        <div class="sdps-row sdps-p-top_x-large" *ngIf="!snapshotSelected">
          <div class="display-flex">
            <app-report-from-to-datepicker (calendarValidation)="calendarValidation($event)" [dateRange]="90" [initialDateRange]="7" (getEmittedVal)="setDate($event)"
                                           [calendarForm]="reportDetails" (throwDateError)="throwDateError($event)" (throwDateRangeError)="throwDateRangeError($event)"></app-report-from-to-datepicker>
          </div>
        </div>
        <div class="sdps-row sdps-display-inline-block sdps-p-top_large">
          <div class="col-3 sdps-p-left_none sdps-p-right_small request-list-filter addlink-report-multi-dropdown">
            <p [innerHTML]="getShellConstant.reports.RulesDetails.ruleType"></p>
            <p-multiSelect appendTo="body" [options]="ruleTypes" placeholder="Rule Type" [defaultLabel]="' '" formControlName="selectedRuleType"
                           [displaySelectedLabel]= "true" [maxSelectedLabels]= "1" [filterPlaceHolder]="'All'"
                           [filter]="true" [showHeader]="true" optionLabel="name">
            </p-multiSelect>
        </div>
          <div class="col-3 sdps-p-left_none sdps-p-right_small request-list-filter addlink-report-multi-dropdown">
            <p [innerHTML]="getShellConstant.reports.RulesDetails.subTypeTxt"></p>
            <p-multiSelect appendTo="body" [options]="appliesTo" [defaultLabel]="' '" formControlName="selectedAppliesTo"
                           [displaySelectedLabel]= "true" [maxSelectedLabels]= "1" [filterPlaceHolder]="'All'"
                           [filter]="true" [showHeader]="true" optionLabel="name">
            </p-multiSelect>
          </div>
          <div class="col-3 sdps-p-left_none sdps-p-right_small request-list-filter addlink-report-multi-dropdown">
            <p [innerHTML]="getShellConstant.reports.RulesDetails.ruleStatus"></p>
            <p-multiSelect appendTo="body" [options]="ruleStatus" [defaultLabel]="' '" formControlName="selectedRuleStatus"
                           [displaySelectedLabel]= "true" [maxSelectedLabels]= "1" [filterPlaceHolder]="'All'"
                           [filter]="true" [showHeader]="true" optionLabel="name">
            </p-multiSelect>
          </div>
        </div>

        <div class="sdps-row sdps-p-top_large select-report-type">
          <div class="col-3 sdps-p-left_none">
            <div class="report-textbox-border sdps-p-around_medium"
            [ngClass]="{'invalidField': (reportDetails.get('tradeSymbol').value && reportDetails.get('tradeSymbol').invalid), 'disabled-grey': reportDetails.get('tradeSymbol').disabled}">
              <span class="p-float-label">
                <input type="text" name="tradeSymbol" [maxlength]="100" formControlName="tradeSymbol" emsAlphaNumericSpecialCharacterValidation pInputText/>
                <label [innerHTML]="getShellConstant.reports.RulesDetails.symbol"></label>
              </span>
            </div>
          </div>
          <div class="col-3 sdps-p-left_none">
            <div class="report-textbox-border sdps-p-around_medium"
            [ngClass]="{'invalidField': (reportDetails.get('cusipSymbol').value && (reportDetails.get('cusipSymbol').invalid || reportDetails.get('cusipSymbol').value.length < 9)), 'disabled-grey': reportDetails.get('cusipSymbol').disabled}">
              <span class="p-float-label">
                <input type="text" name="cusipSymbol" emsAlphaNumericSpecialCharacterValidation [maxlength]="9" formControlName="cusipSymbol" pInputText/>
                <label [innerHTML]="getShellConstant.reports.RulesDetails.cusip"></label>
              </span>
            </div>
          </div>

          <div class="col-3 sdps-p-left_none">
            <div class="sdps-p-left_none sdps-dropdown--lightGrey emtr-dropdown employee-dropdown dropdown-width employee-association-dropdown">
              <span class="p-float-label">
              <p-dropdown #dropdown [placeholder]="sharedConstant[businessUnit].enterGroupOrPlanName" [options]="empGroupDropDownOpts"
                          formControlName="empGroupName" (onShow)="clearSearch(dropdown)"
                          filter="true" (keyup)="searchedText($event, 'groupName')">
                <ng-template let-empRecords pTemplate="item">
                  <div class="ui-helper-clearfix display-image">
                    <span>{{empRecords.value}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
               <label [ngClass]="{'emp-label': dropdown.value !== null}" [innerHTML]="sharedConstant[businessUnit].enterGroupOrPlanName"></label>
              </span>
            </div>
          </div>
        </div>

      </div>
    </form>
    <ems-step-wizard-buttons [showCancelBtn]="true" (closeBtn)="cancelReport()" [showRunReportBtn]="true" (runReport)="runReports()"
                             [disableBtn]="reportDetails.invalid || endDateLessThanStartDate || dateError || (reportDetails.get('cusipSymbol').value && reportDetails.get('cusipSymbol').value.length < 9)"></ems-step-wizard-buttons>
</div>

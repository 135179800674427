import {Component, OnInit, OnDestroy, ViewChild, AfterViewChecked, ViewEncapsulation, Inject} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { AddlinkService } from '../../addlink.service';
import { affiliations } from '../../constants/affiliations';
import { linkRequestColumns } from '../../constants/requestColumns';
import { AddAuthorizedAgentModalComponent } from '../../shared/components/modals/add-authorized-agent-modal/add-authorized-agent-modal.component';
import { RemarksModalComponent } from '../../shared/components/modals/remarks-modal/remarks-modal.component';
import { CommonService } from '../../shared/services/common.service';
import { LinkService } from '../../shared/services/link.service';
import { AddlinkConstant } from '../../constants/addlinkConstants';
import { SharedService, AccountNumberValidationService, LinkDelinkService } from '@ems/shared';
import {EditRequestsModalComponent} from '../../shared/components/modals/edit-requests-modal/edit-requests-modal.component';
import {Subscription} from 'rxjs';
import {RequestListService} from '../../shared/services/request-list.service';

export interface Request {
  requestId?;
  status?;
  masterNumber?;
  accountNumber?;
  employeeId?;
  employeeFirstName?;
  employeeLastName?;
  accountHolderFirstName?;
  accountHolderLastName?;
  accountHolderAffiliation?;
  indexNumber?;
  disableIcon?;
}

@Component({
  selector: 'addlink-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkComponent implements OnInit, OnDestroy, AfterViewChecked {

  addLinkConstant = AddlinkConstant;
  public affiliations: SelectItem[] = affiliations;
  public authorizedAgents: SelectItem[];
  public masterAccountOptions: any[];

  public linkRequestForm: UntypedFormGroup;
  public requests: Request[];
  public requestColumns: any[] = linkRequestColumns;

  public clientId: string;
  public jobId: string;
  public clientName: string;
  public linkType: string;
  public liable407: string;
  public blanket407: string;
  public index: string;
  public businessUnit: string[];
  public clientInfo: any;
  public isAAExceptBU: boolean;
  public orgId: any;
  public roles: any[] = [];
  public isAuthAgentRole: boolean;
  public usertype: string;
  showTextboxCharLimit: boolean;
  showEmpIdCharLimit: boolean;
  isAccNumInvalid = false;
  public selectedClientId: string;
  private clientInfoSubscribe: Subscription;
  is10b5Client = false;
  showPlanIDField = false;
  planIDTxt = 'planId';
  countOfSL = 0;
  showRemarksTxtboxCharLimit: boolean;
  doNotShowResubmitBtn = false;

  @ViewChild('addAuthAgent', { static: true }) addAuthorizedAgentViewChild: AddAuthorizedAgentModalComponent;
  @ViewChild('remarks', { static: true }) remarksViewChild: RemarksModalComponent;
  @ViewChild('toastMessage', { static: true }) msgToast: any;
  @ViewChild('editRequestsModal', { static: true }) editRequestsChild: EditRequestsModalComponent;
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;

  public previousAcctNumber: any;
  intervalJobs: any = {};
  RequestInterval: any;
  externalAccess = false;
  wholeRecordArr: any = [];
  hasADDLinkAccess = true;
  public confirmMessage: any;
  cancelBtnGutTack = 'addlinkLink_cancel';
  linkInitialsValues: any;
  isMasterAccountVisible = false;
  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService,
              private linkService: LinkService, public addlinkService: AddlinkService,
              private router: Router, private activatedRouter: ActivatedRoute, public sharedService: SharedService, @Inject('entitlementConst') public entitlementConst,
              @Inject('commonConstants') public commonConstants, private accountNumberValidationService: AccountNumberValidationService, public requestListService: RequestListService,
              public linkDelinkService: LinkDelinkService) {
    /* When any account is selected in MasterAccount Dropdown getting the selected Value and verifying it is 'SL 10b5-1'*/
    this.sharedService.selectedMasterAccnt.subscribe(value => {
      if (value && value.some(accnt => accnt.accountSubType && accnt.accountSubType.indexOf('10b5-1') !== -1)) {
        this.showPlanIDField = true;
      } else if (this.linkRequestForm) {
        this.linkRequestForm.patchValue({
          planId: ''
        });
        this.showPlanIDField = false;
      }
      /* If selected Account is 10b5-1 MasterAccount adding validators to formControlName-PlanId */
      if (this.showPlanIDField && this.is10b5Client && this.linkRequestForm) {
        this.linkRequestForm.controls[this.planIDTxt].setValidators(Validators.required);
        this.linkRequestForm.controls[this.planIDTxt].updateValueAndValidity();
      } else if (this.linkRequestForm) {  /* If selected Account is not a 10b5-1 MasterAccount removing validators to formControlName-PlanId */
        this.linkRequestForm.controls[this.planIDTxt].clearValidators();
        this.linkRequestForm.controls[this.planIDTxt].updateValueAndValidity();
      }
    });
  }

  /* Initialize component variables */
  ngOnInit() {
    this.setupLinkRequestForm();
    this.showPlanIDField = false;
    this.addlinkService.userType.subscribe(result => {
      this.usertype = result;
    });
    this.addlinkService.userPolicy.subscribe(result => {
      const value = result.getValue();
      this.roles = value.roles;

      if (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAccess.uuid, '', '') && this.usertype !== this.addLinkConstant.addLkGlobal.internal) {
        this.isAuthAgentRole = true;
        this.linkRequestForm.controls.indexNumber.setValidators([]);
        this.linkRequestForm.controls.indexNumber.updateValueAndValidity();
        this.linkRequestForm.controls.authAgent.setValidators([]);
        this.linkRequestForm.controls.authAgent.updateValueAndValidity();
        const index = this.requestColumns.findIndex(x => x.field === 'indexNumber');
        if (index !== -1) {
          this.requestColumns.splice(index, 1);
        }
      } else {
        this.isAuthAgentRole = false;
      }
    });

    this.detectAccountHolderSameAsEmployeeChanges();
    this.detectEmployeeNameChanges();
    if (this.hasADDLinkAccess) {
      this.commonService.getJobIdSequence().subscribe(result => {
        this.jobId = result.data.jobId;
      });
    }
    this.construct();

    this.sharedService.specificClientView.next(true);

    this.linkRequestForm.get('accountNumber').valueChanges.subscribe(() => {
      this.isAccNumInvalid = false;
    });
    // authAgent DBS Exception Validation
    this.linkDelinkService.authAgentValidation(this.linkRequestForm);
    this.confirmMessage = this.confirmationMessage.confirmMessage;

    this.clientInfoSubscribe = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.hasADDLinkAccess = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.accountLinking.uuid, '', '');
        this.selectedClientId = clientInfo.clientId;
        clientInfo.externalAccess && clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
        this.linkRequestForm.get('masterAccounts').setValue([]);
        this.linkRequestForm.get('authAgent').setValue([]);
        this.countOfSL = 0;
        this.updateClientInformation(clientInfo);
        this.linkInitialsValues = this.linkRequestForm.getRawValue();
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.linkType === this.addLinkConstant.addLkGlobal.multi && this.countOfSL === 1 && this.usertype === this.addLinkConstant.addLkGlobal.internal) {
      this.linkRequestForm.get('masterAccounts').disable({emitEvent: false});
    }
    this.sharedService.isConfirmationRequiredClientChange = this.formHasChanged();
  }

  public editRequestPopup(e: any, request: any): void {
    const clientId = this.selectedClientId !== null ? this.selectedClientId : request.client.clientId;
    request.disableIcon = true;
    this.editRequestsChild.openModal(e, request.requestId, request.accountNumber, this.orgId, request.authAgentId, request.masterNumber,
      request.employeeFirstName, request.employeeLastName, request.accountHolderFirstName, request.accountHolderLastName, request.accountHolderAffiliation, 'ADDLink(DBS)');
  }

  public updateClientInformation(clientInfo: any): void {
    if (clientInfo === null) {
      return;
    }
    this.construct();
    this.orgId = clientInfo.orgId;
    this.clientId = clientInfo.clientId;
    this.clientName = clientInfo.k4ClientName;
    this.linkType = clientInfo.linkType === 'Dual' ? 'Multi' : clientInfo.linkType;
    this.liable407 = clientInfo.liable407;
    this.blanket407 = clientInfo.blanket407;
    this.index = clientInfo.index;
    this.clientInfo = clientInfo;
    this.is10b5Client = clientInfo.tenB51;

    if (this.clientId !== null) {
      this.initialize();
    }
  }


  public initialize(): void {
    this.linkRequestForm.enable();
    this.linkRequestForm.controls.masterAccounts.disable();
    this.linkRequestForm.controls.affiliation.disable();
    this.populateMasterAccounts();
    this.populateIndexKey();
    if (this.hasADDLinkAccess) {
      this.linkDelinkService.getAuthorizedAgents(this.orgId).subscribe(result => {
        this.businessUnit = result.data.authAgentExceptionBusinesUnits;
        this.populateAuthorizedAgents(result.data);
        this.linkInitialsValues = this.linkRequestForm.getRawValue();
      });
    }
    // store initial link form values
    this.isMasterAccountVisible = (((this.linkType === this.addLinkConstant.addLkGlobal.multi && (this.countOfSL === 0 || this.countOfSL > 1))
    || this.linkType === this.addLinkConstant.addLkGlobal.standard) && this.usertype === this.addLinkConstant.addLkGlobal.external) || this.usertype === this.addLinkConstant.addLkGlobal.internal;
    this.linkInitialsValues = this.linkRequestForm.getRawValue();
  }

  public construct() {
    // Initialize Variables
    this.requests = [];
    this.masterAccountOptions = [];
    this.previousAcctNumber = '';
    this.resetLinkRequestForm('');
    this.linkRequestForm.disable();
  }

  public populateAuthorizedAgents(result): void {
    this.authorizedAgents = [];
    this.isAAExceptBU = this.linkDelinkService.populateAuthorizedAgents(this.businessUnit, this.clientInfo);
    if (this.isAAExceptBU) {
      this.linkRequestForm.controls.authAgent.enable();
      this.authorizedAgents = this.linkDelinkService.getAuthAgents(this.clientId, result);
    } else {
      this.linkRequestForm.controls.authAgent.disable();
    }
    this.linkInitialsValues = this.linkRequestForm.getRawValue();
  }

  public populateIndexKey(): void {
    if ((this.liable407 === 'Y' || this.liable407 === 'y') && (this.blanket407 === 'Y' || this.blanket407 === 'y')) {
      this.linkRequestForm.controls.indexNumber.setValue(this.index);
      this.linkRequestForm.controls.indexNumber.disable();
      this.doNotShowResubmitBtn = true;
    } else if ((this.liable407 === 'N' || this.liable407 === 'n') && (this.blanket407 === 'N' || this.blanket407 === 'n')) {
      this.linkRequestForm.controls.indexNumber.setValue('');
      this.linkRequestForm.controls.indexNumber.disable();
      this.doNotShowResubmitBtn = false;
    } else if ((this.liable407 === 'Y' || this.liable407 === 'y') && (this.blanket407 === 'N' || this.blanket407 === 'n')) {
      this.linkRequestForm.controls.indexNumber.setValue('');
      this.linkRequestForm.controls.indexNumber.enable();
      this.doNotShowResubmitBtn = false;
    }
    this.linkInitialsValues = this.linkRequestForm.getRawValue();
  }

  public getAuthorizedAgents(): void {
    this.linkDelinkService.getAuthorizedAgents(this.orgId).subscribe( result => {
      this.authorizedAgents = this.linkDelinkService.getAuthAgents(this.clientId, result.data);
    });
  }

  public linkRequestCreate(): void {
    this.linkRequestForm = this.formBuilder.group({
      accountNumber: new UntypedFormControl('', Validators.required),
      employeeID: new UntypedFormControl(''),
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      accountHolderFirstName: new UntypedFormControl('', Validators.required),
      accountHolderLastName: new UntypedFormControl('', Validators.required),
      accountHolderSameAsEmployee: new UntypedFormControl(true),
      affiliation: new UntypedFormControl(''),
      masterAccounts: new UntypedFormControl([], Validators.required),
      authAgent: new UntypedFormControl([], Validators.required),
      indexNumber: new UntypedFormControl('', Validators.required),
      remarks: new UntypedFormControl(''),
      doNotShowRemarkToClient: new UntypedFormControl(false),
      planId: new UntypedFormControl(''),
    });
  }

  /* Setup Edit Requests Form */
  public setupLinkRequestForm(): void {
    this.linkRequestCreate();
    if (this.is10b5Client) {
      this.linkRequestForm.controls[this.planIDTxt].setValidators(Validators.required);
      this.linkRequestForm.controls[this.planIDTxt].updateValueAndValidity();
    }
    this.linkRequestForm.controls.accountHolderFirstName.disable();
    this.linkRequestForm.controls.accountHolderLastName.disable();
    this.linkRequestForm.controls.affiliation.disable();
    this.linkRequestForm.controls.masterAccounts.disable();
  }

  /* Reset The Link Request Form To Defualt Values. */
  public resetLinkRequestForm(reset): void {
    this.sharedService.clearErrorMessage();
    // Loop through all of the form controls.
    Object.keys(this.linkRequestForm.controls).forEach(key => {

      switch (key) {
        case 'doNotShowRemarkToClient':
          this.linkRequestForm.controls[key].setValue(false);
          break;
        case 'accountHolderSameAsEmployee':
          this.linkRequestForm.controls[key].setValue(true);
          break;
        case 'masterAccounts':
        case 'authAgent':
          if (reset && this.masterAccountOptions.length > 1) {
              this.linkRequestForm.get('masterAccounts').setValue([]);
          }
          if (reset) {
              this.linkRequestForm.get('authAgent').setValue([]);
          }
          break;
        case 'affiliation':
          this.linkRequestForm.controls[key].setValue([]);
          break;
        case 'indexNumber':
          this.populateIndexKey();
          break;
        default:
          this.linkRequestForm.controls[key].setValue('');
          break;
      }
    });
    /* Resetting planId textbox after Submit Button is clicked.*/
    if (this.is10b5Client) {
      this.linkRequestForm.controls[this.planIDTxt].setValue('');
      const masterAccntArr = this.linkRequestForm.get('masterAccounts').value;
      if (masterAccntArr && masterAccntArr.some(accnt => accnt.accountSubType.indexOf('10b5-1') !== -1)) {
        this.showPlanIDField = true;
      } else {
        this.showPlanIDField = false;
      }
    }
    // Maintaining DBS value
    if (this.linkRequestForm.get('authAgent').value.length > 0 && this.linkRequestForm.get('authAgent').value[0].label.indexOf('DBS') > -1) {
      this.linkRequestForm.controls.remarks.setValidators(Validators.required);
      this.linkRequestForm.controls.remarks.updateValueAndValidity();
      this.linkRequestForm.controls.doNotShowRemarkToClient.setValue(true);
      this.linkRequestForm.controls.doNotShowRemarkToClient.disable();
      this.linkRequestForm.controls.doNotShowRemarkToClient.updateValueAndValidity();
    }
  }

  /* Detect Any Changes To The Account Holder Same As Employee Checkbox Field */
  public detectAccountHolderSameAsEmployeeChanges(): void {

    // Detect Changes To Account Holder Same as Employee Check Box.
    this.linkRequestForm.controls.accountHolderSameAsEmployee.valueChanges.subscribe(accountHolderSameAsEmployee => {

      if (accountHolderSameAsEmployee === false) {

        // Clear the Account Holder Names.
        this.linkRequestForm.controls.accountHolderFirstName.setValue('');
        this.linkRequestForm.controls.accountHolderLastName.setValue('');

        // Enable Account Holder Fields If CheckBox is UnChecked.
        this.linkRequestForm.controls.accountHolderFirstName.enable();
        this.linkRequestForm.controls.accountHolderLastName.enable();
        this.linkRequestForm.controls.affiliation.enable();

      } else {

        this.linkRequestForm.controls.affiliation.setValue([]);

        // Set the Account Holder First and Last Names Equal To The Employees.
        const firstName = this.linkRequestForm.controls.firstName.value;
        const lastName = this.linkRequestForm.controls.lastName.value;
        this.linkRequestForm.controls.accountHolderFirstName.setValue(firstName.toUpperCase());
        this.linkRequestForm.controls.accountHolderLastName.setValue(lastName.toUpperCase());

        // Disable Account Holder Fields If CheckBox is Checked.
        this.linkRequestForm.controls.accountHolderFirstName.disable();
        this.linkRequestForm.controls.accountHolderLastName.disable();
        this.linkRequestForm.controls.affiliation.disable();
      }

    });

  }

  /* Detect Any Changes To The Employee Name Fields */
  public detectEmployeeNameChanges(): void {

    // Detect Changes On Employee First Name.
    this.linkRequestForm.controls.firstName.valueChanges.subscribe(firstName => {
      // If Same As Employee is Checked Then Copy The Employee First Name To Account Holder First Name.
      if (this.linkRequestForm.controls.accountHolderSameAsEmployee.value === true) {
        this.linkRequestForm.controls.accountHolderFirstName.setValue(firstName.toUpperCase());
      }

    });

    // Detect Changes On Employee Last Name.
    this.linkRequestForm.controls.lastName.valueChanges.subscribe(lastName => {
      // If Same As Employee is Checked Then Copy The Employee Last Name To Account Holder Last Name.
      if (this.linkRequestForm.controls.accountHolderSameAsEmployee.value === true) {
        this.linkRequestForm.controls.accountHolderLastName.setValue(lastName.toUpperCase());
      }

    });

  }

  public populateMasterAccounts(): void {
    if (this.hasADDLinkAccess) {
      this.linkDelinkService.getLinkMasterAccounts(this.orgId, this.clientId).subscribe(result => {
        const getMasterAccData: any = this.linkDelinkService.populateMasterAccounts(this.linkRequestForm, this.clientId, this.linkType, this.countOfSL, this.usertype, result.data);
        this.masterAccountOptions = getMasterAccData.masterAccountOptions;
        this.countOfSL = getMasterAccData.countOfSL;
        if (this.usertype === 'External' && this.countOfSL === 1 && this.is10b5Client) {
          this.showPlanIDField = true;
          this.linkRequestForm.controls[this.planIDTxt].setValidators(Validators.required);
          this.linkRequestForm.controls[this.planIDTxt].updateValueAndValidity();
        }
        this.linkInitialsValues = this.linkRequestForm.getRawValue();
      });
    }
  }

  public displayAuthAgentPopup(e: any): void {
    if (this.orgId) {
      this.addAuthorizedAgentViewChild.openModal(e, this.orgId);
    }
  }

  public validForm(): boolean {
    const indeyKeyVal: number = this.linkRequestForm.controls.indexNumber.value;
    if (this.linkRequestForm.valid &&
      this.linkRequestForm.controls.masterAccounts.value !== '' &&
      this.linkRequestForm.controls.accountHolderFirstName.value !== '' &&
      this.linkRequestForm.controls.accountHolderLastName.value !== '' &&
      (this.linkRequestForm.controls.doNotShowRemarkToClient.value !== true || this.linkRequestForm.controls.remarks.value !== '')) {
      if (!this.isAuthAgentRole && indeyKeyVal !== 0 || this.isAuthAgentRole) {
        return true;
      }
    }
    return false;
  }

  public processWebsocketResponse(responseObject: any): void {

    for (const request of this.requests) {
      if (request.requestId !== null && request.requestId === responseObject.requestId) {
        request.status = responseObject.status;
        break;
      }
    }
  }

  public submitLinkRequest(): void {
    this.sharedService.clearErrorMessage();
    this.sharedService.gutTracking('adobe-lc_submit|fireonce');

    /*To clear the toast message*/
    this.msgToast.pushMessage('', '');
    this.linkRequestForm.patchValue({
      firstName: this.linkRequestForm.controls.firstName.value.toUpperCase(),
      lastName: this.linkRequestForm.controls.lastName.value.toUpperCase(),
      accountHolderFirstName: this.linkRequestForm.controls.accountHolderFirstName.value.toUpperCase(),
      accountHolderLastName: this.linkRequestForm.controls.accountHolderLastName.value.toUpperCase()
    });
    const requestObject = [];
    Object.keys(this.linkRequestForm.controls).forEach(key => {
      requestObject[key] = this.linkRequestForm.controls[key].value;
    });

    this.resetLinkRequestForm('');

    this.linkService.saveMultiLinkLinkage(requestObject, this.orgId, this.jobId, this.liable407, null, this.is10b5Client).subscribe(result => {
      /* Created common function in linkService to form request Data */
      if (result.data.listOfMulitEntry !== null) {
        // populate requests
        for (const entry of result.data.listOfMulitEntry) {

          const request = {
            requestId: entry.requestId,
            status: this.isAuthAgentRole ? entry.status : entry.internalStatus,
            masterNumber: entry.masterAccountLabel + ' - ' + entry.masterAccountNbr,
            accountNumber: entry.acctNumber,
            employeeId: entry.employeeId,
            employeeFirstName: entry.employeeFirstName,
            employeeLastName: entry.employeeLastName,
            accountHolderFirstName: entry.accountOwnerFirstName,
            accountHolderLastName: entry.accountOwnerLastName,
            accountHolderAffiliation: entry.accountHolderAffiliation,
            indexNumber: entry.indexKey,
            disableIcon: false
          };
          this.requests.push(request);
          let count = 0;
          const status = this.sharedService.isInternalApp() ? entry.internalStatus : entry.status;
          if (status === 'Validating' || status === 'Received') {
            const localObj = {requestId: request.requestId, count};
            this.wholeRecordArr.push(localObj);
            this.intervalJobs[request.requestId] = setInterval(() => {
              this.getRequestStatus(request.requestId);
              count++;
              this.wholeRecordArr.forEach((data) => {
                if (data.requestId === request.requestId) {
                  data.count = count;
                  if (data.count === 5) {
                    window.clearInterval(this.intervalJobs[request.requestId]);
                  }
                }
              });
              }, this.addLinkConstant.reqDetailPollingInterval);
          }
        }
      }
    });
  }

  getRequestStatus(requestIds) {
    this.sharedService.setRefresh.emit(true);
    this.commonService.pollRequestDetailsByRequestId(requestIds, this.orgId).subscribe( (res) => {
      const data = {status: this.sharedService.isInternalApp() ? res.data.requestInfo.internalStatus : res.data.requestInfo.status, requestId: res.data.requestInfo.requestId};
      this.processWebsocketResponse(data);
      if (data.requestId === requestIds && (data.status !== 'Validating' && data.status !== 'Received')) {
        res.data.requestInfo.disableIcon = false;
        clearInterval(this.intervalJobs[requestIds]);
        this.sharedService.setRefresh.emit(false);
      }
      this.updateSingleRequest(res.data.requestInfo);
    }, error => {
      clearInterval(this.intervalJobs[requestIds]);
    });
  }

  public updateSingleRequest(requestObject: any): void {
    this.requests.forEach(request => {
      if (request.requestId === requestObject.requestId) {
        request.status = requestObject.status ? requestObject.status : request.status;
        // request.indexNumber = requestObject.IndexKey;
        request.employeeFirstName = requestObject.employeeFirstName ? requestObject.employeeFirstName : request.employeeFirstName;
        request.employeeLastName = requestObject.employeeLastName ? requestObject.employeeLastName : request.employeeLastName;
        request.accountHolderFirstName = requestObject.accountOwnerFirstName ? requestObject.accountOwnerFirstName : request.accountHolderFirstName;
        request.accountHolderLastName = requestObject.accountOwnerLastName ? requestObject.accountOwnerLastName : request.accountHolderLastName;
        request.accountHolderAffiliation = requestObject.accountHolderAffiliation ? requestObject.accountHolderAffiliation : request.accountHolderAffiliation;
        request.disableIcon = requestObject.disableIcon;
      }
    });
  }

  /*check Account Number contains 8 digits*/
  validateAccountNumber(event) {
    this.isAccNumInvalid = this.accountNumberValidationService.validateAccountNumber(event.target.value);
  }

  public openRemarkPopup(e: any, requestId: string, accountNumber: string, status: string): void {
    this.sharedService.clearErrorMessage();
    this.remarksViewChild.openModal(e, requestId, accountNumber, status, true);
  }

  public bulkSubmit(): void {
    this.sharedService.clearErrorMessage();
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue('upload');
    this.sharedService.parentPage = '../link';
    this.router.navigate(['../addlink-upload'], {relativeTo: this.activatedRouter});
  }

  ngOnDestroy(): void {
    this.sharedService.specificClientView.next(false);
    this.sharedService.setRefresh.emit(false);
    this.sharedService.isConfirmationRequiredClientChange = false;
    if (this.clientInfoSubscribe) {
      this.clientInfoSubscribe.unsubscribe();
    }
  }

  reSubmitRequests(requestIds) {
    const requestIdArray = [];
    requestIds.disableIcon = true;
    requestIdArray.push(requestIds.requestId);
    this.requestListService.addlinkRequestPutEndPoint(requestIdArray, '', 'RESUBMIT', this.orgId).subscribe((data) => {
      requestIdArray.forEach(id => {
          this.intervalJobs[id] = setInterval(() => {
            this.getRequestStatus(id);
          }, this.addLinkConstant.reqDetailPollingInterval);
        });
    });
  }

  formHasChanged(): boolean {
    if (this.linkInitialsValues) {
      const isFormChanged = Object.entries(this.linkInitialsValues).some(
        ([field, value]) => {
          if (Array.isArray(value)) {
            // check for Empty array as [] === [] will give you false
            if ((this.linkInitialsValues[field].length === 0 && this.linkRequestForm.getRawValue()[field].length === 0)) {
              return false;
            } else {
              return value.length !== this.linkRequestForm.getRawValue()[field].length;
            }
          } else {
            const initialValue = value || '';
            const formValue = this.linkRequestForm.getRawValue()[field] || '';
            return initialValue !== formValue;
          }
        }
      );
      return isFormChanged;
    } else {
      return false;
    }
  }
  canDeactivate(event, observer) {
    if (this.confirmMessage) {
      this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.addLinkConstant.confirmationMessage.menuClickMsg;
    }
    if (this.formHasChanged()) {
      this.sharedService.isLoadPage = true;
      // open modal
      this.confirmationMessage.cancelBtnRejectGutTack = this.cancelBtnGutTack;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }
}

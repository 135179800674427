import {AfterViewChecked, Component, ElementRef, Injector, OnDestroy, OnInit} from '@angular/core';
import {ReportsService} from '@core_services/report/reports.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SharedConstant, SharedService} from '@ems/shared';
import {AppService} from '@core_services/app.service';
import {commonConstants} from '@shell_components/constants/commonConstants';
import {PresetReportsComponent} from '@shell_components/reports/preset-reports/preset-reports.component';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addlink-requests',
  templateUrl: './addlink-requests.component.html'
})
export class AddlinkRequestsComponent implements OnInit, AfterViewChecked, OnDestroy {

  parent: PresetReportsComponent;
  getShellConstant = ShellConstant;
  sharedConstant = SharedConstant;
  public reportName: any;
  public getOrgId: any;
  public showClientMsg: any = false;
  getCommConst = commonConstants;
  addlinkRequestsReportForm: UntypedFormGroup;
  endDateLessThanStartDate = '';
  fromDate: any;
  toDate: any;
  dateError: any;
  selectedOptionsOfStatus = [];
  selectedOptionsOfSource = [];
  sourceDropdownData = [];
  statusDropdownData = [];
  showEmpIdCharLimit: boolean;
  showTextboxCharLimit: boolean;
  dateRangeConstarint = '';
  businessUnit: any;
  clientInfoSubscribe: Subscription;

  constructor(public reportsService: ReportsService, private injector: Injector, private fb: UntypedFormBuilder, private el: ElementRef, public sharedService: SharedService, private appService: AppService) {
    this.reportName = this.reportsService.getReportName();
    this.parent = this.injector.get(PresetReportsComponent);
    this.reportsService.checkForAccess();
  }

  ngOnInit() {
    this.addlinkRequestsReportForm = this.fb.group({
      dateTo: this.fb.group({
        date: ['', Validators.required],
      }),
      dateFrom: this.fb.group({
        date: ['']
      }),
      status: [this.selectedOptionsOfStatus],
      source: [this.selectedOptionsOfSource],
      action: '',
      employeeID: '',
      employeeAccount: '',
      employeeLastName: '',
      masterAccountNumber: '',
      liable: ''
    });

    this.clientInfoSubscribe = this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      if (clientInfo && clientInfo !== null) {
        this.getOrgId = clientInfo.orgId;
        this.reportsService.orgId = clientInfo.orgId;
        this.businessUnit = clientInfo.businessUnit;
      }
      if (this.reportsService.accessToOnlyRbs || this.businessUnit === 'RBS') {
        this.addlinkRequestsReportForm.get('status').disable();
        this.addlinkRequestsReportForm.get('source').disable();
      } else {
        this.addlinkRequestsReportForm.get('status').enable();
        this.addlinkRequestsReportForm.get('source').enable();
      }
    });

    this.reportsService.getSourceAndStatusValue().subscribe(data => {
      this.reportsService.sourceAndStatusMultiselectValues.next(data);
    });

    this.reportsService.reportsArray.subscribe((data: any) => {
      if (data !== null) {
        data.map((res) => {
          if (res.name === this.reportName) {
            res.accessType === 'CLIENT' ? this.getOrgId > 0 ? this.showClientMsg = false : this.showClientMsg = true : this.showClientMsg = false;
          }
        });
      }
    });
    this.reportsService.makeFormDirty(this.addlinkRequestsReportForm, this.parent);
    this.onChanges();

    this.selectedOptionsOfStatus = [];
    this.statusDropdownData = [];
    const newObj = {label: 'All', value: 'All'};
    this.addlinkRequestsReportForm.get('action').valueChanges.subscribe(value => {
      this.reportsService.sourceAndStatusMultiselectValues.subscribe(data => {
        this.sourceDropdownData = [];
        this.statusDropdownData = [];
        if (data !== null) {
          data.data.sources.forEach(sourceName => {
            const sourceObj = {label: sourceName, value: sourceName};
            this.sourceDropdownData.push(sourceObj);
          });

          if (value && value.value === this.getShellConstant.reports.ADDLinkRequestsReport.linkTxt) {
            data.data.status.link.forEach(statusName => {
              const obj = {label: statusName, value: statusName};
              this.statusDropdownData.push(obj);
            });
          } else if (value && value.value === this.getShellConstant.reports.ADDLinkRequestsReport.delinkTxt) {
            data.data.status.delink.forEach(statusName => {
              const obj = {label: statusName, value: statusName};
              this.statusDropdownData.push(obj);
            });
          } else {
            data.data.status.all.forEach(statusName => {
              const obj = {label: statusName, value: statusName};
              this.statusDropdownData.push(obj);
            });
          }
          this.selectedOptionsOfStatus = [];
          this.selectedOptionsOfSource = [];
          this.statusDropdownData.forEach((item) => this.selectedOptionsOfStatus.push(item.value));
          this.sourceDropdownData.forEach((item) => this.selectedOptionsOfSource.push(item.value));
          this.addlinkRequestsReportForm.patchValue({
            status: this.reportsService.accessToOnlyRbs || this.businessUnit === 'RBS' ? this.selectedOptionsOfStatus.slice(0, 1) : this.selectedOptionsOfStatus,
            source: this.reportsService.accessToOnlyRbs || this.businessUnit === 'RBS' ? this.selectedOptionsOfSource.slice(0, 1) : this.selectedOptionsOfSource
          }, {onlySelf: true, emitEvent: false});
        }
      });
    });

    this.addlinkRequestsReportForm.patchValue({
      action: newObj,
      liable: newObj
    });
  }

  ngAfterViewChecked() {
    const elementRefStatus = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[0];
    this.sharedService.setMultiSelectDefaultLabel(elementRefStatus, this.addlinkRequestsReportForm, 'status', this.selectedOptionsOfStatus.length);
    const elementRef = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[1];
    this.sharedService.setMultiSelectDefaultLabel(elementRef, this.addlinkRequestsReportForm, 'source', this.selectedOptionsOfSource.length);

  }

  calendarValidation(event) {
    this.parent.calendarValidation(event);
  }

  throwDateError(event) {
    this.endDateLessThanStartDate = event;
    this.parent.throwDateError(event);
  }


  setDate(event) {
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
    this.dateError = event.dateError;
  }

  cancelReport() {
    this.reportsService.cancelReport(this.addlinkRequestsReportForm, this.parent);
  }

  runReports() {
    const localUpdatedFormValues = this.addlinkRequestsReportForm.getRawValue();
    const isStatusAll = this.statusDropdownData.every((val, index) => val.value === localUpdatedFormValues.status[index]);
    const isSourceAll = this.sourceDropdownData.every((val, index) => val.value === localUpdatedFormValues.source[index]);
    const localInput = {
      endDate: encodeURIComponent(this.reportsService.formatDate(this.toDate)),
      startDate: encodeURIComponent(this.reportsService.formatDate(this.fromDate)),
      action: localUpdatedFormValues.action ? localUpdatedFormValues.action.value : '',
      liable407: this.sharedService.isInternalApp() ? (localUpdatedFormValues.liable ? localUpdatedFormValues.liable.value : '') : '',
      source: localUpdatedFormValues.source && localUpdatedFormValues.source.length > 0 ? (isSourceAll && (this.businessUnit === 'DBS' || this.businessUnit === 'SPS') ?
      localUpdatedFormValues.source = [this.getShellConstant.allClientInfo.clientName] : localUpdatedFormValues.source) : [this.getShellConstant.allClientInfo.clientName],
      status: localUpdatedFormValues.status && localUpdatedFormValues.status.length > 0 ? (isStatusAll ? localUpdatedFormValues.status = [this.getShellConstant.allClientInfo.clientName] : localUpdatedFormValues.status) : [this.getShellConstant.allClientInfo.clientName],
      clientName: this.reportsService.clientName !== this.getShellConstant.allClientInfo.clientName ? this.reportsService.clientName : '',
      clientId: this.reportsService.clientId ? this.reportsService.clientId : '',
      orgId: this.getOrgId ? this.getOrgId : '',
      employeeId: this.addlinkRequestsReportForm.get('employeeID').value ? this.addlinkRequestsReportForm.get('employeeID').value.trim() : '',
      accountNumber: this.addlinkRequestsReportForm.get('employeeAccount').value ? this.addlinkRequestsReportForm.get('employeeAccount').value.trim() : '',
      employeeLastName: this.addlinkRequestsReportForm.get('employeeLastName').value ? this.addlinkRequestsReportForm.get('employeeLastName').value.trim().toUpperCase() : '',
      masterAccountNumber: this.addlinkRequestsReportForm.get('masterAccountNumber').value ? this.addlinkRequestsReportForm.get('masterAccountNumber').value.trim() : '',
    };

    this.reportsService.executeReports(this.parent, localInput, this.reportsService.selectedReport, this.getShellConstant.reports.ADDLinkRequestsReport.name, this.getShellConstant.reports.presetReports.reportNames.addlinkRequestTxt,
      this.getShellConstant.reports.presetReports.reportNames.addlinkRequestReportName, this.appService, '');
  }

  onChanges() {
    this.reportsService.formErrorMessage(this.addlinkRequestsReportForm, this.parent, '', 'employeeID', this.getShellConstant.reports.presetReports.employeeIdErrorMsg);
    this.reportsService.formErrorMessage(this.addlinkRequestsReportForm, this.parent, '', 'employeeLastName', this.getShellConstant.manageClients.clientDetailsPage.errorMsg.invalidCharacterEnteredAlphabeticValue);
    this.reportsService.formCharLimitErrorMessage(this.addlinkRequestsReportForm, this.parent, 'employeeAccount', 8, this.getShellConstant.manageClients.clientDetailsPage.errorMsg.empAcctNumShouldContain8Digits);
    this.reportsService.formCharLimitErrorMessage(this.addlinkRequestsReportForm, this.parent, 'masterAccountNumber', 8, this.getShellConstant.manageClients.clientDetailsPage.errorMsg.masterAcctNumShouldContain8Digits);
  }

  throwDateRangeError(event) {
    this.dateRangeConstarint = event;
    this.parent.throwDateRangeError(event);
  }

  ngOnDestroy() {
    if (this.clientInfoSubscribe) {
      this.clientInfoSubscribe.unsubscribe();
    }
    this.parent.clearErrorMsg();
    this.reportsService.errorInEmpLastName = false;
    this.reportsService.errorInAccntHolderLastName = false;
    this.parent.reportFormIsDirty = false;
  }
}

import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {SharedConstant, SharedService} from '@ems/shared';
import {emtrConstant} from '../../../constants/emtrConstants';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {InstrumentGroupFormDataService} from '../instrument-group-form-data/instrument-group-form-data.service';
import {InstrumentGroupService} from '../../../services/instrument-group-service/instrument-group.service';
import {RuleService} from '../../../services/rule-service/rule.service';

@Component({
  selector: 'tr-step1-enter-instrument-group-details',
  templateUrl: './step1-enter-instrument-group-details.component.html',
})
export class Step1EnterInstrumentGroupDetailsComponent implements OnInit {

  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;

  getSharedConstant = SharedConstant;
  getConstant = emtrConstant;
  cancelButton = true;
  createInstrumentGrpForm: UntypedFormGroup;
  groupNameExists = false;
  confirmMessage: any;
  message: string;
  showGroupNameCharLimit: boolean;
  showGroupDescCharLimit: boolean;
  businessUnitData = [];
  userPolicy: any;
  errorMsg = [];
  instGroupNameExistsErrMsg = '';
  formData: any;

  constructor(@Inject('commonConstants') public commonConstants, private router: Router, private r: ActivatedRoute, public fb: UntypedFormBuilder, public formDataService: InstrumentGroupFormDataService,
              public sharedService: SharedService, private instrumentGroupService: InstrumentGroupService, public ruleService: RuleService) {
  }

  ngOnInit() {
    this.userPolicy = this.sharedService.getPolicyResponse('success');
    this.formData = {...this.formDataService.getFormData()};
    this.createInstrumentGrpForm = this.fb.group({
      groupName: [this.formData.name, {validators: [Validators.required]}],
      description: [this.formData.description],
      businessUnit: [this.formData.businessUnit, {validators: [Validators.required]}]
    });
    /* Adding BU values to BU dropdown for which MANAGE_INSTRUMENTS access is present */
    this.sharedService.userBUList.forEach(bu => {
      if (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === bu && val.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_INSTRUMENTS') !== -1)) {
        const obj = {name: bu, value: bu};
        this.businessUnitData.push(obj);
      }
    });
    if (this.businessUnitData.length === 1 && this.createInstrumentGrpForm.get('businessUnit').value.length <= 1) {
      this.createInstrumentGrpForm.get('businessUnit').setValue(this.businessUnitData[0]);
    }
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.onChanges();
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = (this.confirmMessage.message !== undefined && this.confirmMessage.message) ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.cancelButton && (this.createInstrumentGrpForm.dirty || this.createInstrumentGrpForm.get('groupName').value)) {
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  goToNextStep() {
    this.cancelButton = false;
    const formObj = {
      groupName: this.createInstrumentGrpForm.get('groupName').value,
      description: this.createInstrumentGrpForm.get('description').value,
      businessUnit: this.createInstrumentGrpForm.get('businessUnit').value
    };
    this.checkWhetherInstGroupNameExists(formObj);
  }

  checkWhetherInstGroupNameExists(ruleObj) {
    const formData = this.formDataService.getFormData();
    if (this.createInstrumentGrpForm.get('groupName').value === formData.currentEditedName) {
      this.navigateTo(ruleObj);
    } else {
      this.instrumentGroupService.checkWhetherInstGrpExists(ruleObj.groupName, ruleObj.businessUnit.name).subscribe(res => {
        if (res && res.data !== null) {
          /* eslint-disable-next-line max-len */
          this.instGroupNameExistsErrMsg = this.getConstant.rulesConstant.ruleNameMsg.the + '<strong>' + ' " ' + this.createInstrumentGrpForm.get('groupName').value + '"' + '</strong>' + this.getConstant.instrumentGroups.instrumentGrpStep1.groupNameExists;
          this.groupNameExists = true;
          this.cancelButton = true;

          /*To show group name already exist error msg*/
          if (this.errorMsg.indexOf(this.instGroupNameExistsErrMsg) === -1) {
            this.errorMsg.push(this.instGroupNameExistsErrMsg);
          }
          this.ruleService.schErrorMsg.emit(this.errorMsg);
          this.ruleService.showSchError.emit(true);

        } else {
          this.navigateTo(ruleObj);
        }
      });
    }
  }

  navigateTo(steps) {
    const instGrpObj = {
      groupName: steps.groupName.trim(),
      description: steps.description,
      businessUnit: steps.businessUnit
    };
    this.formDataService.setStep1(instGrpObj, true);
    this.router.navigate(['../step2'], {relativeTo: this.r});
  }

  cancelCreateInstrumentGroup() {
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.router.navigate([this.getConstant.instrumentGroups.instrumentGroupList.toLandingPage]);
  }

  onChanges(): void {
    this.createInstrumentGrpForm.get('groupName').valueChanges.subscribe((groupNameValue) => {
      this.groupNameExists = false;
      const regExp = new RegExp('^[a-zA-Z0-9+_()\[\\],.\\- ]*$');
      if (groupNameValue && !regExp.test(groupNameValue)) {
        if (this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName) === -1) {
          this.errorMsg.push(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName);
        }
        this.ruleService.showSchError.emit(true);
      } else {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName), 1);
      }

      /*To remove group name already exist error msg from the errorMsg array*/
      if (this.errorMsg.indexOf(this.instGroupNameExistsErrMsg) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.instGroupNameExistsErrMsg), 1);
      }

      /*Hide the error block when errorMsg is empty*/
      if (this.errorMsg.length === 0) {
        this.ruleService.showSchError.emit(false);
      }

      this.ruleService.schErrorMsg.emit(this.errorMsg);
    });
  }

}

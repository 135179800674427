<div class="accordion-panel">
  <sdps-accordion class="emtr-accordion" sdps-id="accordion-variant-multiSelect" expand-mode="multiple">
      <sdps-accordion-section #dataTable class="emtr-accordion" open>
          <div class="sdps-display-inline emtr-accordion" slot="title" (keydown.spacebar)="toggle($event)"
               (keydown.space)="toggle($event)" tabindex="0" (click)="toggle(); isCollapsed = !isCollapsed;">
              <button id="panelVariantButton" class="cursor sdps-button sdps-p-around_none"
                      aria-labelledby="custom-panel-heading" [attr.aria-expanded]="!isCollapsed">
                  <span aria-hidden="true"
                        [ngClass]="{'sch-chevron-down': !isCollapsed, 'sch-chevron-right': isCollapsed}"></span>
              </button>
              <h2 class="sdps-text-m-heading sdps-display-inline sdps-wrap custom-title"
                  id="custom-panel-heading" [innerHTML]="label"></h2>
          </div>
        <div slot="content">
          <ng-content></ng-content>
        </div>
        <button slot="title" *ngIf="editable" type="button"
                  class="cursor sdps-pull-right sdps-button sdps-button--secondary sdps-is-selected sdps-display-inline edit-button"
                  (click)="goToEditPage()" [disabled]="disabledButton" [ngClass]="{'sdps-hide': showEditIcon === 'EXPIRED'}">
              <em *ngIf="toolTip" class="pi pi-pencil" [pTooltip]="message" tooltipPosition="left"></em>
              <em *ngIf="!toolTip" class="pi pi-pencil"></em>
          </button>
      </sdps-accordion-section>
  </sdps-accordion>
</div>

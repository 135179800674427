import { Component, OnDestroy, OnInit} from '@angular/core';
import { EmtrService } from '@ems/emtr';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import { SharedService, SharedConstant } from '@ems/shared';
import { Subscription} from 'rxjs';
import { AuthService } from '@core_services/auth/auth.service';
import { ChartsService } from '@core_services/charts/charts.service';
import { LocalStorageService } from '@core_services/local-storage.service';
import * as temp from 'moment-timezone';
const moment = temp['default'];
import { authAgentTableHeader } from '../model/authAgent-model';
import { authAgentTableHeaderSCT } from '../model/authAgent-model';
import { entitlementConst } from '@shell_components/constants/entitlementConstants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-workbench',
  templateUrl: './workbench.component.html',
  styleUrls: ['./workbench.component.scss']
})
export class WorkbenchComponent implements OnInit, OnDestroy {

  orgId: any;
  shellConstant = ShellConstant;
  entitlementConst = entitlementConst;
  getSharedConstant = SharedConstant;
  private currentOrgSubscription: Subscription;
  startDateArr: any = [];
  endDateArr: any = [];
  overrideEndDateArr: any = [];
  workBenchConst = this.shellConstant.workBenchChart;
  userGuideName = this.getSharedConstant.userGuide.workbenchUG.workbenchUserGuideTxt;
  ugGutTrack = 'adobe-dl_quickuserguide|fireonce';
  dataValues: any = [];

  labels: any;

  /* Restriction Dropdown model variables */
  selectedStartDate: any;
  selectedEndDate: any;

  restrictStartDtValues: any;
  restrictStartDtLabel: any;

  restrictEndDtValues: any;
  restrictEndDtLabel: any;

  overrideEndDtValues: any;
  overrideStartDtLabel: any;
  /* Override Dropdown model variables */
  selectedOverrideStartDate: any;
  selectedOverrideEndDate: any;

  overrideStartDtValues: any;
  overrideEndDtLabel: any;
  restrictionResponse: any;
  overrideResponse: any;
  workbenchData: any = [];
  selectedClientInfo: any;
  authorizedAgentData: any = {
    agentResponse: [],
    agentTimestamp: ''
  };
  addlinkData: any = {
    addlinkResponse: [],
    addlinkTimestamp: '',
    onHoldCount: 0,
    validationMismatchCount: 0,
    dbsActionNeededCount: 0,
    requireIndexing: 0,
    clientCentralLinks: 0,
    clientCentralDelinks: 0
  };
  authAgentTableColumn: any;
  loader: boolean;
  loadSection: any;
  getUserEntitlement: any;
  showPanel: any;
  showAAPanel = false;
  masterAccCount = 0;
  isWorkbenchComponent = true;
  businessUnit: any;
  downloadTemplate = '';
  userPolicy: any;
  noRUAUEntForDBS = false;
  noRUAUEntForSPS = false;
  showUGForClientAcc = false;
  tradeRulesLinks: any;
  constructor(private router: Router, public e: EmtrService, public s: SharedService, private authService: AuthService,
              private chartsService: ChartsService, public localStrService: LocalStorageService, public sharedService: SharedService) {
  }

  ngOnInit() {
    this.startDateArr = [
      {label: '≤3', value: 3},
      {label: '≤7', value: 7}
    ];
    this.endDateArr = this.overrideEndDateArr = Array.from(this.startDateArr);
    this.userPolicy = this.s.getPolicyResponse('success');
    this.currentOrgSubscription = this.e.clientInformation.subscribe(clientInfo => {
      this.sharedService.clearErrorMessage();
      if (clientInfo !== null) {
        this.businessUnit = clientInfo.businessUnit ? clientInfo.businessUnit : 'All';
        this.tradeRulesLinks = this.businessUnit === 'RBS' ? this.workBenchConst.rules.RBSRulesLinks : this.workBenchConst.rules.RulesLinks;
        this.orgId = clientInfo.orgId;
        this.chartsService.orgId = this.orgId;
        const userPolicy = this.authService.getUserPolicy();
        const clientPolicy = this.s.policy.data.userPolicy;
        if (this.orgId) {
          this.getUserEntitlement = clientPolicy.buPolicy && clientPolicy.buPolicy.length > 0 ? clientPolicy.buPolicy : clientPolicy.clientPolicy;
        } else {
          this.getUserEntitlement = this.s.userPolicyEntitlementList;
        }
        this.authorizedAgentData.agentResponse = [];
        this.selectedClientInfo = JSON.parse(this.localStrService.getProfile());
        this.showPanel = this.s.checkUserEntitlements(this.getUserEntitlement);

        /* If client is selected and the selected client does not have access to Rule Agent and Auth Agent access, then navigate to Admin menu */
        if (this.isWorkbenchComponent && this.orgId && !this.s.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, entitlementConst.navUUID.accountSummary.uuid, '')) {
          this.s.loadDataTrackValue('client_redirect');
          this.isWorkbenchComponent = false;
          if (this.s.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
            this.router.navigate(['/ems/emtr/instrument-groups/instrument-group-list']);
          } else {
            this.router.navigate(['ems/admin/manage-clients']);
          }
        }

        if (this.showPanel.hasAddlink) {
          if (!this.orgId) {
            this.selectedClientInfo = {clientId: 0};
            this.fetchAddlinkData('');
          } else {
            this.fetchAddlinkData('addLink');
            this.fetchMasterAccCount(this.orgId);
            this.fetchAuthorizedAgents('clients');
          }
        }

        if (this.showPanel.hasEmtr) {
          this.fetchRestrictionResponse();
          if (!this.showPanel.hasAddlink && this.showPanel.hasEmtr) {
            this.fetchAddlinkData('addLink');
          }
        }

        /* to display user guide to users with client leve access */
        if (this.userPolicy.data.userPolicy.clientPolicy && this.userPolicy.data.userPolicy.clientPolicy.length > 0) {
          /* eslint-disable-next-line max-len */
          const showUGForClients = this.userPolicy.data.userPolicy.clientPolicy.some(x => this.userPolicy.data.clients[x.clientId] !== this.getSharedConstant.rbsBUText && (x.entitlementList.map(y => y.entitlementName).indexOf('ACCOUNT_SUMMARY') !== -1 || (x.entitlementList.map(y => y.entitlementName).indexOf('RULES_SUMMARY') !== -1)));

          if (this.businessUnit === 'All') {
            this.showUGForClientAcc = showUGForClients;
          } else if (this.businessUnit !== this.getSharedConstant.rbsBUText) {
            /* eslint-disable-next-line max-len */
            this.showUGForClientAcc = this.userPolicy.data.userPolicy.clientPolicy.some(x => x.clientId === clientInfo.clientId && (x.entitlementList.map(y => y.entitlementName).indexOf('ACCOUNT_SUMMARY') !== -1 || (x.entitlementList.map(y => y.entitlementName).indexOf('RULES_SUMMARY') !== -1)));
          } else {
            this.showUGForClientAcc = false;
          }
        }
      }

      /* for user guide download */
      if (this.businessUnit && this.businessUnit !== this.getSharedConstant.rbsBUText) {
        this.downloadTemplate = this.getSharedConstant.userGuide.workbenchUG[this.businessUnit].value;
      }
    });

    if (this.userPolicy.data.userPolicy.buPolicy) {
      /* to display user guide when RA and AA access is der for DBS/SPS BU */
      /* eslint-disable-next-line max-len */
      this.noRUAUEntForDBS = (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === this.shellConstant.manageUserPage.dbs && (val.entitlementList.map(y => y.entitlementName).indexOf('ACCOUNT_SUMMARY') !== -1 || (val.entitlementList.map(y => y.entitlementName).indexOf('RULES_SUMMARY') !== -1))));
      /* eslint-disable-next-line max-len */
      this.noRUAUEntForSPS = (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === this.shellConstant.manageUserPage.sps && (val.entitlementList.map(y => y.entitlementName).indexOf('ACCOUNT_SUMMARY') !== -1 || (val.entitlementList.map(y => y.entitlementName).indexOf('RULES_SUMMARY') !== -1))));
    }
  }

  ngOnDestroy(): void {
    this.isWorkbenchComponent = false;
    this.currentOrgSubscription.unsubscribe();
    this.s.setRefresh.emit(false);
  }

  reduceArr(Arr) {
    return Arr.reduce((acc, curr) => {
      return acc + curr;
    }, 0);
  }

  pushValues(value, label, type) {
    const valueLessThanTwo: any = [];
    const valueLessThanFive: any = [];
    const wholeArr: any = [];
    if (value) {
      value.map((val, i) => {
        if (val.id <= 0) {
          this.dataValues.push(val.value);
        } else if ((val.id > 0) && (val.id <= 2)) {
          valueLessThanTwo.push(val.value);
        } else if ((val.id > 2) && (val.id <= 5)) {
          valueLessThanFive.push(val.value);
        } else {
          wholeArr.push(val.value);
        }
      });
      this.dataValues.push(this.reduceArr(valueLessThanTwo));
      this.dataValues.push(this.reduceArr(valueLessThanFive));
      this.dataValues.push(this.reduceArr(wholeArr));
      this.labels = this.workBenchConst.weekLabels;
      this.generateChart(label, type);
    }

  }

  fetchRestrictionResponse() {
    /* Load Restriction Doughnut chart */
    this.chartsService.getRestrictOrOverrideData().subscribe(res => {
      this.restrictionResponse =  res.data.scheduledActiveRestrictions;
      if (this.restrictionResponse) {
        this.loadChartForDates(this.restrictionResponse, 'restriction');
      }
      this.overrideResponse =  res.data.scheduledActiveOverrides;
      if (this.overrideResponse) {
        this.loadChartForDates(this.overrideResponse, 'override');
      }
    });
  }

  fetchAuthorizedAgents(type) {
    /* If Refresh Icon clicked Functionality handled */
    if (type === 'authorizedAgents') {
      this.s.setRefresh.emit(true);
      this.loadSection = type;
      this.loader = true;
    } else {
      /* Initial Load/Client Selection Load */
      this.s.setRefresh.emit(false);
    }

    if (this.selectedClientInfo && (this.selectedClientInfo.businessUnit === 'DBS') && this.showPanel.hasAddlink) {

      this.chartsService.getAuthorizedAgent(this.selectedClientInfo.orgId).subscribe(res => {
        this.authAgentTableColumn = res.data.dbsauthAgentlist !== null ? authAgentTableHeader : authAgentTableHeaderSCT;
        this.loader = false;
        this.authorizedAgentData = {
          agentResponse: res.data.dbsauthAgentlist !== null ? res.data.dbsauthAgentlist : res.data.authAgentlist,
          agentTimestamp: moment(this.authorizedAgentData.timestamp).tz('America/New_York').format('hh:mm A')
        };
        this.showAAPanel = true;
      });
    } else {
      this.showAAPanel = false;
    }
  }

  fetchAddlinkData(type) {
    if (type === 'addLink') {
      this.s.setRefresh.emit(true);
      this.loadSection = type;
      this.loader = true;
    } else {
      /* Initial Load/Client Selection Load */
      this.s.setRefresh.emit(false);
    }

    if (this.selectedClientInfo.clientId && this.showPanel.hasAddlink) {
      this.chartsService.getAddlinkClientData(this.selectedClientInfo.orgId).subscribe(res => {
        this.updateAddlinkRequestStatus(res);
      });
    } else {
      if (this.showPanel.hasAddlink) {
        this.chartsService.getAddlinkDataAll().subscribe(res => {
          this.updateAddlinkRequestStatus(res);
        });
      }
    }

  }

  /* updating status count for addlink*/
  updateAddlinkRequestStatus(res) {
    this.loader = false;
    const response = res.data;
    this.addlinkData = {
      onHoldCount: response.onHoldCount,
      validationMismatchCount: response.validationMismatchCount,
      dbsActionNeededCount: response.dbsActionNeededCount,
      requireIndexing: response.indexMissingCount,
      clientCentralLinks: response.clientCentralLinkCount,
      clientCentralDelinks: response.clientCentralDelinkCount,
      addlinkTimestamp: moment.tz('America/New_York').format('hh:mm A')
    };
  }

  /* Loading both startDate & endDate Doughnut chart for Restriction/Override*/
  loadChartForDates(res, type) {
    const initialLoadLabel = ['startDate', 'endDate'];
    initialLoadLabel.forEach((element) => {
      this.loadSelectedChart(res[element], 3, element, type);
    });
  }

  getDropdownVal(days, label, type) {
    const updatedResponse = (type === 'restriction') ? this.restrictionResponse : this.overrideResponse;
    this.loadSelectedChart(updatedResponse[label], days, label, type);
  }

  loadSelectedChart(res, days, label, type) {
    this.dataValues = [];
    if (res && days === 3) {
      res.map((val, i) => {
        if (i <= days) {
          this.dataValues.push(val.value);
        }
      });
      this.labels = this.workBenchConst.dayLabels;
      this.generateChart(label, type);
    } else {
      this.pushValues(res, label, type);
    }
  }

  validateResponse(data) {
    return data && data.every(item => item === 0);
  }

  generateChart(label, type) {
    this.dataValues.reverse();
    switch (label) {
      case 'startDate': {
        (type === 'restriction') ? this.restrictStartDtValues = this.dataValues : this.overrideStartDtValues = this.dataValues;
        (type === 'restriction') ? this.restrictStartDtLabel = this.labels : this.overrideStartDtLabel = this.labels;
        break;
      }

      case 'endDate': {
        (type === 'restriction') ? this.restrictEndDtValues = this.dataValues : this.overrideEndDtValues = this.dataValues;
        (type === 'restriction') ? this.restrictEndDtLabel = this.labels : this.overrideEndDtLabel = this.labels;
        break;
      }
    }
  }

  fetchMasterAccCount(id) {
    if (id !== 0) {
      this.chartsService.getMasterAccount(id).subscribe(res => {
        if (res.data) {
          this.masterAccCount = res.data.length;
        }
      });
    }
  }

  redirectToRequestList(status) {
    /* Set isDTValueUpdated to true, to call the loadpage on navigation to request-list page. */
    this.s.isDTValueUpdated = true;
    let gutTrackValue = 'linkid-lc_rl_';
    if (status === this.workBenchConst.addLink.requestStatus.clientCentralLinks) {
      gutTrackValue = gutTrackValue + 'cc_addlink';
    } else if (status === this.workBenchConst.addLink.requestStatus.clientCentralDelinks) {
      gutTrackValue = gutTrackValue + 'cc_delink';
    } else {
      gutTrackValue = gutTrackValue + status.split(' ').join('');
    }
    this.s.gutTracking(gutTrackValue.trim().toLowerCase());
    this.s.requestStatus = status;
    this.router.navigate(['ems/addLink']);
  }

  quickLinkNavigation(link, dataTrack) {
    /* Set isDTValueUpdated value to true, which will let the call of pageLoad method on navigating to respective screen based on the quick link clicked. */
    this.s.isDTValueUpdated = true;
    this.s.gutTracking(dataTrack);
    this.router.navigate([link]);
  }
}

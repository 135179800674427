import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService, SharedService, SharedConstant} from '@ems/shared';
import {EmpGroupFormDataService} from './emp-group-form-data/emp-group-form-data.service';
import {emtrConstant} from '../../constants/emtrConstants';
import {EmtrService} from '../../emtr.service';

@Component({
  selector: 'tr-create-employee-group',
  templateUrl: './create-employee-group.component.html'
})
export class CreateEmployeeGroupComponent implements OnInit, OnDestroy {

  items: any;
  sharedConstant = SharedConstant;
  getConstant = emtrConstant;
  schErrorMsg: string[];
  showSchError = false;
  businessUnit: any;
  grpData: any;
  constructor(public sharedService: SharedService, public route: ActivatedRoute, public workflowService: WorkflowService, private formDataService: EmpGroupFormDataService,
              public emtrService: EmtrService, private router: Router, @Inject('entitlementConst') public entitlementConst) {
    this.route.data.subscribe(data => {
      this.sharedService.selectedClientInfo.subscribe(clientInfo => {
        this.businessUnit = this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP ? this.sharedService.groupType : clientInfo.businessUnit;
        this.items = this.workflowService.getWorkflowSteps('manageEmpGrp');
      });
    });
    this.grpData = this.formDataService.getFormData();
  }

  ngOnInit() {
    this.sharedService.disableClientSelector.next(true);
    this.emtrService.schErrorMsg.subscribe(value => {
      this.schErrorMsg = value;
    });

    this.emtrService.showSchError.subscribe(value => {
      this.showSchError = value;
    });

    if (this.items) {
      this.items[0].name = this.sharedConstant[this.businessUnit].step1IndicatorName;
      this.items[1].name = this.sharedConstant[this.businessUnit].step2IndicatorName;
    }
  }

  ngOnDestroy() {
    this.sharedService.groupType = '';
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
    if (this.businessUnit && this.router.routerState.snapshot.url.indexOf(this.sharedConstant[this.businessUnit].mainRoute) === -1) {
      this.formDataService.resetForm();
      this.sharedService.clearLocalStorage();
    }
  }
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {BulkUploadComponent, SharedConstant} from '@ems/shared';
import {Router} from '@angular/router';
import {SharedService} from '@ems/shared';
import {emtrConstant} from '../constants/emtrConstants';
import {EmtrService} from '../emtr.service';
import {EmpGroupFormDataService} from '../manage-employee-groups/create-employee-group/emp-group-form-data/emp-group-form-data.service';

@Component({
  selector: 'tr-emp-group-bulk-upload',
  templateUrl: './emp-group-bulk-upload.component.html'
})
export class EmpGroupBulkUploadComponent implements OnInit, OnDestroy {

  private currentOrgSubscription: Subscription;
  fileTemplate: string;
  library: any;
  orgId: any;
  uploadFilePoint: any;
  getStep1Details: any;
  navigateTo: string;
  getConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  nameEntered: any;
  isFileUploaded = false;
  showError = false;
  confirmMessage: any;
  downloadTemplateLabel: any;
  bulkUploadTitleName: string;
  businessUnit: string;
  downloadTempGutTrackValue = '';
  chooseFileGutTrackValue = '';
  uploadBtnGutTack = '';
  cancelBtnGutTack = '';

  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild(BulkUploadComponent) bulkUploadComponent: BulkUploadComponent;

  constructor(public emtrService: EmtrService, public empGrpDataServ: EmpGroupFormDataService, public router: Router, public sharedService: SharedService) {
  }

  ngOnInit() {
    this.currentOrgSubscription = this.emtrService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.orgId = clientInfo.orgId;
        this.businessUnit = clientInfo.businessUnit;
        this.getStep1Details = this.empGrpDataServ.getEmpGrpStep1();
        const postURL = this.sharedService.parentPage ? '/empaccounts' : '/empgroups';
        this.library = postURL === '/empaccounts' ? 'EMPLOYEE' : 'EMPLOYEEGROUP';
        this.nameEntered = this.getStep1Details.name;
        if (!this.sharedService.parentPage) {
          this.downloadTempGutTrackValue = this.businessUnit ? this.getSharedConstant[this.businessUnit].grpPlanDownloadTemplateGTValue : '';
          this.chooseFileGutTrackValue = this.businessUnit ? this.getSharedConstant[this.businessUnit].grpPlanChooseFileGTValue : '';
          this.uploadFilePoint = postURL + '?name=EmpGroupUpload&orgId=' + this.orgId + '&groupName=' + this.getStep1Details.name + '&groupDesc=' + this.getStep1Details.groupDesc;
        } else {
          this.downloadTempGutTrackValue = 'adobe-dl_manageemployeesupload_template|fireonce';
          this.chooseFileGutTrackValue = 'adobe-lc_manageemployeesupload_choosefile|fireonce';
          this.uploadFilePoint = postURL + '?name=EmpUpload&orgId=' + this.orgId;
        }
      }
    });
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    setTimeout( () => {
      if (this.sharedService.parentPage) {
        this.navigateTo = this.sharedService.parentPage;
        this.fileTemplate = 'EMP';
        this.downloadTemplateLabel = this.getConstant.bulkUpload.empDownloadTemplateTxt;
        this.bulkUploadTitleName = this.getConstant.bulkUpload.empBulkUploadTitleTxt;
      } else {
        this.sharedService.disableClientSelector.next(true);
        this.navigateTo = this.businessUnit ? this.getSharedConstant[this.businessUnit].toStep2FromBulkUpload : '/ems/emtr/manage-employee-groups/create-employee-group/step2';
        this.fileTemplate = this.businessUnit ? this.getSharedConstant[this.businessUnit].groupOrPlanDownloadTemplate : 'EMPGROUP';
        this.downloadTemplateLabel = this.businessUnit ? this.getSharedConstant[this.businessUnit].empGrpOrPlanownloadTemplateTxt : this.getConstant.bulkUpload.empGrpDownloadTemplateTxt;
        this.bulkUploadTitleName = this.businessUnit ? this.getSharedConstant[this.businessUnit].bulkUploadManageEmployeeGroupOrPlanTitle : this.getConstant.bulkUpload.bulkUploadManageEmployeeGroupTitle;
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.currentOrgSubscription.unsubscribe();
    this.sharedService.isConfirmationRequiredClientChange = false;
    if (this.businessUnit && this.router.routerState.snapshot.url.indexOf(this.getSharedConstant[this.businessUnit].mainRoute) === -1) {
      if ( this.sharedService.stateValues && this.sharedService.stateValues.stateManagementFor === 'employeeGroup') {
        this.sharedService.stateValues.stateManagementFor = '';
        this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
        this.sharedService.clearLocalStorage();
      }
    }
  }

  getEmittedFileVal(data) {
    this.showError = data.showError;
    this.isFileUploaded = data.isFileUploaded;
    this.sharedService.isConfirmationRequiredClientChange = this.isFileUploaded;
  }

  uploadFile() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    let dataTrack = this.uploadBtnGutTack = 'manageemployeesupload_upload';
    if (this.library === 'EMPLOYEEGROUP') {
      dataTrack = this.uploadBtnGutTack = this.businessUnit ? this.getSharedConstant[this.businessUnit].grpPlanUploadBtnGTValue : '';
    }
    this.sharedService.loadDataTrackValue(dataTrack);
    this.isFileUploaded = false;
    this.sharedService.parentPage = '';
    this.bulkUploadComponent.uploadFile();
  }

  cancelFileUpload() {
    this.cancelBtnGutTack = 'manageemployeesupload_cancel';
    if (this.library === 'EMPLOYEEGROUP') {
      this.cancelBtnGutTack = this.businessUnit ? this.getSharedConstant[this.businessUnit].grpPlanCancelBtnGTValue : '';
    }
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue(this.cancelBtnGutTack);
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.sharedService.parentPage = '';
    this.router.navigate([this.navigateTo]);
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.isFileUploaded) {
      this.sharedService.isLoadPage = true;
      this.confirmationMessage.cancelBtnRejectGutTack = this.cancelBtnGutTack;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }
}

import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AddlinkConstant} from '../../../constants/addlinkConstants';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {RequestListService} from '../../services/request-list.service';
import {AddlinkService} from '../../../addlink.service';
import {DateRangeFilterComponent, SharedService} from '@ems/shared';
import * as temp from 'moment';
const moment = temp['default'];

@Component({
  selector: 'addlink-request-list-sub-header',
  templateUrl: './request-list-sub-header.component.html',
  styleUrls: ['./request-list-sub-header.component.scss']
})
export class RequestListSubHeaderComponent implements OnInit, AfterViewChecked {

  @ViewChild('range') rangeCalendar: DateRangeFilterComponent;

  @Input() expandedRowColumns: any[] = [];
  @Input() isHidden: boolean;
  @Input() onFilterClicked: boolean;
  @Input() disableForm: any;
  @Input() businessRbs: string;
  @Output() sendFilterObject: EventEmitter<any> = new EventEmitter<any>();
  @Output() subheaderValue = new EventEmitter<any>();

  getAdlConst = AddlinkConstant;
  requestListSubHeaderForm: UntypedFormGroup;
  filteredRequest: any[];
  selectedOptions = [];
  sourceDropdownData = [];
  filterSelectedDate: any;

  constructor(public fb: UntypedFormBuilder, @Inject('commonConstants') public commonConstants, public requestListService: RequestListService,
              public addlinkService: AddlinkService, public sharedService: SharedService, @Inject('entitlementConst') public entitlementConst, private el: ElementRef) {
  }

  ngOnInit() {
    this.requestListSubHeaderForm = this.fb.group({
      acctHolderName: [''],
      accountHolderAffiliation: [''],
      liable407: [''],
      indexKey: [''],
      commissionCode: [''],
      authAgentName: [''],
      source: [''],
      createdBy: [''],
      createdDate: [''],
      completedBy: ['']
    });
    this.filterRequest();

    /* To clear the requestListSubHeaderForm when clearAll button is clicked */
    if (this.requestListService.clearClicked !== undefined) {
      this.requestListService.clearClicked.subscribe(value => {
        if (value === true) {
          this.clearFilterForm();
          this.rangeCalendar.clearSelection();
          /* Set subheaderValue to false when clear btn is clicked */
          this.subheaderValue.emit(false);
        }
      });
    }

    /* To disable the requestListSubHeaderForm when value is entered in requestId */
    if (this.requestListService.disableForm !== undefined) {
      this.requestListService.disableForm.subscribe(value => {
        if (value === 'disable') {
          this.clearFilterForm();
          this.rangeCalendar.clearSelection();
        }
        Object.keys(this.requestListSubHeaderForm.controls).forEach((controlName) => {
          this.requestListSubHeaderForm.controls[controlName][value]();
          if (this.businessRbs === 'RBS') {
            this.requestListSubHeaderForm.controls.source.disable();
          }
        });
      });
    }

    if (this.requestListService.filterClicked !== undefined) {
      this.requestListService.filterClicked.subscribe(value => {
        if (value === true) {
          /* Set the selected date value to the requestListSubHeaderForm before sending the form value to request-list component */
          if (this.rangeCalendar) {
            this.rangeCalendar.applyToDate();
            this.getSelectedDate(this.rangeCalendar.dateRangeForm.get('dateSelected').value);
          }
          const localUpdatedFormValues = this.requestListSubHeaderForm.getRawValue();
          this.sendFilterObject.emit({
            requestListSubHeaderForm: localUpdatedFormValues,
            sourceDropdownData: this.sourceDropdownData
          });
        }
      });
    }

    /* To enable clear button, checking for form dirty as few fields will have default value set */
    this.requestListSubHeaderForm.valueChanges.subscribe(value => {
      if (this.requestListSubHeaderForm.dirty) {
        this.subheaderValue.emit(true);
      }
    });

    this.setDefaultValue();
  }

  ngAfterViewChecked() {
    const elementRef = this.el.nativeElement.querySelector('.p-multiselect-label');
    this.sharedService.setMultiSelectDefaultLabel(elementRef, this.requestListSubHeaderForm, 'source', this.selectedOptions.length);
  }

  public filterRequest(): void {
    this.filteredRequest = [];
    for (const expandedRowColumn of this.expandedRowColumns) {
      this.filteredRequest.push({
        header: expandedRowColumn.header,
        enum: expandedRowColumn.enum,
        field: expandedRowColumn.field
      });
    }
  }

  /* Setting the default value */
  setDefaultValue() {
    this.sourceDropdownData = [];
    this.selectedOptions = [];
    this.requestListService.sourceAndStatusMultiselectValues.subscribe((data) => {
      if (data !== null) {
        data.data.sources.forEach(sourceName => {
          const obj = {label: sourceName, value: sourceName};
          this.sourceDropdownData.push(obj);
        });
        this.sourceDropdownData.forEach((item) => this.selectedOptions.push(item.value));
      }
    });
    if (this.sharedService.requestStatus === this.getAdlConst.statusLabel.requireIndexing || this.sharedService.requestStatus === this.getAdlConst.clientCentralLinks || this.sharedService.requestStatus === this.getAdlConst.clientCentralDelinks) {
      const newObj = {
        value: this.sharedService.requestStatusFilterObj.liable407,
        label: this.sharedService.requestStatusFilterObj.liable407
      };
      this.requestListSubHeaderForm.get('liable407').setValue(newObj);
      this.requestListSubHeaderForm.get('source').setValue(this.sharedService.requestStatusFilterObj.source);
      const fromDate = new Date(moment(this.sharedService.requestStatusFilterObj.fromDate));
      const toDate = new Date(moment(this.sharedService.requestStatusFilterObj.toDate));
      const date = [fromDate, toDate];
      this.getSelectedDate(date);
    } else {
      this.requestListSubHeaderForm.patchValue({authAgentName: this.selectedOptions, source: this.selectedOptions});
    }

    /* Enable the Apply filter button */
    this.requestListService.disableFilterButton.emit(false);
  }

  /* Clear the filterRequestListForm */
  public clearFilterForm(): void {
    this.requestListSubHeaderForm.reset();
    this.setDefaultValue();
  }

  getSelectedDate(date) {
    this.filterSelectedDate = date;
    this.requestListSubHeaderForm.get('createdDate').setValue(this.filterSelectedDate);
    /* To enable clear button when date range is selected */
    if (this.filterSelectedDate) {
      this.subheaderValue.emit(true);
    }
  }
}

<ems-confirm-message #confirmationMessage></ems-confirm-message>

<tr-security-type #reviewSecurity [selectedValues]="multiLevelSecurityType" [reviewSecurity]="true"
[modalId]="'review-security-modal'"></tr-security-type>

<div class="heading">
    <span class="select-attributes" [innerHTML]="editReviewRuleData.mode"></span>
    <tr-rule-name-type [ruleName]="editReviewRuleData.name"
                       [ruleType]="rulesCategory"></tr-rule-name-type>
</div>

<div class="edit-review-rules">
    <form [formGroup]="editReviewRuleForm" *ngIf="editReviewRuleForm">
        <!-- Display Rule Name -->
        <div class="sdps-p-bottom_large sdps-p-top_large step-border-box select-rule-type ">
            <div class="sdps-grid-container sdps-wrap">
                <div class="sdps-row">
                  <div class="rule-type-textbox sdps-p-around_medium col-6" [ngClass]="{'invalidField': ((editReviewRuleForm.get('reviewRuleName').value && editReviewRuleForm.get('reviewRuleName').invalid)),'ruleWarning': (editReviewRuleForm.get('reviewRuleName').value && (ruleNameExists && !sharedService.closeToast))}">
                        <span class="p-float-label">
                            <input type="text" name="ruleName" [maxlength]=100 formControlName="reviewRuleName" value="editReviewRuleForm.get('reviewRuleName').value"
                                #focusName emsAlphaNumericSpecialCharacterValidation emsCharacterLimit
                                   [charMaxLimit]="commonConstants.charLimitValues.hundredMaxLimit"
                                   (emsCharacterLimitCheck)="showCharLimit = $event" pInputText/>
                            <label [ngClass]="{'ruleNameWarningColor': (editReviewRuleForm.get('reviewRuleName').value && (ruleNameExists && !sharedService.closeToast))}">{{emtrConst.rulesConstant.ruleName}}
                              <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
                            </label>
                        </span>
                    </div>
                    <div class="sdps-p-top_medium sdps-p-left_medium" *ngIf="(editReviewRuleData.rulesCategory === emtrConst.rulesConstant.autoComplete.RESTRICT_LST_ENGTxt) && editReviewRuleData.global && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageGlobalRule.uuid,'','')">
                      <strong [innerHTML]="emtrConst.rulesConstant.globalRuleLbl"></strong>
                      <span class="sdps-m-left_large">
                        <p-checkbox class="emtr-checkbox" name="globalRule" formControlName="globalRule" binary = "true"
                        ></p-checkbox>
                      </span>
                    </div>
                </div>
              <p class="col-6 sdps-text-right sdps-m-around_none sdps-text-small line-height-nml" *ngIf="editReviewRuleForm.get('reviewRuleName').value.length > showCharLimit">{{emtrConst.rulesConstant.characterLimit}}
                <span class="character-limit">{{editReviewRuleForm.get('reviewRuleName').value.length}} /{{commonConstants.charLimitValues.hundredMaxLimit}}
                </span>
              </p>
            </div>
                    </div>

      <!-- Display Justification for Override -->
        <ems-accordion [label]="emtrConst.rulesConstant.ruleGlobalConst.justification" class="disabled-button-icon" [disabledButton]="true" *ngIf="editReviewRuleData.override === 'Y' && editReviewRuleData.mode !== this.emtrConst.rulesConstant.ruleGlobalConst.override">
            <div class="emtr-radio sdps-grid-container sdps-p-left_xx-large sdps-m-around_none sdps-p-vertical_small" *ngIf="editReviewRuleData.override === 'Y'">
                <div class="col-8 sdps-grid sdps-m-around_none sdps-p-left_none select-rule-type">
                    <p-radioButton class="col-2 sdps-p-top_small" name="justification" value="10b5-1" [label]="emtrConst.rulesConstant.editRule.overrideJustification.justificaton10b5"
                        formControlName="justification"></p-radioButton>
                    <p-radioButton class="col-2 sdps-p-top_small" name="justification" value="Legal Clearance" [label]="emtrConst.rulesConstant.editRule.overrideJustification.legalClearance"
                        formControlName="justification"></p-radioButton>
                    <p-radioButton class="col-2 sdps-p-top_small" name="justification" value="Tax Cover" [label]="emtrConst.rulesConstant.editRule.overrideJustification.taxCOver"
                        formControlName="justification"></p-radioButton>
                    <p-radioButton class="col-2 sdps-p-top_small" name="justification" value="Margin Sellout" [label]="emtrConst.rulesConstant.editRule.overrideJustification.marginSellout"
                        formControlName="justification"></p-radioButton>
                    <p-radioButton class="col-1 sdps-p-top_small" name="justification" value="Other" [label]="emtrConst.rulesConstant.editRule.overrideJustification.other"
                        formControlName="justification"></p-radioButton>
                    <span class="p-float-label rule-type-textbox sdps-p-around_small col-5" [ngClass]="(editReviewRuleForm.get('justification').value !== 'Other' ? 'input-disabled' : 'input-enabled')">
                        <input type="text" name="others" maxlength="35" formControlName="otherInput" pInputText #step4_otherInput/>
                    </span>
                </div>
            </div>
        </ems-accordion>

      <!-- Display Rule Attributes -->
        <ems-accordion [label]="(editReviewRuleData.ruleType === emtrConst.rulesConstant.autoComplete.FNDISUTxt) ? emtrConst.rulesConstant.ruleTypeLabels.restrictBuysSells : emtrConst.rulesConstant.ruleTypeLabels.ruleAttributes">
            <tr-restrict-checkbox class="emtr-checkbox-disable" [rulesCategory]="rulesCategory" [ruleType]="editReviewRuleData.ruleType" [restrictForm]="editReviewRuleForm.get('restrictForm')"></tr-restrict-checkbox>
        </ems-accordion>
        
        <!--Display rule subtype IND SEC-->
        <ems-accordion [label]="subTypeLabel" *ngIf='editReviewRuleData.ruleType !== "SECTYP"'>
          <div *ngIf="editReviewRuleData.ruleType !== emtrConst.rulesConstant.autoComplete.FNDISUTxt">
            <div class="sdps-p-top_small sdps-p-bottom_xx-small sdps-p-left_xx-large sdps-text-l-bold-body sdps-display-inline-block" [innerHTML]="emtrConst.rulesConstant.appliesTo"></div>
            <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
            <!-- Display Applies To -->
            <div class="sdps-p-horizontal_xx-large">
              <p-checkbox class="emtr-checkbox emtr-checkbox-disable" *ngIf="appliesTo === 'BTH' || appliesTo === 'IND'" binary="true"
                    formControlName="indSecs" label="{{emtrConst.rulesConstant.individualSecurity}}"></p-checkbox>
              <span *ngIf="appliesTo === 'BTH'">&nbsp;&nbsp;</span>
              <p-checkbox *ngIf="appliesTo === 'BTH' || appliesTo === 'OPT'" class="emtr-checkbox emtr-checkbox-disable" binary="true"
                    formControlName="underlyingOpts" label="{{emtrConst.rulesConstant.underlyingOptions}}"></p-checkbox>
            </div>
            <!-- Display Search and Select Securities -->
            <div class="sdps-p-top_medium sdps-p-bottom_small sdps-p-horizontal_xx-large sdps-text-l-bold-body">
                <p class="sdps-m-bottom_x-small">{{emtrConst.rulesConstant.autoComplete.searchAndSelectSecurityTo}} {{(rulesCategory === 'RESTRICT_LST_ENG') ? emtrConst.rulesConstant.ruleTypeLabels.RESTRICT_LST_ENG
                    : (rulesCategory === this.emtrConst.rulesConstant.ruleGlobalConst.override) ? emtrConst.rulesConstant.ruleGlobalConst.override
                    : emtrConst.rulesConstant.ruleGlobalConst.watchList}}
                  <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
                </p>
                <div class="sdps-grid-container sdps-m-around_none">
                    <tr-custom-multiselect class="col-2 sdps-p-around_none" [selectedValues]="multiselectSelectedData" (emitSelectedvalues)="updateMultiselectValues($event)"
                        [isDisabled]=true></tr-custom-multiselect>
                </div>
            </div>
          </div>

          <!-- Display Selected Securities -->
            <ems-table-record-count [count]="indSecs && indSecs.length" (emitter)="tt.reset();receiveDataFromChild($event, 'indSecs')" [showHeaderIcons]="false"></ems-table-record-count>
            <p-table [columns]="securityTableHeader" [value]="indSecs" [paginator]="true" [rows]="noOfRecords" class="common-data-table" #tt>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">{{col.header}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td [attr.colspan]="columns.length" class="sdps-text-center"
                        [innerHTML]="emtrConst.rulesConstant.autoComplete.noSecuritySelected"></td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-indSec let-columns="columns">
                    <tr>
                      <td *ngFor="let col of columns">
                        <span *ngIf="col.field === 'name'">{{indSec[col.field] | titlecase}}</span>
                        <span *ngIf="col.field !== 'name'">{{indSec[col.field]}}</span>
                      </td>
                    </tr>
                </ng-template>
            </p-table>

          <!-- Display selected Instruments Groups -->
          <div
            *ngIf="editReviewRuleData.ruleType === 'INDSEC' && rulesCategory !== emtrConst.rulesConstant.ruleTypeLabels.WATCH_LST_ENGText && (sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', ''))">
            <ems-table-record-count [count]="instGroup && instGroup.length" [showHeaderIcons]="false"
                                    (emitter)="indTable.reset();receiveDataFromChild($event, 'instGroup')"></ems-table-record-count>
            <p-table [columns]="instGrpTableHeader" [value]="instGroup" [paginator]="true" [rows]="noOfRecordsInstGroup"
                     #indTable class="common-data-table">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns">{{col.header}}</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td [attr.colspan]="columns.length" class="sdps-text-center"
                      [innerHTML]="emtrConst.rulesConstant.autoComplete.noInstrumentGroupSelected"></td>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-instGrp let-columns="columns">
                <tr>
                  <td *ngFor="let col of columns">
                    <span *ngIf="col.field !== 'tickerSymbols'"
                          [title]="instGrp[col.field]">{{instGrp[col.field]}}</span>
                    <span *ngIf="col.field === 'tickerSymbols'">
                      <span class="ruleGrpLinks cursor" (click)="child.openModal(instGrp)"
                            [title]="emtrConst.rulesConstant.ruleGlobalConst.viewSymboleInstGrpText"
                            [innerHTML]="emtrConst.rulesConstant.ruleGlobalConst.viewSymboleInstGrpText"></span>
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <tr-view-symbols-modal #child></tr-view-symbols-modal>
        </ems-accordion>

        <!--Display Rule subType Security Data-->
        <ems-accordion [label]="subTypeLabel" *ngIf='editReviewRuleData.ruleType === "SECTYP"'>
          <div *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')">
            <div class="security-type-holder">
                <div class="security-selected-result">
                    <div class="sdps-p-top_medium sdps-datepicker__range" *ngFor='let item of multiLevelUpdatedList | keyvalue '>
                      <div class="security-type-text">
                            <span class="sch-check-circle security-highlight"></span>
                            <span class="sdps-p-left_medium security-level-text">{{item.key}}</span>
                        </div>
                        <div class="whole-container">
                            <div *ngFor="let first of item.value | slice:0;">
                                <span *ngIf="first.data.level === 'SC1'">Level 1 {{first.data.desc}} ({{first.data.code}})
                                    <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                                </span>
                                <span *ngIf="first.data.level === 'SC2'">Level 2 {{first.data.desc}} ({{first.data.code}})
                                    <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                                </span>
                                <span *ngIf="first.data.level === 'SC3'">Level 3 {{first.data.desc}} ({{first.data.code}})
                                    <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>

          <!-- Review Security Type button Section - for Asset group or Miscellaneous asset grp selection -->
          <div *ngIf="displaySelectedAssetGrp && displaySelectedAssetGrp.length > 0" class="security-type-holder">
            <div class="choose-security-box" (click)="openSecurityType()">
              <div class="security-box-content pi pi-list"></div>
              <span class="sdps-p-left_x-small">{{emtrConst.presetRules.reviewSecTypeDetailsText}}</span>
            </div>
          </div>

          <!--Display Selected Asset Group -->
          <div
            *ngIf="(sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.presetRuleSummary.uuid,'','') && editReviewRuleData.rulesCategory === emtrConst.rulesConstant.autoComplete.RESTRICT_LST_ENGTxt && editReviewRuleData.override === 'Y') && (!editReviewRuleData.micAGSelectedFlag)">
            <div class="security-type-holder">
              <div class="security-selected-result">
                <div class="sdps-p-top_medium sdps-p-bottom_medium sdps-datepicker__range" *ngFor='let item of displaySelectedAssetGrp'>
              <span class="security-type-text">
                <span class="assetGroup-highlight" [ngClass]="businessUnit === 'RBS' ? item['attributes'].length > 0 ? 'sch-minus-square-large font-21' : 'sch-check-circle' : 'sch-check-circle'"></span>
                <span class="sdps-p-left_medium security-level-text">{{item['name']}}</span>
              </span>
                  <div class="whole-container display-flex">
                    <div *ngFor="let first of item['attributes']">
                      <span class="sch-check-circle assetGroup-highlight sdps-p-right_medium"></span>
                      <span class="sdps-p-right_medium">{{first['name']}}</span>
                      <span class="sdps-p-right_medium" *ngIf="first['name'] === emtrConst.ruleSteps.step2.operatingNetExpenseRatio">{{first['value']}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Miscellaneous Asset Groups Section -->
          <div
        *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.globalRuleSummary.uuid,'','') && (editReviewRuleData.rulesCategory === emtrConst.rulesConstant.autoComplete.RESTRICT_LST_ENGTxt || editReviewRuleData.override === 'Y') && (editReviewRuleData.micAGSelectedFlag) ">
        <div class="security-type-holder">
          <div class="security-selected-result">
            <div class="sdps-p-top_medium sdps-p-bottom_medium sdps-p-right_x-large sdps-display-inline-block"
                 *ngFor='let item of displaySelectedAssetGrp'>
                <span class="sch-check-circle assetGroup-highlight sdps-p-right_medium"></span>
                <span class="misc-asset-group-color" *ngIf="item.name !== 'Debt'">{{item['name']}}</span>
                <span class="misc-asset-group-color" *ngIf="item.name === 'Debt'">{{item['attributes'][0]['name']}}</span>
            </div>
          </div>
        </div>
      </div>
        </ems-accordion>
    </form>
</div>
<ems-step-wizard-buttons [showCancelBtn]="true" [showContinueBtn]="true" [disableBtn]="editReviewRuleForm?editReviewRuleForm.invalid:''" (goToNext)="goToNextStep()" (closeBtn)="cancelCreateRule()"></ems-step-wizard-buttons>

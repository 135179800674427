import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {HeaderService} from '@core_services/header/header.service';
import {AuthService} from '@core_services/auth/auth.service';
import {SharedService} from '@ems/shared';
import { entitlementConst } from '@shell_components/constants/entitlementConstants';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  menuList: any;
  hasRuleTab: any;
  shellConstant = ShellConstant;
  isPageLoad = true;

  constructor(private router: Router, private headerService: HeaderService, private activeRoute: ActivatedRoute,
              private authService: AuthService, public sharedService: SharedService) {
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      this.navigationMenu();
    });
  }

  ngOnInit() {
    if (this.authService.getUserPolicy() !== null) {
      this.hasRuleTab = this.sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '') || false;
      this.navigationMenu();
    }

    /* Making call to loadSpsPageCall when ever the page is reloaded */
    this.sharedService.loadSpsPageCall();

    /* Code to dynamically generate data-track value and call gutTracking */
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (this.sharedService.isLoadPageOnClientChange && !this.sharedService.isDTValueUpdated) {
        this.sharedService.isLoadPageOnClientChange = false;
        this.sharedService.gutTracking('linkid-nav_client_redirect');
        this.sharedService.isDTValueUpdated = true;
      }
      if (this.sharedService.isDTValueUpdated) {
        this.sharedService.isDTValueUpdated = false;
        /* loadSpsPageCall method has to be called after every gutTrack method call to update the data-track value. */
        this.sharedService.loadSpsPageCall();
      }
    });
  }

  private navigationMenu(): void {
    const entitlement = entitlementConst.navUUID;
    const menuItems: any = [
      {
        menuName: 'Workbench',
        routerLink: 'workbench',
        accessName: 'workbench',
        showLink: this.sharedService.checkEntitlements(entitlement.addLinkAccess.uuid, entitlement.emtrAccess.emtrAppAccess.uuid, '')
      },
      {
        menuName: 'ADDLink',
        routerLink: 'addLink',
        accessName: 'addLink',
        showLink: this.sharedService.checkEntitlements(entitlement.addLinkAccess.uuid, '', '')
      },
      {
        menuName: 'Schwab Trade Rules<sup>&#174</sup>',
        routerLink: 'emtr',
        accessName: 'emtrOrInstGrp',
        showLink: this.sharedService.checkEntitlements(entitlement.emtrAccess.ruleSummary.uuid, entitlement.emtrAccess.instrumentsSummary.uuid, '')
      },
      {
        menuName: 'Uploads',
        routerLink: 'uploads',
        accessName: 'uploads',
        showLink: this.sharedService.checkEntitlements(entitlement.addLinkAccess.uuid, entitlement.emtrAccess.emtrAppAccess.uuid, '')
      },
      {
        menuName: 'Reports',
        routerLink: 'reports',
        accessName: 'reports',
        showLink: this.sharedService.checkEntitlements(entitlement.addLinkAccess.uuid, entitlement.emtrAccess.emtrAppAccess.uuid, '')
      },
      {
        menuName: 'Admin',
        routerLink: 'admin',
        accessName: 'admin',
        showLink: this.sharedService.checkEntitlements(entitlement.manageClient.uuid, entitlement.manageUser.uuid, entitlement.manageRole.uuid)
      },
    ];

    this.menuList = menuItems;
  }
}

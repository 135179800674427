import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { commonConstants } from '@shell_components/constants/commonConstants';
import { ReportsService } from '@core_services/report/reports.service';
import { AppService } from '@core_services/app.service';
import { PresetReportsComponent } from '@shell_components/reports/preset-reports/preset-reports.component';
import {SharedConstant, SharedService} from '@ems/shared';
import { AddlinkService } from '@ems/addlink';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trade-attempts',
  templateUrl: './trade-attempts.component.html',
  styleUrls: ['./trade-attempts.component.scss']
})
export class TradeAttemptsComponent implements OnInit, OnDestroy {
  showEmpIdCharLimit: boolean;
  showTextboxCharLimit: boolean;
  getShellConstant = ShellConstant;
  reportDetails: UntypedFormGroup;
  getCommConst = commonConstants;
  fromDate: any;
  toDate: any;
  parent: PresetReportsComponent;
  tradeResultsDropDownData: any[];
  dateError: any;
  endDateLessThanStartDate = '';
  getOrgId: any;
  showClientMsg: any = true;
  reportName: any;
  sharedConstant = SharedConstant;
  businessUnit: any;
  dateRangeConstarint = '';
  private currentOrgSubscription: Subscription;

  constructor(public addlinkService: AddlinkService, private appService: AppService, private fb: UntypedFormBuilder, public reportService: ReportsService, private injector: Injector, private activatedRoute: ActivatedRoute, public sharedService: SharedService) {
    this.parent = this.injector.get(PresetReportsComponent);
    this.reportName = this.reportService.getReportName();
  }

  ngOnInit() {
    this.reportDetails = this.fb.group({
      dateTo: this.fb.group({
        date: ['', Validators.required],
      }),
      dateFrom: this.fb.group({
        date: ['']
      }),
      employeeID: '',
      employeeAccount: '',
      employeeLastName: '',
      tradeSymbol: '',
      cusipSymbol: '',
      tradeResultsDropDownData: ''
    });

    /*Freeze the global client selector when the report is selected*/
    this.reportService.freezeGlobalClientSelector(this.reportDetails);

    this.tradeResultsDropDownData = this.getShellConstant.reports.presetReports.tradeResultsDropDown;
    this.reportService.makeFormDirty(this.reportDetails, this.parent);
    this.onChanges();
    this.currentOrgSubscription = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.getOrgId = clientInfo && clientInfo.orgId;
        this.businessUnit = clientInfo && clientInfo.businessUnit;
      }
    });
    this.reportService.reportsArray.subscribe((data: any) => {
      if (data !== null) {
        data.map((res) => {
          if (res.name === this.reportName) {
            res.accessType === 'CLIENT' ? this.getOrgId > 0 ? this.showClientMsg = false : this.showClientMsg = true : this.showClientMsg = false;
          }
        });
      }
    });
  }

  setDate(event) {
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
    this.dateError = event.dateError;
  }

  throwDateRangeError(event) {
    this.dateRangeConstarint = event;
    this.parent.throwDateRangeError(event);
  }
  calendarValidation(event) {
    this.parent.calendarValidation(event);
  }

  throwDateError(event) {
    this.endDateLessThanStartDate = event;
    this.parent.throwDateError(event);
  }

  fetchEmpIdAndTradeStatus() {
    const tradeStatus = this.reportDetails.get('tradeResultsDropDownData').value;
    let status = '';
    status = tradeStatus === 'Allowed' ? 'Y' : tradeStatus === 'Blocked' ? 'N' : 'BOTH';
    return status;
  }

  runReports() {
    const localInput = {
      employeeId: this.reportDetails.get('employeeID').value ? this.reportDetails.get('employeeID').value.trim() : '',
      accountNumber: this.reportDetails.get('employeeAccount').value ? this.reportDetails.get('employeeAccount').value.trim() : '',
      employeeLastName: this.reportDetails.get('employeeLastName').value ? this.reportDetails.get('employeeLastName').value.trim().toUpperCase() : '',
      cusip: this.reportDetails.get('cusipSymbol').value ? this.reportDetails.get('cusipSymbol').value.trim() : '',
      symbol: this.reportDetails.get('tradeSymbol').value ? this.reportDetails.get('tradeSymbol').value.trim() : '',
      endDate: encodeURIComponent(this.reportService.formatDate(this.toDate)),
      startDate: encodeURIComponent(this.reportService.formatDate(this.fromDate)),
      tradeResult: this.fetchEmpIdAndTradeStatus(),
      orgId: this.reportService.orgId,
      clientName: this.reportService.clientName,
      clientId: this.reportService.clientId
    };
    this.reportService.executeReports(this.parent, localInput, this.reportService.selectedReport, this.getShellConstant.reports.presetReports.reportNames.tradeAttemptTxt, this.getShellConstant.reports.sendReportAPI.tradeAttempt,
      this.getShellConstant.reports.presetReports.reportNames.tradeAttemptsReportName, this.appService, '');
  }

  onChanges() {
    this.reportService.formErrorMessage(this.reportDetails, this.parent, '', 'employeeID', this.getShellConstant.reports.presetReports.employeeIdErrorMsg);
    this.reportService.formErrorMessage(this.reportDetails, this.parent, '', 'employeeLastName', this.getShellConstant.manageClients.clientDetailsPage.errorMsg.invalidCharacterEnteredAlphabeticValue);
    this.reportService.formCharLimitErrorMessage(this.reportDetails, this.parent, 'cusipSymbol', 9, this.sharedConstant.textFieldErrorMsg.cusipShouldContain9Digits);
    this.reportService.formCharLimitErrorMessage(this.reportDetails, this.parent, 'employeeAccount', 8, this.sharedConstant.textFieldErrorMsg.accountNumberShouldContain8Digits);
    this.reportDetails.get('tradeSymbol').valueChanges.subscribe(tradeSymbolValue => {
      this.reportService.invalidSymbol = tradeSymbolValue && this.reportDetails.get('tradeSymbol').invalid;
      this.reportService.disableReportField(this.reportDetails, tradeSymbolValue, 'cusipSymbol');
      this.parent.msgToast.pushMessage('warn', this.sharedConstant.textFieldErrorMsg.reportWarnMsg);
      if (!tradeSymbolValue) {
        this.parent.msgToast.pushMessage('', '');
      }
    });
    this.reportDetails.get('cusipSymbol').valueChanges.subscribe(cusipSymbolValue => {
      this.reportService.invalidCusip = cusipSymbolValue && this.reportDetails.get('cusipSymbol').invalid;
      this.reportService.disableReportField(this.reportDetails, cusipSymbolValue, 'tradeSymbol');
      this.parent.msgToast.pushMessage('warn', this.sharedConstant.textFieldErrorMsg.reportWarnMsg);
      if (!cusipSymbolValue) {
        this.parent.msgToast.pushMessage('', '');
      }
    });
  }

  cancelReport() {
    this.reportService.cancelReport(this.reportDetails, this.parent);
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.reportService.invalidSymbol = this.reportService.invalidCusip = false;
    this.parent.clearErrorMsg();
    this.parent.reportFormIsDirty = false;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import { ConfigUrlService, SharedService } from '@ems/shared';



@Injectable()
export class LinkService {

  public env: any;
  configURL = this.configService.setApplicationUrl();

  constructor(@Inject('env') public e, private http: HttpClient, private configService: ConfigUrlService, public sharedService: SharedService) {
    this.env = e;
  }

  public saveMultiLinkLinkage(requestObject: any, orgId: any, jobId: string, liable407: string, fileAsBytes: any, is10b5Client: any): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    const url = this.configURL.bffAddlinkRequestEndPointV1;
    const masterAccountNbrList = [];
    const masterAccountNbrTypeList = [];
    const selectedFileAsBytes = [];
    const remarks: any = [];
    const localPlanId = 'planId';
    const masterAccounts: any = requestObject.masterAccounts;
    let accountHolderAffiliation = requestObject.affiliation[0];
    let hideFromClient = 'N';
    let validIndex = '';

    if (liable407.toLowerCase() !== 'n') {
      validIndex = requestObject.indexNumber;
    }

    if (requestObject.doNotShowRemarkToClient) {
      hideFromClient = 'Y';
    }

    remarks.push({ remark: requestObject.remarks, hideFromClient });

    for (const masterAccount of masterAccounts) {
      masterAccountNbrList.push(masterAccount.value);
      if (masterAccount.accountType.includes('SL')) {
        masterAccountNbrTypeList.push('SL');
      } else {
        masterAccountNbrTypeList.push('FA');
      }
    }

    if (accountHolderAffiliation == null) {
      accountHolderAffiliation = '';
    } else {
      accountHolderAffiliation = accountHolderAffiliation.value;
    }

    const body = {
      action: 'LINK',
      orgId,
      authAgentId: requestObject.authAgent.length > 0 ? requestObject.authAgent[0].value : '',
      masterAccountNbrList,
      masterAccountNbrTypeList,
      acctNumber: requestObject.accountNumber,
      employeeId: requestObject.employeeID,
      employeeFirstName: requestObject.firstName.trim(),
      employeeLastName: requestObject.lastName.trim(),
      accountOwnerFirstName: requestObject.accountHolderFirstName.trim(),
      accountOwnerLastName: requestObject.accountHolderLastName.trim(),
      accountHolderAffiliation,
      acctHolderEmpMatchCheck: requestObject.accountHolderSameAsEmployee,
      remarks,
      indexKey: validIndex,
      jobId,
      source: 'ADDLink(DBS)',
      file: selectedFileAsBytes
    };

    if (is10b5Client) {
      body[localPlanId] = requestObject.planId.trim();
    }
    if (orgId && orgId !== 0) {
      return this.http.post(url + '&Schwab-Client-Ids=' + schwabClientHeader, body);
    } else {
      return this.http.post(url, body);
    }
  }
}

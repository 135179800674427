import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SharedConstant } from '../../constants/sharedConstants';
import {
  empGrpOrPlanTableSubHeader, EmpTableHead,
  masterGroupHeaderTableColoumn
} from '../../models/manage-employee-group-table-header';
import { SharedService } from '../../shared.service';
import * as temp from 'moment';
const moment = temp['default'];
import { MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import {
  ManageEmployeeGroupsListActionMenuItems,
  ManageEmployeeGroupsListHistoryActionMenu
} from '../../models/manage-employee-group-action-menu';
import { DateRangeFilterComponent } from '../date-range-filter/date-range-filter.component';
import { CustomSortService } from '../../services/custom-sort/custom-sort.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'ems-shared-master-groups',
  templateUrl: './shared-master-groups.component.html',
  styleUrls: ['./shared-master-groups.component.scss']
})
export class SharedMasterGroupsComponent implements OnInit, OnDestroy, OnChanges {

  @Output() paginateMasterGrpList: any = new EventEmitter();
  @Output() sendError: any = new EventEmitter();
  @Output() deleteEmployeeGroup: any = new EventEmitter();
  @Output() employeeGroups: any = new EventEmitter();
  @Output() rowSelected: any = new EventEmitter();
  @Output() selectedMasterGrp: any = new EventEmitter();
  @Output() masterGrpEdit: any = new EventEmitter();
  @Input() showMGPSelection: any;
  @Output() deleteMasterGroup: any = new EventEmitter();
  @Output() restoreMasterGroup: any = new EventEmitter();
  @Input() masterAcctList: any[];
  @Input() mstrGrpformData: any;
  @Input() tableCountData: any = {
    totalRecords: 0,
    paginationCount: 0
  };
  @Input() showActionBtn: any;
  @Input() isStateManagementRequired = false;
  @ViewChild('p', { static: true }) paginator: Paginator;
  @ViewChild('range') rangeCalendar: DateRangeFilterComponent;
  @ViewChild('viewHistory', { static: true }) viewHistoryData: any;
  @ViewChild('masGrpDeleteConfirm') masGrpDeleteConfirm: any;
  currentOrgSubscription: Subscription;
  masterGroupForm: UntypedFormGroup;
  getSharedConstant = SharedConstant;
  masterGroupsTableCol = masterGroupHeaderTableColoumn;
  groupSubHeader: EmpTableHead[] = empGrpOrPlanTableSubHeader;
  sortField: any;
  pageNo: any = 1;
  multiSortMeta: any = [];
  param = {
    pageNo: 1,
    pageSize: 15,
    sortField: ''
  };
  defaultObj: any;
  noOfRecords: any = 15;
  moment = moment;
  empId: any;
  actionMenu: any[];
  showActionMenu: boolean;
  showActionItems = false;
  toggle = false;
  businessUnit: any;
  filterBtnDisableForName = false;
  filterClicked = false;
  filterSelectedDate: any;
  schError = {
    minCharacter: false,
    invalidCharacter: false
  };
  errMsg = [];
  orgId: any;
  formFilterObj: any;
  selectedArr: any = [];
  unAssignedGrps: any[] = [];
  masterGrpHistoryRecord = {
    historyId: '',
    masterGrpSubType: '',
    masterGrpName: '',
    masterGrpHistory: '',
    historyRecords: ''
  };
  showSpinnerFlag: any = {};
  processID: any = [];
  showErrorFlag: any = {};
  wholeRecordArr: any = [];
  intervalJobs: any = {};
  showUpdatedCount = {
    data: {
      noOfRules: null,
      noOfSubAccounts: null
    }
  };
  selection = false;
  unAssignedGrpsArray: any[] = [];
  empAcc: any;
  assignFormData: any;
  isSortingChanged = false;
  defaultSortDataField = {field: 'updTs', order: -1};
  stateVal: any;
  initialLoad = false;
  constructor(public sharedService: SharedService, public fb: UntypedFormBuilder, @Inject('entitlementConst') public entitlementConst, public customSortService: CustomSortService, private router: Router, public messageService: MessageService) {
    this.sharedService.setHistoryResponse.subscribe(value => {
      this.masterGrpHistoryRecord.masterGrpHistory = value.data.content;
      this.masterGrpHistoryRecord.historyRecords = value.data.totalElements;
    });
  }

  ngOnInit() {
    this.masterGroupForm = this.fb.group({
      groupName: [''],
      groupDescription: [''],
      updTs: [''],
      showRecords: this.getSharedConstant.showRecords[0]
    });

    this.stateManageMentPatch();
    this.currentOrgSubscription = this.sharedService.clientInformation.subscribe(clientInfo => {
      this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageMasterAccountGroup.uuid, '', '');
      if (clientInfo && (!this.stateVal)) {
        this.resetForm();
      }
    });
    this.customSortService.sortArr = [];
    if (this.mstrGrpformData) {
      this.mstrGrpformData.filter(data => this.selectedArr.push(data));
      this.unAssignedGrps = this.selectedArr;
    }
    this.onChanges();
    this.initialLoad = true;
    if (this.selectedArr.length > 0) {
      this.emitSelectedMasterGrp();
    }
    this.resetFilterForm();
  }

  resetFilterForm() {
    this.sharedService.resetFilterForm.subscribe(val => {
      /* if user changes the tab then it will reset the filter form for previous active tab but won't make any API call for that */
      if (val.tabChange && this.masterGroupForm.dirty) {
        this.masterGroupForm.reset();
        this.sharedService.clearValidationFields();
        this.masterGroupForm.markAsPristine();
        this.masterGroupForm.updateValueAndValidity();
        this.filterBtnDisableForName = false;
      }
      /* if user clicks to previous active tab then only it will make API call. API call will happen when toggle has been clicked. */
      if (val.tabName === this.sharedService.getSharedConstant.MGP.employeeGrpOrPlan && (this.toggle || this.filterClicked)) {
        this.toggle = false;
        this.filterClicked = false;
        const noOfRecords = this.param.pageSize;
        this.paginateMasterGrpList.emit({
          paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
          filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
        });
      }
    });
  }

  stateManageMentPatch() {
    if (this.isStateManagementRequired) {
      this.stateVal = this.sharedService.getStateVal();
      this.formFilterObj = this.stateVal && this.stateVal.filterObj || null;
      this.filterClicked = this.stateVal && this.stateVal.filterClicked;
      this.toggle = this.stateVal && this.stateVal.toggle;
      this.stateVal && this.stateVal.multiSortMeta ? this.multiSortMeta = this.stateVal.multiSortMeta : this.multiSortMeta.push(this.defaultSortDataField);
      this.pageNo = this.stateVal && this.stateVal.pageObj && this.stateVal.pageObj.pageNo ? this.stateVal.pageObj.pageNo : 0;
      this.param.pageNo = this.stateVal && this.stateVal.pageObj.pageNo ? this.stateVal.pageObj.pageNo : 0;
      this.param.pageSize = this.stateVal && this.stateVal.pageObj.pageSize ? this.stateVal.pageObj.pageSize : 15;
      this.noOfRecords = this.stateVal && this.stateVal.pageObj.pageSize ? this.stateVal.pageObj.pageSize : 15;
      this.sharedService.tableDropdownVal.next(this.stateVal ? this.stateVal.pageObj.pageSize : 15);
      if (this.filterClicked && this.toggle) {
        this.patchFormValues(this.formFilterObj);
      } else {
        this.toggle = false;
        this.masterGroupForm.reset();
        this.masterGroupForm.get('showRecords').setValue(this.getSharedConstant.showRecords[0]);
        this.updateFormFilterObject();
      }
    }
  }

  patchFormValues(formVals) {
    if (formVals) {
      this.sharedService.updatedDate.next(formVals.lastUpdatedFromDate ? {name: 'Require Indexing', fromDate: formVals.lastUpdatedFromDate, toDate: formVals.lastUpdatedToDate } : '');
      this.masterGroupForm.patchValue({
        groupName: formVals.groupName ? formVals.groupName : '',
        groupDescription: formVals.groupDescription ? formVals.groupDescription : '',
        showRecords: formVals.active ? this.getSharedConstant.showRecords[0] : this.getSharedConstant.showRecords[1]
      });

      this.masterGroupForm.markAsDirty();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.masterAcctList && this.masterAcctList.length > 0 && this.getSharedConstant.manageMasterGroups.groupTypeMGP === 'MGP') {
      this.wholeRecordArr.forEach((data) => {
        window.clearInterval(this.intervalJobs[data.id]);
      });
      this.showSpinner();
    }
  }

  onChanges() {
    this.masterGroupForm.get('groupName').valueChanges.subscribe(value => {
      if (value) {
        if (value.length < 2) {
          this.filterBtnDisableForName = true;
          this.schError.minCharacter = true;
        } else {
          this.filterBtnDisableForName = false;
          this.schError.minCharacter = false;
        }
      } else {
        this.schError.minCharacter = false;
        this.filterBtnDisableForName = false;
      }
      this.sharedService.validationFields.filterBtnDisableForGrpName = this.filterBtnDisableForName;
      this.sharedService.setErrorMessage(this.sharedService.isAccountNumInvalid, this.errMsg);
      this.sendError.emit(this.schError);
    });
  }

  toggleFunction() {
    if (!this.showMGPSelection) {
      this.sharedService.gutTracking('adobe-fl_managemastergroupsfilter|fireonce');
    }
    this.toggle = !this.toggle;
    if (this.isStateManagementRequired && this.toggle) {
      this.stateVal = this.sharedService.getStateVal();
      this.updateFormFilterObject();
      this.updateStateValues(this.formFilterObj);
    }
  }

  defaultFiltering() {
    this.defaultObj = {
      groupName: '',
      groupDescription: '',
      lastUpdatedFromDate: '',
      lastUpdatedToDate: '',
      type: this.getSharedConstant.manageMasterGroups.groupTypeMGP,
      active: true
    };
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    this.paginateMasterGrpList.emit({
      paramObj: this.paramObj(this.pageNo, this.noOfRecords, this.sortField),
      filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
    });
  }

  paramObj(pageNo, records, sortCol) {
    return this.param = {
      pageNo,
      pageSize: records,
      sortField: sortCol
    };
  }

  paginate(e) {
    if (this.pageNo !== e.page + 1) {
      this.defaultObj = {
        orgId: 0,
        groupName: '',
        groupDescription: '',
        lastUpdatedFromDate: '',
        lastUpdatedToDate: '',
        type: this.getSharedConstant.manageMasterGroups.groupTypeMGP,
        active: true
      };
      this.pageNo = e.page + 1;
      this.sortField = this.param.sortField;
      const noOfRecords = this.param.pageSize;
      this.sharedService.clearSuccessMsgs(this.messageService, this.showMGPSelection);
      this.paginateMasterGrpList.emit({
        paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
        filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
      });
      this.updateStateValues(this.filterClicked ? this.formFilterObj : this.defaultObj);
    }
  }

  clickedClearButton(resetSortFromUI?) {
    this.resetForm(resetSortFromUI);
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    if (!resetSortFromUI) {
      this.defaultFiltering();
    }
  }

  resetForm(resetSortFromUI?) {
    this.filterClicked = false;
    if (resetSortFromUI) {
      this.sortField = this.customSortService.customSort({multiSortMeta: this.multiSortMeta});
      this.param.sortField = this.sortField;
      this.multiSortMeta = [this.defaultSortDataField];
    }
    if (this.masterGroupForm) {
      this.masterGroupForm.reset();

      /* Set 'Active' as default value for Show Records dropdown */
      this.masterGroupForm.get('showRecords').setValue(this.getSharedConstant.showRecords[0]);
      this.sharedService.deletedMastGrp = false;

      this.paginator.changePage(0);
      if (this.toggle) {
        this.rangeCalendar.clearSelection();
      }
    }
    this.filterBtnDisableForName = false;
    this.masterGroupForm.markAsPristine();
    this.updateFormFilterObject();
    this.updateStateValues(this.formFilterObj);
  }

  getSelectedDate(date) {
    this.filterSelectedDate = date;
    this.masterGroupForm.get('updTs').setValue(this.filterSelectedDate);
    if (this.filterSelectedDate !== '' && this.filterSelectedDate !== null && this.filterSelectedDate !== undefined) {
      this.masterGroupForm.markAsDirty();
    }
  }

  onActionMenuClick(event: any, rowData: any) {
    const actionItem = event.target.parentElement.innerText;
    this.clearSuccessAndErrorMessages();
    if (actionItem === 'Delete') {
      this.deleteEmpGroup(rowData);
    } else if (actionItem === 'History') {
      this.loadMstrGrpHistory(rowData);
    } else if (actionItem === 'Restore') {
      this.restoreMstrGroup(rowData);
    }
  }

  deleteEmpGroup(grpData) {
    this.deleteMasterGroup.emit({
      groupData: grpData,
      filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj,
      paramObj: this.param
    });
  }

  restoreMstrGroup(grpData) {
    this.restoreMasterGroup.emit({
      groupData: grpData,
      filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj,
      paramObj: this.param
    });
  }

  receiveDataFromChild(e) {
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    this.noOfRecords = Number(e);
    this.sharedService.clearSuccessMsgs(this.messageService, this.showMGPSelection);
    this.paginateMasterGrpList.emit({
      paramObj: this.paramObj(this.pageNo, this.noOfRecords, this.sortField),
      filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
    });
    this.updateStateValues(this.filterClicked ? this.formFilterObj : this.defaultObj);
  }

  @HostListener('document:click', [])
  onClick() { /* Capture the Click Event outside the function */
    if (!this.showActionMenu) {
      this.empId = '';
    }
    this.showActionMenu = false;
  }

  formData() {
    const actionItem = this.showActionItems ? ManageEmployeeGroupsListActionMenuItems : ManageEmployeeGroupsListHistoryActionMenu;
    if (this.sharedService.deletedMastGrp) {
      this.actionMenu = actionItem.filter(item => item.label !== 'Delete');
    } else {
      this.actionMenu = actionItem.filter(item => item.label !== 'Restore');
    }
    return this.actionMenu;
  }

  applyFilter() {
    if (this.rangeCalendar) {
      this.rangeCalendar.applyToDate();
      this.getSelectedDate(this.rangeCalendar.dateRangeForm.get('dateSelected').value);
    }
    this.filterClicked = true;
    this.updateFormFilterObject();
    this.updateformValues(this.formFilterObj);
    this.paginator.changePage(0);
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    this.sharedService.orgIdValue = 0;
    this.clearSuccessAndErrorMessages();
    this.paginateMasterGrpList.emit({
      paramObj: this.paramObj(this.pageNo, this.noOfRecords, this.sortField),
      filterObj: this.formFilterObj
    });
    this.updateStateValues(this.formFilterObj);
  }

  updateFormFilterObject() {
    const localUpdatedFormValues = this.masterGroupForm.getRawValue();
    const fromDate = [];
    if (this.filterSelectedDate !== undefined && this.filterSelectedDate !== '' && this.filterSelectedDate !== null) {
      this.filterSelectedDate.forEach(date => {
        fromDate.push(date);
      });
    }
    this.formFilterObj = {
      orgId: 0,
      groupName: localUpdatedFormValues.groupName ? localUpdatedFormValues.groupName : '',
      groupDescription: localUpdatedFormValues.groupDescription ? localUpdatedFormValues.groupDescription : '',
      lastUpdatedFromDate: fromDate.length > 0 ? moment(fromDate[0]).format('MM/DD/YYYY') : '',
      lastUpdatedToDate: fromDate.length > 0 ? moment(fromDate[1]).format('MM/DD/YYYY') : '',
      type: this.getSharedConstant.manageMasterGroups.groupTypeMGP,
      active: localUpdatedFormValues.showRecords ? localUpdatedFormValues.showRecords.value : '',
    };
  }

  updateformValues(formVals) {
    this.sharedService.deletedMastGrp = formVals && !formVals.active;
    this.sharedService.updatedDate.next(formVals.lastUpdatedFromDate ? {
      name: 'Require Indexing',
      fromDate: formVals.lastUpdatedFromDate,
      toDate: formVals.lastUpdatedToDate
    } : '');
  }

  customSort(event) {
    this.defaultObj = {
      orgId: 0,
      groupName: '',
      groupDescription: '',
      lastUpdatedFromDate: '',
      lastUpdatedToDate: '',
      type: this.getSharedConstant.manageMasterGroups.groupTypeMGP,
      active: true
    };

    /*this line can be enabled in order to empty slected list while sorting*/
    if (event.multiSortMeta) {
      if (JSON.stringify(this.sortField) !== JSON.stringify(this.customSortService.customSort(event))) {
        this.sortField = this.customSortService.customSort(event);
        this.param.sortField = this.sortField;
        const noOfRecords = this.param.pageSize;
        this.multiSortMeta = event.multiSortMeta;
        this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
        if (this.initialLoad) {
          if (this.stateVal && this.stateVal.pageObj && this.stateVal.pageObj.pageNo && this.isStateManagementRequired) {
            this.paginateMasterGrpList.emit({
              paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
              filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
            });
            setTimeout(() => {
              this.paginator.totalRecords = this.stateVal.totalElem ? this.stateVal.totalElem : this.tableCountData.totalRecords;
              this.paginator.changePage(this.stateVal.pageObj.pageNo - 1);
              this.initialLoad = false;
            }, 0);
          } else {
            this.pageNo = this.param.pageNo;
            this.paginateMasterGrpList.emit({
              paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
              filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
            });
            this.paginator.changePage(0);
            this.initialLoad = false;
          }
        } else {
          this.pageNo = 1;
          this.sharedService.clearSuccessMsgs(this.messageService, this.showMGPSelection);
          this.paginateMasterGrpList.emit({
            paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
            filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
          });
          this.paginator.changePage(0);
        }
        this.updateStateValues(this.filterClicked ? this.formFilterObj : this.defaultObj);
      }
    }
  }

  updateStateValues(filterObj) {
    if (this.isStateManagementRequired) {
      /* Updating State Values for paginator to support page Val for State management*/
      this.sharedService.stateValues.totalElem = this.sharedService.stateValues.totalElem ? this.sharedService.stateValues.totalElem : this.tableCountData.totalRecords;
      this.sharedService.stateValues.pageObj.pageNo = this.pageNo;
      this.sharedService.stateValues.pageObj.pageSize = this.param.pageSize;
      this.sharedService.stateValues.multiSortMeta = this.multiSortMeta;
      this.sharedService.stateValues.toggle = this.toggle;
      this.sharedService.stateValues.filterClicked = this.filterClicked;
      this.sharedService.stateValues.filterObj = filterObj;
      this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
      this.stateVal = this.sharedService.getStateVal();
      this.sharedService.tableDropdownVal.next(this.stateVal ? this.stateVal.pageObj.pageSize : this.param.pageSize);
    }
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    if (this.router.routerState.snapshot.url.indexOf(this.getSharedConstant[this.getSharedConstant.manageMasterGroups.groupTypeMGP].mainRoute) === -1 && this.isStateManagementRequired) {
      this.sharedService.stateValues.multiSortMeta = '';
      this.sharedService.stateValues.pageObj.pageSize = 15;
      this.sharedService.stateValues.pageObj.pageNo = 1;
      this.sharedService.stateValues.filterClicked = false;
      this.sharedService.stateValues.toggle = false;
      this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
      this.sharedService.clearLocalStorage();
      this.sharedService.tableDropdownVal.next(15);
    }
  }

  loadMstrGrpHistory(masterGrpData) {
    this.masterGrpHistoryRecord.historyId = masterGrpData.id;
    this.masterGrpHistoryRecord.masterGrpSubType = masterGrpData.type;
    this.masterGrpHistoryRecord.masterGrpName = this.getSharedConstant.manageMasterGroups.masterGrpHistoryTxt + ' - ' + masterGrpData.name;
    this.sharedService.loadViewHistory(0, masterGrpData, 'empgroup', this.viewHistoryData);
  }

  getHistoryPagination(id, data) {
    this.sharedService.getHistoryPaginatedValues(id, 'empgroup', data);
  }

  showSpinner() {
    this.masterAcctList.forEach((process) => {
      let count = 0;
      if (process.assignStatus && process.assignStatus.toLowerCase() === 'inprogress') {
        this.processID.push(process.id);
        const localObj = { id: process.id, count };
        this.showSpinnerFlag[process.id] = true;
        this.showErrorFlag[process.id] = false;
        this.wholeRecordArr.push(localObj);
        this.intervalJobs[process.id] = setInterval(() => {
          this.loadRecords(process.id, this.wholeRecordArr);
          count++;
          this.wholeRecordArr.forEach((data) => {
            if (data.id === process.id) {
              data.count = count;
              if (data.count === 5) {
                window.clearInterval(this.intervalJobs[process.id]);
              }
            }
          });
        }, 5000);
      }
    });
  }

  loadRecords(id, arr) {
    this.sharedService.getEmpGroupData(id).subscribe((empGrp: any) => {
      const localCount = this.wholeRecordArr.filter((val) => val.id === id);
      if (empGrp.data.assignStatus && empGrp.data.assignStatus.toLowerCase() === 'completed') {
        window.clearInterval(this.intervalJobs[id]);
        this.showUpdatedCount.data = empGrp.data;
        this.updateCount(id, 'Completed');
      } else if (empGrp.data.assignStatus && empGrp.data.assignStatus.toLowerCase() === 'failed') {
        window.clearInterval(this.intervalJobs[id]);
        this.updateCount(id, 'Failed');
      } else if (empGrp.data.assignStatus && empGrp.data.assignStatus.toLowerCase() === 'inprogress' && localCount[0].count === 5) {
        window.clearInterval(this.intervalJobs[id]);
        this.updateCount(id, 'Inprogress');
      }

    }, error => {
      this.updateCount(id, 'Error');
    });
  }

  updateCount(id, param) {
    this.wholeRecordArr.forEach((record) => {
      if (record.id === id && param === 'Completed') {
        this.showSpinnerFlag[id] = false;
        this.showErrorFlag[id] = false;
      } else {
        this.showErrorFlag[id] = true;
        this.showSpinnerFlag[id] = false;
      }
    });
  }

  grpSelected(data, event) {
    if (data) {
      if (data.type === 'ORG') {
        this.selectedArr = [];
        /* eslint-disable-next-line max-len */
        event.target.matches('.p-highlight') ? this.selectedArr.push(data) : this.selectedArr.splice(this.selectedArr.indexOf(data), 1);
      } else {
        const index = this.selectedArr.map(x => x.type).indexOf('ORG');
        if (index > -1) {
          const localArr = this.selectedArr;
          localArr.splice(index, 1);
          this.selectedArr = [...localArr];
        }
      }
    } else {
      this.selection = !this.selection;
      /* eslint-disable-next-line max-len */
      if (!this.selection) {
        this.selectedArr = [];
      }
      const index = this.selectedArr.map(x => x.type).indexOf('ORG');
      if (index > -1) {
        const localArr = this.selectedArr;
        localArr.splice(index, 1);
        this.selectedArr = [...localArr];
      }
    }
    const unAssignedGrpArray = this.unAssignedGrps.filter(x => this.selectedArr.findIndex(y => x.id === y.id) === -1);
    this.unAssignedGrpsArray = [];
    unAssignedGrpArray.forEach(grp => {
      this.unAssignedGrpsArray.push(grp.id);
    });
    const emitData = {
      selectedArr: this.selectedArr,
      unassignedEmpGroup: this.unAssignedGrpsArray
    };
    this.employeeGroups.emit(emitData);
    this.rowSelected.emit();
  }

  getDatafromParent(e) {
    this.empAcc = e;
    const index = this.selectedArr.map(x => x.type).indexOf('ORG');
    if (e && e.length >= 1 && index > -1) {
      this.selectedArr = [];
      this.getSelectedCount();
    } else {
      this.getSelectedCount();
    }
  }

  getSelectedCount() {
    let partialSelected;
    const localEmpId = [];
    if (this.empAcc && this.empAcc.length > 0) {
      this.empAcc.forEach(data => {
        if (data) {
          /* Edit Scenario partialSelected will be undefined so setting it to true */
          partialSelected = data ? data : false;
          if (partialSelected && data !== null && localEmpId.indexOf(data) === -1) {
            localEmpId.push(data);
          }
        }
      });
    }
  }

  emitSelectedMasterGrp() {
    this.selectedArr.forEach((newSelected) => {
      newSelected.selectedAcct = true;
    });
    this.selectedMasterGrp.emit(this.selectedArr);
  }

  editMasterGrp(rowData) {
    this.masterGrpEdit.emit(rowData);
  }

  clearSuccessAndErrorMessages() {
    this.sharedService.clearSuccessMsgs(this.messageService, this.showMGPSelection); 
    this.sharedService.clearErrorMessage();
  }
}

<ems-toast-message #toastMessage></ems-toast-message>

<sdps-notification type="error" class="sdps-m-top_none" *ngIf="filterBtnDisable">
  <div class="sdps-notification__text">
    <p [innerHTML]="shellConstant.manageUserPage.errorMsg.pleaseCorrectFollowingError"></p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngIf="filterBtnDisable"
          [innerHTML]="shellConstant.manageUserPage.managerUserFilterErrorMsgs.minCharLimitErrorMsg">
      </li>
    </ul>
  </div>
</sdps-notification>

<h2 class="page-title sdps-m-bottom_large sdps-display-inline-block"
    [innerHTML]="shellConstant.manageClients.mangeClientList.manageClientsLabel"></h2>

<span class="sdps-m-horizontal_large sdps-display-inline-block client-selection-indicator"
      [ngClass]="this.externalAccess ? 'external-access' : (addlinkService.clientInformation | async)?.businessUnit | lowercase"
        [innerHTML]="(addlinkService.clientInformation | async)?.k4ClientName">
</span>

<ems-service-error *ngIf="sharedService.errorMessage | async"></ems-service-error>

<div>
  <button *ngIf="displaySetupNewClient" type="button" class="buttons button-save sdps-pull-left sdps-m-top_small sdps-m-left_small"
          (click)="navigateToSetupNewClient()">
    <em class="pi pi-plus"></em>
    {{shellConstant.manageClients.mangeClientList.setupNewClient}}
  </button>

  <ems-table-record-count class="tbl-rec-count" [count]="totalClients" [showTotal]="totalClients"
                          (emitter)="pageObject.pageNo = 0;pageObject.pageSize = $event;applyFilter()"
                          [showHeaderIcons]="true" [storedVal] = 'tablePageSize'></ems-table-record-count>

  <form [formGroup]="manageClientListForm">
  <p-table [columns]="manageClientListTableCols" [value]="clientList" dataKey="id" [customSort]="true"
           metaKeySelection="true" [lazy]="true" (onLazyLoad)="customSort($event)" [multiSortMeta]="multiSortMeta"
           sortMode="multiple" class="common-data-table manage-client-list-table custom-table-filter">
    <ng-template pTemplate="header" let-columns>
      <tr class="filter-header-input">
        <th class="table-checkbox-selection filter-icon" *ngIf="this.getOrgId === 0">
          <a (click)="sharedService.gutTracking('adobe-fl_manageclientsfilter|fireonce'); toggleFctn()" class="cursor">
            <em class="pi pi-filter"></em>
          </a>
        </th>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="col.field !== 'actions'" [pSortableColumn]="col.field" (click)="getOrgId === 0 ? clearSuccessAndErrorMessages() : ''"
            [pSortableColumnDisabled]="col.field === 'masterAccount' || this.getOrgId !== 0"
            [ngClass]="{'w-13-per': col.header === shellConstant.manageClients.clientDetailsPage.clientRegName}">
            {{col.header}}
            <p-sortIcon [field]="col.field" *ngIf="col.field !=='masterAccount' && col.field !=='actions' && this.getOrgId === 0"></p-sortIcon>
          </th>
          <th *ngIf="col.field === 'actions' && toggle && this.getOrgId === 0" [ngClass]="'w-200-px'">
            {{col.header}}
          </th>
        </ng-container>
      </tr>
      <tr *ngIf="this.toggle && this.getOrgId === 0" class="filtered-row-input">
        <th></th>
        <th *ngFor="let col of columns ; let i = index;" class="sdps-p-left_small">
          <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="filter" (keydown.enter)="!(filterBtnDisable) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
            onkeypress="return (event.which >= 48 && event.which <= 57)" *ngIf="col.field === 'custId'"
            [ngClass]="{'disabled': disableCustId, 'invalidField': (manageClientListForm.get('custId').value && filterBtnDisable)}" [readOnly]="disableCustId" [maxLength]="10">

          <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="filter" (keydown.enter)="!(filterBtnDisable) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                 onkeypress="return (event.which >= 48 && event.which <= 57)" *ngIf="col.field === 'masterAccount'"
                 [ngClass]="{'disabled': disableK4Master, 'invalidField': (manageClientListForm.get('masterAccount').value && filterBtnDisable)}" [readOnly]="disableK4Master" [maxLength]="8">

          <!-- Integrated Primeng Date-Range Component -->
          <ems-date-range-filter #range [disableForm]="filterState === 'disable'" (submittedDate)="getSelectedDate($event);" *ngIf="col.enum === 'calendar'">
          </ems-date-range-filter>

          <div class="timePickerForm" *ngIf="col.enum === 'dropdown' && col.field !== 'businessUnit'">
            <p-dropdown [options]="shellConstant.manageClients.mangeClientList[col.field]" placeholder=" "
              formControlName="{{col.field}}" class="sdps-p-top_small editableTimepicker request-list-header-dropdown"
              optionLabel="label">
            </p-dropdown>
          </div>

          <div class="timePickerForm" *ngIf="col.enum === 'dropdown' && col.field === 'businessUnit'">
            <p-dropdown [options]="userBUData" placeholder=" "
                        formControlName="{{col.field}}" class="sdps-p-top_small editableTimepicker request-list-header-dropdown"
                        optionLabel="label">
            </p-dropdown>
          </div>
          <div *ngIf="col.field === 'actions'" class="w-200-px">
            <button class="buttons button-filter" [innerHTML]="getAdlConst.requestListFilterButtons.clearAllButton"
              [disabled]="!manageClientListForm.dirty && !isSortingChanged"
              (click)="isClickedClearBtn = true;filterClicked? clickedClearBtn(isSortingChanged) : resetForm(isSortingChanged); clearSuccessAndErrorMessages();"></button>
            <button class="buttons button-save button-filter" [disabled]="filterBtnDisable"
              [innerHTML]="getAdlConst.requestListFilterButtons.applyFilterButton" (click)="applyFilter()"></button>
          </div>
        </th>
      </tr>

      <!-- Sub-header header section -->
      <tr *ngIf="this.toggle && this.getOrgId === 0" class="filter-header-input">
        <th class="table-checkbox-selection filter-icon"></th>
        <th></th>
        <th *ngFor="let col of manageClientListTableSubHeaderCols">
          {{col.header}}
        </th>
        <th class="filter-action"></th>
      </tr>

      <!-- Sub-header filter field section -->
      <tr *ngIf="this.toggle && this.getOrgId === 0" class="filtered-row-input">
        <th></th>
        <th></th>
        <th class="sdps-p-left_small">
          <div class="timePickerForm emtr-dropdown access-type-dropdown">
            <p-dropdown [options]="shellConstant.manageClients.mangeClientList['accessType']" placeholder=" "
                        formControlName="accessType" optionLabel="label">
            </p-dropdown>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length + 1" class="sdps-text-center"
            [innerHTML]="shellConstant.manageUserPage.noRecords"></td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td class="col-1" *ngIf="this.getOrgId === 0"></td>
        <ng-container *ngFor="let col of columns">
          <td *ngIf="col.field !== 'actions'" [ngClass]="col.field === 'clientName' ? 'word-break' : 'sdps-truncate--ellipses'">
            <span *ngIf="col.field === 'clientName'" class="ruleGrpLinks cursor" (click)="editClient(rowData['orgId'])"
              [innerHTML]="rowData['k4ClientName']"></span>
            <span *ngIf="col.field === 'custId'" [innerHTML]="rowData[col.field]"></span>
            <ng-container *ngIf="col.field === 'masterAccount'">
              <div *ngFor="let obj of rowData.clientMasterAccounts">
                <div *ngIf="rowData.clientMasterAccounts.length === 1">{{obj.masterAccountNbr}} ({{obj.accountType}})</div>
                <div *ngIf="rowData.clientMasterAccounts.length > 1 && obj.accountType === shellConstant.k4Text">
                  {{obj.masterAccountNbr}} ({{obj.accountType}})</div>
              </div>
            </ng-container>
            <span
              *ngIf="col.field !== 'clientName' && col.field !== 'activeStatus' && col.field !== 'custId' && col.field !== 'modifiedDate' && col.field !== 'applications'"
              [innerHTML]="rowData[col.field]"></span>
            <span *ngIf="col.field === 'applications'" [innerHTML]="removeEmptyValue(rowData[col.field])"></span>
            <span *ngIf="col.field === 'activeStatus'">
              <span class="status sdps-p-around-xx-large" ngClass="{{rowData[col.field] | lowercase}}">
                <span class="sdps-m-left_large" [innerHTML]="rowData[col.field]"></span>
              </span>
            </span>
            <span *ngIf="col.field === 'modifiedDate'"
              [innerHTML]="rowData[col.field]? moment(rowData[col.field]).format('MM/DD/YYYY') : ''"></span>
          </td>
          <td *ngIf="col.field === 'actions' && toggle && this.getOrgId === 0" [ngClass]="'w-200-px'"></td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
  </form>

  <p-paginator class="common-pagination" [rows]="pageObject.pageSize" [totalRecords]="paginationCount"
  (onPageChange)="paginate($event);" #p></p-paginator>

</div>

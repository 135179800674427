<ems-table-record-count [count]="totalRecords" (emitter)="receiveDataFromChild($event);"
                        *ngIf="employees" [selectedCount]="" [empIds]='' [showHeaderIcons]="false"></ems-table-record-count>

<form [formGroup]="empAccountForm">
  <p-treeTable [value]="employees" [columns]="empAccTableCols" selectionMode="checkbox" [(selection)]="selectedArr" metaKeySelection=true
      dataKey="empId" class="common-data-table" #dt [lazy]="true" sortMode="multiple" [multiSortMeta]="multiSortMeta" (onLazyLoad)="customSort($event)" [customSort]="true">
      <ng-template pTemplate="header" let-columns>
        <tr class="filter-header-input">
          <th class="table-checkbox-selection filter-icon">
            <a (click)="toggleFunction()" class="cursor" href="javascript:void(0)">
              <em class="pi pi-filter"></em>
            </a>
          </th>
          <th *ngFor="let col of columns" [ttSortableColumn]="col.field" [ttSortableColumnDisabled]="col.field === 'ruleIds'">
            <span [innerHTML]="col.header"></span>
            <p-treeTableSortIcon [field]="col.field" *ngIf="(col.field !== 'ruleIds')"></p-treeTableSortIcon>
          </th>
          <th *ngIf="toggle" class="w-200-px"></th>
        </tr>
        <tr *ngIf="toggle" class="filtered-row-input">
          <th></th>
          <th class="sdps-p-left_small sdps-m-bottom_small" *ngFor="let col of columns ;let i = index;">
            <input pInputText formControlName="empId" class="w-100-per" type="text" name="empIdFilter" [maxLength]="35"
                   *ngIf="col.field === 'empId'" emsEmployeeIdPatterncheck (keydown.enter)="!(filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                   [ngClass]="{'invalidField': (filterBtnDisableForEmpId || invalidEmpId)}">
            <input pInputText formControlName="acctNum" class="w-100-per" type="text" name="acctNumFilter" (keydown.enter)="!(filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                   *ngIf="col.field === 'subAcctNum'" [maxLength]="8" [ngClass]="{'invalidField': isAccNumInvalid}"
                   onkeypress="return (event.which >= 48 && event.which <= 57)">
            <input pInputText formControlName="firstName" class="w-100-per" type="text" name="firstNameFilter" (keydown.enter)="!(filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                   *ngIf="col.field === 'firstName' && businessUnit !== getSharedConstant.rbsBUText"  [maxLength]="30"
                   [ngClass]="{'invalidField': (filterBtnDisableForFN || invalidFN)}">
            <input pInputText formControlName="lastName" class="w-100-per" type="text" name="lastNameFilter" (keydown.enter)="!(filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                   *ngIf="col.field === 'lastName' && businessUnit !== getSharedConstant.rbsBUText"  [maxLength]="30"
                   [ngClass]="{'invalidField': (filterBtnDisableForLN || invalidLN)}">
            <input pInputText formControlName="acctHolderFn" class="w-100-per" type="text" name="acctHolderFNFilter" (keydown.enter)="!(filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                   *ngIf="col.field === 'acctHolderFn' && businessUnit !== getSharedConstant.rbsBUText" [maxLength]="30"
                   [ngClass]="{'invalidField': (filterBtnDisableForacctHolderFN || invalidacctHolderFN)}">
            <input pInputText formControlName="acctHolderLn" class="w-100-per" type="text" name="acctHolderLNFilter" (keydown.enter)="!(filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                   *ngIf="col.field === 'acctHolderLn' && businessUnit !== getSharedConstant.rbsBUText" [maxLength]="30"
                   [ngClass]="{'invalidField': (filterBtnDisableForacctHolderLN || invalidacctHolderLN)}">
            <input pInputText formControlName="firstName" class="w-100-per" type="text" name="firstNameFilter" (keydown.enter)="!(filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                   *ngIf="col.field === 'firstName' && businessUnit === getSharedConstant.rbsBUText" emsNamePatternCheck [maxLength]="30"
                   [ngClass]="{'invalidField': (filterBtnDisableForFN || invalidFN)}">
            <input pInputText formControlName="lastName" class="w-100-per" type="text" name="lastNameFilter" (keydown.enter)="!(filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                   *ngIf="col.field === 'lastName' && businessUnit === getSharedConstant.rbsBUText" emsNamePatternCheck [maxLength]="30"
                   [ngClass]="{'invalidField': (filterBtnDisableForLN || invalidLN)}">
            <input pInputText formControlName="acctHolderFn" class="w-100-per" type="text" name="acctHolderFNFilter" (keydown.enter)="!(filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                   *ngIf="col.field === 'acctHolderFn' && businessUnit === getSharedConstant.rbsBUText" [maxLength]="30" emsNamePatternCheck
                   [ngClass]="{'invalidField': (filterBtnDisableForacctHolderFN || invalidacctHolderFN)}">
            <input pInputText formControlName="acctHolderLn" class="w-100-per" type="text" name="acctHolderLNFilter" (keydown.enter)="!(filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                   *ngIf="col.field === 'acctHolderLn' && businessUnit === getSharedConstant.rbsBUText" [maxLength]="30" emsNamePatternCheck
                   [ngClass]="{'invalidField': (filterBtnDisableForacctHolderLN || invalidacctHolderLN)}">
          </th>
          <th class="sdps-p-right_none w-200-px">
            <button class="buttons button-filter sdps-m-bottom_small" [disabled]="!empAccountForm.dirty && !isSortingChanged"
                    [innerHTML]="getSharedConstant.filterButton.clearAllButton"
                    (click)="filterClicked? clickedClearButton(isSortingChanged) : resetForm(isSortingChanged); clearSuccessAndErrorMessages();"></button>
            <button class="buttons button-save button-filter sdps-m-bottom_small" (click)="applyFilter()"
                    [disabled]="filterBtnDisableForFN || filterBtnDisableForLN || filterBtnDisableForEmpId || filterBtnDisableForacctHolderFN || filterBtnDisableForacctHolderLN || empAccountForm.invalid || isAccNumInvalid"
                    [innerHTML]="getSharedConstant.filterButton.applyFilterButton"></button>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
          <tr>
              <td [attr.colspan]="columns.length + 2" class="sdps-text-center sdps-font-weight-bold">
                  {{getSharedConstant.noRecords}}
              </td>
          </tr>
      </ng-template>

    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
      <tr *ngIf="isParentNode(rowNode)">
        <td class="table-checkbox-selection filter-icon"></td>
        <td *ngFor="let col of columns; let i = index">
          <p-treeTableCheckbox [value]="currentParentNode" class="emtr-checkbox sdps-p-right_x-small" *ngIf="i === 0 && businessUnit !== 'RBS' && (rowNode.parent.children[0] === rowNode.node || rowNode.parent.data[col.field] === null)"
                               (click)="getSelectedEmpAccounts('parent',rowNode.node,$event)" [disabled]="rowNode.parent.data[col.field] === 0 || rowNode.parent.data[col.field].indexOf('null') !== -1" [ngClass]="{'check-disabled': rowNode.parent.data[col.field] === 0 || rowNode.parent.data[col.field].indexOf('null') !== -1}"></p-treeTableCheckbox>
          <p-treeTableCheckbox [value]="rowNode" class="emtr-checkbox sdps-p-right_x-small" *ngIf="(i === 0 && businessUnit === 'RBS') || (i === 1 && businessUnit !=='RBS')" (click)="getSelectedEmpAccounts('child',rowNode.node,$event)"></p-treeTableCheckbox>
          <span *ngIf="rowNode.parent.data[col.field] === null || (i === 0 && businessUnit !== 'RBS' && (rowNode.parent.children[0] === rowNode.node) && (rowNode.parent.data[col.field] !== 0) && (rowNode.parent.data[col.field].indexOf('null') === -1))">{{rowNode.parent.data[col.field]}}</span>
          <span *ngIf="(i === 0 && businessUnit === 'RBS') || (i === 1 && businessUnit !== 'RBS')">{{rowData[col.field]}}</span>
          <span *ngIf="col.field === 'firstName' || col.field === 'lastName' || col.field === 'acctHolderFn' || col.field === 'acctHolderLn' || col.field === 'masterSubTypes'">{{rowData[col.field]}}</span>
          <span *ngIf="col.field === 'ruleIds' || col.field === 'groupIds'">{{rowData[col.field] && rowData[col.field].length}}</span>
          <span *ngIf="col.field === 'acctHolderName'">{{rowData['acctHolderLn']}} {{rowData['acctHolderFn']}}</span>
        </td>
        <td *ngIf="toggle"></td>
      </tr>
    </ng-template>
  </p-treeTable>
</form>

<p-paginator [rows]="noOfRecords" [totalRecords]="totalRecords" (onPageChange)="paginate($event)" class="common-pagination" #p></p-paginator>

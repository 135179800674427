<div class="custom-shared-modal">
  <sdps-modal id='view-history-modal' [sdpsTitle]="historyTitle" class="sdps-custom-modal" [size]="'flex--large'"
                   (close)="closeHistoryModal()">
    <div slot="modal-body">
      <div class="view-history-container modal-container">
        <sdps-notification type="error" class="sdps-m-top_none" *ngIf="!historyRecords">
          <div class="sdps-notification__text">
            {{searchHistoryInput}}
          </div>
        </sdps-notification>

        <ems-table-record-count class="tbl-rec-count" [count]="totalElements" (emitter)="receiveDataFromChild($event);paginator.changePage(0);"
          [showHeaderIcons]="true" [showTotal]='historyData' *ngIf="historyData"></ems-table-record-count>

        <!-- Bottom Grid -->
        <div class="sdps-m-around_none" *ngIf="historyRecords">
          <!-- <div class="sdps-grid-container">
            <p-table [columns]="historyColumns" [value]="historyData" [sortOrder]=1 sortMode="multiple" class="common-data-table">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngClass]="col.field === 'tickerSymbol' ? 'search-text-highlight' : null">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" [attr.arialabel]="commonConstants.sorting.sortText" [attr.ariaLabelDesc]="commonConstants.sorting.descText"
                      [attr.ariaLabelAsc]="commonConstants.sorting.ascText"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
            </p-table>
          </div> -->

          <div class="sdps-grid-container" *ngIf="historyRecords">
            <p-table [columns]="historyColumns" [value]="historyData" class="common-data-table w-100-per">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngClass]="col.field === 'tickerSymbol' ? 'search-text-highlight' : null">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" [attr.arialabel]="commonConstants.sorting.sortText" [attr.ariaLabelDesc]="commonConstants.sorting.descText"
                      [attr.ariaLabelAsc]="commonConstants.sorting.ascText"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="emptyMessage">
                  <td [attr.colspan]="columns.length + 2" class="sdps-text-center" [innerHTML]="emptyMessage | titlecase"></td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns">
                    <div [ngSwitch]="checkTypeOf(rowData[col.field])">
                      <div *ngSwitchCase="'string'">
                        <span *ngIf="col.field !== 'date'">{{ rowData[col.field] }}</span>
                        <span *ngIf="col.field === 'date'">{{ rowData[col.field] }}</span>
                      </div>

                      <div *ngSwitchCase="'number'">
                        {{ rowData[col.field] }}
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
      <p-paginator class="common-pagination" [rows]="param.pageSize" [totalRecords]="totalElements" (onPageChange)="paginate($event)"
        #p></p-paginator>
    </div>

    <!-- Footer Buttons -->
    <div slot="modal-footer">
      <div class="step-action-buttons sdps-pull-left">
        <button class="buttons" type="button" (click)="sharedService.closeModal('view-history-modal');closeHistoryModal()">Close</button>
      </div>
    </div>
  </sdps-modal>
</div>

import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import { reportListTableHeaders } from '@shell_components/model/report-list-table-header';
import { AppService } from '@core_services/app.service';
import { ReportsService } from '@core_services/report/reports.service';
import { ShellPagination, ShellPaginationValues } from '@shell_components/model/pagination-model';
import { Observable, Observer, Subscription } from 'rxjs';
import { environment } from '@environment/environment';
import * as temp from 'moment';
const moment = temp['default'];
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AddlinkService } from '@ems/addlink';
import { SharedService, ConfigUrlService, SharedConstant } from '@ems/shared';
import { commonConstants } from '@shell_components/constants/commonConstants';

@Component({
  selector: 'app-preset-reports',
  templateUrl: './preset-reports.component.html',
  styleUrls: ['./preset-reports.component.scss']
})
export class PresetReportsComponent implements OnInit, OnDestroy {

  private currentOrgSubscription: Subscription;
  sharedConstant = SharedConstant;
  reportListHeaders: any = reportListTableHeaders;
  pageObject: ShellPagination = ShellPaginationValues;
  getConstant = ShellConstant;
  expandClicked = false;
  showDropDown = false;
  categoryDropDownData: any;
  selectedCategories: any;
  noOfRecords: any = 15;
  reportsList: any[];
  totalRecords: any;
  paginationCount: any;
  getOrgId: any;
  env = environment;
  showDetailSection = false;
  selectedReportInfo: string;
  cancelButtonClick = true;
  localObserver: any;
  confirmMessage: any;
  reportOptionalFieldsError: any;
  moment = moment;
  errorType: any;
  reportFormIsDirty: any;
  isInValidFromDate = false;
  isPastFromDate = false;
  isInValidToDate = false;
  isFutureFromDate = false;
  isFutureToDate = false;
  isPastToDate = false;
  pastDateErrorMessage: any;
  endDateLessThanStartDate = '';
  dateRangeConstraint = '';
  jobName: any;
  schErrorMsg = [];
  getCommConst = commonConstants;
  reportName: any;
  @ViewChild('toastMessage', { static: true }) msgToast: any;
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  configURL = this.config.setApplicationUrl();
  showClientMsg: any;
  externalAccess = false;
  selectedReport = '';
  warnMsg: boolean;

  /* eslint-disable-next-line max-len */
  constructor(private activatedRoute: ActivatedRoute, private shellService: AppService, public reportService: ReportsService, private router: Router, public messageService: MessageService, public addlinkService: AddlinkService, public config: ConfigUrlService, public sharedService: SharedService, @Inject('entitlementConst') public entitlementConst) {
    this.router.events.subscribe((url: any) => {
      if (url.url && url.url === '/ems/reports/preset-reports') {
        this.expandClicked = false;
        this.reportName = '';
        this.reportService.setReportName('');
        this.reportListHeaders = reportListTableHeaders;
        this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.reportService.clearMessage();
    this.reportService.setReportName('');
    this.expandClicked = false;
    this.reportService.invalidSymbol = this.reportService.invalidCusip = false;
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
  }

  ngOnInit() {
    const activatedReport = this.reportService.getReportName();
    if (activatedReport) {
      this.loadSelectedReports(activatedReport);
    }
    this.pageObject.pageNo = 0;
    this.pageObject.pageSize = 15;
    this.currentOrgSubscription = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.getOrgId = clientInfo && clientInfo.orgId;
        this.shellService.setCurrentOrg(this.getOrgId);
        clientInfo.externalAccess && clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
        this.reportService.orgId = this.getOrgId;
        this.reportService.clientName = clientInfo.clientName;
        this.reportService.clientId = clientInfo.clientId;
        this.reportService.businessUnit = clientInfo.businessUnit;
        this.getReportsList();
      }
    });
    this.categoryDropDownData = this.getConstant.reports.presetReports.reportCategoryDropDown;
    this.confirmMessage = this.confirmationMessage.confirmMessage;

    this.reportService.schErrorMsg.subscribe(value => {
      this.schErrorMsg = value;
    });
  }

  getReportsList() {
    this.msgToast.pushMessage('', '');
    if (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, this.entitlementConst.navUUID.accountSummary.uuid, '')) {
      this.reportService.getReports(this.pageObject).subscribe((reports) => {
        this.reportsList = reports.data.content;
        this.reportService.reportsArray.next(reports.data.content);
        this.totalRecords = this.sharedService.totalElementsWithCommas(reports.data.totalElements);
        this.paginationCount = reports.data.totalElements;
      });
    }
  }

  selectCategory() {     /* Clicking Div to Open and close the Category DropDown */
    this.showDropDown = !this.showDropDown;
  }

  showSelectedCategory(data) {   /* To display the selected category in the dropdown  */
    this.selectedCategories = data;
    this.showDropDown = false;
  }

  calendarValidation(value) {
    if (value.dateLabel === this.getConstant.fromDate) {
      this.isPastFromDate = value.isPastDate;
      this.isFutureFromDate = value.isFutureDate;
    } else if (value.dateLabel === this.getConstant.toDate) {
      this.isPastToDate = value.isPastDate;
      this.isFutureToDate = value.isFutureDate;
    }
    if (this.isPastToDate || this.isPastFromDate) {
      this.setPastDateErrorMessage(this.reportName);
    }
  }

  throwDateError(value) {
    if(!this.warnMsg){
    this.msgToast.pushMessage('', '');
    }
    this.endDateLessThanStartDate = value;
    this.dateRangeConstraint = '';
  }

  throwDateRangeError(value) {
    this.dateRangeConstraint = value;
  }

  setPastDateErrorMessage(reportName) {
    if (reportName === this.getConstant.reports.OpenOrderDetails.openOrder) {
      this.pastDateErrorMessage = this.getConstant.reports.OpenOrderDetails.inValidPastDateEntered;
    } else {
      this.pastDateErrorMessage = this.getConstant.reports.RulesDetails.inValidPastDateEntered;
    }
  }

  /* Clicking on the Reports names to open the corresponding Report detail section */
  loadSelectedReports(selectedReport) {
    /* Clearing Success Message while selecting report name in the table*/
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);

    /* reportFormIsDirty value will be set in makeFormDirty method in report service. When the filter fields are edited
    user should be prompted with confirmation msg before changing the report */
    if (!this.reportFormIsDirty) {
      /*Freeze the global client selector when the report is selected*/
      if (this.getOrgId || (!this.getOrgId && (selectedReport === 'ADDLink Usage' || selectedReport === 'ADDLink Requests' || selectedReport === 'Rule Audit'))) {
        this.sharedService.disableClientSelector.next(true);
      }

      this.messageService.clear();
      this.clearErrorMsg();
      this.reportService.errorInEmpLastName = false;
      this.reportService.errorInAccntHolderLastName = false;
      this.reportService.invalidSymbol = this.reportService.invalidCusip = false;
      this.reportService.selectedReport = selectedReport;
      this.reportService.setReportName(selectedReport);
      const navigate = this.getConstant.reports[selectedReport.replace(/[\./ ,:-]+/g, '')].routerLink;
      this.showDetailSection = this.expandClicked = true;
      this.selectedReportInfo = selectedReport;
      this.reportName = selectedReport;
      this.reportService.isroutedAfterApi = !this.reportService.isroutedAfterApi;
      this.router.navigate(['./' + navigate], {relativeTo: this.activatedRoute});
      if (this.reportsList) {
        this.reportService.reportsArray.next(this.reportsList);
      }
      this.reportListHeaders = [{field: 'name', header: 'Reports'}];
    }
  }

  /* Closing the Slider and Loading the Report Landing Page */
  displayLandingPage() {
    this.expandClicked = false;
    this.reportListHeaders = reportListTableHeaders;
    this.selectedReportInfo = '';
    this.getReportsList();
  }

  expandReportDetailsSection() {
    if (this.selectedReportInfo && this.expandClicked) {
      this.reportName = '';
      this.expandClicked = false;
      this.reportListHeaders = reportListTableHeaders;
      this.selectedReportInfo = '';
      this.reportOptionalFieldsError = '';
      this.reportService.invalidSymbol = this.reportService.invalidCusip = false;
      this.reportService.errorInEmpLastName = false;
      this.reportService.errorInAccntHolderLastName = false;
      this.clearErrorMsg();
      this.messageService.clear();
    }
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
    this.router.navigate(['/ems/reports/preset-reports']);
  }

  cancelRunReport($event) {
    this.cancelButtonClick = $event;
    if (this.cancelButtonClick) {
      this.confirmMessage.message = this.getConstant.confirmationMessage.cancelBtnClickMsg;
      Observable.create((observer: Observer<boolean>) => {
        this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
      }).subscribe(accept => {
        if (accept) {
          this.reportName = '';
          this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
          this.reportOptionalFieldsError = '';
          this.reportFormIsDirty = false;
          this.expandReportDetailsSection();
          this.msgToast.pushMessage('', '');
          this.clearErrorMsg();
          this.reportService.errorInEmpLastName = false;
          this.reportService.errorInAccntHolderLastName = false;
          this.reportService.invalidSymbol = this.reportService.invalidCusip = false;
          this.router.navigate(['/ems/reports/preset-reports']);
        }
      });
    }
  }

  changeReportConfirmation(val) {
    this.confirmMessage.message = this.getConstant.confirmationMessage.menuClickMsg;
    Observable.create((observer: Observer<boolean>) => {
      this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }).subscribe(accept => {
      if (accept) {
        this.reportFormIsDirty = false;
        if (val === 'loadReport') {
          this.loadSelectedReports(this.selectedReport);
        } else if (val === 'expandReport') {
          this.expandReportDetailsSection();
        }
      }
    });
  }

  canDeactivate(event, observer) {
    if (this.confirmationMessage) {
      this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.getConstant.confirmationMessage.menuClickMsg;
    }
    if (this.expandClicked && this.reportFormIsDirty) {
      this.localObserver = observer;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  /*Displaying the ErrorMessage if Invalid Value entered while doing Run Report */
  showErrorMessage(e) {
    this.errorType = typeof (e) === 'string';
    this.reportOptionalFieldsError = e;
    if (this.errorType) {
      this.getSaveReportMessage(e);
    }
  }

  /* If ReportDetails form is Dirty calling this method for canDeactivate Functionality */
  reportDetailsForm() {
    this.reportFormIsDirty = true;
  }

  getSaveReportMessage(message) {
    this.msgToast.pushMessage('info', message);
  }

  clearErrorMsg() {
    this.reportOptionalFieldsError = '';
    this.isInValidFromDate = this.isInValidToDate = this.isPastFromDate = this.isPastToDate = this.isFutureFromDate = this.isFutureToDate = false;
    this.schErrorMsg = [];
    this.endDateLessThanStartDate = '';
    this.dateRangeConstraint = '';
  }

  clearDateErrors() {
    this.isInValidFromDate = this.isInValidToDate = this.isPastFromDate = this.isPastToDate = this.isFutureFromDate = this.isFutureToDate = false;
    this.endDateLessThanStartDate = '';
    this.dateRangeConstraint = '';
  }
}

import {EventEmitter, Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EmtrService {
  currentOrg: any = new BehaviorSubject(null);
  userPolicy = new BehaviorSubject(null);
  public clientInformation = new BehaviorSubject(null);
  private environment: any;
  clientOrgId: any;
  showSchError = new EventEmitter<boolean>();
  schErrorMsg = new EventEmitter<string[]>();

  constructor() {
    console.log('EmtrService - const');
  }

  setEnvPath(env) {
    this.environment = env;
  }

  getEnvPath() {
    return this.environment;
  }

  setCurrentOrg(org) {
    this.clientOrgId = org;
  }

  retrieveOrgId() {
    return this.clientOrgId;
  }

  nameAndPlanIdValidation(prevMessage, res, errorMsg, form, getConstant) {
    let validationfield;
    const grpName = 'groupName';
    const planIdTxt = 'planId';
    if (!res.data.validEmpGroup) {
      if (prevMessage && errorMsg.indexOf(prevMessage) !== -1) {
        errorMsg.splice(errorMsg.indexOf(prevMessage), 1);
      }
      if (res.data.message === getConstant.manageEmployeeGroups.empGroup10b5Msg.empGrpExistTxt) {
        form.controls[grpName].setErrors({incorrect: true});
        validationfield = 'emp-grp';
        res.data.message = getConstant.rulesConstant.ruleNameMsg.the + '<strong>' + ' "' + form.get('groupName').value + '" ' + '</strong>' + res.data.message;
      } else if (res.data.message === getConstant.manageEmployeeGroups.empGroup10b5Msg.bothNameAndPlanId) {
        form.controls[grpName].setErrors({incorrect: true});
        form.controls[planIdTxt].setErrors({incorrect: true});
        validationfield = 'both';
      } else if (res.data.message === getConstant.manageEmployeeGroups.empGroup10b5Msg.planIdExitsTxt) {
        form.controls[planIdTxt].setErrors({incorrect: true});
        validationfield = 'planId';
      }
      errorMsg.push(res.data.message);
      this.schErrorMsg.emit(errorMsg);
      this.showSchError.emit(true);
      return validationfield;
    } else {
      form.controls[grpName].setValidators(null);
      form.controls[planIdTxt].setValidators(null);
      form.controls[grpName].updateValueAndValidity();
      form.controls[planIdTxt].updateValueAndValidity();
      errorMsg = [];
      validationfield = '';
      this.schErrorMsg.emit(errorMsg);
      this.showSchError.emit(false);
      return validationfield;
    }
  }

  setModifiedRecords(modifiedSubAccnts, subAccntNumbers, employeeAccounts) {
    const localModifiedSubAccntArr = [];
    if (modifiedSubAccnts.length === 0 && subAccntNumbers && subAccntNumbers.length > 0 ) {
      employeeAccounts.forEach((val, index) => {
        if (val.parent && subAccntNumbers.indexOf(val.data.subAcctNum) !== -1) {
          const checkNullEmpId = (val.parent.data.empId && val.parent.data.empId.indexOf('null') === -1) ? val.parent.data.empId : '';
          const finalSubAccnt = {
            empId: checkNullEmpId,
            subAcctNum: val.data.subAcctNum
          };
          localModifiedSubAccntArr.push(finalSubAccnt);
        }
      });
      return localModifiedSubAccntArr;
    }
  }

  formFundFamily(indSecs) {
    const fundFamilyIds = [];
    if (indSecs.length > 0) {
      indSecs.forEach((val) => {
        fundFamilyIds.push({id: val.id});
      });
    }
    return fundFamilyIds;
  }
}

import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  ClientSelection,
  ClientSelectionTableHeaderForMasterGrp
} from '../../models/client-selection.model';
import {CustomSortService, SharedService, SharedConstant} from '@ems/shared';
import {EmtrPagination, EmtrPaginationValues} from '../../models/pagination-model';
import {RuleService} from '../../services/rule-service/rule.service';
import {emtrConstant} from '../../constants/emtrConstants';
import * as temp from 'moment';
const moment = temp['default'];
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {FormDataService} from '@ems/shared';
import {MessageService} from 'primeng/api';
import {Paginator} from 'primeng/paginator';

@Component({
  selector: 'tr-client-selection-table',
  templateUrl: './client-selection-table.component.html'
})
export class ClientSelectionTableComponent implements OnInit {

  @ViewChild('p') paginator: Paginator;

  @Output() selectedClients: any = new EventEmitter();
  @Output() rowSelected: any = new EventEmitter();
  @Output() selectedMasterAccounts: any = new EventEmitter();
  @Output() unAssignSelectedMstrAccts: any = new EventEmitter();
  @Output() newlySelectedMasterAccounts: any = new EventEmitter();
  @Output() clientsSelection: any = new EventEmitter();
  @Input() assignMasterAcctFormData: any;
  @Input() moduleType: any;
  @Input() mstrAcctData: any;

  getSharedConstant = SharedConstant;
  clientSelectionTableHeader: ClientSelection[] = ClientSelectionTableHeaderForMasterGrp;
  clientList = [];
  pageObject: EmtrPagination = EmtrPaginationValues;
  multiSortMeta: any = [];
  emtrConstant = emtrConstant;
  moment = moment;
  totalClients: any = 0;
  toggle = false;
  clientsForGlobalRule: UntypedFormGroup;
  selectedOrgIDArr: any = [];
  selection = false;
  unAssignOrgIdsArray: any[] = [];
  unAssignOrgIds: any[] = [];
  assignFormData: any;
  filterBtnDisable = false;
  isfilterClicked = false;
  allClientInfo: any = {};
  selectedMastrArr = [];
  selectedArr = [];
  getCompanyGrpEmpCount: any = 0;
  getCompanyGrpAccCount: any = 0;
  chkCompanyGrp: any;
  validateCompanyGrp: any;
  errMsg = [];
  invalidMasterAcc = false;
  selectedNewRecords = [];
  alreadySelectedRemoval: any = [];
  subAccntNewlySelected: any = [];
  unModifiedRecords: any = [];
  isSortingChanged = false;
  defaultSortDataField = {field: 'updTs', order: -1};
  sortClearButtonReset = false;
  initialLoad = false;
  constructor(private fb: UntypedFormBuilder, @Inject('entitlementConst') public entitlementConst, public sharedService: SharedService,
              public customSortService: CustomSortService, public ruleService: RuleService, public formDataService: FormDataService,
              private el: ElementRef, public messageService: MessageService) {
    this.assignFormData = this.formDataService.getFormData();
  }

  ngOnInit() {
    this.initialLoad = true;
    this.clientsForGlobalRule = this.fb.group({
      masterAccount: ['']
    });
    this.pageObject.pageNo = 0;
    this.pageObject.pageSize = 15;
    this.allClientInfo = this.emtrConstant.ruleSteps.step3.allClientInfo;
    this.multiSortMeta.push(this.defaultSortDataField);
    this.onChanges();
    this.resetFilterForm();
  }

  resetFilterForm() {
    this.sharedService.resetFilterForm.subscribe(val => {
      /* if user changes the tab then it will reset the filter form for previous active tab but won't make any API call for that */
      if (val.tabChange && this.clientsForGlobalRule.dirty) {
        this.clientsForGlobalRule.reset();
        this.sharedService.clearValidationFields();
        this.clientsForGlobalRule.markAsPristine();
        this.filterBtnDisable = this.sharedService.validationFields.filterBtnDisableForMasterAcc = false;
        this.invalidMasterAcc = this.sharedService.validationFields.invalidMasterAcc = false;
      }
      /* if user clicks to previous active tab then only it will make API call. API call will happen when toggle has been clicked. */
      if (val.tabName === this.sharedService.getSharedConstant.MGP.employeeOrParticipant && (this.toggle || this.isfilterClicked)) {
        this.toggle = false;
        this.isfilterClicked = false;
        this.getClients();
      }
    })
  }

  setPaginationVal() {
    this.pageObject.pageNo = 0;
    if (this.paginator) {
      this.paginator.changePage(0);
    }
    this.getClients();
  }

  customSort(event) {
    if (event.multiSortMeta) {
      const sortArr = this.customSortService.customSort(event);
      if (this.pageObject.sortField !== sortArr) {
        this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
        this.pageObject.pageNo = 0;
        this.pageObject = {
          pageNo: this.pageObject.pageNo,
          pageSize: this.pageObject.pageSize,
          sortField: sortArr
        };
        this.setPaginationVal();
        this.sortClearButtonReset = false;
        if (!this.initialLoad) {
          this.sharedService.clearSuccessMsgs(this.messageService, true);
        } else {
          this.initialLoad = false;
        }
      }
    }
  }

  getClients() {
    let filterObj = {};
    if (!this.isfilterClicked) {
      filterObj = {type: this.getSharedConstant.manageMasterGroups.acctTypeMSTR};
    } else {
      filterObj = {
        type: this.getSharedConstant.manageMasterGroups.acctTypeMSTR,
        subAcctNum: this.clientsForGlobalRule.get('masterAccount').value ? this.clientsForGlobalRule.get('masterAccount').value : null
      };
    }

    this.sharedService.getEmployeeList(this.pageObject, filterObj, null, null).subscribe((response) => {
      this.setMasterAccntListData(response);
    });
  }

  setMasterAccntListData(response) {
    if ((this.assignFormData && this.assignFormData.mode === 'EDIT') || (this.assignMasterAcctFormData && this.assignMasterAcctFormData.mode === 'EDIT')) {
      this.formSelectedArr(response.data.content);
    }
    if (response.data !== null) {
      this.clientList = response.data.content;
      this.totalClients = response.data.totalElements;
      this.unModifiedRecords = this.clientList;

      /* Set the selected master account value to the selectedOrgIDArr -> Rule step3 */
      if (this.assignFormData.assignOrgIds && this.assignFormData.assignOrgIds.length > 0) {
        this.assignFormData.assignOrgIds.filter(data => this.selectedOrgIDArr.indexOf(data.subAcctNum) === -1 ? this.selectedOrgIDArr.push(data.subAcctNum) : '');
        /* Set the selected clients value to unAssignOrgIds variable for later comparison, to emit the deselected orgIds */
        this.selectedMastrArr = this.assignFormData.assignOrgIds;
        this.selectedArr = this.assignFormData.employeeAccounts;
      }

      /* Set the selected master account value to the selectedOrgIDArr -> Master Group step2 */
      const localFormData = this.assignMasterAcctFormData ? this.assignMasterAcctFormData.employeeGroupAssignInfo : {};
      if (localFormData && localFormData.employeeAccounts && localFormData.employeeAccounts.length > 0) {
        this.selectedMastrArr = localFormData.employeeAccounts;
        this.selectedArr = localFormData.employeeAccounts;
      }

      const editMasterAccountData = (this.moduleType === this.getSharedConstant.manageMasterGroups.groupTypeMGP) ? this.assignMasterAcctFormData.employeeGroupAssignInfo : this.assignFormData;
      /* eslint-disable-next-line max-len */
      if ((this.selectedNewRecords.length > 0 || this.alreadySelectedRemoval.length > 0 || this.subAccntNewlySelected.length > 0 ||
        (editMasterAccountData && editMasterAccountData.newSelectedRecords && editMasterAccountData.newSelectedRecords.length > 0) ||
        (editMasterAccountData && editMasterAccountData.newSelectedRecords && editMasterAccountData.subAccntNewlySelected.length > 0) ||
        (editMasterAccountData && editMasterAccountData.removedExistingSelected && editMasterAccountData.removedExistingSelected.length > 0)) &&
        (this.moduleType === this.getSharedConstant.manageMasterGroups.groupTypeMGP && this.assignMasterAcctFormData.mode === 'EDIT') || this.moduleType === 'Rules') {

        const formSelectedRecords = (editMasterAccountData.newSelectedRecords && editMasterAccountData.newSelectedRecords.length > 0) ? [...editMasterAccountData.newSelectedRecords] : [];
        const formRemovedRecords = (editMasterAccountData.removedExistingSelected && editMasterAccountData.removedExistingSelected.length > 0) ? [...editMasterAccountData.removedExistingSelected] : [];

        const formMultipleSubAccntSelected = (editMasterAccountData.subAccntNewlySelected && editMasterAccountData.subAccntNewlySelected.length > 0) ? [...editMasterAccountData.subAccntNewlySelected] : [];

        const localSelectedArr = (this.selectedNewRecords.length > 0) ? [...this.selectedNewRecords] : [...formSelectedRecords];
        const localRemovedArr =  (this.alreadySelectedRemoval.length > 0) ? [...this.alreadySelectedRemoval] : [...formRemovedRecords];

        this.selectedNewRecords = (this.selectedNewRecords && this.selectedNewRecords.length > 0) ? this.selectedNewRecords : [...formSelectedRecords];
        this.selectedMastrArr = (this.selectedNewRecords && this.selectedNewRecords.length > 0) ? this.selectedNewRecords : [...formSelectedRecords];
        this.alreadySelectedRemoval = (this.alreadySelectedRemoval && this.alreadySelectedRemoval.length > 0) ? this.alreadySelectedRemoval : [...formRemovedRecords];

        this.subAccntNewlySelected = (this.subAccntNewlySelected && this.subAccntNewlySelected.length > 0) ? this.subAccntNewlySelected : [...formMultipleSubAccntSelected];

        this.emitNewlySelectedRecords();
        if (localSelectedArr.length > 0 || localRemovedArr.length > 0) {
          this.removeOrAddRecords(localSelectedArr, localRemovedArr);
        }
      }
    }
    this.selectedArr = [...this.selectedArr];
    this.selectedArr.forEach(acct => {
      if (this.selectedOrgIDArr.indexOf(acct.subAcctNum) === -1) {
        this.selectedOrgIDArr.push(acct.subAcctNum);
      }
      /* Set the selected clients value to unAssignOrgIds variable for later comparison, to emit the deselected orgIds */
      if (this.unAssignOrgIds.indexOf(acct.subAcctNum) === -1) {
        this.unAssignOrgIds.push(acct.subAcctNum);
      }
    });
    this.selectedMasterAccounts.emit(this.selectedArr);
    this.getSelectedAccCount(this.selectedArr);
  }

  formSelectedArr(masterAcct) {
    /* Set the selected master account value to the selectedOrgIDArr, the selected master account value can be
     retrieved from the ruleIds list sent for each record in the api response */
    let selectedArr = [];
    const id = this.moduleType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.assignMasterAcctFormData.id : this.assignFormData.id;
    masterAcct.forEach((mstrRecord) => {
      mstrRecord.accounts.find(item => {
        const selectedIdArr = this.moduleType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? item.groupIds : item.ruleIds;
        /* Check if the rule id of the the rule that is been edited is present in the ruleIds list of each record and
        if it is present then push it into the selectedOrgIDArr array to populate the selected master account value */
        if (selectedIdArr && selectedIdArr.indexOf(id) !== -1 && selectedArr.indexOf(item) === -1) {
          selectedArr.push(item);
        }
      });
    });
    const accountFormData = this.moduleType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.assignMasterAcctFormData.employeeGroupAssignInfo.employeeAccounts : this.assignFormData.employeeAccounts;
    selectedArr = selectedArr.length === 0 ? accountFormData : selectedArr;
    if (this.selectedArr.length === 0) {
      selectedArr.forEach((account) => {
        this.insertRecordInArr(account);
      });
    } else {
      const newlySelectedArr = selectedArr;
      newlySelectedArr.forEach((account) => {
        this.insertRecordInArr(account);
      });
    }
  }

  insertRecordInSelectedArr(localSelectedArr) {
    localSelectedArr.forEach((account) => {
      this.insertRecordInArr(account);
    });
  }

  insertRecordInArr(account) {
    const index = this.selectedArr.findIndex(x => x.subAcctNum === account.subAcctNum);
    const unassignedIndex = this.unAssignOrgIdsArray.findIndex(x => x === account.subAcctNum);
    if (index === -1 && unassignedIndex === -1) {
      this.selectedArr.push(account);
    }
    if (unassignedIndex !== -1) {
      this.removeRecordFromArr(account);
    }
  }

  removeRecordFromArr(account) {
    const index = this.selectedArr.findIndex(x => x.subAcctNum === account.subAcctNum);
    if (index > -1) {
      this.selectedArr.splice(index, 1);
    }
  }

  getSelectedAccCount(selectedArr) {
    const emitData = {
      selectedOrgIDArr: this.selectedMastrArr,
      unAssignOrgIdsArray: this.unAssignOrgIdsArray,
      selectedArr: (selectedArr)
    };
    this.selectedClients.emit(emitData);
  }

  emitNewlySelectedRecords() {
    if (this.alreadySelectedRemoval.length > 0) {
      this.selectedNewRecords = this.selectedNewRecords.filter((removed) => {
        return !this.alreadySelectedRemoval.find((employee) => {
          return employee.subAcctNum === removed.subAcctNum;
        });
      });
    }

    this.alreadySelectedRemoval.forEach((removed) => {
      removed.selectedAcct = false;
    });

    this.selectedNewRecords.forEach((newSelected) => {
      newSelected.selectedAcct = true;
    });

    if (this.subAccntNewlySelected.length > 0) {
      this.subAccntNewlySelected.forEach((subAcc) => {
        const index = this.alreadySelectedRemoval.findIndex(x => x.subAcctNum === subAcc.subAcctNum);
        if (index !== -1) {
          this.alreadySelectedRemoval.splice(index, 1);
        }
      });
    }

    let localValueIndex = [];
    let localAlreadyRemovedArr = [...this.alreadySelectedRemoval];
    localAlreadyRemovedArr.forEach((selected) => {
      if (selected) {
        localValueIndex = this.unModifiedRecords.filter(item => item.subAcctNum === selected.subAcctNum);
        if (localValueIndex.length > 0 && localValueIndex.every(val => ((this.moduleType === this.getSharedConstant.manageMasterGroups.groupTypeMGP && val.groupIds && val.groupIds.indexOf(this.assignMasterAcctFormData.id) !== -1)
          || (this.moduleType === 'Rules' && val.ruleIds && val.ruleIds.indexOf(this.assignFormData.id) !== -1)))) {
          localAlreadyRemovedArr = localAlreadyRemovedArr.filter((removed) => {
            return !localValueIndex.find((account) => {
              return account.subAcctNum === removed.subAcctNum;
            });
          });
        }
      }
    });

    const localFormData = {
      newSelectedRecords: this.selectedNewRecords,
      removedExistingSelected: this.alreadySelectedRemoval,
      subAccntNewlySelected: this.selectedNewRecords,
      localAlreadyRemovedArr
    };
    this.newlySelectedMasterAccounts.emit(localFormData);
  }

  removeOrAddRecords(localSelectedArr, localRemovedArr) {
    this.insertRecordInSelectedArr(localSelectedArr);
    const removedRecords = [...localRemovedArr];
    removedRecords.forEach((emp) => {
      if (this.selectedArr.findIndex(val => (val.subAcctNum === emp.subAcctNum)) >= 0) {
        this.removeRecordFromArr(emp);
      }
    });
  }

  paginate(e) {
    if (this.pageObject.pageNo !== e.page) {
      this.pageObject.pageNo = e.page;
      this.sharedService.clearSuccessMsgs(this.messageService, true);
      this.getClients();
    }
  }

  toggleFunction() {
    this.toggle = !this.toggle;
  }

  onChanges(): void {
    this.clientsForGlobalRule.get('masterAccount').valueChanges.subscribe(value => {
      /* Disable the Apply filter button if value entered in Master Account field is less than 2 character*/
      this.filterBtnDisable = this.sharedService.validationFields.filterBtnDisableForMasterAcc = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidMasterAcc = this.sharedService.validationFields.invalidMasterAcc = this.sharedService.isValidOrInvalid(value, 'masterAccount', this.clientsForGlobalRule);
      this.sharedService.setErrorMessage(false, this.errMsg);
    });
  }

  clearForm(resetSortFromUI?) {
    this.filterBtnDisable = this.isfilterClicked = false;
    this.pageObject.pageNo = 0;
    this.clientsForGlobalRule.reset();
    this.sharedService.clearSuccessMsgs(this.messageService, true);
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
      this.sortClearButtonReset = true;
    }
    if (!this.sortClearButtonReset) {
      this.getClients();
    }
  }

  applyFilter() {
    this.isfilterClicked = true;
    this.sharedService.clearSuccessMsgs(this.messageService, true);
    this.setPaginationVal();
  }

  getSelectedMasterAccounts(selectedVal) {
    this.clientSelected(selectedVal);
    if ((this.moduleType === 'MGP' && this.assignMasterAcctFormData.mode === 'EDIT') || this.moduleType === 'Rules') {
      this.emitNewlySelectedRecords();
    } else {
      this.getSelectedAccCount(this.selectedArr);
      this.selectedArr.forEach((newSelected) => {
        newSelected.selectedAcct = true;
      });
    }
    this.selectedMasterAccounts.emit(this.selectedArr);
    this.rowSelected.emit();
  }

  clientSelected(data) {
    if (data) {
      /* If the selected value is any other client other than Business Unit, then except BU orgId push orgIds of clients selected  */
      this.selection = false;
      /* When all clients are unchecked, then set the selectedMastrArr to empty */
      if (this.selectedOrgIDArr.length === 0) {
        this.selectedMastrArr = [];
        this.selectedArr = [];
        this.removeAlreadySelected(data);
      } else {
        const localArr = this.selectedMastrArr.filter(x => x.subAcctNum === data.subAcctNum);
        if (localArr.length === 0 && this.selectedOrgIDArr.indexOf(data.subAcctNum) !== -1) {
          this.selectedMastrArr.push(data);
          const selectedArrIndex = this.selectedArr.findIndex(x => x.subAcctNum === data.subAcctNum);
          if (selectedArrIndex === -1) {
            this.selectedArr.push(data);
          }
          const subAccntNewlySelectedIndex = this.subAccntNewlySelected.findIndex(x => x.subAcctNum === data.subAcctNum);
          if (subAccntNewlySelectedIndex === -1) {
            this.subAccntNewlySelected.push(data);
          }
          const index = this.selectedNewRecords.findIndex(x => x.subAcctNum === data.subAcctNum);
          if (index === -1) {
            this.selectedNewRecords.push(data);
          }
        } else {
          const index = this.selectedMastrArr.findIndex(x => x.subAcctNum === data.subAcctNum);
          if (index !== -1) {
            this.selectedMastrArr.splice(this.selectedMastrArr.indexOf(localArr[0]), 1);
          }
          this.removeRecordFromArr(data);
          this.removeAlreadySelected(data);
        }
      }
    } else {
      /* If the table-header checkbox is selected, then except BU orgId push all the other orgIds of clients present on that page  */
      this.selection = !this.selection;
      if (!this.selection) {
        this.selectedOrgIDArr = [];
      }
      const localArr = this.selectedOrgIDArr;
      this.selectedMastrArr = [];
      this.selectedOrgIDArr = [];
      this.selectedArr = [];
      localArr.forEach(val => {
        const account = val.accounts[0];
        this.selectedOrgIDArr.push(account.subAcctNum);
        this.selectedMastrArr.push(account);
        const selectedArrIndex = this.selectedArr.findIndex(x => x.subAcctNum === account.subAcctNum);
        const subAccntNewlySelectedIndex = this.subAccntNewlySelected.findIndex(x => x.subAcctNum === account.subAcctNum);
        if (subAccntNewlySelectedIndex === -1 && selectedArrIndex === -1) {
          this.subAccntNewlySelected.push(account);
        }
        const index = this.selectedNewRecords.findIndex(x => x.subAcctNum === account.subAcctNum);
        if (index === -1 && selectedArrIndex === -1) {
          this.selectedNewRecords.push(account);
        }
        if (selectedArrIndex === -1) {
          this.selectedArr.push(account);
        }
      });
    }
    /* Filter out the orgIds which are present in unAssignOrgIds but not in selectedOrgIDArr, for emitting the orgIds that were deselected */
    this.unAssignOrgIdsArray = this.unAssignOrgIds.filter(x => this.selectedOrgIDArr.indexOf(x) === -1);
    this.emitAssignedAndUnassignedOrgIds();
  }

  removeAlreadySelected(selectedVal) {
    let filteredValue = [];
    if (this.moduleType === this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
      filteredValue = [selectedVal.groupIds && (this.moduleType === this.getSharedConstant.manageMasterGroups.groupTypeMGP && selectedVal.groupIds.indexOf(this.assignMasterAcctFormData.id) !== -1)];
    }
    const index = this.alreadySelectedRemoval.findIndex(x => x.subAcctNum === selectedVal.subAcctNum);
    if (index === -1) {
      this.alreadySelectedRemoval.push(selectedVal);
      const localArr = this.alreadySelectedRemoval;
      if (localArr.findIndex(t => (t.subAcctNum === selectedVal.subAcctNum)) >= 0) {
        this.alreadySelectedRemoval.push(selectedVal);
      }

      const parentIndex = this.selectedNewRecords.findIndex(x => x.subAcctNum === selectedVal.subAcctNum);
      if (parentIndex > -1) {
        this.selectedNewRecords.splice(parentIndex, 1);
      }
    }
    this.alreadySelectedRemoval = this.alreadySelectedRemoval.filter((v, i, a) => a.findIndex(t => (t.subAcctNum === v.subAcctNum)) === i);
  }

  emitAssignedAndUnassignedOrgIds() {
    /* emit the assigned and unassigned orgIds to the parent component */
    const emitData = {
      selectedOrgIDArr: this.selectedMastrArr,
      unAssignOrgIdsArray: this.unAssignOrgIdsArray
    };
    this.selectedClients.emit(emitData);
    this.rowSelected.emit();
    this.selectedArr.forEach((newSelected) => {
      newSelected.selectedAcct = true;
    });
    this.selectedMasterAccounts.emit(this.selectedArr);
  }

  /* Method is called when the Business Unit checkbox on under the Master Groups is selected */
  getDatafromParent(data, type, load) {
    if (type) {
      this.getCompanyGrpEmpCount = data.companyGroupEmp;
      this.getCompanyGrpAccCount = data.companyGroupAcc;
    } else {
      this.chkCompanyGrp = data;
      if (data) {
        if (data.length > 0 && data[0].type === 'ORG') {
          this.validateCompanyGrp = data[0].type;
          /* We are assigning all the selected master account to unAssignSelectedMstrAccts when BU is selected to send
          it as part of the payload  */
          const localUnassigenArr = [];
          if (this.assignFormData.empAccounts) {
            this.assignFormData.empAccounts.filter(x => localUnassigenArr.push(x.subAcctNum));
            this.unAssignSelectedMstrAccts.emit(localUnassigenArr);
            this.unAssignOrgIdsArray = localUnassigenArr;
            this.selectedOrgIDArr = [];
            this.selectedArr = [];
            this.selectedMastrArr = [];
          }
         } else {
          if (!load) {
            this.getSelectedCount(this.selectedOrgIDArr);
          }
        }
      }
    }
    this.validateCompanyGrp = data.length > 0 ? data[0].type : '';
  }

  getSelectedCount(selectedArray) {
    const emitData = {selectedArr: selectedArray};
    this.clientsSelection.emit(emitData);
  }
}

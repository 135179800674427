<sdps-notification type="information" class="sdps-m-top_none" *ngIf="!manageEmpGrpService.orgId">
  <p class="sdps-notification__text" [innerHtml]="getConstant.rulesConstant.ruleGlobalConst.noCientSelected">
  </p>
</sdps-notification>

<ems-confirm-message #empGrpDeleteConfirm></ems-confirm-message>

<ems-toast-message *ngIf="manageEmpGrpService.orgId && removeSuccessMsg" #toastMessage></ems-toast-message>

<sdps-notification type="error" class="sdps-m-top_none" *ngIf="manageEmpGrpService.orgId && (schError.minCharacter === true || schError.invalidCharacter === true)">
  <div class="sdps-notification__text">
    <p [innerText]="sharedConstant.pleaseCorrectFollowingError"></p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngIf="schError.minCharacter" [innerHTML]="sharedConstant.textFieldErrorMsg.minCharLimitErrorMsg"></li>
      <li *ngIf="schError.invalidCharacter" [innerHTML]="sharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName"></li>
    </ul>
  </div>
</sdps-notification>

<!--Download User Guide-->
<ems-download-user-guide *ngIf="(businessUnit && businessUnit !== sharedConstant.rbsBUText)  && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '')"
 [downloadTemplate]="downloadTemplate" [userGuideName]="userGuideName" [gutTrackValue]="ugGutTrack"></ems-download-user-guide>

<div *ngIf="manageEmpGrpService.orgId">
  <h2 class="page-title sdps-m-bottom_large sdps-display-inline-block"
      [innerHTML]="sharedConstant[businessUnit].subTabName"></h2>

  <span class="sdps-m-horizontal_large sdps-display-inline-block client-selection-indicator"
        [ngClass]="this.externalAccess ? 'external-access' : (emtrService.clientInformation | async)?.businessUnit | lowercase"
        [innerHTML]="(emtrService.clientInformation | async)?.k4ClientName">
  </span>
</div>

<div *ngIf="manageEmpGrpService.orgId">
  <button *ngIf="showActionItems" type="button"
          class="buttons button-save sdps-pull-left sdps-m-top_small sdps-m-left_small"
          [routerLink]="sharedConstant[businessUnit].toStep1" (click)="navigateToCreateEmpGroup()">
    <em class="pi pi-plus"></em>
    {{sharedConstant[businessUnit].createBtn}}
  </button>

  <ems-shared-manage-employee-group [showSelection]="false" [getOrgId]='orgId' [tableCountData]="tableCountData" [employees]="employees"
                                    [moduleType]="selectedModuleType" (sendError)="setErrorValue($event)"
                                    (deleteEmployeeGroup)="fetchRuleNamesLinkedToEmpGroup($event)"
                                    (restoreEmployeeGroup)="restoreEmployeeGroup($event)"
                                    (paginateEmployeeList)="paginateEmployeeList($event)" [clientInfo]='clientInfo'
                                    (empGrpEdit)="getEmittedValue($event)" (tableRef)="getTableRef($event);">
  </ems-shared-manage-employee-group>
</div>
